import React, { useContext, useState, useRef } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';

const DialogContext = React.createContext()
export const useDialog = () => useContext(DialogContext);

export const DialogProvider = ({children}) => {

    // Array of objects representing props to pass to dialog component
    const [dialogs, setDialogs] = useState([])

    const createDialog = (options) => {
        const dialog = { ...options, open: true };
        setDialogs((dialogs) => [...dialogs, dialog]);
    };
      
    const closeDialog = () => {
        setDialogs((dialogs) => {
            const latestDialog = dialogs.pop();
            if (!latestDialog) return dialogs;
            if (latestDialog.onClose) latestDialog.onClose();
            return [...dialogs].concat({ ...latestDialog, open: false });
        });
    };

    const contextValue = useRef([createDialog, closeDialog]);

    return (
        <DialogContext.Provider value={contextValue.current}>
            {children}
            {dialogs.map((dialog, idx) => {
                return <DialogInterface key={idx} closeDialog={closeDialog} {...dialog} />
            })}
        </DialogContext.Provider>
    )
}

const DialogInterface = ({...props}) => {
    const {title, body, onAccept, open, closeDialog, onClose, onKill} = props

    return (
        <Dialog open={open} onClose={onClose} onExited={onKill}>
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                {body}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={closeDialog}>Cancel</Button>
                <Button color="primary" onClick={onAccept}>Accept</Button>
            </DialogActions>
        </Dialog>
    )
}