import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"


export const HelpPrompt = ({services, title, item, style={}}) => {

    return (
        <span
            className="help-prompt"
            onClick={(e) => {
                services.help.setHelpConfig(title, item)
            }}
            style={style}
        >
            <FontAwesomeIcon icon={faQuestionCircle} size="sm" fixedWidth />
        </span>
    )
}