import './AssetMenu.css'
import React, { Component, useState } from 'react'

import MenuContainer from '../menu/MenuContainer'
import { getPortfolio, setPortfolio, getSite, setSite } from '../../utils/UrlLocation'
import { useOutletContext } from 'react-router-dom'


export const useAssetSelectionState = () => {

    const [selectedPortfolioId, setSelectedPortfolioId] = useState(getPortfolio)
    const [selectedSiteId, setSelectedSiteId] = useState(getSite)

    const updateSiteId = (newSiteId) => {
        setPortfolio(null)
        setSite(newSiteId)
        setSelectedSiteId(newSiteId)
        setSelectedPortfolioId(null)
    }
    const updatePortfolioId = (newPortfolioId) => {
        setPortfolio(newPortfolioId)
        setSite(null)
        setSelectedPortfolioId(newPortfolioId)
        setSelectedSiteId(null)
    }

    return [
        selectedPortfolioId, selectedSiteId,
        updatePortfolioId, updateSiteId
    ]
}

const AssetSidebar = (props) => {

    const { assetFrameworkServices } = useOutletContext()

    const constructAssetHeirarchy = () => {

        var selectedPortfolioId = props.selectedPortfolioId
        var selectedSiteId = props.selectedSiteId

        var portfolios = assetFrameworkServices.getPortfolios()
        var sites = assetFrameworkServices.getSites()
        
        sites = sites.sort(function(a, b) {
            var nameA = a.name.toUpperCase(); // ignore upper and lowercase
            var nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          
            // names must be equal
            return 0;
        })

        var portfolioHeirarchy = []
        
        portfolios.forEach(function(portfolio) {
            let portfolioId = portfolio._id
            let portfolioName = portfolio.id
            let portfolioNameLong = portfolio.name

            let portfolioSites = sites.filter(x => x.portfolio_id===portfolioId).map(function(site) {
                return {
                    "key": site._id,
                    "label": site.id + " - " + site.name,
                    "meta": site,
                    "selectable": true,
                    "active": site._id===selectedSiteId,
                    "children": null,
                }
            })
            let portfolioTree = {
                "key": portfolioId,
                "label": portfolioName + " - " + portfolioNameLong,
                "meta": portfolio,
                "selectable": true,
                "active": portfolioId===selectedPortfolioId,
                "children": portfolioSites,
            }
            portfolioHeirarchy.push(portfolioTree)
        })

        return portfolioHeirarchy
    }

    return (
        <MenuContainer 
            menuSkeleton={constructAssetHeirarchy()}
            headerName={"Asset Menu"}
            leafNodeClick={(siteNode) => props.updateSelectedSite(siteNode.meta._id)}
            parentNodeClick={(portfolioNode) => props.updateSelectedPortfolio(portfolioNode.meta._id)}
            className={"asset-menu " + (props.className || "")}
            searchable
            {...props.menuProps}
        />
    )

}

export default AssetSidebar