import { Alert } from 'rsuite';


class AlerterClass {

    error(message, timeout=5000) {
        Alert.error(message, timeout)
    }

    inform(message, timeout=5000) {
        Alert.info(message, timeout)
    }

}

const Alerter = new AlerterClass()
export default Alerter