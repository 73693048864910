import { useEffect, useRef, useState } from 'react';

import { Switch, Text, Tooltip } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faPauseCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { formatDistanceToNow } from 'date-fns';


export const AutoRefreshToggle = ({autoRefreshEnabled, enableAutoRefresh, disableAutoRefresh}) => {
    return (
        <span style={{display: "flex", alignItems: "center"}}>
            <Switch
                checked={autoRefreshEnabled}
                onChange={(event) => event.currentTarget.checked ? enableAutoRefresh() : disableAutoRefresh()}
                style={{marginRight: "10px"}}
                size="xs"
            />
            <Text size="xs" color="dimmed">Auto Refresh</Text>
        </span>
    )
}

export const AutoRefreshInfo = ({lastRefreshTs, isLoading, isError, autoRefreshEnabled}) => {
    const [timeSinceLastRefresh, setTimeSinceLastRefresh] = useState("now")
    const test = useRef(lastRefreshTs)
    test.current = lastRefreshTs
    useEffect(() => {
        const intervalId = setInterval(update, 5000)
        return () => {
            clearInterval(intervalId)
        }
    }, [])

    const update = () => {
        setTimeSinceLastRefresh(formatDistanceToNow(test.current, {includeSeconds: true}))
    }

    return (
        <div style={{display: "flex", flexShrink: 0}}>
            <AutoRefreshStatusIcon
                isLoading={isLoading}
                isError={isError}
                isAutoRefreshenabled={autoRefreshEnabled}
            />
            <Text size="xs" color="dimmed">Last Refresh: </Text>
            <Text size="xs" ml={5} mr={5} >{timeSinceLastRefresh} ago</Text>
        </div>
    )
}

const AutoRefreshStatusIcon = ({isLoading, isError, isAutoRefreshenabled}) => {
    let IconComponent = AutoRefreshSuccessIcon
    if (isLoading) IconComponent = AutoRefreshLoadingIcon
    else if (!isAutoRefreshenabled) IconComponent = AutoRefreshPausedIcon
    else if (isError) IconComponent = AutoRefreshErrorIcon

    return (
        <Tooltip
            label={
                <>
                    <AutoRefreshTooltipRow IconComponent={AutoRefreshSuccessIcon} description="Auto refresh is enabled and no errors have been encountered." />
                    <AutoRefreshTooltipRow IconComponent={AutoRefreshPausedIcon} description="Auto refresh is currently paused." />
                    <AutoRefreshTooltipRow IconComponent={AutoRefreshErrorIcon} description="Auto refresh encountered an error and will retry using exponential backoff up to a maximum of 5 minutes." />
                    <AutoRefreshTooltipRow IconComponent={AutoRefreshLoadingIcon} description="Data is loading." />
                </>
            }
            position="left-start"
            wrapLines
            width={300}
            closeDelay={500}
        >
            <IconComponent
                style={{position: "relative", top: "2px", right: "4px"}}
            />
        </Tooltip>
    )
}
const AutoRefreshTooltipRow = ({IconComponent, description}) => {
    return (
        <span style={{display: "flex", alignItems: "top"}}>
            <IconComponent size="xs" style={{marginRight: "4px", position: "relative", top: "4px"}} />
            <Text size="xs">{description}</Text>
        </span>
    )
}

const AutoRefreshSuccessIcon = ({size="md", ...props}) => {
    return (
        <FontAwesomeIcon
            icon={faCheckCircle}
            color="green"
            size={size}
            {...props}
        />
    )
}
const AutoRefreshLoadingIcon = ({size="xl", ...props}) => {
    return (
        <FontAwesomeIcon
            icon={faSpinner}
            color="purple"
            size={size}
            className="spin"
            {...props}
        />
    )
}
const AutoRefreshErrorIcon = ({size="md", ...props}) => {
    return (
        <FontAwesomeIcon
            icon={faExclamationCircle}
            color="red"
            size={size}
            {...props}
        />
    )
}
const AutoRefreshPausedIcon = ({size="xl", ...props}) => {
    return (
        <FontAwesomeIcon
            icon={faPauseCircle}
            color="lightblue"
            size={size}
            {...props}
        />
    )
}