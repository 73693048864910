import React from 'react'

import { PalantirAutocomplete } from '../../../components/input/SelectPicker'
import { PalantirTextField } from '../../../components/input/Text'
import { Plant } from '../../table_configuration/Project'
import { Portfolio, Fund } from '../../table_configuration/Entity'
import { Counterparty } from '../../table_configuration/Entity'
import { PlantLLC } from '../../table_configuration/LLC'

import _ from 'lodash'

const plantSchema = Plant.columnSchema
const llcIdCol = PlantLLC.columnSchema.plantLLCId
const llcNameCol = PlantLLC.columnSchema.plantLLCName
const portfolioIdCol = Portfolio.columnSchema.portfolioId
const portfolioNameCol = Portfolio.columnSchema.portfolioName
const fundIdCol = Fund.columnSchema.fundId
const fundNameCol = Fund.columnSchema.fundName
const counterpartyIdCol = Counterparty.columnSchema.counterpartyId
const counterpartyNameCol = Counterparty.columnSchema.counterpartyName


const Entities = React.memo(function(props) {

    var llc = props.llcs.find(x => x[llcIdCol]===props.plantLlcId) || {}
    var portfolio = props.portfolios.find(x => x[portfolioIdCol]===props.plantPortfolioId) || {}
    var fund = props.funds.find(x => x[fundIdCol]===props.plantFundId) || {}
    var assetManager = props.management.find(x => x[counterpartyIdCol]===props.plantManagementId) || {}

    return (
        <div id="plant-entities" className="entities field-group">
            <div className="header">Entities</div>
            <PalantirAutocomplete
                label="LLC"
                value={{label: llc[llcNameCol], id: llc[llcIdCol]}}
                options={_.sortBy(props.llcs.map(x => {
                    return {
                        label: x[llcNameCol], 
                        id: x[llcIdCol]
                    }
                }), x => x.label)}
                fullWidth
                disableClearable
                className=""
                onUpdate={(value) => props.updatePlant({[plantSchema.llcId]: value.id})}
            />
            <PalantirAutocomplete
                label="Fund"
                value={{label: fund[fundNameCol], id: fund[fundIdCol]}}
                options={_.sortBy(props.funds.map(x => {
                    return {
                        label: x[fundNameCol], 
                        id: x[fundIdCol]
                    }
                }), x => x.label)}
                fullWidth
                disableClearable
                className=""
                onUpdate={(value) => props.updatePlant({[plantSchema.fundId]: value.id})}
            />
            <PalantirAutocomplete
                label="Portfolio"
                value={{label: portfolio[portfolioNameCol], id: portfolio[portfolioIdCol]}}
                options={_.sortBy(props.portfolios.map(x => {
                    return {
                        label: x[portfolioNameCol], 
                        id: x[portfolioIdCol]
                    }
                }), x => x.label)}
                fullWidth
                disableClearable
                className="margined"
                onUpdate={(value) => props.updatePlant({[plantSchema.portfolioId]: value.id})}
            />
            <PalantirTextField
                label="Sub-Portfolio"
                value={props.plantSubPortfolioName}
                fullWidth
                onChange={(x) => props.updatePlant({[plantSchema.subPortfolioName]: x})}
            />
            <PalantirAutocomplete
                label="Asset Management"
                value={{label: assetManager[counterpartyNameCol], id: assetManager[counterpartyIdCol]}}
                options={_.sortBy(props.management.map(x => {
                    return {
                        label: x[counterpartyNameCol],
                        id: x[counterpartyIdCol]
                    }
                }), x => x.label)}
                fullWidth
                disableClearable
                onUpdate={(value) => props.updatePlant({[plantSchema.assetManagementCompanyId]: value.id})}
            />
            <PalantirTextField
                label="Website"
                value={props.plantWebsite}
                fullWidth
                onChange={(x) => props.updatePlant({[plantSchema.website]: x})}
            />
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        prevProps.plantLlcId===nextProps.plantLlcId && 
        prevProps.plantPortfolioId===nextProps.plantPortfolioId &&
        prevProps.plantFundId===nextProps.plantFundId &&
        prevProps.plantManagementId===nextProps.plantManagementId &&
        prevProps.plantSubPortfolioName===nextProps.plantSubPortfolioName &&
        prevProps.plantWebsite===nextProps.plantWebsite
    )
})

export default Entities