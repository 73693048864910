import { PalantirAutocomplete } from '../../../components/input/SelectPicker'
import { injectedChangeLogIdCol } from '../../../hooks/changeLog'
import { RNDErrorInstance } from '../../../utils/databaseAppUtils'
import _ from 'lodash'


export const LLCWithCounterpartyFilter = ({
    contractId, contractRecord, contractLLCIdCol,
    ContractCounterpartyTable, contractCounterpartyRecords, contractCounterpartyIdCol,
    llcIdCol, llcNameCol, allLLCRecords,
    llcCounterpartyJunctionRecords, llcCounterpartyJunctionLLCIdCol, llcCounterpartyJunctionCounterpartyIdCol,
    llcLabel, counterpartyLabel, errors, setErrors, updateContract
}) => {

    const llcId = contractRecord[contractLLCIdCol]
    const llcRecord = allLLCRecords.find(llc => llc[llcIdCol]===llcId) || {}

    return (
        <PalantirAutocomplete
            label={llcLabel}
            value={{label: llcRecord[llcNameCol], id: llcId}}
            options={_.sortBy(allLLCRecords.map(x => {
                return {
                    label: x[llcNameCol],
                    id: x[llcIdCol]
                }
            }), x => x.label)}
            disableClearable
            fullWidth
            onUpdate={(value) => {
                let newLLCId = value.id
                const availableCounterpartyRecords = llcCounterpartyJunctionRecords.filter(r => r[llcCounterpartyJunctionLLCIdCol]===newLLCId)
                const availableCounterpartyIds = availableCounterpartyRecords.map(x => x[llcCounterpartyJunctionCounterpartyIdCol])

                // Check that the current bank selections are a part of the newly selected LLC, if not add coresponding error.
                const counterpartyErrorPathsToAdd = []
                contractCounterpartyRecords.forEach(cp => {
                    let counterpartyId = cp[contractCounterpartyIdCol]
                    if (counterpartyId && !availableCounterpartyIds.includes(counterpartyId)) {
                        let changeLogId = cp[injectedChangeLogIdCol]
                        counterpartyErrorPathsToAdd.push([
                            new RNDErrorInstance(`Please select a ${counterpartyLabel} that belongs to the selected ${llcLabel}.`),
                            contractId, ContractCounterpartyTable.buildId(), changeLogId, contractCounterpartyIdCol,
                        ])
                    }
                })
                var newErrors = errors.clone()
                contractCounterpartyRecords.forEach(x => newErrors.remove(contractId, ContractCounterpartyTable.buildId(), x[injectedChangeLogIdCol], contractCounterpartyIdCol))
                if (counterpartyErrorPathsToAdd.length > 0) counterpartyErrorPathsToAdd.forEach(x => newErrors.add(...x))
                setErrors(newErrors)

                /*console.log(contractCounterpartyRecords.map(x => [contractId, ContractCounterpartyTable.buildId(), x[injectedChangeLogIdCol], contractCounterpartyIdCol]))
                removeErrors(...contractCounterpartyRecords.map(x => [contractId, ContractCounterpartyTable.buildId(), x[injectedChangeLogIdCol], contractCounterpartyIdCol]))
                console.log(counterpartyErrorPathsToAdd)
                if (counterpartyErrorPathsToAdd.length > 0) addErrors(...counterpartyErrorPathsToAdd)*/
                updateContract({[contractLLCIdCol]: value.id})

            }}
            InputProps={{
                helperText: `The ${llcLabel} selection affects which ${counterpartyLabel}(s) are available to associate with this contract.`
            }}
            style={{marginBottom: "20px", maxWidth: "400px"}}
        />
    )
}