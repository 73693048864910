import './DimensionSelectionModal.css'
import {Component} from 'react'

import {Button, Modal} from 'rsuite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'

import DimensionMenu from '../menu/DimensionMenu'

class DimensionMenuModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            selectedDimensions: props.selectedDimensions || {}
        };
    }
    close = (cancel) => {
        if (cancel) {
            this.setState({
                show: false,
                selectedDimensions: this.props.selectedDimensions || {}
            });
        }
        else {
            this.setState({
                show: false
            })
            if (this.props.onClose) this.props.onClose(this.state.selectedDimensions)
        }
    }
    open = () => {
        this.setState({ show: true });
    }
    render() {
        var dimensions = this.props.services.assetFramework.getDimensions().filter(x => [1,2,3].includes(x._id))

        return (
            <div className="modal-container">
                <FontAwesomeIcon onClick={this.open} icon={faCog} style={{fontSize: '16px'}} fixedWidth className="fa-spin-hover" />

                <Modal show={this.state.show} onHide={() => this.close(true)}>
                <Modal.Header>
                    <Modal.Title>Select Your Dimensions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DimensionMenu dimensions={dimensions} selectedDimensionIds={this.state.selectedDimensions} onDimensionClick={this.updateSelectedDimensionIds} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.close(false)} appearance="primary">
                    Ok
                    </Button>
                    <Button onClick={() => this.close(true)} appearance="subtle">
                    Cancel
                    </Button>
                </Modal.Footer>
                </Modal>
            </div>
        );
    }

    updateSelectedDimensionIds = (dimension) => {
        var dimId = Number(dimension.split(".").slice(0,1))
        var dimValueId = Number(dimension.split(".").slice(-1))

        var currentSelection = Array.from(this.state.selectedDimensions[dimId] || [])
        var indexOfNewSelection = currentSelection.indexOf(dimValueId)
        if (indexOfNewSelection >= 0) {
            currentSelection.splice(indexOfNewSelection, 1)
        }
        else {
            currentSelection.push(dimValueId)
        }
        
        var selectedDimensions = Object.assign({}, this.state.selectedDimensions, {})
        selectedDimensions[dimId] = currentSelection
        this.setState({
            selectedDimensions: selectedDimensions
        })
    }
}

  export default DimensionMenuModal