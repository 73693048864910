import { format } from 'date-fns'

export function formatDate(x) {
    return format(x, "yyyy-MM-dd HH:mm:ss").replace(" ", "T")
}

export function formatDateDisplay(x) {
    return format(x, "MM/dd/yyyy")
}

export function convertToUTC(date) {
    var utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000)
    return utc
}