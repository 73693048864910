import './EventModal.css'
import { useState } from 'react'

import {Button, Modal} from 'rsuite'
import { MultiSelect } from '@mantine/core'
import { faArrowUpRightFromSquare, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export const StateSelectionModal = ({initialStates, onClose, ...props}) => {

    const [states, setStates] = useState(initialStates)

    const [show, setShow] = useState(false)
    const close = (cancel) => {
        if (cancel) {
            setShow(false)
        }
        else {
            setShow(false)
            if (onClose) onClose(states)
        }
    }
    const open = () => {
        setShow(true)
    }

    const statesList = states ? states.split(",") : []

    return (
        <div className="modal-container">
            <div>
                {props.children}
            </div>
            <FontAwesomeIcon onClick={open} icon={faArrowUpRightFromSquare} style={{fontSize: '16px'}} fixedWidth />

            <Modal show={show} onHide={() => close(true)}>
                <Modal.Header>
                    <Modal.Title>Select one or more states.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MultiSelect
                        value={statesList}
                        data={allStates}
                        onChange={(data) => {
                            setStates(data.length > 0 ? data.join(",") : null)
                        }}
                        zIndex={1051}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => close(false)} appearance="primary">
                    Ok
                    </Button>
                    <Button onClick={() => close(true)} appearance="subtle">
                    Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

const allStates = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CZ', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM',
    'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
]