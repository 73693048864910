import React from 'react'

import { PalantirSelector, PalantirDatePicker } from '../../../components/input/SelectPicker'
import { PalantirTextField, makeTextMaskNumber, makeTextMaskPercentage, PalantirDispatchedTextField } from '../../../components/input/Text'
import { Plant } from '../../table_configuration/Project'
import { HelpProjectCOD, HelpProjectStatus } from '../../../components/help/precious/Columns'
import { HelpPrompt } from '../../../components/help/HelpPrompt'
import { booleanOptions } from '../../table_configuration/CommonColumns'

const plantSchema = Plant.columnSchema

const TextMaskCapacity = makeTextMaskNumber({
    scale: 3,
    min: 0
})
const TextMaskITCQualifying = makeTextMaskPercentage()

const PlantMedata = React.memo(function(props) {
    return (
        <div id="plant-metadata" className="flow-vertical field-group" style={{paddingRight: "10px", flexShrink: 0}}>
            <div className="header">Status</div>
            <div style={{display: "flex", margin: "15px 0 0 0"}}>
                <HelpPrompt
                    services={props.services}
                    style={{position: "relative", top: "20px", marginRight: "4px", fontSize: "small"}}
                    title="Status"
                    item={<HelpProjectStatus />}
                />
                <PalantirSelector
                    label="Status"
                    value={props.plantStage}
                    onChange={(x) => props.updatePlant({[plantSchema.stage]: x})}
                    items={Plant.options.stage}
                    style={{marginTop: 0, flexGrow: 1}}
                />
            </div>
            <PalantirSelector
                label="Offtake Status"
                value={props.plantDevelopmentSubstage}
                onChange={(x) => props.updatePlant({[plantSchema.developmentSubstage]: x})}
                items={Plant.options.developmentSubstage}
                style={{marginTop: 0}}
            />
            <PalantirDispatchedTextField
                label="CPs Needed in PPA"
                value={props.plantCPsNeededInPPA}
                onChange={(x) => props.updatePlant({[plantSchema.CPsNeededInPPA]: x})}
            />
            {props.plantStage==="Sold" &&
                <PalantirDatePicker
                    label="Sold Date"
                    value={props.plantSoldDate}
                    onChange={(date) => props.updatePlant({[plantSchema.soldDate]: date})}
                />
            }
            {props.plantStage==="Write Off" &&
                <PalantirDatePicker
                    label="Write Off Date"
                    value={props.plantWriteOffDate}
                    onChange={(date) => props.updatePlant({[plantSchema.writeOffDate]: date})}
                />
            }
            <PalantirSelector
                label="Status Acquired"
                value={props.plantStageAcquired}
                onChange={(x) => props.updatePlant({[plantSchema.stageAcquired]: x})}
                items={Plant.options.stageAcquired}
                style={{marginTop: 0}}
            />
            {props.plantStageAcquired!==null &&
                <PalantirDatePicker
                    label="Acquisition Date"
                    value={props.plantAcquisitionDate}
                    onChange={(date) => props.updatePlant({[plantSchema.acquisitionDate]: date})}
                />
            }
            <PalantirSelector
                label="Commercial Viability"
                value={props.plantCommercialViability}
                onChange={(x) => props.updatePlant({[plantSchema.commercialViability]: x})}
                items={Plant.options.commercialViability}
            />
            <PalantirSelector
                label="Development Viability"
                value={props.plantDevelopmentViability}
                onChange={(x) => props.updatePlant({[plantSchema.developmentViability]: x})}
                items={Plant.options.developmentViability}
            />
            <PalantirSelector
                label="Board Approval"
                value={props.plantBoardApproval}
                onChange={(x) => props.updatePlant({[plantSchema.boardApproval]: x})}
                items={booleanOptions}
            />
            {props.plantBoardApproval &&
                <>
                    <PalantirDatePicker
                        label="Board Approval Date"
                        value={props.plantBoardApprovalDate}
                        onChange={(date) => props.updatePlant({[plantSchema.boardApprovalDate]: date})}
                    />
                    <PalantirDispatchedTextField
                        label="Board Approval Notes"
                        value={props.plantBoardApprovalNotes}
                        multiline
                        rows={3}
                        variant="filled"
                        onChange={(x) => props.updatePlant({[plantSchema.boardApprovalNotes]: x})}
                    />
                </>
            }
            {/*<PalantirSelector
                label="Domestic Content"
                value={props.plantDomesticContent}
                onChange={(x) => props.updatePlant({[plantSchema.domesticContent]: x})}
                items={booleanOptions}
            />
            <PalantirSelector
                label="Prevailing Wage"
                value={props.plantPrevailingWage}
                onChange={(x) => props.updatePlant({[plantSchema.prevailingWage]: x})}
                items={booleanOptions}
            />
            <PalantirTextField
                label="ITC Qualifying (%)"
                value={props.plantITCQualifying}
                InputProps={{
                    inputComponent: TextMaskITCQualifying
                }}
                onChange={(x) => props.updatePlant({[plantSchema.itcQualifying]: x})}
            />*/}
            <div className="header" >Characteristics</div>
            <PalantirSelector 
                label="Scale"
                value={props.plantScale}
                onChange={(x) => props.updatePlant({[plantSchema.scale]: x})}
                items={Plant.options.scale}
                style={{marginTop: 0}}
            />
            <PalantirSelector
                label="Technology"
                value={props.plantTechnology}
                onChange={(x) => props.updatePlant({[plantSchema.technology]: x})}
                items={Plant.options.technology}
            />
            <div className="header">Capacity</div>
            <PalantirTextField
                label="DC Capacity (MW)"
                value={props.plantCapacityMwDc}
                InputProps={{
                    inputComponent: TextMaskCapacity
                }}
                onChange={(x) => props.updatePlant({[plantSchema.capacityMwDc]: x})}
                style={{marginTop: 0}}
            />
            <PalantirTextField
                label="AC Capacity (MW)"
                value={props.plantCapacityMwAc}
                InputProps={{
                    inputComponent: TextMaskCapacity
                }}
                onChange={(x) => {console.log(x); props.updatePlant({[plantSchema.capacityMwAc]: x})}}
            />
            <PalantirTextField
                label="Storage Capacity (MW)"
                value={props.plantStorageCapacityMw}
                InputProps={{
                    inputComponent: TextMaskCapacity
                }}
                onChange={(x) => props.updatePlant({[plantSchema.storageCapacityMw]: x})}
            />
            <PalantirTextField
                label="Storage Capacity (MWh)"
                value={props.plantStorageCapacityMwh}
                InputProps={{
                    inputComponent: TextMaskCapacity
                }}
                onChange={(x) => props.updatePlant({[plantSchema.storageCapacityMwh]: x})}
            />
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        prevProps.plantStage===nextProps.plantStage &&
        prevProps.plantDevelopmentSubstage===nextProps.plantDevelopmentSubstage &&
        prevProps.plantSoldDate===nextProps.plantSoldDate &&
        prevProps.plantWriteOffDate===nextProps.plantWriteOffDate &&
        prevProps.plantStageAcquired===nextProps.plantStageAcquired &&
        prevProps.plantCPsNeededInPPA===nextProps.plantCPsNeededInPPA &&
        prevProps.plantAcquisitionDate===nextProps.plantAcquisitionDate &&
        prevProps.plantCommercialViability===nextProps.plantCommercialViability &&
        prevProps.plantDevelopmentViability===nextProps.plantDevelopmentViability &&
        prevProps.plantScale===nextProps.plantScale &&
        prevProps.plantTechnology===nextProps.plantTechnology &&
        prevProps.plantFuelType===nextProps.plantFuelType &&
        prevProps.plantCapacityMwDc===nextProps.plantCapacityMwDc &&
        prevProps.plantCapacityMwAc===nextProps.plantCapacityMwAc &&
        prevProps.plantStorageCapacityMw===nextProps.plantStorageCapacityMw &&
        prevProps.plantStorageCapacityMwh===nextProps.plantStorageCapacityMwh &&
        prevProps.plantDomesticContent===nextProps.plantDomesticContent &&
        prevProps.plantPrevailingWage===nextProps.plantPrevailingWage &&
        prevProps.plantITCQualifying===nextProps.plantITCQualifying &&
        prevProps.plantBoardApproval===nextProps.plantBoardApproval &&
        prevProps.plantBoardApprovalDate===nextProps.plantBoardApprovalDate &&
        prevProps.plantBoardApprovalNotes===nextProps.plantBoardApprovalNotes
    )
})

export default PlantMedata