import './RegulationSetpointAdherence.css'
import React, {Component} from 'react'

import Panel from '../../../panel/Panel'
import BaseChart from '../../../charts/BaseChart'
import { OptionNav } from '../../../navigation/SimpleNavs'
import { convertObjectToSeries, createYAxes, createSeriesFromRawData } from '../../../../utils/Highcharts'
import SummarySortMenu from './SummarySortMenu'
import SummaryFilterMenu from './SummaryFilterMenu'

import { formatDistance } from 'date-fns'
import _ from 'lodash'
import Highcharts from 'highcharts'
import HC_more from 'highcharts/highcharts-more'
HC_more(Highcharts)

class RegulationSetpointAdherence extends Component {

    constructor(props) {
        super()

        this.state = {
            selectedInterval: null,
            sortOrder: null,
            sortField: null,
            availabilityFilter: [0,100],
            violationTypeFilter: ["ads", "agc", "mixed"],
            priorityFilter: [2,3]
        }
        this.violationIntervalMenuRef = React.createRef()
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        // Are we adding new items to the list?
        // Capture the scroll position so we can adjust scroll later.
        const list = this.violationIntervalMenuRef.current;
        if (list) {
            return list.scrollHeight - list.scrollTop;
        }
        return null
      }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot!==null) {
            const list = this.violationIntervalMenuRef.current;
            list.scrollTop = list.scrollHeight - snapshot;
        }
    }

    render() {
        return (
            <div className="flow-vertical fill-parent">
                <OptionNav
                    className="flow-vertical fill-parent"
                    navStyle={{marginBottom: "20px"}}
                    options={{
                        "Summary": () => this.createSummaryTab(),
                        "Detailed": () => this.constructRawDataHighchart()
                    }}
                />
            </div>
        )
    }

    //////////////////
    // Summary Methods
    //////////////////

    setFilters = (availabilityFilter, violationTypeFilter, priorityFilter) => {
        this.setState({
            availabilityFilter: availabilityFilter,
            violationTypeFilter: violationTypeFilter,
            priorityFilter: priorityFilter,
        })
    }

    filterIntervals = (intervals) => {
        return intervals.filter(x => {
            let show = true
            if (this.state.availabilityFilter) {
                let f = this.state.availabilityFilter
                let av = x.availability
                show = show && (av >= f[0] && av <= f[1])
            }
            if (this.state.violationTypeFilter.length > 0) {
                show = show && this.state.violationTypeFilter.includes(x.violation_type)
            }
            if (this.state.priorityFilter) {
                show = show && this.state.priorityFilter.includes(x.max_flag)
            }
            return show
        })
    }

    createSummaryTab = () => {

        let flaggedIntervals = this.props.data.flagged_intervals
        flaggedIntervals = this.filterIntervals(flaggedIntervals)
        if (this.state.sortField) {
            flaggedIntervals = _.sortBy(flaggedIntervals, (x) => x[this.state.sortField])
            if (this.state.sortOrder && this.state.sortOrder==="descending") flaggedIntervals = flaggedIntervals.reverse()
        }

        let intervals = flaggedIntervals.map((x) => this.createIntervalPanel(x))

        return <div className="flow-horizontal fill-parent">
            <div className="interval-sidebar flow-vertical" style={{flexShrink: 0}}>
                <div className="flow-horizontal" style={{fontSize: "12px", marginBottom: "6px", alignItems: "baseline"}}>
                    <div style={{textDecoration: "underline", fontSize: "18px"}}>Violations</div>
                    <div className="fill-parent"></div>
                    <SummarySortMenu 
                        setSortParams={this.setSortParams} 
                        sortOrder={this.state.sortOrder} 
                        sortField={this.state.sortField}
                        style={{marginRight: "6px"}}
                    />
                    <SummaryFilterMenu 
                        setFilters={this.setFilters} 
                        availability={this.state.availabilityFilter}
                        violationTypes={this.state.violationTypeFilter}
                        priorities={this.state.priorityFilter}
                    />
                </div>
                <div className="interval-options-menu flow-vertical fill-parent" ref={this.violationIntervalMenuRef}>
                    {intervals.length > 0 ? intervals : <div className="fill-parent flex-centered">
                                                            No violations during selected time period!
                                                        </div>}
                </div>
            </div>
            {this.state.selectedInterval && flaggedIntervals.length > 0 ? <div className="flow-vertical fill-parent">
                {this.createCharts(flaggedIntervals)}
            </div> : <div className="fill-parent flex-centered">Please select a violation interval.</div>
            }
        </div>
    }

    setSortParams = (order, field) => {
        this.setState({
            sortOrder: order,
            sortField: field,
        })
    }

    createCharts = (flaggedIntervals) => {
        var intervalConfig = flaggedIntervals.find(x => x.id===this.state.selectedInterval)
        if (intervalConfig===undefined) {
            return <Panel>Your selection has been filtered out of existence.</Panel>
        }

        var start = intervalConfig.start
        var end = intervalConfig.end

        var chart = <BaseChart 
            services={this.props.services}
            provideChartComponents={true}
            data={{
                series: this.createHighchartsSeries(intervalConfig),
                yAxes: createYAxes({title: "Power", units: "MW"})
            }}
            dataRequestId={this.props.dataRequestId}
            compareData={true}
            chartOptions={{
                xAxis: {
                    plotBands: [{
                        color: "#ee8f8f",
                        from: new Date(start).getTime(),
                        to: new Date(end).getTime(),
                    }]
                }
            }}
            timezone={this.props.siteTimezone}
        />
        return chart
    }

    createHighchartsSeries(data) {

        var allHighchartsSeries = []
        for (let entry of Object.entries(data)) {

            let [label, data] = entry
            if (["id", "start", "end", "availability", "max_flag", "duration", "violation_type"].includes(label)) continue

            let highchartsData = convertObjectToSeries(data)

            let highchartsSeries = {
                data: highchartsData,
                //boostThreshold: 5000,
                name: label,
                tooltip: {
                    valueSuffix: ' ' + this.determineYAxisUnits(label)
                }
            }
            allHighchartsSeries.push(highchartsSeries)
        }
        return allHighchartsSeries
    }

    constructRawDataHighchart = () => {

        var assetFrameworkServices = this.props.services.assetFramework

        var [series, yAxes] = createSeriesFromRawData(this.props.data.data, assetFrameworkServices.getDevices(), assetFrameworkServices.getTags(), assetFrameworkServices.getDimensions())

        return (
            <BaseChart 
                services={this.props.services}
                timezone={this.props.siteTimezone}
                provideChartComponents={true}
                data={{
                    series: series,
                    yAxes: yAxes
                }}
            />
        )
    }

    determineYAxisUnits = (label) => {
        switch(label) {
            case "power":
                return "MW"
            case "agc":
                return "MW"
            case "ads":
                return "MW"
            case "source":
                return "Source"
        }
        return ""
    }

    formatViolation = (violation) => {
        switch(violation) {
            case "ads":
                return "ADS";
            case "agc":
                return "AGC";
            case "mixed":
                return "Mixed";
            default:
                return ""
        }
    }

    createIntervalPanel = (intData) => {

        let key = intData.id

        let start = new Date(intData.start)
        let end = new Date(intData.end)
        let duration = intData.duration
        let violationSignal = this.formatViolation(intData.violation_type)

        let availability = intData.availability
        let availabilityColor = "#69b34c"
        if (availability < 96) availabilityColor = "#fab733"
        if (availability < 90) availabilityColor = "#ff4e11"

        let className = "interval-option-panel" + (key===this.state.selectedInterval ? " active" : "") + ` p${intData.max_flag}`

        return <div className={className} onClick={(e) => this.setState({selectedInterval: key})} style={{marginBottom: "10px"}}>
            <div className="flow-horizontal" style={{marginBottom: "5px", alignItems: "center"}}>
                <div className={"panel-box-highlighter violation-signal " + violationSignal.toLowerCase()}>{violationSignal}</div>
                <div style={{flexShrink: 0}}>Violation {key} - </div>
                <div style={{marginLeft: "3px", flexShrink: 0, fontWeight: "bold"}}><i>{duration} minutes</i></div>
                <div className="fill-parent"></div>
                <div className="panel-box-highlighter availability-box" style={{backgroundColor: availabilityColor, color: "white"}}>{availability.toFixed(2)}%</div>
            </div>
            
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <span>Start:</span>
                <span>{start.toLocaleString("en-US", {timeZone: this.props.siteTimezone})}</span>
            </div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <span>End:</span>
                <span>{end.toLocaleString("en-US", {timeZone: this.props.siteTimezone})}</span>
            </div>
        </div>
    }

    // Summary Menu Options

    createSummaryFilterMenu = () => {

    }

}

export default RegulationSetpointAdherence