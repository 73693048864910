import { schemas } from "../Constants"
import Table from "./BaseTable"
import { makeEntityId } from "./CommonColumns"
import { transformDisplayValue, buildDropdownItems } from "../../utils/databaseAppUtils"


class ReportTypeTable extends Table {

    constructor() {
        var columnSchema = {
            id: "id",
            name: "name"
        }

        var schemaName = schemas.dbo
        var tableName = "report_type"

        var displayNameSingular = "Report Type"
        var displayNamePlural = "Report Types"

        var pkUidColumn = columnSchema.id
        var identifiers = [columnSchema.id]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }

    buildTableProps = (data) => {

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.id, {fixed: true}),
                    {
                        name: "Name",
                        key: this.columnSchema.name,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true
                        }
                    }
                ]
            },
        }
    }

}
export const ReportType = new ReportTypeTable()

class ReportTable extends Table {

    constructor() {
        var columnSchema = {
            id: "id",
            name: "name",
            reportTypeId: "report_type_id",
            datasetDate: "dataset_date"
        }

        var schemaName = schemas.dbo
        var tableName = "report"

        var displayNameSingular = "Report"
        var displayNamePlural = "Reports"

        var pkUidColumn = columnSchema.id
        var identifiers = [columnSchema.id]
        var dependencies = [ReportType]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }

    buildTableProps = (data) => {

        var reportTypeRecords = this.findTableObjectRecords(data, ReportType)
        var reportTypeIdCol = ReportType.columnSchema.id
        var reportTypeNameCol = ReportType.columnSchema.name

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.id, {fixed: true}),
                    {
                        name: "Name",
                        key: this.columnSchema.name,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true
                        }
                    }, {
                        name: "Report Type",
                        key: this.columnSchema.reportTypeId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(reportTypeRecords, value, reportTypeIdCol, reportTypeNameCol),
                            dropdownItems: buildDropdownItems(reportTypeRecords, reportTypeIdCol, reportTypeNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                            fixed: true
                        }
                    }, {
                        name: "Dataset Date",
                        key: this.columnSchema.datasetDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true
                        }
                    }
                ]
            },
        }
    }

}
export const Report = new ReportTable()

class ReportCommentTopicTable extends Table {

    constructor() {
        var columnSchema = {
            id: "id",
            name: "name"
        }

        var schemaName = schemas.dbo
        var tableName = "report_comment_topic"

        var displayNameSingular = "Report Comment Topic"
        var displayNamePlural = "Report Comment Topics"

        var pkUidColumn = columnSchema.id
        var identifiers = [columnSchema.id]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }

    buildTableProps = (data) => {

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.id, {fixed: true}),
                    {
                        name: "Name",
                        key: this.columnSchema.name,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true
                        }
                    }
                ]
            },
        }
    }

}
export const ReportCommentTopic = new ReportCommentTopicTable()

class ReportRelevantCommentTopicsTable extends Table {

    constructor() {
        var columnSchema = {
            id: "id",
            reportTypeId: "report_type_id",
            reportCommentTopicId: "report_comment_topic_id"
        }

        var schemaName = schemas.dbo
        var tableName = "report_relevant_comment_topics"

        var displayNameSingular = "Report Relevant Comment Topic"
        var displayNamePlural = "Report Relevant Comment Topics"

        var pkUidColumn = columnSchema.id
        var identifiers = [columnSchema.id]
        var dependencies = [ReportType, ReportCommentTopic]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }

    buildTableProps = (data) => {

        var reportTypeRecords = this.findTableObjectRecords(data, ReportType)
        var reportTypeIdCol = ReportType.columnSchema.id
        var reportTypeNameCol = ReportType.columnSchema.name
        
        var reportCommentTopicRecords = this.findTableObjectRecords(data, ReportCommentTopic)
        var reportCommentTopicIdCol = ReportCommentTopic.columnSchema.id
        var reportCommentTopicNameCol = ReportCommentTopic.columnSchema.name

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.id, {fixed: true}),
                    {
                        name: "Report Type",
                        key: this.columnSchema.reportTypeId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(reportTypeRecords, value, reportTypeIdCol, reportTypeNameCol),
                            dropdownItems: buildDropdownItems(reportTypeRecords, reportTypeIdCol, reportTypeNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                            fixed: true
                        }
                    }, {
                        name: "Report Comment Topic",
                        key: this.columnSchema.reportCommentTopicId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(reportCommentTopicRecords, value, reportCommentTopicIdCol, reportCommentTopicNameCol),
                            dropdownItems: buildDropdownItems(reportCommentTopicRecords, reportCommentTopicIdCol, reportCommentTopicNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                            fixed: true
                        }
                    }
                ]
            },
        }
    }

}
export const ReportRelevantCommentTopics = new ReportRelevantCommentTopicsTable()

class ReportCommentTable extends Table {

    constructor() {
        var columnSchema = {
            id: "id",
            reportId: "report_id",
            topicId: "topic_id",
            assetAssociationTable: "asset_association_table",
            assetAssociationId: "asset_association_id",
            content: "content"
        }

        var schemaName = schemas.dbo
        var tableName = "report_comment"

        var displayNameSingular = "Report Comment"
        var displayNamePlural = "Report Comments"

        var pkUidColumn = columnSchema.id
        var identifiers = [columnSchema.id]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }

    buildTableProps = (data) => {

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.id, {fixed: true})
                ]
            },
        }
    }

}
export const ReportComment = new ReportCommentTable()