import { schemas } from '../Constants'
import { transformDisplayValue, buildDropdownItems } from '../../utils/databaseAppUtils'
import Table from './BaseTable'
import { makePlantId, makeNotes, makeEntityId, booleanOptions } from './CommonColumns'
import { 
    injectCounterpartyName, 
    Developer, EPC, Interconnection, OEM, OandM, Offtake, SchedulingCoordinator, Permitter
} from './Counterparty'
import { Counterparty } from './Entity'
import { LandownerLLC, OandMLLC } from './LLC'
import { Plant } from './Project'
import { startOfDay } from 'date-fns'


class OfftakeContractOfferTable extends Table {

    constructor() {

        var columnSchema = {
            oid: "oid",
            projectId: "project_id",
            offerName: "offer_name",
            offtakerId: "offtaker_id",
            contractType: "contract_type",
            technology: "technology",
            sentDate: "sent_date",
            dueDate: "due_date",
            finalPricingDueDate: "final_pricing_due_date",
            status: "status",
            cod: "cod",
            sizePVMWAC: "size_pv__mwac",
            sizePVMinMWAC: "size_pv_min__mwac",
            sizePVMaxMWAC: "size_pv_max__mwac",
            sizeBESSMWAC: "size_bess__mwac",
            sizeBESSMinMWAC: "size_bess_min__mwac",
            sizeBESSMaxMWAC: "size_bess_max__mwac",
            durationBESS: "duration_bess__hours",
            price: "price",
            pricingUnit: "pricing_unit",
            marginDollar: "margin__dollar",
            tenor: "tenor",
            product: "product",
            structure: "structure",
            structureNotes: "structure_notes",
            settlementLocation: "settlement_location",
            escalationRatePercent: "escalation_rate__percent",
            purchaseOption: "purchase_option",
            purchaseOptionNotes: "purchase_option_notes",
            priceFloor: "price_floor",
            priceFloorNotes: "price_floor_notes",
            bidFeeIncluded: "bid_fee_included",
            //basisIncluded: "basis_included",
            basis: "basis",
            lifetimeIRR: "lifetime_irr__percent",
            contractedIRR: "contracted_irr__percent",
            contractedMOIC: "contracted_moic",
            totalCapexDollarperwattDC: "total_capex__dollarperwattdc",
            solicitationType: "solicitation_type",
            offerDocumentLink: "offer_document_link",
            previousOfferId: "previous_offer_id",
            nextSteps: "next_steps",
            notes: "notes"
        }

        var schemaName = "contract_offer"
        var tableName = "offtake"

        var displayNameSingular = "Offtake Offer"
        var displayNamePlural = "Offtake Offers"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [Plant, Offtake]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
        this.initOptions()
    }

    initOptions = () => {
        this.attributes = {
            PVOfferTypeOptions: ["PPA", "VPPA", "BTA", "Development Transfer"]
        }
        this.options = {
            contractType: [{
                label: "PPA", value: "PPA"
            }, {
                label: "VPPA", value: "VPPA"
            }, {
                label: "BTA", value: "BTA"
            }, {
                label: "RA", value: "RA"
            }, {
                label: "Toll", value: "Toll"
            }, {
                label: "ES", value: "ES"
            }, {
                label: "Development Transfer", value: "Development Transfer"
            }],
            technology: [{
                label: "PV", value: "pv"
            }, {
                label: "Hybrid PV + BESS", value: "hybrid_pv_bess"
            }, {
                label: "Standalone BESS", value: "standalone_bess"
            }],
            status: [{
                label: "Gathering Inputs", value: "Gathering Inputs"
            }, {
                label: "Offered", value: "Offered"
            }, {
                label: "RFP Follow-ups", value: "RFP Follow-ups"
            }, {
                label: "Shortlisted", value: "Shortlisted"
            }, {
                label: "Negotiating", value: "Negotiating"
            }, {
                label: "Price Run on Hold", value: "Price Run on Hold"
            }, {
                label: "Won", value: "Won"
            }, {
                label: "Contracted", value: "Contracted"
            }, {
                label: "Dead", value: "Dead"
            }, {
                label: "Did Not Bid", value: "Did Not Bid"
            }],
            product: [{
                label: "Energy", value: "Energy"
            }, {
                label: "RA", value: "RA"
            }, {
                label: "RECs", value: "RECs"
            }, {
                label: "Capacity", value: "Capacity"
            }, {
                label: "All-in", value: "All-in"
            }, {
                label: "Development Transfer", value: "Development Transfer"
            }],
            structure: [{
                label: "unit-contingent", value: "unit-contingent"
            }, {
                label: "fixed-shape", value: "fixed-shape"
            }, {
                label: "upside-sharing", value: "upside-sharing"
            }, {
                label: "proxy-generation", value: "proxy-generation"
            }, {
                label: "other", value: "other"
            }],
            settlementLocation: [{
                label: "busbar", value: "busbar"
            }, {
                label: "hub", value: "hub"
            }],
            pricingUnit: [{
                label: "$/kW-mo", value: "$/kW-mo"
            }, {
                label: "$/MWh", value: "$/MWh"
            }, {
                label: "flat-rate", value: "flat-rate"
            }],
            solicitationType: [{
                label: "RFP", value: "RFP"
            }, {
                label: "Bilateral", value: "Bilateral"
            }, {
                label: "Update", value: "Update"
            }]
        }
    }

    buildTableProps = (data) => {

        var offerRecords = this.findTableObjectRecords(data, this)

        var plantRecords = this.findTableObjectRecords(data, Plant)
        var offtakeRecords = this.findTableObjectRecords(data, Offtake)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)
        const offtakeIdCol = Offtake.columnSchema.offtakeId
        const offtakeNameCol = "offtakeName"

        offtakeRecords = injectCounterpartyName(offtakeRecords, counterpartyRecords, offtakeIdCol, offtakeNameCol)

        const validateOfftakeContractOffer = (contract) => {
            if (!contract[this.columnSchema.projectId]) return {pass: false, message: "Please give the offer a project."}
            if (!contract[this.columnSchema.offerName]) return {pass: false, message: "Please give the offer a unique name."}
            if (!contract[this.columnSchema.offtakerId]) return {pass: false, message: "Please give the offer an Offtaker."}
            if (!contract[this.columnSchema.contractType]) return {pass: false, message: "Please give the offer a contract type."}
            if (!contract[this.columnSchema.sentDate]) return {pass: false, message: "Please give the offer a sent date."}
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateOfftakeContractOffer,
            validateUpdateItem: validateOfftakeContractOffer,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Project",
                        key: this.columnSchema.projectId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(plantRecords, value, Plant.columnSchema.plantId, Plant.columnSchema.plantName),
                            dropdownItems: buildDropdownItems(plantRecords, Plant.columnSchema.plantId, Plant.columnSchema.plantName)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    }, {
                        name: "Offer Name",
                        key: this.columnSchema.offerName,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Offtaker",
                        key: this.columnSchema.offtakerId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(offtakeRecords, value, offtakeIdCol, offtakeNameCol),
                            dropdownItems: buildDropdownItems(offtakeRecords, offtakeIdCol, offtakeNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    }, {
                        name: "Technology",
                        key: this.columnSchema.technology,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.technology,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Contract Type",
                        key: this.columnSchema.contractType,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.contractType,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Sent Date",
                        key: this.columnSchema.sentDate,
                        cellType: "date",
                        cellOptions: {
                            timezone: "UTC",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Due Date",
                        key: this.columnSchema.dueDate,
                        cellType: "date",
                        cellOptions: {
                            timezone: "UTC",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Final Pricing Due Date",
                        key: this.columnSchema.finalPricingDueDate,
                        cellType: "date",
                        cellOptions: {
                            timezone: "UTC",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Status",
                        key: this.columnSchema.status,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.status,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Solicitation Type",
                        key: this.columnSchema.solicitationType,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.solicitationType,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "COD",
                        key: this.columnSchema.cod,
                        cellType: "date",
                        cellOptions: {
                            timezone: "UTC",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Size PV (MW AC)",
                        key: this.columnSchema.sizePVMWAC,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Min. Size PV (MW AC)",
                        key: this.columnSchema.sizePVMinMWAC,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Max. Size PV (MW AC)",
                        key: this.columnSchema.sizePVMaxMWAC,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Size BESS (MW AC)",
                        key: this.columnSchema.sizeBESSMWAC,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Min. Size BESS (MW AC)",
                        key: this.columnSchema.sizeBESSMinMWAC,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Max. Size BESS (MW AC)",
                        key: this.columnSchema.sizeBESSMaxMWAC,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "BESS Duration (hrs)",
                        key: this.columnSchema.durationBESS,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Price",
                        key: this.columnSchema.price,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Pricing Unit",
                        key: this.columnSchema.pricingUnit,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.pricingUnit
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Margin ($)",
                        key: this.columnSchema.marginDollar,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Tenor",
                        key: this.columnSchema.tenor,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Product",
                        key: this.columnSchema.product,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.product
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Structure",
                        key: this.columnSchema.structure,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.structure
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Structure Notes",
                        key: this.columnSchema.structureNotes,
                        cellType: "editable",
                        filterable: false,
                        sortable: false,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Settlement Location",
                        key: this.columnSchema.settlementLocation,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.settlementLocation
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Escalation Rate (%)",
                        key: this.columnSchema.escalationRatePercent,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Purchase Option",
                        key: this.columnSchema.purchaseOption,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Purchase Option Notes",
                        key: this.columnSchema.purchaseOptionNotes,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Price Floor",
                        key: this.columnSchema.priceFloor,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Price Floor Notes",
                        key: this.columnSchema.priceFloorNotes,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Bid Fee Included",
                        key: this.columnSchema.bidFeeIncluded,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }/*, {
                        name: "Basis Included",
                        key: this.columnSchema.basisIncluded,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }*/, {
                        name: "Basis",
                        key: this.columnSchema.basis,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Lifetime IRR (%)",
                        key: this.columnSchema.lifetimeIRR,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Contracted IRR (%)",
                        key: this.columnSchema.contractedIRR,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Contracted MOIC",
                        key: this.columnSchema.contractedMOIC,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Total Capex ($/W DC)",
                        key: this.columnSchema.totalCapexDollarperwattDC,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Previous Offer ID",
                        key: this.columnSchema.previousOfferId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(offerRecords, value, this.columnSchema.oid, this.columnSchema.offerName),
                            dropdownItems: buildDropdownItems(offerRecords, this.columnSchema.oid, this.columnSchema.offerName)
                        },
                        filterable: false,
                        sortable: false,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Offer Document Link",
                        key: this.columnSchema.offerDocumentLink,
                        cellType: "editable",
                        filterable: false,
                        sortable: false,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Next Steps",
                        key: this.columnSchema.nextSteps,
                        cellType: "editable",
                        filterable: false,
                        sortable: false,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes),
                ]
            },
        }
    }

}
export const OfftakeContractOffer = new OfftakeContractOfferTable()