import './CRUDTable.css'
import React, { Component } from 'react'

import _ from "lodash"
import {deepIncludes} from "../../utils/MiscUtils"
import axios from "axios"
import { Table, Alert } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import HeaderCellContent from './cells/HeaderCellContent'
import TextCell from './cells/TextCell'
import OptionsCell from './cells/OptionCell'
import ActionCell from './cells/ActionCell'
import DropDownCell from './cells/DropdownCell'
import DatePickerCell from './cells/DatePickerCell'
import CustomRenderCell from './cells/CustomRenderCell'
import SliderCell from './cells/SliderCell'
import RangeSliderCell from './cells/RangeSliderCell'


const { Column, HeaderCell, Cell, Pagination, ColumnGroup } = Table;

/*class IDPool {
    constructor() {
        this.occupiedPool = new Set()
    }

    allocate = () => {
        let max = Math.max(...this.occupiedPool)
        let newId = this.occupiedPool.size === 0 ? 0 : max + 1

        if (this.occupiedPool.has(newId)) return false
        this.occupiedPool.add(newId)
        return newId
    }

    unassign = (id) => {
        return this.occupiedPool.delete(id)
    }

    flush = () => {
        this.occupiedPool = new Set()
    }
}*/

/*
    An initial itemset is provided to the component via props. These items are not manipulated.
    Two types of updates are tracked within the component: staged and committed.
        Staged updates are updates the user has made but has not yet saved.
        Committed updates are staged updates that the user has saved, and were successfully saved.

    The state of the table status is tracked via the statusTracker.
    This tracker stores a mapping of row ids to the corresponding editing status (editing, creating, etc.)

    A unique key must be presented as a prop to the table, which when changed should signify a reset of table data.
*/
class CRUDTable extends Component {

    constructor(props) {
        super()

        this.dataIdKey = "pltr_table_id"
        this.defaultHeightOffset = 147*2
        this.itemStatusEnum = {
            VIEW: "VIEW",
            EDIT: "EDIT",
            CREATE: "CREATE"
        }
        this.changeLogEnum = {
            CREATE: "CREATE",
            EDIT: "EDIT",
            DELETE: "DELETE",
        }

        this.state = {
            tableHeight: window.innerHeight-(props.heightOffset || this.defaultHeightOffset),

            statusTracker: {},
            stagedChanges: {},
            committedChanges: {},

            filters: {},

            sortColumn: null,//this.dataIdKey,
            sortType: null,
            displayLength: 20,
            page: 1,

            loading: null,
        }

    }

    handleResize = () => {
        this.setState({
            tableHeight: window.innerHeight-(this.props.heightOffset || this.defaultHeightOffset),
        })
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    checkPartition = () => {
        // When the partition key changes, add it to the necessary state variables.
        // This modifies state directly, but it modifies an object already tracked by state so the changes will not be overwritten.
        // This ensure when a new partition key is passed in as a prop, all methods that access state values via the partition key can assume the key is already mapped in the object.
        //   Else each method would have to check if the key exists, and if not then create it.
        if (!(this.props.partitionKey in this.state.statusTracker)) {
            let tracker = this.state.statusTracker
            tracker[this.props.partitionKey] = {}
            let committedChanges = this.state.committedChanges
            committedChanges[this.props.partitionKey] = []
            let stagedChanges = this.state.stagedChanges
            stagedChanges[this.props.partitionKey] = {}
            let filters = this.state.filters
            filters[this.props.partitionKey] = {}
        }
    }

    render() {

        this.checkPartition()

        var initialItems = this.getStagedItems()
        var filteredItems = initialItems.filter(x => this.filterItem(x))
        var sortedItems = this.sortItems(filteredItems)
        var paginatedItems = this.paginateItems(sortedItems)
        var items = paginatedItems

        var columns = this.createColumns(this.props.tableConfig.columns, initialItems)

        return (
            <div className="crud-table section-panel fill-parent" style={{overflowY: "auto"}}>
                <div className="section-header flow-horizontal" style={{height: '30px'}}>
                    <div onClick={() => this.addItemEntry()} className="flow-horizontal add-item-prompt" >
                        <FontAwesomeIcon className="" size="1x" icon={faPlusCircle} />
                        <div style={{marginLeft: '5px'}}>New {this.props.itemType}</div>
                    </div>
                </div>
                <Table 
                    height={this.state.tableHeight}
                    headerHeight={80}
                    data={items}
                    cellBordered
                    virtualized={false}
                    autoHeight={false}
                    
                    loading={this.props.forceLoading || this.state.loading}
                    shouldUpdateScroll={false}
                    className="ev-template-table config-table ev-config-table fill-parent"
                >
                    <Column width={40} fixed="left" >
                        <HeaderCell></HeaderCell>
                        <OptionsCell options={this.buildRowOptions()} />
                    </Column>

                    {columns}
                
                    <Column fixed="right" >
                        <HeaderCell>Action</HeaderCell>
                        <ActionCell
                            dataIdKey={this.dataIdKey}
                            onClick={this.handleItemStateChange} 
                            isEditingFunction={this.checkItemStatusEditingOrCreating}
                        />
                    </Column>
                </Table>

                {<Pagination
                    lengthMenu={[
                    {value: 10, label: 10},
                    {value: 20, label: 20},
                    {value: 50, label: 50},
                    ]}
                    activePage={this.state.page}
                    displayLength={this.state.displayLength}
                    total={filteredItems.length}
                    onChangePage={this.handlePageChange}
                    onChangeLength={this.handleLengthChange}
                />}
            </div>
        )

    }

    buildRowOptions = () => {
        var preparedOptions = [
            {label: "Delete", callback: this.deleteItem},
            {label: "Duplicate", callback: this.duplicateItemEntry}
        ]
        return preparedOptions.concat(this.props.rowOptions || [])
    }

    //////////////////
    // Column creation
    //////////////////

    createColumns = (cols, items) => {

        return cols.map(col => {
            if (col.colGroup) {
                let columns = this.createColumns(col.columns || [], items)
                return <ColumnGroup header={col.header}>
                    {columns}
                </ColumnGroup>
            }
            else {
                let _Cell = "";

                let cellKey = col.key
                let cellOptions = col.cellOptions || {}

                switch (col.cellType) {
                    case ("standard"):
                        _Cell = <Cell dataKey={cellKey} />
                        break
                    case ("display"):
                        _Cell = <Cell dataKey={cellKey} />
                        break
                    case ("readOnly"):
                        _Cell = <Cell dataKey={cellKey} style={{color: "#c0c0c0"}} />
                        break
                    case ("editable"):
                        _Cell = <TextCell
                                    dataIdKey={this.dataIdKey}
                                    dataKey={cellKey}
                                    onChange={this.handleRowDataChange}
                                    isEditingFunction={this.checkItemStatusEditingOrCreating}
                                    cellOptions={cellOptions}
                                />
                        break
                    case ("dropdown"):
                        _Cell = <DropDownCell 
                                    dataIdKey={this.dataIdKey}
                                    dataKey={cellKey}
                                    onChange={this.handleRowDataChange}
                                    cellOptions={cellOptions}
                                    items={items}
                                    isEditingFunction={this.checkItemStatusEditingOrCreating}
                                    transformDisplayValueFunction={this.getTransformDisplayValueFunction(cellOptions)}
                                    pageLength={this.state.displayLength}
                                />
                        break
                    case ("customRender"):
                        _Cell = <CustomRenderCell
                                    dataIdKey={this.dataIdKey}
                                    dataKey={cellKey} 
                                    onChange={this.handleRowDataChange}
                                    render={col.render}
                                    isEditingFunction={this.checkItemStatusEditingOrCreating}
                                    transformDisplayValueFunction={this.getTransformDisplayValueFunction(cellOptions)}
                                />
                        break
                    case ("slider"):
                        _Cell = <SliderCell
                                    dataIdKey={this.dataIdKey}
                                    dataKey={cellKey}
                                    onChange={this.handleRowDataChange}
                                    cellOptions={cellOptions}
                                    isEditingFunction={this.checkItemStatusEditingOrCreating}
                                />
                        break
                    case ("rangeSlider"):
                        _Cell = <RangeSliderCell 
                                    dataIdKey={this.dataIdKey}
                                    dataKey={cellKey} 
                                    onChange={this.handleRowDataChange} 
                                    cellOptions={cellOptions}
                                    isEditingFunction={this.checkItemStatusEditingOrCreating}
                                    transformDisplayValueFunction={this.getTransformDisplayValueFunction(cellOptions)}
                                />
                        break
                    case("date"):
                        _Cell = <DatePickerCell
                                    dataIdKey={this.dataIdKey}
                                    dataKey={cellKey}
                                    onChange={this.handleRowDataChange}
                                    cellOptions={cellOptions}
                                    isEditingFunction={this.checkItemStatusEditingOrCreating}
                                    pageLength={this.state.displayLength}
                                />
                        break
                    default:
                        throw `Column cell type ${col.cellType} is not a valid type.`
                }

                return (
                    <Column key={col.key} {...col.colProps}>
                        <HeaderCell>
                            <HeaderCellContent 
                                colTitle={col.name}
                                colDataKey={cellKey}
                                ColHelp={col.info}
                                filterable={col.filterable}
                                sortable={col.sortable}
                                allItems={items}
                                allColumnItems={items.map(x => x[cellKey])}
                                columnFilters={this.getFilters(cellKey)}
                                sortType={this.state.sortType}
                                sortColumn={this.state.sortColumn}
                                transformDisplayValueFunction={this.getTransformDisplayValueFunction(cellOptions)}
                                handleFilter={this.handleFilterColumn}
                                handleSort={this.handleSortColumn}
                                setHelp={this.props.services.help?.setHelpConfig}
                            />
                        </HeaderCell>
                        {_Cell}
                    </Column>
                )
            }
        })
    }

    /////////////////
    // Sort Methods
    /////////////////

    handleSortColumn = (sortColumn, sortType) => {
        if (this.state.sortColumn!==sortColumn) sortType = "asc"
        this.setState({
            sortColumn: sortColumn,
            sortType: sortType,
        });
    }

    sortItems = (items) => {
        const {sortColumn, sortType} = this.state

        if (sortColumn && sortType) {
            let transformDisplayValue = this.getTransformDisplayValueFunction(this.props.tableConfig.columns.find(x => x.key === sortColumn)?.cellOptions)

            // We want to sort using the commited items, because if a user is making updates to a sorted column the sorting on rerender will use the staged changes which will potentially resort their row to a different location.
            // TLDR: Don't resort an item based on staged changes.
            let commitedItems = this.getCommittedItems()
            let commitedItemMap = {}
            commitedItems.forEach(item => commitedItemMap[item.pltr_table_id] = item)
            let sorted = _.sortBy(items, [(x) => {
                let pltrId = x.pltr_table_id
                let matchingCommit = commitedItemMap[pltrId] || {}
                return transformDisplayValue(matchingCommit[sortColumn], x)
            }])
            if (sortType === "desc") {
                return sorted.reverse()
            }
            else {
                return sorted
            }
        }
        else {
            return items
        }
    }

    //////////////////
    // Filter Methods
    //////////////////

    handleFilterColumn = (dataKey, selectedItems, uniqueItems) => {
        var filters = this.setFilters(dataKey, uniqueItems.length===selectedItems.length ? null : selectedItems)
        this.setState({
            filters: filters,
            page: 1,
        })
    }

    filterItem = (item) => {

        // New items should always appear
        if (this.getItemStatus(item.pltr_table_id)===this.itemStatusEnum.CREATE) return true

        var filters = this.getFilters()

        for (let filterKey of Object.keys(filters)) {
            let filteredValues = filters[filterKey]
            if (!filteredValues) continue

            let value = item[filterKey]

            let check = deepIncludes(filteredValues, value)
            if (!check) return false
        }
        return true
    }

    /////////////////////
    // Pagination Methods
    /////////////////////

    paginateItems = (items) => {
        var displayLength = this.state.displayLength
        var page = this.state.page

        const start = displayLength * (page - 1);
        const end = start + displayLength;

        return items.filter((v, i) => {
            return i >= start && i < end;
        })
    }

    handlePageChange = (dataKey) => {
        this.setState({
            page: dataKey
        })
    }
    handleLengthChange = (dataKey) => {
        this.setState({
            page: 1,
            displayLength: dataKey
        });
    }

    ///////////////////
    // CRUD API methods
    ///////////////////

    crudTemplate = (requestBody, item, itemPltrId, endpoint, crudMethod) => {

        var flattenItem = this.props.flattenItem

        console.log(requestBody)

        axios.post(
            endpoint, requestBody
        ).then((response) => {

            try {
                // If the response includes the updated item, use it, else use the original item sent in the request
                let responseItem = response.data?.opResultItem || item
                let responseItemFlat = flattenItem ? flattenItem(responseItem) : responseItem
                responseItemFlat.pltr_table_id = itemPltrId // assign the old item pltr_id to the new flattened item

                var committedChanges, stagedChanges, statusTracker

                if (crudMethod==="create") {
                    [committedChanges, stagedChanges, statusTracker] = this.handleCreateSuccess(itemPltrId, responseItemFlat, responseItem)
                }
                else if (crudMethod==="update") {
                    [committedChanges, stagedChanges, statusTracker] = this.handleUpdateSuccess(itemPltrId, responseItemFlat, responseItem)
                }
                else if (crudMethod==="delete") {
                    [committedChanges, stagedChanges, statusTracker] = this.handleDeleteSuccess(itemPltrId, responseItemFlat, responseItem)
                }
                else {
                    throw new Error("CRUD Method must be one of: create, update, delete")
                }
                
                this.setState({
                    committedChanges: committedChanges,
                    stagedChanges: stagedChanges,
                    statusTracker: statusTracker,
                    loading: false,
                })
            }
            catch (e) {
                Alert.error(`The operation was successful, but an error occured processing the result.`, 0)
                console.error(e)
                this.setState({
                    loading: false
                })
            }
        }).catch((error) => {
            console.log(error)
            this.handleServerError(error, crudMethod)
        })

        this.setState({
            loading: true
        })
    }

    handleUpdateSuccess = (itemId, item, itemStructured) => {
        console.log(item, itemId)
        var committedChanges = this.commitChange(itemId, this.changeLogEnum.EDIT, item)
        var stagedChanges = this.setStagedChanges(itemId, {})
        var statusTracker = this.deleteStatusTracker(itemId)

        if (this.props.onUpdateSuccess) this.props.onUpdateSuccess(itemStructured)
        Alert.success(`Updated ${this.props.itemType} successfully`, 3000)

        return [committedChanges, stagedChanges, statusTracker]
    }
    handleCreateSuccess = (itemId, item, itemStructured) => {
        var committedChanges = this.commitChange(itemId, this.changeLogEnum.CREATE, item)
        var stagedChanges = this.setStagedChanges(itemId, {})
        var statusTracker = this.deleteStatusTracker(itemId)

        if (this.props.onCreateSuccess) this.props.onCreateSuccess(itemStructured)
        Alert.success(`Created ${this.props.itemType} successfully`, 3000)

        return [committedChanges, stagedChanges, statusTracker]
    }
    handleDeleteSuccess = (itemId, item, itemStructured) => {
        var committedChanges = this.commitChange(itemId, this.changeLogEnum.DELETE, item)
        var stagedChanges = this.setStagedChanges(itemId, {})
        var statusTracker = this.deleteStatusTracker(itemId)

        if (this.props.onDeleteSuccess) this.props.onDeleteSuccess(itemStructured)
        Alert.success(`Removed ${this.props.itemType} successfully`, 3000)

        return [committedChanges, stagedChanges, statusTracker]
    }

    handleServerError = (error, crudMethod) => {

        var itemType = this.props.itemType

        if (error.response?.status === 400 || error.response?.status === 403) {   // handle custom response from server
            let message = error.response?.data?.detail
            Alert.error(<div>
                <div>{`Failed to ${crudMethod} ${itemType}`}</div>
                <div style={{textDecoration: "underline", textAlign: "center", marginTop: "20px"}}>Error Message</div>
                <div>{message}</div>
            </div>, 0)
        }
        else if (error.response?.status === 401) {
            let message = "Your session has expired and you will not be able to save any updates. Please login again."
            Alert.error(`Failed to ${crudMethod} ${itemType}: ${message}`, 0)
        }
        else {
            Alert.error(`Failed to ${crudMethod} ${itemType}`, 0)
        }
        this.setState({
            loading: false
        })
    }

    createItem = (newItem) => {

        var crudMethod = "create"
        
        var validation = this.props.validateNewItem ? this.props.validateNewItem(newItem) : {pass: true}
        console.log(validation)
        if (validation.pass!==true) {
            Alert.info(validation.message, 5000)
            return
        }

        // remove the table id before sending the item to the specified endpoint
        // it will be reassigned to the new item
        var itemPltrId = newItem.pltr_table_id
        delete newItem.pltr_table_id

        newItem = this.props.structureItem ? this.props.structureItem(newItem) : newItem

        var requestBody = this.props.buildRequestBody ? this.props.buildRequestBody(crudMethod, {createdItem: newItem}) : {item: newItem}

        this.crudTemplate(requestBody, newItem, itemPltrId, this.props.endpoints.create, crudMethod)
    }

    deleteItem = (item) => {

        var crudMethod = "delete"

        var validation = this.props.validateDeleteItem ? this.props.validateDeleteItem(item) : {pass: true}
        if (validation.pass!==true) {
            Alert.info(validation.message, 5000)
            return
        }

        // remove the table id before sending the item to the specified endpoint
        var itemPltrId = item.pltr_table_id
        delete item.pltr_table_id

        item = this.props.structureItem ? this.props.structureItem(item) : item

        var requestBody = this.props.buildRequestBody ? this.props.buildRequestBody(crudMethod, {deletedItem: item}) : {item: item}

        this.crudTemplate(requestBody, item, itemPltrId, this.props.endpoints.delete, crudMethod)
        
    }

    updateItem = (updatedItem, originalItem) => {

        var crudMethod = "update"

        var validation = this.props.validateUpdateItem ? this.props.validateUpdateItem(updatedItem) : {pass: true}
        if (validation.pass!==true) {
            Alert.info(validation.message, 5000)
            return
        }

        // remove the table id before sending the item to the specified endpoint
        // it will be reassigned to the new item
        var itemPltrId = updatedItem.pltr_table_id
        delete updatedItem.pltr_table_id
        delete originalItem.pltr_table_id

        updatedItem = this.props.structureItem ? this.props.structureItem(updatedItem) : updatedItem
        originalItem = this.props.structureItem ? this.props.structureItem(originalItem) : originalItem

        var requestBody = this.props.buildRequestBody ? this.props.buildRequestBody(crudMethod, {updatedItem: updatedItem, originalItem: originalItem}) : {item: updatedItem}

        this.crudTemplate(requestBody, updatedItem, itemPltrId, this.props.endpoints.update, crudMethod)
        
    }

    //////////////////////////////
    // Handle table status changes
    //////////////////////////////

    /**
     * Handles update when a row state is changed
     * @param {Number} id Template id of row that was updated
     * @param {*} cancel Whether the update was cancelled
     */
     handleItemStateChange = (itemId, cancel) => {

        var itemStatus = this.getItemStatus(itemId)
        var editing = itemStatus===this.itemStatusEnum.EDIT
        var creating = itemStatus===this.itemStatusEnum.CREATE

        if (cancel && editing) {
            this.handleCancel(itemId)
        }
        else if (cancel && creating) {
            this.handleCancel(itemId)
        }
        else if (editing || creating) {
            this.handleSave(itemId, creating, editing)
        }
        else {
            this.handleEnterEditMode(itemId)
        }
    };

    handleCancel = (itemId) => {
        // Restore item to original state by removing all diffs associated with the item
        let stagedChanges = this.setStagedChanges(itemId, {})
        let statusTracker = this.deleteStatusTracker(itemId)
        this.setState({
            stagedChanges: stagedChanges,
            statusTracker: statusTracker,
        })
    }
    handleSave = (itemId, creating, editing) => {
        if (creating) {
            let stagedItems = this.getStagedItems()
            let newItem = stagedItems.find(x => x.pltr_table_id===itemId) || {}
            this.createItem(newItem) //
        }
        else if (editing) {
            let committedItems = this.getCommittedItems()
            let originalItem = committedItems.find(x => x.pltr_table_id===itemId) || {}   // this is the latest version of item with all commits applied
            let updatedItem = this.applyStagedChangesToItem(originalItem)
            this.updateItem(updatedItem, originalItem)
        }
        else {
            throw new Error("Item must have a edit or create status.")
        }
    }
    handleEnterEditMode = (itemId) => {
        let statusTracker = this.setStatusTracker(itemId, this.itemStatusEnum.EDIT)
        this.setState({
            statusTracker: statusTracker,
        })
    }

    /**
     * Updates the item diff state with newly edited values that have not yet been saved
     * @param {*} id id of the item (should be pltr_table_id field of input objects)
     * @param {*} key column key of item
     * @param {*} value updated value
     */
     handleRowDataChange = (id, key, value, render=true) => {
        var stagedChanges = render ? _.cloneDeep(this.state.stagedChanges) : this.state.stagedChanges
        var partitionChanges = stagedChanges[this.props.partitionKey]
        var itemChanges = partitionChanges[id]
        if (!itemChanges) {
            partitionChanges[id] = {}
        }

        if (Array.isArray(key) && Array.isArray(value)) {
            if (key.length!==value.length) throw "Key and value arrays must be of the same length."
            for (let i in key) {
                let k = key[i]
                let v = value[i]
                partitionChanges[id][k] = v
            }
        }
        else {
            partitionChanges[id][key] = value
        }

        // Default is to update the state which will render the whole table again.
        // This option can be toggled off, which will mutate the state directly, but this shouldn't really be necessary.
        // In the case of rapid updates, ex. when typing, can batch the updates using the hooks in ./Hooks.js instead of updating in place.
        if (render) {
            this.setState({
                //sortColumn: null,

                stagedChanges: stagedChanges,
            });
        }
    };

    //////////////
    // Item Status
    //////////////

    checkItemStatusEditingOrCreating = (id) => {
        var itemStatus = this.getItemStatus(id)
        return itemStatus===this.itemStatusEnum.EDIT || itemStatus===this.itemStatusEnum.CREATE;
    }
    getItemStatus = (id) => {
        var statusTrackerPartition = this.state.statusTracker[this.props.partitionKey] || {}
        return statusTrackerPartition[id]
    }

    //////////////////////////////////////////////
    // Methods Handling Application of Change Logs
    //////////////////////////////////////////////

    commitChange = (id, method, content) => {
        var change = {pltr_table_id: id, method: method, content: content}
        
        var committedChanges = _.cloneDeep(this.state.committedChanges)
        committedChanges[this.props.partitionKey].push(change)
        return committedChanges
    }

    getCommittedItems = () => {
        var items = _.cloneDeep(this.props.initialItems)
        var commitChangeLog = _.cloneDeep(this.state.committedChanges)[this.props.partitionKey] || []
        var committedItems = this.applyChangeLog(items, commitChangeLog)
        return committedItems
    }
    getStagedItems = () => {
        var items = this.getCommittedItems()
        var stagedChanges = _.cloneDeep(this.state.stagedChanges)[this.props.partitionKey] || {}
        var stagedItems = this.applyStagedChanges(items, stagedChanges)
        return stagedItems
    }
    applyStagedChangesToItem = (item) => {
        var itemId = item.pltr_table_id
        var stagedChangesPartition = this.state.stagedChanges[this.props.partitionKey] || {}
        var stagedChanges = stagedChangesPartition[itemId] || {}
        var newItem = Object.assign({}, item, stagedChanges)
        return newItem
    }
    
    applyStagedChanges = (items, stagedChanges) => {
        Object.keys(stagedChanges).forEach(changeId => {
            let item = items.find(x => x.pltr_table_id===Number(changeId))
            let changeContent = stagedChanges[changeId]
            if (!item) {   // new item change
                items.push(changeContent)
            }
            else {   // existing item change
                Object.assign(item, changeContent)
            }
        })
        return items
    }
    
    applyChangeLog = (items, changeLog) => {
        changeLog.forEach(change => {
            let changeId = change.pltr_table_id
            let changeMethod = change.method
            let changeContent = change.content
            
            if (changeMethod===this.changeLogEnum.EDIT) {
                let item = items.find(x => x.pltr_table_id===changeId)
                console.log(items, item, change)
                Object.assign(item, changeContent)   // mutates original item with applied changes
            }
            else if (changeMethod===this.changeLogEnum.CREATE) {
                items.push(changeContent)
            }
            else if (changeMethod===this.changeLogEnum.DELETE) {
                let deleteIdx = items.findIndex(x => x.pltr_table_id===changeId)
                if (deleteIdx===-1) return   // only delete if idx was found, else could be erroneously deleting
                else items.splice(deleteIdx, 1)
            }
        })
        return items
    }

    ///////////////////
    // Handle New Items
    ///////////////////

    duplicateItemEntry = (entry) => {
        var newEntry = _.cloneDeep(entry)
        // Remove read only values from the duplicated item as the user won't be able to change these in the new item
        const editableColumns = this.props.tableConfig.columns.filter(x => !["standard", "display", "readOnly"].includes(x.cellType)).map(x => x.key)
        Object.keys(newEntry).forEach(key => {
            if (!editableColumns.includes(key)) delete newEntry[key]
        })
        this.addItemEntry(newEntry, true)
    }

    /**
     * Adds a new item to the items state using the prop itemSchema
     * @returns null
     */
     addItemEntry = (baseItem={}, validateNoExistingCreations=true) => {

        if (validateNoExistingCreations && !this.checkPresenceCreatingItem()) return

        var newItemId = this.determineMinAllocatableId()
        var template = Object.assign({}, _.cloneDeep(this.props.itemSchema), baseItem, {"pltr_table_id": newItemId})

        var stagedChanges = this.setStagedChanges(newItemId, template)
        var statusTracker = this.setStatusTracker(newItemId, this.itemStatusEnum.CREATE)

        var filteredItems = this.getStagedItems().filter(x => this.filterItem(x))
        // Move user to last page as that is where the new item will show up
        var lastPage = Math.ceil((filteredItems.length+1) / this.state.displayLength)

        this.setState({
            statusTracker: statusTracker,
            stagedChanges: stagedChanges,
            sortColumn: null,
            page: lastPage,
        })
    }

    checkPresenceCreatingItem = () => {
        for (let x of Object.entries(this.state.statusTracker[this.props.partitionKey])) {
            if (x[1]===this.itemStatusEnum.CREATE) {
                Alert.info("Only one item may be created at a time.")
                return false
            } 
        }
        return true
    }
    determineMinAllocatableId = () => {
        var items = this.getStagedItems()   // get staged items because new items may be staged which will contain used ids
        var itemIds = items.map(x => x.pltr_table_id)
        itemIds = _.sortBy(itemIds)
        return this.determineMinUnusedInteger(itemIds)
    }
    determineMinUnusedInteger = (items) => {
        var sortedItems = _.sortBy(items)
        
        if (sortedItems.length===0 || sortedItems[0]!==1) return 1
        
        var prev = null
        for (let id of sortedItems) {
            if (prev && id!==prev+1) return prev+1
            prev = id
        }
        return prev+1
    }

    ////////////////
    // Misc. Helpers
    ////////////////

    getTransformDisplayValueFunction = (cellOptions) => {
        var dvf = cellOptions?.transformDisplayValue
        if (dvf) return dvf

        return (v) => {
            let dv;
            // false messes up switch for some reason
            if (v===false) return "false"
            if (v===undefined) return "null"
            let vtype = typeof(v)
            switch (vtype) {
                case "object":
                    dv = JSON.stringify(v)
                    break
                case "number":
                    dv = v
                    break
                default:
                    dv = String(v)
            }
            return dv
        }
    }

    //////////////////////
    // Getters and Setters
    //////////////////////

    setStagedChanges = (id, changes) => {
        var stagedChanges = _.cloneDeep(this.state.stagedChanges)
        if (Object.keys(changes).length===0) delete stagedChanges[this.props.partitionKey][id]   // delete the entry if there are no changes
        else stagedChanges[this.props.partitionKey][id] = changes
        return stagedChanges
    }

    setStatusTracker = (id, status) => {
        var statusTracker = _.cloneDeep(this.state.statusTracker)
        statusTracker[this.props.partitionKey][id] = status
        return statusTracker
    }
    deleteStatusTracker = (id) => {
        var statusTracker = _.cloneDeep(this.state.statusTracker)
        delete statusTracker[this.props.partitionKey][id]
        return statusTracker
    }

    getFilters = (id) => {
        var filters = _.cloneDeep(this.state.filters)[this.props.partitionKey] || {}
        if (id) return filters[id]
        return filters
    }
    setFilters = (id, values) => {
        var filters = _.cloneDeep(this.state.filters)
        filters[this.props.partitionKey][id] = values
        return filters
    }

}

function TableInterceptor(props) {

    var items = _.cloneDeep(props.initialItems)
    if (props.flattenItem) items = items.map(x => props.flattenItem(x))
    items.forEach((x, idx) => x.pltr_table_id=idx+1)

    return (
        <CRUDTable 
            {...props}
            initialItems={items}
        />
    )

}

export default TableInterceptor