import '../ViewPanel.css'
import './Plant.css'
import React, { useState } from 'react'

import ViewPanel from '../ViewPanel'
import Identification from './Identification'
import Entities from './Entities'
import PlantMetadata from './PlantMetadata'
import PlantDates from './Dates'
import Address, { buildAddress } from './Address'
import Notes from './Notes'
import { Plant } from '../../table_configuration/Project'

import _ from 'lodash'
import { PlantLLC } from '../../table_configuration/LLC'
import { Fund, Portfolio } from '../../table_configuration/Entity'
import { AssetManager } from '../../table_configuration/Counterparty'
import { ErrorRND, RNDErrorInstance, ViewFormError } from '../../../utils/databaseAppUtils'
import Summary from './Summary'

const plantSchema = Plant.columnSchema


export default function PlantPanel(props) {

    // This contains the latest plant data that has been persisted to the database
    // Updated when the form is successfully submitted
    const [updatedPlant, setUpdatedPlant] = useState(props.selectedPlant)
    const [errors, setErrors] = useState(new ErrorRND())

    const [plantPartIdentification, setPlantPartIdentification] = useState(_.pick(props.selectedPlant, [
        plantSchema.plantId, plantSchema.plantName
    ]))
    const [plantPartEntities, setPlantPartEntities] = useState(_.pick(props.selectedPlant, [
        plantSchema.llcId, plantSchema.fundId, plantSchema.portfolioId,
        plantSchema.assetManagementCompanyId, plantSchema.subPortfolioName, plantSchema.website
    ]))
    const [plantNotes, setPlantNotes] = useState({
        [plantSchema.notes]: props.selectedPlant[plantSchema.notes]
    })
    const [plantSummary, setPlantSummary] = useState({
        [plantSchema.summary]: props.selectedPlant[plantSchema.summary]
    })
    const [plantPartLocation, setPlantPartLocation] = useState(_.pick(props.selectedPlant, [
        plantSchema.streetAddress, plantSchema.country, plantSchema.region,
        plantSchema.city, plantSchema.county, plantSchema.state,
        plantSchema.latitude, plantSchema.longitude, plantSchema.timezone,
        plantSchema.zipcode,
    ]))
    const [plantPartDates, setPlantPartDates] = useState(_.pick(props.selectedPlant, [
        plantSchema.plannedCommercialOperationDate, plantSchema.commercialOperationDate, plantSchema.targetCommercialOperationDate, plantSchema.baseCommercialOperationDate, plantSchema.downsideCommercialOperationDate,
        plantSchema.actualFinancialCloseDate, plantSchema.memoApprovedFinancialCloseDate, plantSchema.targetFinancialCloseDate, plantSchema.baseFinancialCloseDate, plantSchema.downsideFinancialCloseDate
    ]))
    const [plantPartMetadata, setPlantPartMetadata] = useState(_.pick(props.selectedPlant, [
        plantSchema.stage, plantSchema.developmentSubstage, plantSchema.stageAcquired, , plantSchema.commercialViability, plantSchema.scale, plantSchema.technology,
        plantSchema.CPsNeededInPPA, plantSchema.acquisitionDate, plantSchema.soldDate, plantSchema.writeOffDate,
        plantSchema.developmentViability, plantSchema.fuelType, plantSchema.capacityMwAc, plantSchema.capacityMwDc,
        plantSchema.storageCapacityMw, plantSchema.storageCapacityMwh,
        plantSchema.domesticContent, plantSchema.prevailingWage, plantSchema.itcQualifying,
        plantSchema.boardApproval, plantSchema.boardApprovalDate, plantSchema.boardApprovalNotes
    ]))
    const updatePlantPartIdentification = (update) => {
        let newPlantPart = Object.assign({}, plantPartIdentification, update)
        setPlantPartIdentification(newPlantPart)
    }
    const updatePlantPartEntities = (update) => {
        let newPlantPart = Object.assign({}, plantPartEntities, update)
        setPlantPartEntities(newPlantPart)
    }
    const updatePlantPartLocation = (update) => {
        let newPlantPart = Object.assign({}, plantPartLocation, update)
        setPlantPartLocation(newPlantPart)
    }
    const updatePlantPartDates = (update) => {
        let newPlantPart = Object.assign({}, plantPartDates, update)
        setPlantPartDates(newPlantPart)
    }
    const updatePlantPartMetadata = (update) => {
        let newPlantPart = Object.assign({}, plantPartMetadata, update)
        setPlantPartMetadata(newPlantPart)
    }
    const assemblePlant = () => {
        return Object.assign({},
            plantPartIdentification, plantPartEntities, 
            plantPartLocation, plantPartDates, plantPartMetadata, plantNotes, plantSummary
        )
    }

    var replaceF = (x) => x.replaceAll(" ", "+")
    var address = replaceF(plantPartLocation.street_address || "")
    var city = replaceF(plantPartLocation.city || "")
    var q = plantPartLocation.street_address ? buildAddress(address, city, plantPartLocation.state, plantPartLocation.zipcode) : `${plantPartLocation.latitude},${plantPartLocation.longitude}`
    var mapsEmbedLink = `
    https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GMAP_KEY}
    &q=${q}
    &center=${plantPartLocation.latitude},${plantPartLocation.longitude}
    &zoom=6
    &maptype=satellite
    `

    return (
        <ViewPanel
            services={props.services}
            title="Plant Information"
            hideOnInit={props.hideOnInit}
            submitUrl='api/precious/table/plant'
            verifySubmit={(payload) => {
                let newErr = new ErrorRND()

                // Check plant has a name
                if (!payload.newRecord[Plant.columnSchema.plantName]) {
                    newErr.add(new RNDErrorInstance("Please enter a name"), "plantName")
                }
                
                if (!newErr.empty()) {
                    setErrors(newErr)
                    throw new ViewFormError("Please fix all errors before submitting.")
                }
                return {
                    success: true,
                    message: null,
                }
            }}
            onSubmitSuccess={(response, requestPayload) => {
                props.handleUpdate(true)
                setErrors(new ErrorRND())
                setUpdatedPlant(requestPayload.newRecord)
            }}
            onSubmitError={null}
            buildSubmitPayload={() => {
                let plant = assemblePlant()
                return {
                    createRecord: false,
                    newRecord: plant,
                    originalRecord: updatedPlant,
                }
            }}
        >
            <div className="form-instance flow-horizontal" style={{minWidth: "1100px"}}>
                <div style={{marginRight: "10px"}}>
                    <div className="flow-horizontal" style={{marginBottom: "25px"}}>
                        <div style={{marginRight: "20px"}}>
                            <Identification
                                plantId={plantPartIdentification[plantSchema.plantId]}
                                plantName={plantPartIdentification[plantSchema.plantName]}
                                errors={errors}
                                updatePlant={updatePlantPartIdentification}
                            />
                            <iframe
                                width="620"
                                height="450"
                                style={{
                                    border: "none",
                                    margin: "30px 0 0 15px"
                                }}
                                loading="lazy"
                                src={mapsEmbedLink}>
                            </iframe>
                        </div>
                        <div className="flow-vertical fill-parent">
                            <Entities
                                plantLlcId={plantPartEntities[plantSchema.llcId]}
                                plantPortfolioId={plantPartEntities[plantSchema.portfolioId]}
                                plantFundId={plantPartEntities[plantSchema.fundId]}
                                plantManagementId={plantPartEntities[plantSchema.assetManagementCompanyId]}
                                plantSubPortfolioName={plantPartEntities[plantSchema.subPortfolioName]}
                                plantWebsite={plantPartEntities[plantSchema.website]}
                                llcs={props.data[PlantLLC.buildId()]}
                                portfolios={props.data[Portfolio.buildId()]}
                                funds={props.data[Fund.buildId()]}
                                management={props.data[AssetManager.buildId()]}
                                updatePlant={updatePlantPartEntities}
                            />
                            <Notes
                                plantNotes={plantNotes[plantSchema.notes]}
                                updatePlant={(x) => setPlantNotes({[plantSchema.notes]: x})}
                            />
                            <Summary
                                plantSummary={plantSummary[plantSchema.summary]}
                                updatePlant={(x) => setPlantSummary({[plantSchema.summary]: x})}
                            />
                        </div>
                    </div>
                    <PlantDates
                        services={props.services}
                        plantStage={plantPartMetadata[plantSchema.stage]}
                        plantPlannedCommercialOperationDate={plantPartDates[plantSchema.plannedCommercialOperationDate]}
                        plantCommercialOperationDate={plantPartDates[plantSchema.commercialOperationDate]}
                        plantTargetCommercialOperationDate={plantPartDates[plantSchema.targetCommercialOperationDate]}
                        plantBaseCommercialOperationDate={plantPartDates[plantSchema.baseCommercialOperationDate]}
                        plantDownsideCommercialOperationDate={plantPartDates[plantSchema.downsideCommercialOperationDate]}
                        plantActualFinancialCloseDate={plantPartDates[plantSchema.actualFinancialCloseDate]}
                        plantMemoApprovedFinancialCloseDate={plantPartDates[plantSchema.memoApprovedFinancialCloseDate]}
                        plantTargetFinancialCloseDate={plantPartDates[plantSchema.targetFinancialCloseDate]}
                        plantBaseFinancialCloseDate={plantPartDates[plantSchema.baseFinancialCloseDate]}
                        plantDownsideFinancialCloseDate={plantPartDates[plantSchema.downsideFinancialCloseDate]}
                        updatePlant={updatePlantPartDates}
                    />
                    <Address
                        plantStreetAddress={plantPartLocation[plantSchema.streetAddress]}
                        plantCountry={plantPartLocation[plantSchema.country]}
                        plantRegion={plantPartLocation[plantSchema.region]}
                        plantState={plantPartLocation[plantSchema.state]}
                        plantCounty={plantPartLocation[plantSchema.county]}
                        plantCity={plantPartLocation[plantSchema.city]}
                        plantZipcode={plantPartLocation[plantSchema.zipcode]}
                        plantLatitude={plantPartLocation[plantSchema.latitude]}
                        plantLongitude={plantPartLocation[plantSchema.longitude]}
                        plantTimezone={plantPartLocation[plantSchema.timezone]}
                        updatePlant={updatePlantPartLocation}
                    />
                </div>
                <PlantMetadata
                    services={props.services}
                    plantStage={plantPartMetadata[plantSchema.stage]}
                    plantDevelopmentSubstage={plantPartMetadata[plantSchema.developmentSubstage]}
                    plantCPsNeededInPPA={plantPartMetadata[plantSchema.CPsNeededInPPA]}
                    plantStageAcquired={plantPartMetadata[plantSchema.stageAcquired]}
                    plantSoldDate={plantPartMetadata[plantSchema.soldDate]}
                    plantWriteOffDate={plantPartMetadata[plantSchema.writeOffDate]}
                    plantAcquisitionDate={plantPartMetadata[plantSchema.acquisitionDate]}
                    plantCommercialViability={plantPartMetadata[plantSchema.commercialViability]}
                    plantDevelopmentViability={plantPartMetadata[plantSchema.developmentViability]}
                    plantScale={plantPartMetadata[plantSchema.scale]}
                    plantTechnology={plantPartMetadata[plantSchema.technology]}
                    plantFuelType={plantPartMetadata[plantSchema.fuelType]}
                    plantCapacityMwDc={plantPartMetadata[plantSchema.capacityMwDc]}
                    plantCapacityMwAc={plantPartMetadata[plantSchema.capacityMwAc]}
                    plantStorageCapacityMw={plantPartMetadata[plantSchema.storageCapacityMw]}
                    plantStorageCapacityMwh={plantPartMetadata[plantSchema.storageCapacityMwh]}
                    plantDomesticContent={plantPartMetadata[plantSchema.domesticContent]}
                    plantPrevailingWage={plantPartMetadata[plantSchema.prevailingWage]}
                    plantITCQualifying={plantPartMetadata[plantSchema.itcQualifying]}
                    plantBoardApproval={plantPartMetadata[plantSchema.boardApproval]}
                    plantBoardApprovalDate={plantPartMetadata[plantSchema.boardApprovalDate]}
                    plantBoardApprovalNotes={plantPartMetadata[plantSchema.boardApprovalNotes]}
                    updatePlant={updatePlantPartMetadata}
                />
            </div>
        </ViewPanel>
    )
}