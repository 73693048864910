import './DimensionSelectionModal.css'
import {Component} from 'react'

import { SideNav, Button, Modal, Alert } from 'rsuite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'

import DeviceMenu from '../menu/DeviceMenu'
import TagDimensionMenu from '../menu/TagDimensionMenu'
import ExpressionEditor from './ExpressionEditor'
import { SimpleSideNav } from '../navigation/SimpleNavs'

class DeviceMenuModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            selectedDeviceId: null,
            selectedTagIds: [],
            viisbleDimensionIds: [],
            selectedDimensionIds: {},
        };

        this.editor = null
        this.saveTimer = null
    }

    componentDidMount() {
        console.log("Mounting express editor")

        this.saveTimer = setInterval(() => {
            if (this.state.show && this.editor) this.props.onClose(this.editor.getValue())
        }, 30000)
    }

    componentWillUnmount() {
        if (this.saveTimer) clearInterval(this.saveTimer)
    }

    close = (cancel) => {
        if (cancel) {
            this.setState({
                show: false,
            });
        }
        else {
            this.setState({
                show: false
            })
            if (this.props.onClose) this.props.onClose(this.editor.getValue())
        }
    }
    open = () => {
        this.setState({ show: true });
    }
    render() {

        var devices = this.props.services.assetFramework.getDevices().find(x => x._id===this.state.selectedDeviceId) || {}
        var tagType = devices.services?.interval_tags ? "interval" : "source"

        return (
            <div className="modal-container">
                <FontAwesomeIcon onClick={this.open} icon={faCog} style={{fontSize: '16px'}} fixedWidth className="fa-spin-hover" />

                <Modal show={this.state.show} onHide={() => this.close(false)} full>
                    <Modal.Header>
                        <Modal.Title>Select Your Devices</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="flow-horizontal overflow-y" style={{minHeight: "600px"}}>
                            <SimpleSideNav 
                                options={{
                                    "T1": () => <div>testst</div>,
                                    "T2": () => <div>tesasdfasdt</div>
                                }}
                            />
                            {/*<DeviceMenu 
                                services={this.props.services} 
                                selectedSiteId={this.props.selectedSiteId} 
                                selectedDeviceIds={[this.state.selectedDeviceId]} 
                                onDeviceClick={this.updateSelectedDeviceId}
                                className="section-panel"
                                menuProps={{
                                    style: {
                                        marginRight: "20px",
                                        minWidth: "225px",
                                        maxWidth: "225px"
                                    }
                                }}
                            />
                            <TagDimensionMenu 
                                services={this.props.services}
                                onTagClick={this.updateSelectedTagIds}
                                onDimensionClick={this.updateSelectedDimensionIds}
                                selectedSiteId={this.props.selectedSiteId}
                                selectedDeviceIds={[this.state.selectedDeviceId]}
                                tagType={tagType}
                                className={"section-panel"}
                                style={{marginRight: '20px', minWidth: '200px', maxWidth: '200px'}}
                                tagMenuTitle={"Tags"}
                            />*/}
                            <div className="flow-vertical" style={{marginRight: "20px", flexShrink: 0}}>
                                <div>Add Specific Reference</div>
                                <Button
                                    onClick={() => {
                                        if (this.editor) {
                                            let reference = this.createReferenceFromAssetSelection(true)
                                            if (reference) this.editor.insert(reference)
                                        }
                                    }}
                                >
                                    Add
                                </Button>
                                <div>Add Generic Reference</div>
                                <Button
                                    onClick={() => {
                                        if (this.editor) {
                                            let reference = this.createReferenceFromAssetSelection(false)
                                            if (reference) this.editor.insert(reference)
                                        }
                                    }}
                                >
                                    Add
                                </Button>
                            </div>
                            <div className="fill-parent" style={{marginRight: "20px"}}>
                                <ExpressionEditor
                                    expression={this.props.expression || ""}
                                    getEditorRef={(editor) => {
                                        this.editor = editor
                                    }}
                                />
                            </div>
                            <div className="flow-vertical" style={{marginRight: "20px", minWidth: "200px", border: "solid var(--light) 1px", padding: "15px"}}>
                                <div>Test Code</div>
                                <hr></hr>
                                <p>
                                    These are the variables you have access to
                                </p>
                                <p>
                                    This would be the output if the associated blueprint were evaluated right now.
                                </p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.close(false)} appearance="primary">
                        Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

    createReferenceFromAssetSelection = (includeDevice) => {
        var deviceId = this.state.selectedDeviceId
        var tagIds = this.state.selectedTagIds
        var dimensionIds = this.state.selectedDimensionIds

        if (!deviceId) {Alert.error("Please select a device."); return false}
        if (tagIds.length !== 1) {Alert.error("Please select exactly one tag."); return false}

        var tagId = tagIds[0]

        var reference = includeDevice ? `$$device.${deviceId}$tag.${tagId}` : `$$tag.${tagId}`

        if (this.state.visibleDimensionIds) {
            for (let vizId of this.state.visibleDimensionIds.sort()) {
                if ((dimensionIds[vizId] || []).length !== 1) {
                    Alert.error("Please select exaclty one of each dimension")
                    return false
                }
            }
            var dimensionReference = Object.keys(dimensionIds).map(dk => {
                let dv = dimensionIds[dk][0]
                return `$dk.${dk}$dv.${dv}`
            }).join("")
            reference+=dimensionReference
        }

        return reference
    }
    
    updateSelectedDeviceId = (id) => {
        this.setState({
            selectedDeviceId: id
        })
    }

    updateSelectedTagIds = (tagIds, dimIds, visibleDimIds) => {
        this.setState({
            selectedTagIds: tagIds,
            selectedDimensionIds: dimIds,
            visibleDimensionIds: visibleDimIds,
        })
    }

    updateSelectedDimensionIds = (dimIds) => {
        this.setState({
            selectedDimensionIds: dimIds,
        })
    }

}

  export default DeviceMenuModal