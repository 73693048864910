import React, {Component, useState} from 'react'

import Panel from '../../panel/Panel'
import ProductionOverviewDataProvider from './Power'
import RTE from './RTE'
import BESSAvailability from './Availability'

import { getStartTime, setStartTime, getEndTime, setEndTime, getResolution, setResolution } from '../../../utils/UrlLocation'
import { useOutletContext } from 'react-router-dom'

const HomeOverview = ({selectedSiteId, ...props}) => {

    const { assetFrameworkServices } = useOutletContext()

    //const [selectedStartDate, setSelectedStartDate] = useState(getStartTime())
    //const [selectedEndDate, setSelectedEndDate] = useState(getEndTime())
    //const [selectedResolution, setSelectedResolution] = useState(getResolution())


    if (!selectedSiteId) {
        return <Panel className="section-main">Please select a site</Panel>
    }

    var timezone = assetFrameworkServices.getSiteTimezone(selectedSiteId)

    return (
        <div className="section-main flow-vertical fill-parent">
            <div className="section-header flow-horizontal flex-fixed" style={{marginBottom: "20px"}}>
                <div className="title">Project Synopsis</div>
            </div>
            <div className="overflow-y">
                <div className="flow-horizontal" style={{height: "400px", marginBottom: "20px"}}>
                    <div className="flow-vertical section-panel" style={{width: "70%", marginRight: "20px", padding: "15px 15px 0 15px"}}>
                        <ProductionOverviewDataProvider
                            selectedSiteId={selectedSiteId}
                            timezone={timezone}
                        />
                    </div>
                    <div className="section-panel flow-vertical fill-parent" style={{width: "30%", alignItems: "center", justifyContent: "center"}}>More KPIs Coming Soon</div>
                </div>
                <div className="section-panel flow-vertical" style={{height: "350px", marginBottom: "20px", padding: "15px 15px 0 15px"}}>
                    <RTE
                        selectedSiteId={selectedSiteId}
                        timezone={timezone}
                    />
                </div>
                <div className="section-panel flow-vertical" style={{height: "400px", padding: "15px 15px 0 15px"}}>
                    <BESSAvailability
                        selectedSiteId={selectedSiteId}
                        timezone={timezone}
                    />
                </div>
            </div>
        </div>
    )

}

export default HomeOverview