import React, {Component} from 'react'

import AvailabilityGauge from './AvailabilityGauge'


class AvailabilitySummary extends Component {

    constructor(props) {
        super()
        this.state = {

        }
        
    }

    render() {

        var [height, width] = [100, 145]

        return (
            <div className="flow-vertical fill-parent" style={{overflow: "auto"}}>
                <div className="flow-horizontal"  style={{minHeight: height}}>
                    <AvailabilityGauge
                        title={"Today"}
                        height={height}
                        width={width}
                        value={Number(this.props.data.today.toFixed(2))}
                    />
                    <AvailabilityGauge
                        title={"WTD"}
                        height={height}
                        width={width}
                        value={Number(this.props.data.cur_week.toFixed(2))}
                    />
                </div>
                <div className="flow-horizontal" style={{minHeight: height}}>
                    <AvailabilityGauge
                        title={"MTD"}
                        height={height}
                        width={width}
                        value={Number(this.props.data.cur_month.toFixed(2))}
                    />
                    <AvailabilityGauge
                        title={this.props.data.prev_month.month}
                        height={height}
                        width={width}
                        value={Number(this.props.data.prev_month.value.toFixed(2))}
                    />
                </div>
            </div>
        )
    }

}

export default AvailabilitySummary