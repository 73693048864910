import React, { useState } from 'react'

import ViewPanel from '../../ViewPanel'
import { 
    PalantirDatePicker,
    PalantirAutocomplete
} from '../../../../components/input/SelectPicker'
import { PalantirTextField, PalantirDispatchedTextField, makeTextMaskNumber } from '../../../../components/input/Text'
import { useChangeLog } from '../../../../hooks/changeLog'
import { useDBViewFormValidation } from '../../../../hooks/databaseViewFormValidation'
import { AddFloaterButtonWithPrompt, DeleteFloaterButtonWithPrompt } from '../../../../components/button/FloaterButtonWithPrompt'
import { generateUUID } from '../../../../utils/databaseAppUtils'

import _ from 'lodash'
import { EquipmentDeliveryPaymentMilestone, EquipmentPaymentRule, EquipmentPaymentStructureTemplate, EquipmentPurchaseContract, processPaymentRule } from '../../../table_configuration/Payment'
import { OEM } from '../../../table_configuration/Counterparty'
import { Button, Text } from '@mantine/core'
import { PalantirBasicCRUDMenu } from '../../../../components/menuv2/Menu'
import { DialogProvider, useDialog } from '../../../../components/dialog/DialogProvider'
import { DialogContentText } from '@mui/material'
import { CardHeaderWithMenu } from '../../../../components/panelv2/Panel2'
import { Counterparty } from '../../../table_configuration/Entity'
import {
    sub as subDate, add as addDate,
    subMilliseconds, subSeconds, subMinutes, subHours, subDays, subWeeks, subMonths, subQuarters, subYears,
    addMilliseconds, addSeconds, addMinutes, addHours, addDays, addWeeks, addMonths, addQuarters, addYears
} from 'date-fns'
import { Alert, DatePicker } from 'rsuite'

const TextMaskNumber = makeTextMaskNumber({
    min: 0
})



export default function EquipmentPanelProxy(props) {

    const contractRecords = props.data[EquipmentPurchaseContract.buildId()].filter(x => x[EquipmentPurchaseContract.columnSchema.projectId]===props.selectedPlantId)
    const contractIds = contractRecords.map(x => x[EquipmentPurchaseContract.columnSchema.oid])
    const equipmentPaymentStructureTemplateRecords = props.data[EquipmentPaymentStructureTemplate.buildId()]
    const equipmentPaymentRuleRecords = props.data[EquipmentPaymentRule.buildId()]
    const contractEquipmentDeliveryPaymentMilestoneRecords = _.sortBy(props.data[EquipmentDeliveryPaymentMilestone.buildId()].filter(x => contractIds.includes(x[EquipmentDeliveryPaymentMilestone.columnSchema.contractId])), (milestone) => {
        const rule = equipmentPaymentRuleRecords.find(x => x[EquipmentPaymentRule.columnSchema.oid]===milestone[EquipmentDeliveryPaymentMilestone.columnSchema.paymentRuleId])
        if (!rule) return null
        return rule[EquipmentPaymentRule.columnSchema.processingOrder]
    })
    const OEMRecords = props.data[OEM.buildId()]

    return (
        <DialogProvider>
            <EquipmentContractPanel
                initialProjectContracts={contractRecords}
                initialContractEquipmentDeliveryPaymentMilestoneRecords={contractEquipmentDeliveryPaymentMilestoneRecords}
                OEMRecords={OEMRecords}
                equipmentPaymentStructureTemplateRecords={equipmentPaymentStructureTemplateRecords}
                equipmentPaymentRuleRecords={equipmentPaymentRuleRecords}
                {...props}
            />
        </DialogProvider>
    )

}


function EquipmentContractPanel({initialProjectContracts, initialContractEquipmentDeliveryPaymentMilestoneRecords, OEMRecords, equipmentPaymentStructureTemplateRecords, equipmentPaymentRuleRecords,  ...props}) {

    const [
        contractRecords, prepareLog, updateLog, addToLog,
        deleteFromLog, bulkOpOnLog, mergeAndResetLog, resetLog, popLog
    ] = useChangeLog(initialProjectContracts, EquipmentPurchaseContract)
    const [selectedContractId, setSelectedContractId] = useState(null)
    const [isCreating, setIsCreating] = useState(false)
    const selectedContract = contractRecords.find(x => x[EquipmentPurchaseContract.columnSchema.oid]===selectedContractId)
    
    const [
        contractPaymentMilestonesRecords, prepareMilestoneLog, updateMilestoneLog, addToMilestoneLog,
        deleteFromMilestoneLog, bulkOpOnMilestoneLog, mergeAndResetMilestoneLog, resetMilestoneLog, popMilestoneLog
    ] = useChangeLog(initialContractEquipmentDeliveryPaymentMilestoneRecords, EquipmentDeliveryPaymentMilestone)
    const filteredPaymentMilestoneRecords = contractPaymentMilestonesRecords.filter(x => x[EquipmentDeliveryPaymentMilestone.columnSchema.contractId]===selectedContractId)

    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()
    const [openDialog, closeDialog] = useDialog();
    const updateContract = (update) => updateLog(selectedContract, update)

    const addToContractLog = () => {
        const contractID = generateUUID()
        const contract = EquipmentPurchaseContract.buildNewRecord()
        contract[EquipmentPurchaseContract.columnSchema.projectId] = props.selectedPlantId
        contract[EquipmentPurchaseContract.columnSchema.oid] = contractID
        setSelectedContractId(contractID)
        bulkOpOnLog([{
            method: "clear"
        }, {
            method: "insert", changes: [contract]
        }])
        resetMilestoneLog()
    }
    const addToPaymentMilestoneLog = (arg) => {
        if (arg) addToMilestoneLog(arg)
        else {
            const milestone = EquipmentDeliveryPaymentMilestone.buildNewRecord()
            milestone[EquipmentDeliveryPaymentMilestone.columnSchema.oid] = generateUUID()
            milestone[EquipmentDeliveryPaymentMilestone.columnSchema.contractId] = selectedContractId
            addToMilestoneLog(milestone)
        }
    }

    const createContract = () => {
        const create = () => {
            addToContractLog()
            resetErrors()
            setIsCreating(true)
        }
        if (isCreating) {
            window.alert("You may only add one at a time.")
            return
        }
        if (prepareLog().length > 0 || prepareMilestoneLog().length > 0) {
            openDialog({
                title: "You have unsaved edits.",
                body: <DialogContentText>Are you sure you want to discard your changes?</DialogContentText>,
                onAccept: () => {
                    closeDialog()
                    create()
                }
            });
        }
        else create()
    }
    const selectContract = (record) => {
        if (record[EquipmentPurchaseContract.columnSchema.oid]===selectedContractId) return
        const switchSelection = () => {
            setSelectedContractId(record[EquipmentPurchaseContract.columnSchema.oid])
            resetLog()
            resetMilestoneLog()
            resetErrors()
            setIsCreating(false)
        }
        if (prepareLog().length > 0 || prepareMilestoneLog().length > 0) {
            openDialog({
                title: "You have unsaved edits.",
                body: <DialogContentText>Are you sure you want to discard your changes?</DialogContentText>,
                onAccept: () => {
                    closeDialog()
                    switchSelection()
                }
            });
        }
        else {
            switchSelection()
        }
    }
    const deleteContract = (callback) => {
        openDialog({
            title: "Watch out!",
            body: <DialogContentText>Are you sure you want to delete the selected Contract and all associated Payment Milestones?</DialogContentText>,
            onAccept: () => {
                closeDialog()
                setSelectedContractId(null)
                deleteFromLog(selectedContract)
                deleteFromMilestoneLog(filteredPaymentMilestoneRecords)
                setTimeout(() => {
                    callback({
                        onFail: () => {
                            resetErrors()
                            setSelectedContractId(selectedContractId)
                            popLog()
                            popMilestoneLog(filteredPaymentMilestoneRecords.length)
                        }
                    })
                }, 100)
            }
        });
    }

    return (
        <div className="flow-horizontal fill-parent" style={{overflow: "auto"}}>
            <PalantirBasicCRUDMenu
                title="Equipment Contracts"
                selectedId={selectedContractId}
                records={contractRecords}
                recordIdCol={EquipmentPurchaseContract.columnSchema.oid}
                recordNameCol={EquipmentPurchaseContract.columnSchema.contractName}
                onSelect={(record) => {
                    selectContract(record)
                }}
                //isCreateActive={isCreating}
                onAdd={() => {
                    createContract()
                }}
                style={{marginRight: "20px", marginTop: "25px", width: "225px", flexShrink: 0}}
            />
            <ViewPanel
                services={props.services}
                submitUrl='api/precious/table/equipment_purchase_contract'
                verifySubmit={(payload) => {
                    return verifyChangelogSubmission(
                        {
                            changeLog: payload.contractChangeLog,
                            checks: []
                        }, {
                            changeLog: payload.contractMilestonesChangeLog,
                            checks: []
                        }
                    )
                }}
                onSubmitSuccess={(response, requestPayload) => {
                    props.handleUpdate(false)
                    resetErrors()
                    mergeAndResetLog()
                    mergeAndResetMilestoneLog()
                    setIsCreating(false)
                }}
                showDelete={!isCreating && selectedContractId}
                onDelete={deleteContract}
                onSubmitError={null}
                buildSubmitPayload={() => {
                    return {
                        contractChangeLog: prepareLog(),
                        contractMilestonesChangeLog: prepareMilestoneLog()
                    }
                }}
                style={{minWidth: "440px"}}
            >
                {selectedContract ?
                    <ContractForm
                        key={selectedContractId}
                        contract={selectedContract}
                        OEMs={OEMRecords}
                        equipmentPaymentStructureTemplateRecords={equipmentPaymentStructureTemplateRecords}
                        equipmentPaymentRuleRecords={equipmentPaymentRuleRecords}
                        addToMilestoneLog={addToPaymentMilestoneLog}
                        updateMilestone={updateMilestoneLog}
                        deleteMilestone={deleteFromMilestoneLog}
                        updateContract={updateContract}
                        paymentMilestones={filteredPaymentMilestoneRecords}
                        createContract={createContract}
                        deleteContract={deleteContract}
                        errors={errors}
                        removeErrors={removeErrors}
                    />
                    :
                    <div className="flow-vertical" style={{textAlign: "center"}}>Please select a Contract.</div>}
            </ViewPanel>
        </div>
    )
}

/**
 * 
 */
const ContractForm = ({
    contract, updateContract,
    paymentMilestones, updateMilestone, deleteMilestone, addToMilestoneLog,
    OEMs, equipmentPaymentStructureTemplateRecords, equipmentPaymentRuleRecords,
    
}) => {

    const OEMId = contract[EquipmentPurchaseContract.columnSchema.OEMId]
    const OEMRecord = OEMs.find(x => x[Counterparty.columnSchema.counterpartyId]===OEMId)
    const OEMName = OEMRecord ? OEMRecord[Counterparty.columnSchema.counterpartyName] : null
    
    const paymentStructureTemplateId = contract[EquipmentPurchaseContract.columnSchema.paymentStructureTemplateId]
    const paymentStructureTemplateRecord = equipmentPaymentStructureTemplateRecords.find(x => x[EquipmentPaymentStructureTemplate.columnSchema.oid]===paymentStructureTemplateId)
    const paymentStructureTemplateName = paymentStructureTemplateRecord ? paymentStructureTemplateRecord[EquipmentPaymentStructureTemplate.columnSchema.templateName] : null
    
    return (
        <div className="form-instance2">
            <CardHeaderWithMenu
                label="Equipment Purchase Contract"
                /*inlineComponents={[
                    <ActionIcon onClick={createContract}><FontAwesomeIcon icon={faCopy} size="lg" /></ActionIcon>
                ]}*/
            />
            <div className="body">
                <div className="flow-vertical">
                <PalantirTextField
                    label="Contract ID"
                    value={contract[EquipmentPurchaseContract.columnSchema.oid]}
                    disabled
                    style={{marginRight: "20px", marginBottom: "20px", width: "300px"}}
                />
                <PalantirDispatchedTextField
                    label="Contract Name"
                    value={contract[EquipmentPurchaseContract.columnSchema.contractName]}
                    onChange={(x) => {
                        updateContract({[EquipmentPurchaseContract.columnSchema.contractName]: x})
                    }}
                    style={{marginRight: "20px", marginBottom: "20px", width: "300px"}}
                />
                <PalantirAutocomplete
                    label="OEM"
                    value={{label: OEMName, id: OEMId}}
                    options={OEMs.map(oem => {
                        return {
                            label: oem[Counterparty.columnSchema.counterpartyName], id: oem[Counterparty.columnSchema.counterpartyId]
                        }
                    })}
                    onUpdate={(x) => {
                        updateContract({[EquipmentPurchaseContract.columnSchema.OEMId]: x.id})
                    }}
                    style={{marginRight: "20px", marginBottom: "20px", maxWidth: "220px"}}
                />
                <PalantirAutocomplete
                    label="Payment Structure Template"
                    value={{label: paymentStructureTemplateName, id: paymentStructureTemplateId}}
                    options={equipmentPaymentStructureTemplateRecords.map(template => {
                        return {
                            label: template[EquipmentPaymentStructureTemplate.columnSchema.templateName], id: template[EquipmentPaymentStructureTemplate.columnSchema.oid]
                        }
                    })}
                    onUpdate={(value) => {
                        if (paymentMilestones.length!==0) {
                            Alert.info("The contract must not have any existing Payment Milestones.")
                            return
                        }
                        updateContract({[EquipmentPurchaseContract.columnSchema.paymentStructureTemplateId]: value.id})
                    }}
                    style={{marginRight: "20px", marginBottom: "20px", maxWidth: "220px"}}
                />
                <PalantirDatePicker
                    label="Initial Delivery Date"
                    value={contract[EquipmentPurchaseContract.columnSchema.initialDeliveryDate]}
                    onChange={(date) => {
                        updateContract({[EquipmentPurchaseContract.columnSchema.initialDeliveryDate]: date})
                    }}
                    style={{marginRight: "20px", marginBottom: "20px", maxWidth: "220px"}}
                />
                <PalantirTextField
                    label="Total Purchase Amount ($)"
                    value={contract[EquipmentPurchaseContract.columnSchema.totalPurchaseAmountDollars]}
                    onChange={(x) => {
                        updateContract({[EquipmentPurchaseContract.columnSchema.totalPurchaseAmountDollars]: x})
                    }}
                    style={{marginRight: "20px", marginBottom: "20px", maxWidth: "220px"}}
                    InputProps={{
                        inputComponent: TextMaskNumber
                    }}
                />
                <PalantirDispatchedTextField
                    label="Notes"
                    value={contract[EquipmentPurchaseContract.columnSchema.notes]}
                    onChange={(x) => updateContract({[EquipmentPurchaseContract.columnSchema.notes]: x})}
                    multiline
                    rows={5}
                    variant="filled"
                    style={{marginRight: "20px", marginBottom: "20px", flexGrow: 1}}
                />
                </div>
                <div>
                    <Text size="lg" weight={500}>Payment Milestones</Text>
                    <hr></hr>
                    <Text color="dimmed" size="sm">
                        For each Payment Milestone, you may select a Payment Rule from the dropdown or enter a custom Milestone name. The displayed Payment Rules belong to the Contract's Payment Structure Template.
                    </Text>
                    <Text color="dimmed" size="sm">
                        Selecting a Payment Rule will evaluate the rule on the contract and auto populate all of the relevant fields for the respective Payment Milestone.
                    </Text>
                    <hr></hr>
                    <Text color="dimmed" size="sm">
                        You may choose to apply all Payment Rules from the contract's Payment Structure below.
                    </Text>
                    <Text color="dimmed" size="sm">
                        This may only be selected when no current Payment Milestones exist for the contract.
                    </Text>
                    <Button
                        onClick={() => {
                            const paymentStructureTemplateId = contract[EquipmentPurchaseContract.columnSchema.paymentStructureTemplateId]
                            if (paymentMilestones.length!==0) {
                                Alert.info("The contract must not have any existing Payment Milestones.")
                                return
                            }
                            if (!paymentStructureTemplateId) {
                                Alert.info("The contract has not been assigned a Payment Structure Template.")
                                return
                            }
                            
                            const templateRules = equipmentPaymentRuleRecords.filter(x => x[EquipmentPaymentRule.columnSchema.paymentStructureTemplateId]===paymentStructureTemplateId)
                            if (templateRules.length===0) {
                                Alert.info("The contract;s Payment Structure Template does not have any Payment Rules.")
                                return
                            }

                            const newMilestones = []
                            const errors = []
                            _.sortBy(templateRules, EquipmentPaymentRule.columnSchema.processingOrder).forEach(rule => {
                                const milestone = EquipmentDeliveryPaymentMilestone.buildNewRecord()
                                milestone[EquipmentDeliveryPaymentMilestone.columnSchema.oid] = generateUUID()
                                milestone[EquipmentDeliveryPaymentMilestone.columnSchema.contractId] = contract[EquipmentPurchaseContract.columnSchema.oid]
                                milestone[EquipmentDeliveryPaymentMilestone.columnSchema.paymentRuleId] = rule[EquipmentPaymentRule.columnSchema.oid]
                                try {
                                    const ruleChanges = processPaymentRule(contract, rule, newMilestones)
                                    Object.assign(milestone, ruleChanges)
                                }
                                catch {
                                    errors.push(rule[EquipmentPaymentRule.columnSchema.milestone])
                                }
                                newMilestones.push(milestone)
                            })
                            if (errors.length > 0) {
                                Alert.error(`Failed to process the following Payment Rules: ${JSON.stringify(errors)}.`, 8000)
                            }
                            if (newMilestones.length > 0) addToMilestoneLog(newMilestones)
                            
                        }}
                        style={{marginTop: "10px"}}
                    >
                        Process Payment Rules
                    </Button>
                    <hr></hr>
                    {paymentMilestones.map(x => {
                        return (
                            <PaymentMilestone
                                contract={contract}
                                paymentMilestones={paymentMilestones}
                                paymentMilestone={x}
                                equipmentPaymentRuleRecords={equipmentPaymentRuleRecords.filter(x => x[EquipmentPaymentRule.columnSchema.paymentStructureTemplateId]===paymentStructureTemplateId)}
                                updatePaymentMilestone={(y) => updateMilestone(x, y)}
                                deletePaymentMilestone={(y) => deleteMilestone(x, y)}
                            />
                        )
                    })}
                    <AddFloaterButtonWithPrompt
                        labelContent="Add Milestone"
                        onClick={() => addToMilestoneLog()}
                    />
                </div>
            </div>
        </div>
    )
}

const PaymentMilestone = React.memo(({contract, paymentMilestones, paymentMilestone, updatePaymentMilestone, deletePaymentMilestone, equipmentPaymentRuleRecords}) => {

    const ruleId = paymentMilestone[EquipmentDeliveryPaymentMilestone.columnSchema.paymentRuleId]
    const ruleRecord = equipmentPaymentRuleRecords.find(x => x[EquipmentPaymentRule.columnSchema.oid]===ruleId)
    const ruleName = ruleRecord ? ruleRecord[EquipmentPaymentRule.columnSchema.milestone] : null
    
    return (
        <div className="form-sub-instance " style={{marginBottom: "20px", flexGrow: 1, flexShrink: 0}}>
            <DeleteFloaterButtonWithPrompt
                labelContent="Delete Milestone"
                onClick={deletePaymentMilestone}
                style={{marginBottom: "15px"}}
            />
            <div className="flow-horizontal" style={{alignItems: "center", flexWrap: "wrap"}}>
                <PalantirAutocomplete
                    label="Payment Milestone Rule"
                    value={{label: ruleName, id: ruleId}}
                    options={_.sortBy(equipmentPaymentRuleRecords, EquipmentPaymentRule.columnSchema.processingOrder).map(rule => {
                        return {
                            label: rule[EquipmentPaymentRule.columnSchema.milestone], id: rule[EquipmentPaymentRule.columnSchema.oid]
                        }
                    })}
                    noOptionsText={`Selected Payment Structure Template has no attached payment rules.`}
                    onUpdate={(x) => {
                        const changes = {
                            [EquipmentDeliveryPaymentMilestone.columnSchema.paymentRuleId]: x.id,
                            [EquipmentDeliveryPaymentMilestone.columnSchema.milestone]: null
                        }

                        const newRule = equipmentPaymentRuleRecords.find(y => y[EquipmentPaymentRule.columnSchema.oid]===x.id)
                        if (newRule) {
                            try {
                                const ruleChanges = processPaymentRule(contract, newRule, paymentMilestones)
                                Object.assign(changes, ruleChanges)
                            }
                            catch (e) {
                                Alert.error(`
                                Failed to process Payment Rule ${newRule[EquipmentPaymentRule.columnSchema.milestone]}.
                                ${e}
                                `, 8000)
                            }
                        }
                        updatePaymentMilestone(changes)
                    }}
                    style={{marginBottom: "20px", maxWidth: "300px", minWidth: "200px"}}
                />
                <div className="flow-horizontal" style={{alignItems: "center", flexWrap: "wrap"}}>
                    <Text weight={500} style={{margin: "0 30px"}}>OR</Text>
                    <PalantirDispatchedTextField
                        label="Custom Milestone"
                        value={paymentMilestone[EquipmentDeliveryPaymentMilestone.columnSchema.milestone]}
                        onChange={(x) => {
                            updatePaymentMilestone({
                                [EquipmentDeliveryPaymentMilestone.columnSchema.milestone]: x,
                                [EquipmentDeliveryPaymentMilestone.columnSchema.paymentRuleId]: null
                            })
                        }}
                        style={{marginBottom: "20px", minWidth: "200px", width: "300px"}}
                    />
                </div>
            </div>
            <div style={{display: "flex", flexWrap: "wrap"}}>
                <div className="flow-vertical" style={{overflow: "inherit", marginBottom: "25px", border: "solid #efefef 1px", padding: "10px", borderRadius: "2px", marginRight: "30px"}}>
                    {ruleRecord && <RuleReadOnlyDisplay
                        description={ruleRecord[EquipmentPaymentRule.columnSchema.amountDescription]}
                        formula={ruleRecord[EquipmentPaymentRule.columnSchema.amountFormula]}
                    />}
                    <PalantirTextField
                        label="Amount ($)"
                        value={paymentMilestone[EquipmentDeliveryPaymentMilestone.columnSchema.amountDollars]}
                        onChange={(x) => {
                            updatePaymentMilestone({[EquipmentDeliveryPaymentMilestone.columnSchema.amountDollars]: x})
                        }}
                        InputProps={{
                            inputComponent: TextMaskNumber
                        }}
                        style={{marginTop: "10px", width: "300px"}}
                    />
                </div>
                <div className="flow-vertical" style={{overflow: "inherit", marginBottom: "25px", border: "solid #efefef 1px", padding: "10px", borderRadius: "2px"}}>
                    {ruleRecord && <RuleReadOnlyDisplay
                        description={ruleRecord[EquipmentPaymentRule.columnSchema.dueDateDescription]}
                        formula={ruleRecord[EquipmentPaymentRule.columnSchema.dueDateFormula]}
                    />}
                    <PalantirDatePicker
                        label="Due Date"
                        value={paymentMilestone[EquipmentDeliveryPaymentMilestone.columnSchema.dueDate]}
                        onChange={(date) => {
                            updatePaymentMilestone({[EquipmentDeliveryPaymentMilestone.columnSchema.dueDate]: date})
                        }}
                        style={{marginTop: "10px", width: "300px", paddingRight: "10px"}}
                    />
                </div>
            </div>
            {/*<div>
                {ruleId && <div className="flow-horizontal">
                        <RuleReadOnlyDisplay
                            description={ruleRecord[EquipmentPaymentRule.columnSchema.amountDescription]}
                            formula={ruleRecord[EquipmentPaymentRule.columnSchema.amountFormula]}
                        />
                        <RuleReadOnlyDisplay
                            description={ruleRecord[EquipmentPaymentRule.columnSchema.dueDateDescription]}
                            formula={ruleRecord[EquipmentPaymentRule.columnSchema.dueDateFormula]}
                        />
                </div>
                }
                <div className="flex">
                    <PalantirTextField
                        label="Amount ($)"
                        value={paymentMilestone[EquipmentDeliveryPaymentMilestone.columnSchema.amountDollars]}
                        onChange={(x) => {
                            updatePaymentMilestone({[EquipmentDeliveryPaymentMilestone.columnSchema.amountDollars]: x})
                        }}
                        InputProps={{
                            inputComponent: TextMaskNumber
                        }}
                        style={{marginTop: "10px", width: "300px", marginRight: "30px", padding: "0 15px 0 0"}}
                    />
                    <PalantirDatePicker
                        label="Due Date"
                        value={paymentMilestone[EquipmentDeliveryPaymentMilestone.columnSchema.dueDate]}
                        onChange={(date) => {
                            updatePaymentMilestone({[EquipmentDeliveryPaymentMilestone.columnSchema.dueDate]: date})
                        }}
                        style={{marginTop: "10px", width: "300px", marginRight: "30px", padding: "0 15px 0 0"}}
                    />
                </div>
            </div>*/}
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        _.isEqual(prevProps.paymentMilestone, nextProps.paymentMilestone) &&
        prevProps.contract[EquipmentPurchaseContract.columnSchema.paymentStructureTemplateId]===nextProps.contract[EquipmentPurchaseContract.columnSchema.paymentStructureTemplateId]
    )
})


const RuleReadOnlyDisplay = ({description, formula}) => {
    return (
        <div style={{maxWidth: "300px", marginBottom: "auto"}}>
            <Text size="xs" weight={500}>Rule Description</Text>
            <Text size="xs" color="dimmed">{description}</Text>
            <div style={{backgroundColor: "#F0F0F0", borderRadius: "3px", padding: "8px 15px", marginTop: "4px"}}>
                <Text size="xs" weight={500}>Rule Formula</Text>
                <Text size="xs" color="dimmed">{formula}</Text>
            </div>
        </div>
    )
}