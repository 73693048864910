import './Panel.css'
import React, {Component} from 'react'

class MessagePanel extends Component {

    constructor(props) {
        super()
        this.state = {

        }
        this.defaults = {
            className: "panel flow-vertical fill-parent",
            style: {
                'justifyContent': 'center',
                'alignItems': 'center',
            }
        }
    }

    mergeClassNames() {
        return this.defaults.className.concat(" ", (this.props.className || ""))
    }

    mergeStyle() {
        return Object.assign({}, this.defaults.style, (this.props.style || {}))
    }

    render() {



        return (
            <div className={this.mergeClassNames()} style={this.mergeStyle()} >
                {this.props.children}
            </div>
        )
    }

}

export default MessagePanel