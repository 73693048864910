export function injectServices(existingServices, newServiceName) {
    if (newServiceName in existingServices) {
        throw Error(`A service with the name ${newServiceName} already exists in the current services object`)
    }

    var newServices = {}
    var newServiceFunctions = Array.from(arguments).slice(2)
    newServiceFunctions.forEach(x => {
        if (typeof(x) == "function") {
            newServices[x.name] = x
        }
        else if (typeof(x) == "object") {
            Object.assign(newServices, x)
        }
    })

    var newServicesObject = {}
    newServicesObject[newServiceName] = newServices

    return Object.assign({}, existingServices, newServicesObject)
}