import './EventTemplateMenu.css'
import React, {Component} from 'react'

import _ from "lodash"

import MenuContainer from '../menu/MenuContainer'

class EventTemplateMenu extends Component {

    constructor(props) {
        super()
        this.state = {
            
        }
        this.className = "ev-template-menu "
    }

    componentDidMount() {
        console.log("Mounting ev template menu")
    }

    shouldComponentUpdate(prevProps, prevState) {
        if (prevProps.selectedTemplateId !== this.props.selectedTemplateId) return true
        // if (prevProps.selectedSiteId !== this.props.selectedSiteId) return true
        return false
    }

    render() {
        console.log("Rendering Event Template Menu")

        const retrievePayloadFunction = ((x) => x.meta._id)

        return (
            <MenuContainer 
                menuSkeleton={this.constructEventTemplateHeirarchy()}
                headerName={"Event Template Menu"}
                leafNodeClick={(menuItem) => this.props.onTemplateClick(menuItem.meta._id)}
                retrievePayloadFunction={retrievePayloadFunction}
                className={this.className + (this.props.className || "")}
                includeRadio={true}
                searchable
                style={this.props.style || {}}
            />
        )
    }

    constructEventTemplateHeirarchy() {

        var eventTemplates = this.props.services.assetFramework.getEventTemplates()
        console.log(eventTemplates)
        eventTemplates = _.sortBy(eventTemplates, ['code', '_id'])

        var menuObjects = eventTemplates.map(template => {
            return {
                "key": template._id,
                "label": template.code + " - " + template.description,
                "meta": template,
                "selectable": true,
                "active": this.props.selectedTemplateId === template._id,
                "children": null,
            }
        })
        return menuObjects
    }

}

export default EventTemplateMenu