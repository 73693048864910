import {Component, useState} from 'react'

import { formatDate } from '../../../../utils/Dates'
import { startOfDay, endOfDay } from 'date-fns'
import Highcharts from 'highcharts'

import DatePicker from '../../../date_picker/DateRangePicker'
import ResolutionDropdown from '../../../resolution_dropdown/ResolutionDropdown'
import Panel from '../../../panel/Panel'
import RevenueContainer from './RevenueContainer'
import PriceContainer from './PriceContainer'
import AwardContainer from './AwardContainer'
import KPIContainer from './KPIContainer'
import { isSiteBess } from '../../../../utils/assetFrameworkMacros'
import { getSite } from '../../../../utils/UrlLocation'
import { useOutletContext } from 'react-router-dom'

var animationLimit = 500

const SummaryContent = () => {

    const { assetFrameworkServices, authServices } = useOutletContext()

    const [selectedResolution, setSelectedResolution] =  useState(3600)
    const [selectedStartDate, setSelectedStartDate] =  useState(formatDate(startOfDay(new Date())))
    const [selectedEndDate, setSelectedEndDate] =  useState(formatDate(endOfDay(new Date())))

    const selectedSiteId = getSite()

    const chartOptions = {
        legend: {
            floating: false,
            verticalAlign: 'top',
            align: 'right',
        },
        plotOptions: {
            series: {
                animationLimit: animationLimit,
                point: {
                    events: {
                        mouseOver: function(e) {
                            let point = this
                            showHoverContentAllCharts(point)
                        }
                    }
                },
                events: {
                    mouseOut: function(e) {
                        let point = this
                        hideHoverContentAllCharts(point)
                    },
                },
                zoneAxis: 'x',
                zones: [
                    {value: Date.now(), dashStyle: "solid"},
                    {dashStyle: "ShortDot"}
                ]
            }
        },
        xAxis: {
            events: {
                setExtremes: function(e) {
                    const chart = this.chart
                    setExtremesAllCharts(chart, e)
                }
            }
        },
    }

    const updateSelectedResolution = (res) => {
        setSelectedResolution(res)
    }

    const updateSelectedDates = (start, end) => {
        setSelectedStartDate(start)
        setSelectedEndDate(end)
    }

    const showHoverContentAllCharts = (hoveredPoint) => {
        Highcharts.charts.forEach(chart => {
            if (!chart) return
            if (chart.index===hoveredPoint.series.chart.index) return   // Don't modify the chart being hovered

            let matchingPoints = []
            chart.series.forEach(series => {
                series.points.forEach(point => {
                    if (point.index===hoveredPoint.index) {
                        //point.setState('hover')
                        matchingPoints.push(point)
                    }
                })
            })
            if (matchingPoints.length > 0) {
                chart.tooltip.refresh(matchingPoints)
                chart.xAxis[0].drawCrosshair(null, matchingPoints[0])
            }
        })
    }

    const hideHoverContentAllCharts = (point) => {
        Highcharts.charts.forEach(chart => {
            if (!chart) return
            chart.tooltip.hide()
            chart.xAxis[0].hideCrosshair()
        })
    };

    const setExtremesAllCharts = (originalChart, e) => {
        if (e.trigger==="setExtremesAllCharts") return   // exit when the calling function is self, otherwise stack overflow
        Highcharts.charts.forEach(chart => {
            if (!chart) return
            if (chart.index===originalChart.index) return   // don't modify the triggering chart
            if (chart.xAxis[0].setExtremes) { // It is null while updating
                chart.xAxis[0].setExtremes(e.min, e.max, undefined, (chart.pointCount <= animationLimit), {
                    trigger: "setExtremesAllCharts"
                });
            }
        })
    }

    if (!selectedSiteId) {
        return <Panel>Please select a site</Panel>
    }

    // Tab is only available for sites with a battery
    const selectedSite = assetFrameworkServices.getSites(selectedSiteId)
    if (!isSiteBess(selectedSite)){
        return <Panel>This dashboard is only available for sites with a battery</Panel>
    }

    var selectionProps = {
        selectedSiteId: selectedSiteId,
        selectedStartDate: selectedStartDate,
        selectedEndDate: selectedEndDate,
        selectedResolution: selectedResolution,
    }

    const services = {assetFramework: assetFrameworkServices, auth: authServices}

    return (
        <div className="section-main flow-vertical fill-parent overflow-y">
            <div className="section-header flow-horizontal hide-overflow flex-fixed" style={{marginBottom: '10px'}}>
                <div className="title">Market Summary</div>
                <div className="fill-parent"></div>
                <ResolutionDropdown 
                    selectedResolution={selectedResolution}
                    onSelectItem={updateSelectedResolution}
                    resolutionOptions={["raw", "1H", "1D"]}
                    containerProps={{
                        appearance: "subtle"
                    }}
                />
                <DatePicker 
                    services={services}
                    startDate={selectedStartDate}
                    endDate={selectedEndDate}
                    onValueChange={updateSelectedDates}
                    containerProps={{
                        appearance: "subtle",
                        placement: 'bottomEnd'
                    }}
                />
            </div>
            <div className="flow-horizontal overflow-visible fill-parent" style={{marginBottom: "20px", minHeight: '300px'}}>
                <div className="flow-vertical overflow-visible" style={{width: '400px', minWidth: '300px', marginRight: '20px'}}>
                    <KPIContainer services={services} {...selectionProps} />
                </div>
                <RevenueContainer services={services} chartOptions={chartOptions} {...selectionProps} />
            </div>
            <div className="flow-horizontal overflow-visible" style={{minHeight: '400px'}}>
                <div className="flow-vertical overflow-visible" style={{width: "49.5%", marginRight: '0.5%'}}>
                    <PriceContainer services={services} chartOptions={chartOptions} {...selectionProps} />
                </div>
                <div className="flow-vertical overflow-visible" style={{width: "49.5%", marginLeft: '0.5%'}}>
                    <AwardContainer services={services} chartOptions={chartOptions} {...selectionProps} />
                </div>
            </div>
        </div>
    )

}

export default SummaryContent