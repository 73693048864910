import {Component} from 'react'

import {Input, Button} from 'rsuite'

class GenericForm extends Component {
    constructor(props) {
        super()
        this.state = {
            idCounter: props.initialFields.length,
            fields: props.initialFields.map((x,idx) => Object({"id": idx, "value": x}))
        }
    }

    render() {
        console.log("Rendering generic form")
        var fields = this.state.fields.map(field => {
            return (
                <div key={field.id}>
                    <Input defaultValue={field.value} placeholder="Start typing..." onChange={(event) => this.handleChange(field.id, event)} />
                    <div onClick={() => this.deleteField(field.id)}>delete</div>
                </div>
            )
        })

        return (
            <div>
                Form
                {fields}
                <Button onClick={this.addField}>Add field</Button>
            </div>
        )
    }

    addField = () => {
        var fields = this.state.fields
        fields.push({
            "id": this.state.idCounter,
            "value": "",
        })
        this.setState({
            fields: fields,
            idCounter: this.state.idCounter+1
        })
        this.props.onChange(fields)
    }

    deleteField = (id) => {
        var fields = this.state.fields
        var fieldIdx = fields.findIndex(x => x.id===id)
        fields.splice(fieldIdx, 1)
        this.setState({
            fields: fields
        })
        this.props.onChange(fields)
    }

    handleChange = (id, newData) => {
        var fields = this.state.fields
        var fieldIdx = fields.findIndex(x => x.id===id)
        fields[fieldIdx].value = newData
        this.setState({
            fields: fields
        })
        this.props.onChange(fields)
    }

}

export default GenericForm