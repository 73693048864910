import { TAG_TYPES, SERVICES, SITE_TECHNOLOGIES } from './palantirSchema'

/* Public */

export const isSiteHybrid = (site) => {
    return isSiteOfType(site, [SITE_TECHNOLOGIES.hybridSolarStorage])
}
export const isSiteStandaloneBess = (site) => {
    return isSiteOfType(site, [SITE_TECHNOLOGIES.standAloneStorage])
}
// Returns true for any site that has a battery system (hybrid and standalone)
export const isSiteBess = (site) => {
    return isSiteOfType(site, [SITE_TECHNOLOGIES.standAloneStorage, SITE_TECHNOLOGIES.hybridSolarStorage])
}
export const isSiteSolar = (site) => {
    return isSiteOfType(site, [SITE_TECHNOLOGIES.solar])
}

export const isDeviceSubscribedToIntervalTagService = (device) => {
    return isAssetSubscribedToService(device, SERVICES.intervalTags)
}

export const getSiteTimezone = (site) => {
    return (site?.meta || {})["timezone"]
}

/* Check a device tag type */
export const checkTagTypeIsSource = (tag) => {
    // Raw is legacy and should be removed
    return [TAG_TYPES.source, TAG_TYPES.raw].includes(tag.type)
}
export const checkTagTypeIsCalculated = (tag) => {
    return tag.type===TAG_TYPES.calculated
}


export const isHighPriorityTag = (tag) => {
    return tag.config?.priority===1 || tag.config?.priority===undefined
}

export const getLocalSettingShowOnlyHighPrioTags = () => {
    const value = localStorage.getItem("palantirShowOnlyHighPrioTags")
    if (value===null) {
        localStorage.setItem("palantirShowOnlyHighPrioTags", true)
        return true
    }
    return value==="true"
}
export const toggleLocalSettingShowOnlyHighPrioTags = () => {
    const curValue = getLocalSettingShowOnlyHighPrioTags()
    localStorage.setItem("palantirShowOnlyHighPrioTags", !curValue)
    return !curValue
}


/* Private */

const isAssetSubscribedToService = (asset, serviceName) => {
    return asset.services[serviceName]
    // The below implementation should be used when database schema is updated
    // return asset.services[serviceName]?.enabled
}
const isSiteOfType = (site, types) => {
    const siteTech = site.meta?.technology
    return types.includes(siteTech)
}