import {Component} from 'react'

import BlueprintConfigTable from './EventBlueprintConfigTable'
import SectionHeader from '../../../section/SectionHeader'
import {HelpEventBlueprintConfiguration} from '../../../help/descriptions/tabs/Configuration'
import HelpPanel from '../../../help/HelpPanel'
import { useOutletContext } from 'react-router-dom'
import { getSite } from '../../../../utils/UrlLocation'

const EventBlueprintConfiguration = () => {

    const {assetFrameworkServices, authServices} = useOutletContext()

    const selectedSiteId = getSite()

    const services = {assetFramework: assetFrameworkServices, auth: authServices}

    const Tab = <HelpPanel
                    services={services}
                    render={(newServices) => {
                        return (
                            <div className="section-main flow-vertical fill-parent">
                                <SectionHeader services={newServices} sectionTitle="Event Blueprint Configuration" sectionDescription={<HelpEventBlueprintConfiguration />} />
                                <BlueprintConfigTable services={newServices} selectedSiteId={selectedSiteId} />
                            </div>
                        )
                    }}
                />

    return Tab

}

export default EventBlueprintConfiguration