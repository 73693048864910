import {Component} from 'react'

import { injectServices } from '../utils/ServiceInjector'
import TableSelector from './TableSelector'
import StandardDataProvider from '../components/data_provider/StandardDataProvider'
import Panel from '../components/panel/Panel'


class TableEditingContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            
        }
    }

    shouldComponentUpdate(prevProps, prevState) {
        if (this.props.selectedTable !== prevProps.selectedTable) return true
        if (this.props.selectedPlantId !== prevProps.selectedPlantId) return true
        if (this.props.selectedPortfolioId !== prevProps.selectedPortfolioId) return true
        if (this.props.displayMode !== prevProps.displayMode) return true
        return false
    } 

    render() {

        console.log("rendering table editing container")

        if (!this.props.selectedTable) {
            return <Panel className=" section-panel fill-parent">Please select a table.</Panel>
        }

        var tableName = this.props.selectedTable.tableName
        var schemaName = this.props.selectedTable.schemaName
        
        console.log(this.props.services)
        const Table = <StandardDataProvider
                            services={this.props.services}
                            selectedTable={tableName}
                            selectedSchema={schemaName}
                            additionalParams={{
                                dependencies: this.props.selectedTable.getDependenciesList()
                            }}
                            allowEmpty={true}
                            queryProps={["selectedSchema", "selectedTable"]}
                            requestType="POST"
                            queryUrl={'/api/precious/table/get'}
                            render={(data, dataRequestId, loading, refreshData) => {
                                let servicesInjected = injectServices(this.props.services, 'data', {
                                    refresh: refreshData,
                                })
                                return <TableSelector
                                    {...this.props}
                                    services={servicesInjected}
                                    data={data || []}
                                    dataRequestId={dataRequestId}
                                    loading={loading}
                                />}}
                        />

        return Table
    }
}

export default TableEditingContainer