import {Component} from 'react'

import HelpPanel from '../../../help/HelpPanel'
import Panel from '../../../panel/Panel'
import { isSiteBess } from '../../../../utils/assetFrameworkMacros'

import ForecastViewContainer from './ForecastViewContainer'
import { useOutletContext } from 'react-router-dom'
import { getSite } from '../../../../utils/UrlLocation'

const ForecastsContent = () => {

    const {assetFrameworkServices, authServices} = useOutletContext()

    const selectedSiteId = getSite()

    const services = {assetFramework: assetFrameworkServices, auth: authServices}

    if (!selectedSiteId) {
        return <Panel>Please select a site</Panel>
    }

    // Tab is only available for sites with a battery
    var selectedSite = assetFrameworkServices.getSites(selectedSiteId)
    if (!isSiteBess(selectedSite)){
        return <Panel>This dashboard is only available for sites with a battery</Panel>
    }

    return (
        <HelpPanel
            services={services}
            render={(newServices) =>
                <ForecastViewContainer 
                    services={newServices}
                    selectedSiteId={selectedSiteId}
                />
            }
        />
    )

}

export default ForecastsContent