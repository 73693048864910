import { Card, Divider, JsonInput, Text, Paper, Alert, Skeleton, Badge, Switch, ColorSwatch } from '@mantine/core';
import { useDataProvider } from '../../data_provider/StandardDataProvider';
import { useEffect, useState } from 'react';
import { Card as PltrCard, CardHeaderWithMenu as PltrCardHeaderWithMenu, CardBody as PltrCardBody } from '../../panelv2/Panel2';
import { AutoRefreshToggle, AutoRefreshInfo } from './AutoRefresh';
import { formatDistance, formatDistanceToNow, format as formatDate, isValid as isDateValid } from 'date-fns';
import _ from 'lodash'
import { DataLoadingError } from './Common';

const DEFAULT_AUTO_REFRESH_DURATION = 60000*5


const Widget2 = ({}) => {

    const {data, requestId, isDataReady, isLoading, isError, errorMessage, lastRefreshTs, refreshData, autoRefreshEnabled, enableAutoRefresh, disableAutoRefresh} = useDataProvider({
        requestMethod: "GET",
        requestUrl: "/api/monitor/dashboard_events",
        buildRequestBody: () => Object({"monitorDashboardId": 1}),
        resetDataOnRequest: false,
        autoRefreshDuration: DEFAULT_AUTO_REFRESH_DURATION
    })
    const [showContextData, setShowContextData] = useState(false)

    useEffect(() => {
        //refreshData()
        enableAutoRefresh()
    }, [])

    return (
        <PltrCard style={{flexGrow: 0, minWidth: "450px", marginLeft: "15px"}}>
            <PltrCardHeaderWithMenu
                label="Event Summary"
                inlineComponents={
                    <AutoRefreshInfo
                        lastRefreshTs={lastRefreshTs}
                        isLoading={isLoading}
                        isError={isError}
                        autoRefreshEnabled={autoRefreshEnabled}
                    />
                }
                menuItems={
                    <>
                        <Paper p="sm">
                            <AutoRefreshToggle
                                autoRefreshEnabled={autoRefreshEnabled}
                                enableAutoRefresh={enableAutoRefresh}
                                disableAutoRefresh={disableAutoRefresh}
                            />
                        </Paper>
                        <Paper p="sm">
                            <DisplayContextDataToggle
                                showContextData={showContextData}
                                setShowContextData={setShowContextData}
                            />
                        </Paper>
                    </>
                }
            />
            <PltrCardBody>
                {isError && <DataLoadingError message={errorMessage} mb={15} />}
                {data && <EventList data={data} showContextData={showContextData} />}
                {!data && !isError && isLoading && <DataLoadingSkeleton />}
            </PltrCardBody>
        </PltrCard>
    )
}

const DisplayContextDataToggle = ({showContextData, setShowContextData}) => {
    return (
        <span className="flow-horizontal" style={{alignItems: "center"}}>
            <Switch
                checked={showContextData}
                onChange={(event) => setShowContextData(event.currentTarget.checked)}
                style={{marginRight: "10px"}}
                size="xs"
            />
            <Text size="xs" color="dimmed">Show Context Data</Text>
        </span>
    )
}

const EventList = ({data, showContextData}) => {

    const openEvents = _.sortBy(data.openEvents, "eventOpenedTs").reverse()
    const closedEvents = _.sortBy(data.closedEvents, "eventClosedTs").reverse()

    const openEventComponents = openEvents.map(x => <OpenEventCard event={x} showContextData={showContextData} />)
    const closedEventComponents = closedEvents.map(x => <ClosedEventCard event={x} showContextData={showContextData} />)

    return (
        <div>
            <Divider mb="lg" label="Open Events" />
            {openEventComponents}
            <Divider my="lg" label="Recently Closed Events" />
            {closedEventComponents}
        </div>
    )
}

const DataLoadingSkeleton = () => {
    return (
        <>
            <Skeleton height={100} width={400} mb={15} />
            <Skeleton height={100} width={400} mb={15} />
            <Skeleton height={100} width={400} mb={15} />
        </>
    )
}


const OpenEventCard = ({event, ...props}) => {

    const eventOpenedDate = new Date(event.eventOpenedTs)

    const formattedDuration = isDateValid(eventOpenedDate) ? formatDistanceToNow(eventOpenedDate) : null
    const formattedOpenDate = isDateValid(eventOpenedDate) ? formatDate(eventOpenedDate, "h:mm b, E LLL d y") : null

    return (
        <EventCard
            type="open"
            event={event}
            formattedDate={formattedOpenDate}
            formattedDuration={formattedDuration}
            {...props}
        />
    )
}
const ClosedEventCard = ({event, ...props}) => {
    
    const eventOpenedDate = new Date(event.eventOpenedTs)
    const eventClosedDate = new Date(event.eventClosedTs)

    const formattedDuration = isDateValid(eventOpenedDate) && isDateValid(eventClosedDate) ? formatDistance(eventOpenedDate, eventClosedDate) : null
    const formattedCloseDate = isDateValid(eventClosedDate) ? formatDate(eventClosedDate, "h:mm b, E LLL d y") : null

    return (
        <EventCard
            type="closed"
            event={event}
            formattedDate={formattedCloseDate}
            formattedDuration={formattedDuration}
            {...props}
        />
    )
}
const EventCard = ({type, event, formattedDate, formattedDuration, showContextData}) => {
    return (
        <Card withBorder style={{width: "400px", marginBottom: "15px", flexShrink: 0}}>
                <EventCardHeader
                    type={type}
                    formattedDuration={formattedDuration}
                    formattedDate={formattedDate}
                />
                <EventCardMainContent
                    siteName={event.siteName}
                    eventTemplateName={event.eventTemplateName}
                    eventTemplateDescription={event.eventTemplateDescription}
                    eventTemplateColor={event.eventTemplateColor}
                />
                {showContextData &&
                    <EventContextData event={event} />
                }
        </Card>
    )
}
const EventCardHeader = ({type, formattedDate, formattedDuration}) => {
    return (
        <div className="flow-horizontal align-bl" style={{justifyContent: "space-between"}}>
            <span className="flow-horizontal align-bl">
                {type==="open" && <ColorSwatch size={10} color="red" />}
                {type==="closed" && <ColorSwatch size={10} color="green" />}
                
                <Text size="xs" color="dimmed" ml={6}>{formattedDuration}</Text>
            </span>
            <div className="flow-vertical" style={{paddingLeft: "20px", flexShrink: 0}}>
                <Text size="xs" color="dimmed">{formattedDate}</Text>
            </div>
        </div>
    )
}
const EventCardMainContent = ({siteName, eventTemplateName, eventTemplateDescription, eventTemplateColor}) => {
    return (
        <div className="flow-vertical">
            <div className="flow-horizontal align-bl">
                <Text size="md" weight={500}>{siteName}</Text>
                <EventCodeBadge color={eventTemplateColor}>{eventTemplateName}</EventCodeBadge>
            </div>
            <Text size="xs" color="dimmed">{eventTemplateDescription}</Text>
        </div>
    )
}
const EventContextData = ({event}) => {

    const contextData = event.eventDetail.data || []
    const contextDataItems = contextData.map(data => {
        return (
            <MetricLineItem
                name={data.name}
                value={data.value}
                units={data.units}
            />
        )
    })

    return (
        <>
            <Divider my="sm" label="Context data" labelPosition="center" />
            {contextDataItems}
        </>
    )
}

const MetricLineItem = ({name, value, units}) => {

    const formattedValue = typeof(value) === "number" ? value.toLocaleString(undefined, {maximumFractionDigits: 2}) : null

    return (
        <div style={{display: "flex", flexDirection: "row", alignItems: "baseline", paddingRight: "0px"}}>
            <Text size="xs" color="dimmed" mr={6}>{name}: </Text>
            <Text size="sm">{formattedValue}</Text>
            <Text size="xs" color="dimmed" component="i" style={{marginLeft: "4px"}}>{units}</Text>
        </div>
    )
}

const EventCodeBadge = ({color, ...props}) => {
    return (
        <div style={{
            backgroundColor: color,
            padding: "0 5px",
            display: "inline-flex",
            alignItems: "center",
            borderRadius: "32px",
            fontWeight: "700px",
            marginLeft: "6px",
            letterSpacing: "0.25px",
            justifyContent: "center",
            height: "16px",
            fontSize: "9px"
        }}>
            <span style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", color: "white"}}>{props.children}</span>
        </div>
    )
}

export default Widget2