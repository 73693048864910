import './Panel2.css'
import { Menu, Switch, Text } from '@mantine/core'
import { PalantirPopMenuItem, PalantirPopMenu } from '../menuv2/Menu'

export const Card = ({children, className, ...props}) => {
    return (
        <div className={"form-instance2 flow-vertical" + (className ? ` ${className}` : "")} {...props}>
            {children}
        </div>
    )
}
export const CardHeader = ({label, ...props}) => {
    return (
        <div className="header2 flow-horizontal">
            <CardHeaderLabel label={label} />
            <div className="fill-parent"></div>
            {props.children}
        </div>
    )
}
export const CardBody = ({flow="vertical", ...props}) => {
    const flowClassName = flow==="vertical" ? "flow-vertical" : "flow-horizontal"
    return (
        <div className={`body ${flowClassName} overflow`}>
            {props.children}
        </div>
    )
}
export const CardFooter = ({...props}) => {
    return (
        <div className="footer flow-horizontal">
            {props.children}
        </div>
    )
}

export const CardHeaderLabel = ({label}) => {
    return <Text size="lg" weight={500} style={{flexShrink: 0}}>{label}</Text>
}



export const CardHeaderWithMenu = ({label, menuItems, inlineComponents, menuProps={placement: "end"}}) => {
    return (
        <CardHeader label={label}>
            {inlineComponents}
            <PalantirPopMenu {...menuProps}>
                {menuItems}
            </PalantirPopMenu>
        </CardHeader>
    )
}