import './Link.css'
import { createSearchParams, useLocation } from "react-router-dom"
import { Link as ReactRouterLink } from "react-router-dom"

export const Link = ReactRouterLink
export const LinkWithSParams = ({to, children, ...props}) => {

    const location = useLocation()
    const newSearchParams = createSearchParams(location.search)

    var className = "pltr-link"
    if (props.className) className+=` ${props.className}`

    return (
        <Link {...props} className={className} to={`${to}?${newSearchParams.toString()}`} >
            {children}
        </Link>
    )
}