import './Events.css'
import React, { useState } from 'react'

import AssetSidebar from '../../menu/AssetMenu'
import EventsDisplay from './EventsDisplay'

import { getPortfolio, setPortfolio, getSite, setSite } from '../../../utils/UrlLocation'
import { useOutletContext } from 'react-router-dom'


const EventsTab = () => {

    const { assetFrameworkServices, authServices } = useOutletContext()

    const [selectedPortfolioId, setSelectedPortfolioId] = useState(getPortfolio())
    const [selectedSiteId, setSelectedSiteId] = useState(getSite())

    const updateURLSelectedSiteId = (id) => {
        setSite(id)
        setPortfolio(null)
        updateSelectedSiteId()
    }

    const updateURLSelectedPortfolioId = (id) => {
        setPortfolio(id)
        setSite(null)
        updateSelectedPortfolioId()
    }

    const updateSelectedPortfolioId = () => {
        setSelectedPortfolioId(getPortfolio())
        setSelectedSiteId(null)
    }
    
    const updateSelectedSiteId = () => {
        setSelectedPortfolioId(null)
        setSelectedSiteId(getSite())
    }
    
    return (
        <div className="flow-horizontal fill-parent">
            <AssetSidebar 
                updateSelectedSite={updateURLSelectedSiteId} 
                updateSelectedPortfolio={updateURLSelectedPortfolioId}
                selectedSiteId={selectedSiteId}
                selectedPortfolioId={selectedPortfolioId}
                className="sidebar"
                menuProps={{
                    collapsable: true,
                }}
            />
            <EventsDisplay services={{assetFramework: assetFrameworkServices, auth: authServices}} selectedSiteId={selectedSiteId} />
        </div>
    )

}

export default EventsTab