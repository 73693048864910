import './OptionCell.css'

import { useState } from 'react'
import { Table, Whisper, Popover, Dropdown } from 'rsuite';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'

const {Cell} = Table

export default function OptionsCell({rowData, options, ...props }) {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const optionItems = options ? options.map((opt, idx) => {
        return (
            <MenuItem
                key={idx}
                onClick={() => {
                    handleClose();
                    opt.callback(rowData)
                }}
            >
                    {opt.label}
            </MenuItem>
        )
    }) : ""

    return (
        <Cell {...props}>
            <FontAwesomeIcon
                className="options-icon"
                icon={faEllipsisH}
                onClick={handleClick}
            />
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                {optionItems}
            </Menu>
        </Cell>
    )
};