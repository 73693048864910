import { groupBy } from './MiscUtils'

    
export function constructAssetFiltersFromSelections(allSites, allDeviceTypes, selectedDevices, selectedSiteId, selectedTagIds, selectedDimensionIds, forecastTime) {

    var assetFilters = []

    for (let selectedSite of [selectedSiteId]) {

        let site = allSites.find(x => x._id===selectedSite)
        let database = site["database_name"]
        let timezone = site["meta"]["timezone"]

        let siteDevices = selectedDevices.filter(x => x["site_id"]===selectedSite)
        if (siteDevices.length===0) continue
        let siteDeviceTypes = new Set(siteDevices.map(x=>x["device_type"]))

        // Iterate through all devices and add to a collection tracker
        
        for (let deviceTypeId of siteDeviceTypes) {

            let deviceType = allDeviceTypes.find(x => x._id===deviceTypeId)
            let collection = deviceType["databaseCollection"]
            let includeForecast = deviceType.config?.forecast

            // Find devices of the selected type that belong to the selected site
            let devicesOfType = siteDevices.filter(x => x["device_type"]===deviceTypeId)
            if (devicesOfType.length===0) continue

            // Get the ids of the devices
            let deviceIdsOfType = devicesOfType.map(x=>x._id)                    

            // Find all tags that are currently selected and belong to devices of current type
            let deviceTypeTags = Array.from(new Set(devicesOfType.map(x=>x.tags.map(y=>y)).flat()))

            Object.entries(selectedTagIds).forEach(entry => {
                let tagType = entry[0]
                let selectedTypeTagIds = entry[1]
                let selectedTypeDimensions = selectedDimensionIds[tagType]

                let deviceTypeTagsFiltered = deviceTypeTags.filter(x => selectedTypeTagIds.includes(x.template_id))

                // Attempt to group tags by dimensions
                let chunkedTags = groupBy(deviceTypeTagsFiltered, "dimensions")

                // If there are no dimensions
                if (Object.keys(chunkedTags).length===0) {

                    let deviceTypeTagIds = deviceTypeTagsFiltered.map(x => x.template_id)
                    // If none of devices contain any of the selected tags continue
                    if (deviceTypeTagIds.length===0) return

                    let assetFilter = {
                        "tag_type": tagType,
                        "database": database,
                        "collection": collection,
                        "timezone": timezone,
                        "device_ids": deviceIdsOfType,
                        "tag_ids": Array.from(new Set(deviceTypeTagIds))
                    }
                    if (includeForecast && forecastTime) assetFilter["forecast_time"] = forecastTime
                    assetFilters.push(assetFilter)
                }
                else {
                    for (let uniqueDimensions of Object.keys(chunkedTags)) {

                        let [deviceIds, tagIds, dimensionIds] = getDimensionFilters(devicesOfType, uniqueDimensions, selectedTypeTagIds, selectedTypeDimensions)

                        let assetFilter = {
                            "tag_type": tagType,
                            "database": database,
                            "collection": collection,
                            "timezone": timezone,
                            "device_ids": deviceIds,
                            "tag_ids": Array.from(new Set(tagIds)),
                            "dimensions": dimensionIds,
                        }
                        if (includeForecast && forecastTime) assetFilter["forecast_time"] = forecastTime
                        assetFilters.push(assetFilter)
                    }
                }
            })

        }
    }
    return assetFilters
}

function getDimensionFilters(devices, uniqueDimensions, selectedTagIds, selectedDimensionIds) {

    let dimensions = uniqueDimensions.split(",").map(x => Object({"id": Number(x), "val": selectedDimensionIds[x]}))

    // Check at least one value is selected for all available dimensions
    let dimensionCheck = dimensions.map(x => x.val).reduce((acc,cur) => {
        let c1 = acc instanceof Array ? acc.length > 0 : Boolean(acc)
        let c2 = cur instanceof Array ? cur.length > 0 : Boolean(cur)
        return c1 && c2
    })
    if (!dimensionCheck) {
        throw new Error("Must select at least one value for each available dimension.")
    }

    let devicesOfTypeGroupedDimensions = devices.filter(x => x.tags.map(x => String(x.dimensions)===uniqueDimensions).reduce((acc,cur) => acc | cur))
    let devicesOfTypeGroupedDimensionsIds = devicesOfTypeGroupedDimensions.map(x=>x._id)

    // Get tags that match the device and dimension filters currently applied
    let chunkedDeviceTypeTags = Array.from(new Set(devicesOfTypeGroupedDimensions.map(x=>x.tags.map(y=>y)).flat()))
    chunkedDeviceTypeTags = chunkedDeviceTypeTags.filter(x => selectedTagIds.includes(x.template_id))
    chunkedDeviceTypeTags = chunkedDeviceTypeTags.filter(x => String(x.dimensions)===uniqueDimensions)
    let chunkedDeviceTypeTagIds = chunkedDeviceTypeTags.map(x => x.template_id)

    return [devicesOfTypeGroupedDimensionsIds, chunkedDeviceTypeTagIds, dimensions]
}