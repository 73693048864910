
import React, { Component } from 'react';

import LoginForm from './LoginForm';
import { injectServices } from '../../utils/ServiceInjector'
import Panel from '../panel/Panel'
import { Outlet } from 'react-router-dom'

//import {isAfter, add, sub} from 'date-fns'
import axios from 'axios'

class AuthHandler extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loggedIn: this.getToken(true) ? true : false,
            tokenUpdatedAt: new Date(),
            user: {},
            loginFailed: false,
        };

        //this.tokenExpiresAfter = (process.env.NODE_ENV == "production" ? 180 : 15)
        //this._logoutTimer = null

    }

    componentDidMount() {
        if (this.state.loggedIn) {
            axios.get('/current_user')
            .then(response => {
                let user = response.data
                this.setState({
                    user: user,
                });
            })
            .catch((error) => {
                if (!error.response) return
                console.log(error.response)
                if (error.response.status === 401) {
                    this.logout()
                }
            })
        }
    }

    componentWillUnmount() {
        clearTimeout(this._logoutTimer)
    }

    render() {

        if (!this.state.loggedIn) {
            return (
                <Panel>
                    <LoginForm login={this.login} loginFailed={this.state.loginFailed} loginFailedReason={this.state.loginFailedReason} />
                </Panel>
            )
        }
        else {
            //var servicesInjectedAuth = injectServices(this.props.services, 'auth', this.buildAuthServices())
            //return this.props.render({services: servicesInjectedAuth})
            const authServices = this.buildAuthServices()
            return <Outlet context={{ authServices }} />
        }

    }

    buildAuthServices() {
        return {
            user: this.state.user,
            logout: this.logout.bind(this),
            //refreshToken: this.refreshToken.bind(this),
        }
    }

    login = (username, password) => {
        console.log(JSON.stringify({username: username, password: password}))
        axios.post('/token', JSON.stringify({username: username, password: password}), {
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(response => {
            let data = response.data
            this.setToken(data.token)
            this.setState({
                loggedIn: true,
                loginFailed: false,
                user: data.user
            });
        }).catch(err => {
            let loginFailedReason = null
            if (err.response.status===400) loginFailedReason = "auth"
            this.setState({
                loginFailed: true,
                loginFailedReason: loginFailedReason,
            })
        });
    };

    /*setNewLogoutTimer() {
        console.log("Refreshing logout timer")
        if (this._logoutTimer) {
            clearTimeout(this._logoutTimer)
        }
        this._logoutTimer = setTimeout(this.logout.bind(this), this.tokenExpiresAfter*60*1000)
    }*/

    /*refreshToken() {
        if (!this.getTokenUpdatedAt()) return

        var currentTime = new Date()
        var tokenExpirationTime = add(new Date(this.getTokenUpdatedAt()), {minutes: this.tokenExpiresAfter})

        if (isAfter(currentTime, tokenExpirationTime)) {
            console.log("Token expired, logging out")
            this.logout()
        }

        var tokenRefreshAfterTime = sub(tokenExpirationTime, {minutes: Math.sqrt(this.tokenExpiresAfter)})

        if (isAfter(currentTime, tokenRefreshAfterTime)) {
            console.log("Fetching new token")
            axios.post('/token/refresh', {
                "token": this.getToken()
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.data)
            .then(json => {
                this.setToken(json.token)
                this.setNewLogoutTimer()
            });
        }

    }*/

    logout() {
        console.log("Logging out")
        this.removeToken()
        if (this._logoutTimer) {
            clearTimeout(this._logoutTimer)
            this._logoutTimer = null
        }
        this.setState({ loggedIn: false, username: ''});
    };

    getToken(setAxios=false) {
        var token = localStorage.getItem('token')
        if (setAxios && token) this.setAxiosDefaultHeaderAuth(token);
        return token
    }

    getTokenUpdatedAt() {
        return localStorage.getItem('tokenUpdatedAt')
    }

    setToken(token) {
        localStorage.setItem('token', token);
        localStorage.setItem('tokenUpdatedAt', new Date())
        this.setAxiosDefaultHeaderAuth(token);
    }

    removeToken() {
        localStorage.removeItem('token');
        localStorage.removeItem('tokenUpdatedAt')
        this.setAxiosDefaultHeaderAuth("");
    }

    setAxiosDefaultHeaderAuth(token) {
        axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
    }

}

export default AuthHandler;