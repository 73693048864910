import {Component, useState} from 'react'

import TagBlueprintConfigTable from './TagBlueprintConfigTable'
import DeviceMenu from '../../../menu/DeviceMenu'
import { useOutletContext } from 'react-router-dom'
import { getSite } from '../../../../utils/UrlLocation'

const TagBlueprintConfiguration = () => {

    const {assetFrameworkServices, authServices} = useOutletContext()

    const [selectedDeviceId, setSelectedDeviceId] = useState(null)

    const selectedSiteId = getSite()

    const services = {assetFramework: assetFrameworkServices, auth: authServices}

    const updateSelectedDeviceId = (_, id) => {
        setSelectedDeviceId(id)
    }


    return (
        <div className="flow-horizontal fill-parent">
            <DeviceMenu 
                services={services} 
                onDeviceClick={updateSelectedDeviceId} 
                selectedSiteId={selectedSiteId} 
                selectedDeviceIds={[selectedDeviceId]}
                className="sidebar"
                menuProps={{
                    collapsable: true,
                }}
            />
            <div className="section-main flow-vertical fill-parent">
                <TagBlueprintConfigTable services={services} selectedSiteId={selectedSiteId} selectedDeviceId={selectedDeviceId}/>
            </div>
        </div>
    )

}

export default TagBlueprintConfiguration