import React, {Component, useState} from 'react'

import AssetMenu from '../../menu/AssetMenu'
import Navbar from '../../navbar/Navbar'
import EventBlueprintConfiguration from './events/EventBlueprintConfiguration'
import EventTemplateConfiguration from './events/EventTemplateConfiguration'
import TagBlueprintConfiguration from './tags/TagBlueprintConfiguration'

import { getPortfolio, setPortfolio, getSite, setSite } from '../../../utils/UrlLocation'
import { Outlet, useOutletContext } from 'react-router-dom'


const ConfigureTab = () => {

    const [selectedPortfolioId, setSelectedPortfolioId] = useState(getPortfolio())
    const [selectedSiteId, setSelectedSiteId] = useState(getSite())

    const {assetFrameworkServices, authServices} = useOutletContext()

    const services = {assetFramework: assetFrameworkServices, auth: authServices}


    const updateSelectedSiteId = (id) => {
        setSite(id)
        setPortfolio(null)
        setSelectedPortfolioId(null)
        setSelectedSiteId(id)
    }

    const updateSelectedPortfolioId = (id) => {
        setPortfolio(id)
        setSite(null)
        setSelectedPortfolioId(id)
        setSelectedSiteId(null)
    }
    
    return (
        <div className="flow-horizontal fill-parent">
            <AssetMenu 
                updateSelectedSite={updateSelectedSiteId} 
                updateSelectedPortfolio={updateSelectedPortfolioId}
                selectedSiteId={selectedSiteId}
                selectedPortfolioId={selectedPortfolioId}
                className="sidebar"
                menuProps={{
                    collapsable: true
                }}
            />
            <div className="flow-vertical fill-parent">
                <Navbar 
                    services={services}
                    defaultURL="event_blueprints"
                    items={[
                        {
                            label: "Event Blueprints",
                            url: "",
                            exact: true,
                            component: EventBlueprintConfiguration,
                            props: {
                                key: selectedSiteId,
                                services: services,
                                selectedSiteId: selectedSiteId
                            }
                        },
                        {
                            label: "Event Templates",
                            url: "event_templates",
                            component: EventTemplateConfiguration,
                            props: {
                                key: selectedSiteId,
                                services: services,
                                selectedSiteId: selectedSiteId
                            }
                        },
                        {
                            label: "Tag Blueprints",
                            url: "tag_blueprints",
                            component: TagBlueprintConfiguration,
                            props: {
                                key: selectedSiteId,
                                services: services,
                                selectedSiteId: selectedSiteId
                            }
                        },
                    ]}
                />
                <Outlet context={{assetFrameworkServices, authServices}} />
            </div>
        </div>
    )

}

export default ConfigureTab