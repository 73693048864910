import './Monitor.css'

import Widget1 from './Dashboard1Widget1'
import Widget2 from './Dashboard1Widget2'


const DashboardComponent = ({onBack}) => {

    return (
        <div className="flow-vertical fill-parent">
            <div className="flow-horizontal" style={{justifyContent: "space-between", marginBottom: "15px"}}>
                <div onClick={onBack} style={{cursor: "pointer"}}>
                    {" < Back to dashboards"}
                </div>
            </div>
            <div className="flow-horizontal fill-parent">
                <Widget1/>
                <Widget2/>
            </div>
        </div>
    )
}

export default DashboardComponent