import './ForecastChart.css'
import '../context_menu/ContextMenu.css'
import React, {Component} from 'react'

import _ from 'lodash'
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import {fromUnixTime} from 'date-fns'
import {Alert} from 'rsuite'

import { createBasicSeries, convertObjectToSeries, createYAxes, defaultOptions } from '../../utils/Highcharts'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


class BaseChart extends Component {

    constructor(props) {
        super()

        this.state = {
            chartOptions: {},
            frozen: false,
            snapshot: false,
            initialSeries: [],
            historicalDataObject: null,
            historicalDataLength: null,
            counter: 0,
            snapshotCounter: 0,
            snapshotSeries: {}
        }

        this.legendAggregationRefs = {}
        this.defaultStyle = {'height': '100%', 'overflow': 'hidden'}
        
    }

    componentDidMount() {
        this.initializeData()
    }

    /**
     * Only update when the input data changes
     * @param {*} prevProps 
     * @param {*} prevState 
     * @returns 
     */
    shouldComponentUpdate(prevProps, prevState) {
        // Only rerender when specific state components have changed
        if (this.state.frozen!==prevState.frozen) return true
        if (this.state.counter < 1) return true
        if (this.props.fixedAxis!==prevProps.fixedAxis) return true
        if (Object.keys(this.state.snapshotSeries).length!==Object.keys(prevState.snapshotSeries).length) return true
        return false
    }

    render() {
        console.log("Rendering chart")

        // Recreate chart options on every render
        var allowChartUpdate = (this.state.counter < 1 ? true : false)

        // Create table rows for series
        var series = this.state.initialSeries.map(series => {
            return (
                <tr key={series.name} className="" onClick={() => Alert.info("Can't remove an active series", 3000)}>
                    <td colSpan="1">Active</td>
                    <td colSpan="9">{series.name}</td>
                    <td className="sum" colSpan="1" ref={this.legendAggregationRefs[series.name].sum}></td>
                    <td className="avg" colSpan="1" ref={this.legendAggregationRefs[series.name].avg}></td>
                </tr>
            )
        })

        // Create table rows for snapshots
        var snapshots = Object.keys(this.state.snapshotSeries).map(snapshotName => {
            let snapshot = this.state.snapshotSeries[snapshotName]
            return <tr className="" key={Math.random()} onClick={() => this.removeSnapshot(snapshotName)} >
                <td colSpan="1">Snapshot</td>
                <td colSpan="7">{snapshotName}</td>
                <td colSpan="2">{snapshot.time}</td>
                <td className="sum" colSpan="1">{snapshot.sum}</td>
                <td className="avg" colSpan="1">{snapshot.avg}</td>
            </tr>
        })

        return (
            <div className="section-panel flow-vertical fill-parent">
                <ContextMenuTrigger id="same_unique_identifier" attributes={{className: "flow-vertical fill-parent"}}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={this.state.chartOptions}
                        containerProps = {{
                            className: (this.props.className || ""),
                            style: Object.assign(this.defaultStyle),
                        }}
                        allowChartUpdate={allowChartUpdate}
                        callback={(chart) => {this.setState({chart: chart})}}
                    />
                </ContextMenuTrigger>
                <div className="fill-parent" style={{overflowX: 'auto', minHeight: '150px', maxHeight: '150px', padding: '10px 20px'}}>
                    <table className="series-table" style={{minWidth: '800px'}}>
                        <tbody>
                        <tr className="">
                            <th colSpan="1">Type</th>
                            <th colSpan="7">Name</th>
                            <th colSpan="2">Snapshot Time</th>
                            <th colSpan="1">Sum</th>
                            <th colSpan="1">Avg</th>
                        </tr>
                        {series}
                        {snapshots.length > 0 && snapshots}
                        </tbody>
                    </table>
                </div>

                <ContextMenu id="same_unique_identifier" hideOnLeave={true}>
                    <MenuItem data={{}} onClick={this.toggleFreeze}>
                        {(this.state.frozen ? "Unfreeze" : "Freeze")}
                    </MenuItem>
                    <MenuItem onClick={this.addSnapshot}>
                        Add Snapshot
                    </MenuItem>
                </ContextMenu>
            </div>
        )
    }

    toggleFreeze = () => {
        this.setState({
            frozen: !this.state.frozen
        })
    }

    removeSnapshot(snapshotId) {
        var series = this.state.snapshotSeries[snapshotId].series
        series.remove()

        // clone because we modify the object here, and don't want to be modifying the refrence to the component state
        // shallow clone because stores references to the actual HC series objects, don't want to clone those
        var mutateSnapshotSeries = _.clone(this.state.snapshotSeries)   
        delete mutateSnapshotSeries[snapshotId]
        this.setState({
            snapshotSeries: mutateSnapshotSeries
        })
    }

    createSeriesData(seriesName, selectedFlooredDate) {
        let forecastSeries = this.state.forecastDataObject[seriesName]
        if (!forecastSeries) return null

        let forecastData = forecastSeries[selectedFlooredDate] || []
        forecastData = forecastData.filter(x => x[0]>selectedFlooredDate)
        if (forecastData.length===0) return null   // time has not been forecasted
        
        let filteredHistorical = this.state.historicalDataObject[seriesName].filter((el,idx) => el[0] <= selectedFlooredDate)
        let data = filteredHistorical.concat(forecastData)
        
        return data
    }

    calculateAggregationsOnSeriesData(data) {
        let rawData = data.map(x => x[1])
        let sum = _.sum(rawData).toLocaleString("en-US", {maximumFractionDigits: 2})
        let avg = _.mean(rawData).toLocaleString("en-US", {maximumFractionDigits: 2})
        return [sum, avg]
    }

    addSnapshot = () => {
        var selectedFlooredDate = this.state.selectedFlooredDate
        var allChartSeries = this.state.chart.series

        var allNewSnapshotSeries = {}
        for (let series of allChartSeries) {
            if (series.userOptions.isSnapshot || !series.visible) continue

            var data = this.createSeriesData(series.name, selectedFlooredDate)
            if (!data) continue

            var [sum, avg] = this.calculateAggregationsOnSeriesData(data)

            let yAxisIndex = this.state.yAxisMap[series.name]
            let yAxis = this.state.yAxes[yAxisIndex]

            let snapshotTime = fromUnixTime(selectedFlooredDate/1000).toLocaleString("en-US", {timeZone: this.props.timezone})
            let snapshotName = "Snapshot " + this.state.snapshotCounter + " - " + series.name

            let snapshotSeries = createBasicSeries({data: data, label: snapshotName, type: "line", yAxisIndex: yAxisIndex, axisUnits: yAxis.units, color: this.state.zoneDict[series.name][0]})
            snapshotSeries["zones"] = this.createZones(series.name, this.state.selectedFlooredDate, this.state.zoneDict)
            snapshotSeries.isSnapshot = true
            let addedSeries = this.state.chart.addSeries(snapshotSeries)

            allNewSnapshotSeries[snapshotName] = {
                series: addedSeries,
                time: snapshotTime,
                sum: sum,
                avg: avg,
            }
        }

        if (Object.keys(allNewSnapshotSeries).length===0) {
            Alert.error("Couldn't create snapshot. This is likely due to attempting to take a snapshot for a period where no forecast has been made.", 7000)
            return
        }
        
        this.setState({
            snapshotCounter: this.state.snapshotCounter+1,
            snapshotSeries: Object.assign({}, this.state.snapshotSeries, allNewSnapshotSeries)
        })
    }

    handleClose = (stateUpdates={}) => {
        const allUpdates = Object.assign({
            contextMenuCoords: [null, null]
        }, stateUpdates)
        console.log(allUpdates)
        this.setState(allUpdates);
    };

    toggleFreeze = () => {
        this.setState({
            frozen: !this.state.frozen
        })
    }

    createZones = (label, x, zoneDict) => {
        var unixTimedeltaHour = 3600000
        let zones = [
            {value: x, color: zoneDict[label][2]},
            {value: (x ? x+unixTimedeltaHour : x), color: zoneDict[label][1]},
            {color: zoneDict[label][0], dashStyle: "ShortDot"}
        ]
        return zones
    }

    initializeData = () => {
        
        var colors = [
            ["#215de6", "red", "#5782e4"],
            ["#e4b217", "red", "#e6c560"],
            ["#ea561b", "red", "#ec936f"]
        ]
        var zoneDict = {}
        var yAxes = []
        var yAxisMap = {}

        let forecastDataObject = {}
        let historicalDataObject = {}
        let historicalDataLength = 0;

        this.props.data.forEach((item) => {
            let item_name = item.label
            let forecast_time = item.forecast_time

            let tagId = Number(item.tag)
            let tagTemplate = this.props.services.assetFramework.getTags().find(x => x._id===tagId)

            // Add new yAxis if none is found
            let matchingAxis = yAxes.findIndex(x => x.units===tagTemplate.eng_units)
            if (matchingAxis===-1) {
                let yAxis = createYAxes({title: tagTemplate.eng_units, units: tagTemplate.eng_units})
                yAxis["units"] = tagTemplate.eng_units
                yAxes.push(yAxis)
                yAxisMap[item_name] = yAxes.length-1
            }
            else {
                yAxisMap[item_name] = matchingAxis
            }

            if (forecast_time) {
                if (!(item_name in forecastDataObject)) forecastDataObject[item_name] = {}
                forecastDataObject[item_name][forecast_time] = convertObjectToSeries(item.data)
            }
            else { // item contains historical data
                if (!(item_name in historicalDataObject)) historicalDataObject[item_name] = {}
                let highchartsData = convertObjectToSeries(item.data)
                historicalDataObject[item_name] = highchartsData
                historicalDataLength = highchartsData.length
            }
        })

        var seriesRefs = {}

        let initialSeries = []
        Object.entries(historicalDataObject).forEach((item, idx) => {
            let label = item[0]
            let data = item[1]
            let assignedColors = colors[idx] || []
            zoneDict[label] = assignedColors

            let axisIndex = yAxisMap[label]
            let axis = yAxes[axisIndex]
            let currentMin = yAxes[axisIndex].min
            let currentMax = yAxes[axisIndex].max
            var [min, max] = this.findMinMaxInAllData(label, currentMin, currentMax)
            console.log(label, min, max)
            yAxes[axisIndex].min = min
            yAxes[axisIndex].max = max

            var sumRef = React.createRef()
            var avgRef = React.createRef()
            seriesRefs[label] = {
                sum: sumRef,
                avg: avgRef,
            }

            let series = createBasicSeries({data: _.cloneDeep(data), label: label, type: "line", yAxisIndex: axisIndex, axisUnits: axis.units, color: assignedColors[0]})
            series["zones"] = this.createZones(label, null, zoneDict)
            series["tooltip"]["valueDecimals"] = 2
            initialSeries.push(series)
        })

        this.legendAggregationRefs = seriesRefs

        this.setState({
            chartOptions: Highcharts.merge({}, defaultOptions, this.constructHighchartOptions(initialSeries, yAxes)),
            forecastDataObject: forecastDataObject,
            historicalDataObject: historicalDataObject,
            historicalDataLength: historicalDataLength,
            initialSeries: initialSeries,
            zoneDict: zoneDict,
            yAxisMap: yAxisMap,
            yAxes: yAxes,
        })
    }

    constructHighchartOptions(initialSeries, yAxes) {
        return {
            time: {
                timezone: this.props.timezone,
            },
            series: initialSeries,
            tooltip: {
                followPointer: false,
                animation: false,
                outside: false,
                positioner: function (labelWidth, labelHeight, point) {
                    var chart = this.chart
                    var offset = 90
                    var chartRight = chart.plotLeft+chart.chartWidth-offset
                    var overflow = point.plotX+labelWidth
                    if (overflow > chartRight) {
                        return {x: point.plotX-labelWidth+offset, y: 50}
                    }
                    return { x: point.plotX, y: 50 };
                },
            },
            plotOptions: {
                series: {
                    cropThreshold: 100000000000,   // want all points to be drawn so when we set series data it will always be the same length
                    zoneAxis: 'x',
                    point: {
                        events: {
                            mouseOver: this.createHoverPointEvent()
                        }
                    },
                    step: 'left',
                }
            },
            legend: {
                floating: false,
                verticalAlign: 'top',
                align: 'right',
            },
            yAxis: yAxes,
        }
    }

    findMinMaxInAllData = (label, globalMin, globalMax) => {
        for (let chunk of this.props.data) {
            if (chunk.label!==label) continue
            let values = Object.values(chunk.data)
            let max = Math.max(...values)
            if (globalMax === undefined) globalMax = max
            if (max > globalMax) globalMax = max
            let min = Math.min(...values)
            if (globalMin === undefined) globalMin = min
            if (min < globalMin) globalMin = min
        }
        return [globalMin, globalMax]
    }

    createHoverPointEvent() {
        var self = this
        var callback = function() {

            if (self.state.frozen) {
                return
            }

            let flooredDate = new Date(this.x)
            flooredDate.setMilliseconds(0)
            flooredDate.setSeconds(0)
            flooredDate.setMinutes(0)
            flooredDate = Number(flooredDate)
            if (flooredDate===self.state.selectedFlooredDate) return
            
            var aggregations = {}
            
            for (let series of this.series.chart.series) {
                if (series.userOptions.isSnapshot || !series.visible) continue
                // be careful of mutations here

                let data = self.createSeriesData(series.name, flooredDate)
                if (!data) continue

                // Ensure the new data is the same length as the current series data otherwise highcharts bugs incoming
                // console.log(data.length, series.data.length, self.state.historicalDataLength, series.points.length)
                // console.log(data.length, series.points.length, series.selected)
                if (data.length!==series.points.length) {
                    Alert.error("Failed to update series: data length mismatch")
                    continue
                }

                // DEBUG print statements
                // console.log(new Date(Number(flooredDate)).toLocaleString('en-US', {timeZone: "US/Pacific"}))
                // console.log(Object.keys(self.state.forecastDataObject[series.name]).map(x => x ? new Date(Number(x)).toLocaleString('en-US', {timeZone: "US/Pacific"}) : null))
                // console.log(data.length)

                let [sum, avg] = self.calculateAggregationsOnSeriesData(data)
                aggregations[series.name] = {
                    sum: sum,
                    avg: avg,
                }

                series.update({
                    "zones": self.createZones(series.name, flooredDate, self.state.zoneDict),
                })
                series.setData(data, true, self.props.animate, true)
            }

            for (let seriesName of Object.keys(self.legendAggregationRefs)) {
                let seriesRefs = self.legendAggregationRefs[seriesName]
                try {
                    seriesRefs.sum.current.textContent = aggregations[seriesName]?.sum
                    seriesRefs.avg.current.textContent = aggregations[seriesName]?.avg
                }
                catch (e) {
                    console.error(e)
                }
            }

            self.setState({selectedFlooredDate: flooredDate, counter: self.state.counter+1})
        }
        return callback
    }

}

export default BaseChart