import { useRef, useState } from 'react'

import { PalantirButton } from '../../components/button/Button'
import { ViewFormError } from '../../utils/databaseAppUtils'

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { faAnglesDown, faAnglesUp } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import _ from 'lodash'

export default function ViewPanel (props) {

    const panelRef = useRef()
    const [collapsed, setCollapsed] = useState(props.hideOnInit || false)
    const [updateAlert, setUpdateAlert] = useState({
        type: null,
        inFlight: false,
        severity: null,
        message: null,
    })

    var toggleCollapseIcon = collapsed ? faAnglesDown : faAnglesUp
    // don't allow closing of error alert
    var alertOnClose = updateAlert.type==="response" && updateAlert.severity==="success" ? () => {
        setUpdateAlert({
            type: null,
            inFlight: false,
            severity: null,
            message: null,
        })
    } : null
    const UpdateAlert = (props) => (
        <Alert 
            severity={updateAlert.severity} 
            onClose={alertOnClose}
            style={{marginBottom: (props.top ? "0px" : "15px"), marginTop: (props.top ? "15px" : "15px")}}
        >
            <AlertTitle>{_.capitalize(updateAlert.severity) + " - " +  _.capitalize(updateAlert.type)}</AlertTitle>
            {updateAlert.message}
        </Alert>
    )

    return (
        <div className="view-panel fill-parent flow-vertical overflow-yz" style={props.style||{}}>
            {/*<div className="flow-horizontal header" >
                <div style={{fontSize: "26px", flexShrink: 0}}>{props.title}</div>
            </div>*/}
            <div className="flow-vertical" style={{padding: "10px 20px"}}>
                {updateAlert.type && <UpdateAlert top={true}/>}
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={updateAlert.inFlight}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                    <div className="flow-vertical" style={{marginTop: "15px"}}>
                        <form
                            ref={panelRef}
                            className="view-content field-group overflow-y"
                            style={{overflow: "auto"}}
                        >
                            {props.children}
                        </form>
                        <div className="flow-horizontal" style={{margin: "15px 5px 5px 5px", flexShrink: 0}}>
                            <div className="fill-parent"></div>
                            {props.showDelete &&
                                <PalantirButton
                                    onClick={() => {
                                        const callback = ({onFail}) => {
                                            const payload = props.buildSubmitPayload()
                                            axios.post(props.submitUrl, payload)
                                            .then((response) => {
                                                console.log(response)
                                                setUpdateAlert({
                                                    type: "response",
                                                    inFlight: false,
                                                    severity: 'success',
                                                    message: 'Successfully updated record.',
                                                })
                                                // set update flag to true in props
                                                if (props.onSubmitSuccess) props.onSubmitSuccess(response, payload)
                                            }).catch((error) => {
                                                console.log(error)
                                                // On authentication fail, notify user. Don't want to log them out as their changes would be lost.
                                                // TODO: Could just open an auth popup instead of redirection to auth page to maintain state
                                                if (error.response?.status === 401) {
                                                    // props.services.auth.logout()
                                                    setUpdateAlert({
                                                        type: "response",
                                                        inFlight: false,
                                                        severity: 'error',
                                                        message: 'Authentication failed. Please login again.'
                                                    })    
                                                }
                                                else if (error.response?.status===400 || error.response?.status===403) {
                                                    setUpdateAlert({
                                                        type: "response",
                                                        inFlight: false,
                                                        severity: 'error',
                                                        message: error.response.data?.detail
                                                    })
                                                }
                                                else {
                                                    console.log(error.response)
                                                    setUpdateAlert({
                                                        type: "response",
                                                        inFlight: false,
                                                        severity: 'error',
                                                        message: 'Failed to update record.'
                                                    })
                                                }
                                                if (props.onSubmitError) props.onSubmitError(error, payload)
                                                if (onFail) onFail()
                                            })
                                            // update state to reflect request in flight
                                            panelRef.current.scrollTop = 0
                                            setUpdateAlert({
                                                type: null,
                                                inFlight: true,
                                                severity: null,
                                                message: null,
                                            })
                                        }
                                        if (props.onDelete) props.onDelete(callback)
                                    }}
                                    style={{marginRight: "20px"}}
                                    color="error"
                                >
                                    Delete
                                </PalantirButton>
                            }
                            <PalantirButton
                                onClick={(e) => {
                                    const payload = props.buildSubmitPayload()

                                    // Handle verification of the payload
                                    try {
                                        let verification = props.verifySubmit ? props.verifySubmit(payload) : {}
                                    } catch (e) {
                                        if (e instanceof ViewFormError) {
                                            panelRef.current.scrollTop = 0
                                            setUpdateAlert({
                                                type: "verification",
                                                inFlight: false,
                                                severity: 'info',
                                                message: e.message,
                                            })
                                            return
                                        }
                                        else {
                                            throw e
                                        }
                                    }

                                    // send update request
                                    axios.post(props.submitUrl, payload)
                                    .then((response) => {
                                        console.log(response)
                                        setUpdateAlert({
                                            type: "response",
                                            inFlight: false,
                                            severity: 'success',
                                            message: 'Successfully updated record.',
                                        })
                                        // set update flag to true in props
                                        if (props.onSubmitSuccess) props.onSubmitSuccess(response, payload)
                                    }).catch((error) => {
                                        console.log(error)
                                        // On authentication fail, notify user. Don't want to log them out as their changes would be lost.
                                        // TODO: Could just open an auth popup instead of redirection to auth page to maintain state
                                        if (error.response?.status === 401) {
                                            // props.services.auth.logout()
                                            setUpdateAlert({
                                                type: "response",
                                                inFlight: false,
                                                severity: 'error',
                                                message: 'Authentication failed. Please login again.'
                                            })    
                                        }
                                        else if (error.response?.status===400 || error.response?.status===403) {
                                            setUpdateAlert({
                                                type: "response",
                                                inFlight: false,
                                                severity: 'error',
                                                message: error.response.data?.detail
                                            })
                                        }
                                        else {
                                            console.log(error.response)
                                            setUpdateAlert({
                                                type: "response",
                                                inFlight: false,
                                                severity: 'error',
                                                message: 'Failed to update record.'
                                            })
                                        }
                                        if (props.onSubmitError) props.onSubmitError(error, payload)
                                    })
                                    // update state to reflect request in flight
                                    panelRef.current.scrollTop = 0
                                    setUpdateAlert({
                                        type: null,
                                        inFlight: true,
                                        severity: null,
                                        message: null,
                                    })
                                }}
                            >
                                Save
                            </PalantirButton>
                        </div>
                </div>
            </div>
        </div>
    )
}