import _ from 'lodash'

export function findTableObjectRecords(dataList, tableClass) {
    let object = dataList.find(x => x.schema===tableClass.schemaName && x.table===tableClass.tableName) || {}
    let records = object.records || []
    return records
}

/*export function parseFromSQLDate(date) {
    // assumes format YYYY-MM-DD
    if (typeof(date) !== "string") return null
    let parts = date.split("-")
    return new Date(parts[0], parts[1]-1, parts[2])
}

export function parseToSQLDate(date) {
    if (!(date instanceof Date)) return null
    return date.toISOString().slice(0,10)
}*/



export function transformDisplayValue(records, recordId, recordIdCol, recordLabelCols, {delimeter=" - ", formatter=null}={}) {
    let record = records.find(x => x[recordIdCol]===recordId) || {}
    if (Array.isArray(recordLabelCols)) {
        let labels = recordLabelCols.map(y => record[y])
        if (formatter) {
            for (let i in labels) {
                let label = labels[i]
                formatter = formatter.replace(`$${i}`, label)
            }
            return formatter
        }
        else {
            return labels.join(` ${delimeter} `)
        }
    }
    else {
        return record[recordLabelCols]
    }
}

export function buildDropdownItems(records, recordIdCol, labelCols, {delimeter=" - ", formatter=null}={}) {
    var x = _.sortBy(records, x => Array.isArray(labelCols) ? x[labelCols[0]] : x[labelCols]).map(x => {
        let label;
        if (Array.isArray(labelCols)) {
            let labels = labelCols.map(y => x[y])
            if (formatter) {
                label = formatter
                for (let i in labels) {
                    label = label.replace(`$${i}`, labels[i])
                }
            }
            else {
                label = labels.join(` ${delimeter} `)
            }
        }
        else {
            label = x[labelCols]
        }
        return {label: label, value: x[recordIdCol]}
    })
    return x
}

export function generateUUID() {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16).toUpperCase();
    });
    return uuid;
}

/**
 * Class to handle management of error state in Views
 * (Russian Nesting Doll)
 * Usage of this class allows in place updating of form errors. This relies on the assumption that whenever an error state is changed, the content state is also changed which will trigger an update.
 */
export class ErrorRND {

    constructor(e) {
        this._e = e || {}
    }

    // errorCol, errorContractId, errorId2
    add(message, ...heirarchy) {
        var obj = this._e
        for (let i = 0; i <= heirarchy.length-1; i++) {
            let levelValue = heirarchy[i]
            if (!(levelValue in obj)) obj[levelValue] = {}
            i===(heirarchy.length-1) ? obj[levelValue] = message : obj = obj[levelValue]
        }
        return this
    }

    remove(...heirarchy) {
        var obj = this._e
        for (let i = 0; i <= heirarchy.length-2; i++) {
            let levelValue = heirarchy[i]
            obj = obj[levelValue]
            if (!obj) return this
        }
        let idx = heirarchy[heirarchy.length-1]
        delete obj[idx]
        return this
    }

    get(...heirarchy) {
        var obj = this._e
        for (let i = 0; i <= heirarchy.length-1; i++) {
            let levelValue = heirarchy[i]
            if (!obj) return null
            obj = obj[levelValue]
        }
        return obj
    }

    clone() {
        return new ErrorRND(_.cloneDeep(this._e))
    }

    empty(obj) {
        if (!obj) obj = this._e
        for (let k of Object.keys(obj)) {
            let value = obj[k]
            if (value instanceof RNDErrorInstance) return false
            else if (value instanceof Object) {
                let isValueEmpty = this.empty(value)
                if (!isValueEmpty) return false
            }
        }
        return true // Object.keys(this._e).length===0
    }

}

export class ViewFormError {

    constructor(message) {
        this.message = message
    }

}

export class RNDErrorInstance {

    constructor(message) {
        this.message = message
    }
    getMessage() {
        return this.message
    }

}