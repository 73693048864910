import { schemas } from '../Constants'
import Table from './BaseTable'
import { Counterparty } from './Entity'
import { HelpCounterpartyTable } from '../help_panels/Entity'
import { makeCounterpartyId, makeCounterpartyDescription } from './CommonColumns'
import _ from 'lodash'


/**
 * Modifies input cp instance records to include the corresponding cp name
 * @param {*} counterpartyInstanceRecords 
 * @param {*} counterpartyRecords
 * @param {*} instanceIdCol 
 * @param {*} injectedNameCol 
 */
export function injectCounterpartyName(counterpartyInstanceRecords, counterpartyRecords, instanceIdCol, injectedNameCol) {
    var counterpartyRecordMapper = {}
    counterpartyRecords.forEach(x => {
        counterpartyRecordMapper[x[Counterparty.columnSchema.counterpartyId]] = x
    })
    var newRecords = _.cloneDeep(counterpartyInstanceRecords)
    newRecords.forEach(x => {
        let match = counterpartyRecordMapper[x[instanceIdCol]] || {}
        x[injectedNameCol] = match[Counterparty.columnSchema.counterpartyName]
    })
    return newRecords
}


class BankTable extends Table {

    constructor() {

        var columnSchema = {
            bankId: "bank_id",
            description: "description",
        }

        var schemaName = schemas.counterparty
        var tableName = "bank"

        var displayNameSingular = "Bank"
        var displayNamePlural = "Banks"

        var pkUidColumn = null
        var identifiers = [columnSchema.bankId]
        var dependencies = [Counterparty]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpCounterpartyTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        //var bankRecords = this.findTableObjectRecords(data, this)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    makeCounterpartyId(counterpartyRecords, this.columnSchema.bankId, Counterparty.columnSchema.counterpartyId, Counterparty.columnSchema.counterpartyName),
                    makeCounterpartyDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var Bank = new BankTable()

class DeveloperTable extends Table {

    constructor() {

        var columnSchema = {
            developerId: "developer_id",
            description: "description",
        }

        var schemaName = schemas.counterparty
        var tableName = "developer"

        var displayNameSingular = "Developer"
        var displayNamePlural = "Developers"

        var pkUidColumn = null
        var identifiers = [columnSchema.developerId]
        var dependencies = [Counterparty]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpCounterpartyTable type="Developer" />

    buildTableProps = (data) => {

        //var developerRecords = this.findTableObjectRecords(data, this)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    makeCounterpartyId(counterpartyRecords, this.columnSchema.developerId, Counterparty.columnSchema.counterpartyId, Counterparty.columnSchema.counterpartyName),
                    makeCounterpartyDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var Developer = new DeveloperTable()

class EPCTable extends Table {

    constructor() {

        var columnSchema = {
            epcId: "epc_id",
            description: "description",
        }

        var schemaName = schemas.counterparty
        var tableName = "epc"

        var displayNameSingular = "EPC"
        var displayNamePlural = "EPCs"

        var pkUidColumn = null
        var identifiers = [columnSchema.epcId]
        var dependencies = [Counterparty]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpCounterpartyTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        //var epcRecords = this.findTableObjectRecords(data, this)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    makeCounterpartyId(counterpartyRecords, this.columnSchema.epcId, Counterparty.columnSchema.counterpartyId, Counterparty.columnSchema.counterpartyName),
                    makeCounterpartyDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var EPC = new EPCTable()

class InterconnectionTable extends Table {

    constructor() {

        var columnSchema = {
            interconnectionId: "interconnection_id",
            description: "description",
        }

        var schemaName = schemas.counterparty
        var tableName = "interconnection"

        var displayNameSingular = "Interconnection"
        var displayNamePlural = "Interconnections"

        var pkUidColumn = null
        var identifiers = [columnSchema.interconnectionId]
        var dependencies = [Counterparty]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpCounterpartyTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        //var interconnectionRecords = this.findTableObjectRecords(data, this)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    makeCounterpartyId(counterpartyRecords, this.columnSchema.interconnectionId, Counterparty.columnSchema.counterpartyId, Counterparty.columnSchema.counterpartyName),
                    makeCounterpartyDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var Interconnection = new InterconnectionTable()

class InvestorTable extends Table {

    constructor() {

        var columnSchema = {
            investorId: "investor_id",
            description: "description",
        }

        var schemaName = schemas.counterparty
        var tableName = "investor"

        var displayNameSingular = "Investor"
        var displayNamePlural = "Investors"

        var pkUidColumn = null
        var identifiers = [columnSchema.investorId]
        var dependencies = [Counterparty]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpCounterpartyTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        //var investorRecords = this.findTableObjectRecords(data, this)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    makeCounterpartyId(counterpartyRecords, this.columnSchema.investorId, Counterparty.columnSchema.counterpartyId, Counterparty.columnSchema.counterpartyName),
                    makeCounterpartyDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var Investor = new InvestorTable()

class AssetManagerTable extends Table {

    constructor() {

        var columnSchema = {
            assetManagerId: "asset_manager_id",
            description: "description",
        }

        var schemaName = schemas.counterparty
        var tableName = "asset_manager"

        var displayNameSingular = "Asset Manager"
        var displayNamePlural = "Asset Managers"

        var pkUidColumn = null
        var identifiers = [columnSchema.assetManagerId]
        var dependencies = [Counterparty]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpCounterpartyTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        //var managementRecords = this.findTableObjectRecords(data, this)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    makeCounterpartyId(counterpartyRecords, this.columnSchema.assetManagerId, Counterparty.columnSchema.counterpartyId, Counterparty.columnSchema.counterpartyName),
                    makeCounterpartyDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var AssetManager = new AssetManagerTable()

class OwnerTable extends Table {

    constructor() {

        var columnSchema = {
            ownerId: "owner_id",
            description: "description",
        }

        var schemaName = schemas.counterparty
        var tableName = "owner"

        var displayNameSingular = "Owner"
        var displayNamePlural = "Owners"

        var pkUidColumn = null
        var identifiers = [columnSchema.ownerId]
        var dependencies = [Counterparty]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpCounterpartyTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        //var ownerRecords = this.findTableObjectRecords(data, this)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    makeCounterpartyId(counterpartyRecords, this.columnSchema.ownerId, Counterparty.columnSchema.counterpartyId, Counterparty.columnSchema.counterpartyName),
                    makeCounterpartyDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var Owner = new OwnerTable()

class OandMTable extends Table {

    constructor() {

        var columnSchema = {
            oandmId: "oandm_id",
            description: "description",
        }

        var schemaName = schemas.counterparty
        var tableName = "oandm"

        var displayNameSingular = "O&M"
        var displayNamePlural = "O&Ms"

        var pkUidColumn = null
        var identifiers = [columnSchema.oandmId]
        var dependencies = [Counterparty]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpCounterpartyTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        //var oandmRecords = this.findTableObjectRecords(data, this)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    makeCounterpartyId(counterpartyRecords, this.columnSchema.oandmId, Counterparty.columnSchema.counterpartyId, Counterparty.columnSchema.counterpartyName),
                    makeCounterpartyDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var OandM = new OandMTable()

class OEMTable extends Table {

    constructor() {

        var columnSchema = {
            oemId: "oem_id",
            description: "description",
        }

        var schemaName = schemas.counterparty
        var tableName = "oem"

        var displayNameSingular = "OEM"
        var displayNamePlural = "OEMs"

        var pkUidColumn = null
        var identifiers = [columnSchema.oemId]
        var dependencies = [Counterparty]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpCounterpartyTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        //var oemRecords = this.findTableObjectRecords(data, this)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    makeCounterpartyId(counterpartyRecords, this.columnSchema.oemId, Counterparty.columnSchema.counterpartyId, Counterparty.columnSchema.counterpartyName),
                    makeCounterpartyDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var OEM = new OEMTable()

class OfftakeTable extends Table {

    constructor() {

        var columnSchema = {
            offtakeId: "offtake_id",
            description: "description",
        }

        var schemaName = schemas.counterparty
        var tableName = "offtake"

        var displayNameSingular = "Offtake"
        var displayNamePlural = "Offtakers"

        var pkUidColumn = null
        var identifiers = [columnSchema.offtakeId]
        var dependencies = [Counterparty]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpCounterpartyTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        //var offtakeRecords = this.findTableObjectRecords(data, this)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    makeCounterpartyId(counterpartyRecords, this.columnSchema.offtakeId, Counterparty.columnSchema.counterpartyId, Counterparty.columnSchema.counterpartyName),
                    makeCounterpartyDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var Offtake = new OfftakeTable()

class OperatorTable extends Table {

    constructor() {

        var columnSchema = {
            operatorId: "operator_id",
            description: "description",
        }

        var schemaName = schemas.counterparty
        var tableName = "operator"

        var displayNameSingular = "Operator"
        var displayNamePlural = "Operators"

        var pkUidColumn = null
        var identifiers = [columnSchema.operatorId]
        var dependencies = [Counterparty]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpCounterpartyTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        //var operatorRecords = this.findTableObjectRecords(data, this)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    makeCounterpartyId(counterpartyRecords, this.columnSchema.operatorId, Counterparty.columnSchema.counterpartyId, Counterparty.columnSchema.counterpartyName),
                    makeCounterpartyDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var Operator = new OperatorTable()

class SchedulingCoordinatorTable extends Table {

    constructor() {

        var columnSchema = {
            schedulerId: "scheduler_id",
            description: "description",
        }

        var schemaName = schemas.counterparty
        var tableName = "scheduling_coordinator"

        var displayNameSingular = "Scheduling Coordinator"
        var displayNamePlural = "Scheduling Coordinators"

        var pkUidColumn = null
        var identifiers = [columnSchema.schedulerId]
        var dependencies = [Counterparty]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpCounterpartyTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        //var schedulerRecords = this.findTableObjectRecords(data, this)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    makeCounterpartyId(counterpartyRecords, this.columnSchema.schedulerId, Counterparty.columnSchema.counterpartyId, Counterparty.columnSchema.counterpartyName),
                    makeCounterpartyDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var SchedulingCoordinator = new SchedulingCoordinatorTable()

class PermitterTable extends Table {

    constructor() {

        var columnSchema = {
            permitterId: "permitter_id",
            description: "description",
        }

        var schemaName = schemas.counterparty
        var tableName = "permitter"

        var displayNameSingular = "Permitter"
        var displayNamePlural = "Permitters"

        var pkUidColumn = null
        var identifiers = [columnSchema.permitterId]
        var dependencies = [Counterparty]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpCounterpartyTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        //var permitterRecords = this.findTableObjectRecords(data, this)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    makeCounterpartyId(counterpartyRecords, this.columnSchema.permitterId, Counterparty.columnSchema.counterpartyId, Counterparty.columnSchema.counterpartyName),
                    makeCounterpartyDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var Permitter = new PermitterTable()

class BESSIntegratorTable extends Table {

    constructor() {

        var columnSchema = {
            bessIntegratorId: "bess_integrator_id",
            description: "description",
        }

        var schemaName = schemas.counterparty
        var tableName = "bess_integrator"

        var displayNameSingular = "BESS Integrator"
        var displayNamePlural = "BESS Integrators"

        var pkUidColumn = null
        var identifiers = [columnSchema.bessIntegratorId]
        var dependencies = [Counterparty]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpCounterpartyTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    makeCounterpartyId(counterpartyRecords, this.columnSchema.bessIntegratorId, Counterparty.columnSchema.counterpartyId, Counterparty.columnSchema.counterpartyName),
                    makeCounterpartyDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export const BESSIntegrator = new BESSIntegratorTable()

class ServiceProviderTable extends Table {

    constructor() {

        var columnSchema = {
            serviceProviderId: "service_provider_id",
            serviceProvided: "service_provided",
            description: "description"
        }

        var schemaName = schemas.counterparty
        var tableName = "service_provider"

        var displayNameSingular = "Service Provider"
        var displayNamePlural = "Service Providers"

        var pkUidColumn = null
        var identifiers = [columnSchema.serviceProviderId]
        var dependencies = [Counterparty]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }

    buildTableProps = (data) => {

        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    makeCounterpartyId(counterpartyRecords, this.columnSchema.serviceProviderId, Counterparty.columnSchema.counterpartyId, Counterparty.columnSchema.counterpartyName),
                    {
                        name: "Service Provided",
                        key: this.columnSchema.serviceProvided,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    },
                    makeCounterpartyDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export const ServiceProvider = new ServiceProviderTable()

class LandownerTable extends Table {

    constructor() {

        var columnSchema = {
            landownerId: "landowner_id",
            description: "description"
        }

        var schemaName = schemas.counterparty
        var tableName = "landowner"

        var displayNameSingular = "Landowner"
        var displayNamePlural = "Landowners"

        var pkUidColumn = null
        var identifiers = [columnSchema.landownerId]
        var dependencies = [Counterparty]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }

    buildTableProps = (data) => {

        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    makeCounterpartyId(counterpartyRecords, this.columnSchema.landownerId, Counterparty.columnSchema.counterpartyId, Counterparty.columnSchema.counterpartyName),
                    makeCounterpartyDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export const Landowner = new LandownerTable()