import React, {Component, useState} from 'react'

import AssetSidebar from '../../menu/AssetMenu'
import HomeContent from './HomeContent'

import { getPortfolio, setPortfolio, getSite, setSite } from '../../../utils/UrlLocation'
import { useOutletContext } from 'react-router-dom'

const HomeTab = (props) => {

    const [selectedPortfolioId, setSelectedPortfolioId] = useState(getPortfolio())
    const [selectedSiteId, setSelectedSiteId] = useState(getSite())

    const updateSelectedSiteId = (id) => {
        setSite(id)
        setPortfolio(null)
        setSelectedPortfolioId(null)
        setSelectedSiteId(id)
    }

    const updateSelectedPortfolioId = (id) => {
        setPortfolio(id)
        setSite(null)
        setSelectedPortfolioId(id)
        setSelectedSiteId(null)
    }

    return (
        <div className="flow-horizontal fill-parent">
            <AssetSidebar 
                services={props.services}
                updateSelectedSite={updateSelectedSiteId} 
                updateSelectedPortfolio={updateSelectedPortfolioId}
                selectedSiteId={selectedSiteId}
                selectedPortfolioId={selectedPortfolioId}
                className="sidebar"
                menuProps={{
                    collapsable: true,
                }}
            />
            <HomeContent
                key={selectedSiteId}
                services={props.services}
                selectedPortfolioId={selectedPortfolioId}
                selectedSiteId={selectedSiteId}
            />
        </div>
    )

}

export default HomeTab