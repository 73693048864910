import { schemas } from '../Constants'
import Table from './BaseTable'
import {validateEmail} from '../../utils/MiscUtils'
import { 
    HelpFundTable, HelpPortfolioTable, HelpEmployeeTable,
    HelpEmployeeFirstName, HelpEmployeeLastName, HelpEmployeeEmail
 } from '../help_panels/Entity'
 import { makeEntityId, makeEntityName, makeEntityDescription, makeNotes } from './CommonColumns'
 import { transformDisplayValue, buildDropdownItems } from '../../utils/databaseAppUtils'

/**
 * Performs basic entity validation
 * - entity has name
 * - entity name is not duplicated
 * @param {Array[Object]} records list of all records
 * @param {Object} record record to validate
 * @param {String} idCol id col name 
 * @param {String} nameCol name col name
 * @returns 
 */
export const validateEntity = (records, record, idCol, nameCol, entityName) => {
    
    if (!record[nameCol]) {
        return {
            pass: false,
            message: `Please give the ${entityName} a name.`
        }
    }

    return {
        pass: true,
        message: null,
    }
}


class CounterpartyTable extends Table {

    constructor() {

        var columnSchema = {
            counterpartyId: "counterparty_id",
            counterpartyName: "counterparty_name",
            description: "description",
        }

        var schemaName = schemas.entity
        var tableName = "counterparty"

        var displayNameSingular = "Counterparty"
        var displayNamePlural = "Counterparties"

        var pkUidColumn = columnSchema.counterpartyId
        var identifiers = [columnSchema.counterpartyId]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildTableProps = (data) => {

        var counterpartyRecords = this.findTableObjectRecords(data, this)

        var validateCounterparty = (counterpartyRecord) => validateEntity(counterpartyRecords, counterpartyRecord, this.columnSchema.counterpartyId, this.columnSchema.counterpartyName, this.displayNameSingular)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: validateCounterparty,
            validateUpdateItem: validateCounterparty,
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.counterpartyId),
                    makeEntityName(this.columnSchema.counterpartyName, this.displayNameSingular, 300),
                    makeEntityDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var Counterparty = new CounterpartyTable()

class FundTable extends Table {

    constructor() {

        var columnSchema = {
            fundId: "fund_id",
            fundName: "fund_name",
            description: "description",
        }

        var schemaName = schemas.entity
        var tableName = "fund"

        var displayNameSingular = "Fund"
        var displayNamePlural = "Funds"

        var pkUidColumn = columnSchema.fundId
        var identifiers = [columnSchema.fundId]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = HelpFundTable

    buildTableProps = (data) => {

        var fundRecords = this.findTableObjectRecords(data, this)

        var validateFund = (fundRecord) => validateEntity(fundRecords, fundRecord, this.columnSchema.fundId, this.columnSchema.fundName, this.displayNameSingular)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: validateFund,
            validateUpdateItem: validateFund,
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.fundId),
                    makeEntityName(this.columnSchema.fundName, this.displayNameSingular, 100),
                    makeEntityDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var Fund = new FundTable()

class PortfolioTable extends Table {

    constructor() {

        var columnSchema = {
            portfolioId: "portfolio_id",
            portfolioName: "portfolio_name",
            description: "description",
        }

        var schemaName = schemas.entity
        var tableName = "portfolio"

        var displayNameSingular = "Portfolio"
        var displayNamePlural = "Portfolios"

        var pkUidColumn = columnSchema.portfolioId
        var identifiers = [columnSchema.portfolioId]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = HelpPortfolioTable

    buildTableProps = (data) => {

        var portfolioRecords = this.findTableObjectRecords(data, this)

        var validatePortfolio = (portfolioRecord) => validateEntity(portfolioRecords, portfolioRecord, this.columnSchema.portfolioId, this.columnSchema.portfolioName, this.displayNameSingular)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: validatePortfolio,
            validateUpdateItem: validatePortfolio,
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.portfolioId),
                    makeEntityName(this.columnSchema.portfolioName, this.displayNameSingular, 150),
                    makeEntityDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var Portfolio = new PortfolioTable()

class EmployeeTable extends Table {

    constructor() {

        var columnSchema = {
            employeeId: "employee_id",
            firstName: "first_name",
            lastName: "last_name",
            email: "email",
            startDate: "start_date",
            endDate: "end_date",
            department: "department",
        }

        var schemaName = schemas.entity
        var tableName = "employee"

        var displayNameSingular = "Employee"
        var displayNamePlural = "Employees"

        var pkUidColumn = columnSchema.employeeId
        var identifiers = [columnSchema.employeeId]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
        this.initOptions()
    }

    initOptions = () => {
        this.options = {
            department: [{
                label: "Accounting", value: "Accounting"
            }, {
                label: "Asset Management", value: "Asset Management"
            }, {
                label: "CCO Org", value: "CCO Org"
            }, {
                label: "CEO Org", value: "CEO Org"
            }, {
                label: "CFO Org", value: "CFO Org"
            }, {
                label: "COO Org", value: "COO Org"
            }, {
                label: "CPO Org", value: "CPO Org"
            }, {
                label: "Construction", value: "Construction"
            }, {
                label: "Corporate", value: "Corporate"
            }, {
                label: "Development", value: "Development"
            }, {
                label: "Engineering", value: "Engineering"
            }, {
                label: "Finance", value: "Finance"
            }, {
                label: "Finance & Investing", value: "Finance & Investing"
            }, {
                label: "FP&A", value: "FP&A"
            }, {
                label: "Government & Marketing", value: "Government & Marketing"
            }, {
                label: "Interconnection", value: "Interconnection"
            }, {
                label: "Investment", value: "Investment"
            }, {
                label: "Legal", value: "Legal"
            }, {
                label: "M&A", value: "M&A"
            }, {
                label: "Origination", value: "Origination"
            }, {
                label: "Power Markets", value: "Power Markets"
            }, {
                label: "Procurement", value: "Procurement"
            }, {
                label: "Project Finance", value: "Project Finance"
            }, {
                label: "Tax", value: "Tax"
            }, {
                label: "Treasury", value: "Treasury"
            }]
        }
    }

    buildHelpPanel = HelpEmployeeTable

    buildFullName = (employee) => `${employee[this.columnSchema.firstName]} ${employee[this.columnSchema.lastName]}`

    buildTableProps = (data) => {

        var employeeRecords = this.findTableObjectRecords(data, this)

        var employeeIdCol = this.columnSchema.employeeId
        var firstNameCol = this.columnSchema.firstName
        var lastNameCol = this.columnSchema.lastName
        var emailCol = this.columnSchema.email

        const validateEmployee = (employeeRecord) => {
            if (!employeeRecord[firstNameCol] || !employeeRecord[lastNameCol]) {
                return {
                    pass: false,
                    message: "Employee must have a first and last name"
                }
            }
            if (!validateEmail(employeeRecord[emailCol])) {
                return {
                    pass: false,
                    message: "Invalid email address"
                }
            }
            return {pass: true}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: validateEmployee,
            validateUpdateItem: validateEmployee,
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.employeeId),
                    {
                        name: "First Name",
                        key: this.columnSchema.firstName,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        },
                        info: HelpEmployeeFirstName
                    }, {
                        name: "Last Name",
                        key: this.columnSchema.lastName,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        },
                        info: HelpEmployeeLastName
                    }, {
                        name: "Email Address",
                        key: this.columnSchema.email,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 300,
                            resizable: true
                        },
                        info: HelpEmployeeEmail
                    }, {
                        name: "Start Date",
                        key: this.columnSchema.startDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        },
                    }, {
                        name: "End Date",
                        key: this.columnSchema.endDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        },
                    }, {
                        name: "Department",
                        key: this.columnSchema.department,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.department
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        },
                    }
                ]
            },
        }
    }

}
export var Employee = new EmployeeTable()

class AddressTable extends Table {

    constructor() {

        var columnSchema = {
            oid: "oid",
            addressLine1: "address_line_1",
            addressLine2: "address_line_2",
            zipcode: "zipcode",
            city: "city",
            state: "state"
        }

        var schemaName = schemas.dbo
        var tableName = "address"

        var displayNameSingular = "Address"
        var displayNamePlural = "Addresses"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }

    buildTableProps = (data) => {

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid),
                    {
                        name: "Address Line 1",
                        key: this.columnSchema.addressLine1,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Address Line 2",
                        key: this.columnSchema.addressLine2,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Zipcode",
                        key: this.columnSchema.zipcode,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "City",
                        key: this.columnSchema.city,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "State",
                        key: this.columnSchema.state,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 100,
                            resizable: true
                        }
                    }
                ]
            },
        }
    }

}
export const Address = new AddressTable()

class ContactTable extends Table {

    constructor() {

        var columnSchema = {
            contactId: "contact_id",
            counterpartyId: "counterparty_id",
            name: "name",
            email: "email",
            phoneNumber: "phone_number",
            address: "address",
            notes: "notes"
        }

        var schemaName = schemas.entity
        var tableName = "contact"

        var displayNameSingular = "Contact"
        var displayNamePlural = "Contacts"

        var pkUidColumn = columnSchema.contactId
        var identifiers = [columnSchema.contactId]
        var dependencies = [Counterparty, Address]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }

    buildTableProps = (data) => {

        const counterpartyRecords = this.findTableObjectRecords(data, Counterparty)
        const counterpartyIdCol = Counterparty.columnSchema.counterpartyId
        const counterpartyNameCol = Counterparty.columnSchema.counterpartyName

        const addressRecords = this.findTableObjectRecords(data, Address)
        const addressIdCol = Address.columnSchema.oid
        const addressNamecols = [Address.columnSchema.addressLine1, Address.columnSchema.addressLine2, Address.columnSchema.zipcode, Address.columnSchema.city, Address.columnSchema.state]

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.contactId),
                    {
                        name: "Counterparty",
                        key: this.columnSchema.counterpartyId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(counterpartyRecords, value, counterpartyIdCol, counterpartyNameCol),
                            dropdownItems: buildDropdownItems(counterpartyRecords, counterpartyIdCol, counterpartyNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Name",
                        key: this.columnSchema.name,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Email Address",
                        key: this.columnSchema.email,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 300,
                            resizable: true
                        },
                    }, {
                        name: "Phone Number",
                        key: this.columnSchema.phoneNumber,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        },
                    }, {
                        name: "Address",
                        key: this.columnSchema.address,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(addressRecords, value, addressIdCol, addressNamecols),
                            dropdownItems: buildDropdownItems(addressRecords, addressIdCol, addressNamecols),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        },
                    },
                    makeNotes(this.columnSchema.notes)
                ]
            },
        }
    }

}
export const Contact = new ContactTable()