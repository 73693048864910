import { DataProviderChart } from '../../charts/BaseChart'
import { TopBarVariant2 } from '../../charts/TopBars'
import { createBasicSeries } from '../../../utils/Highcharts'
import { useDateRangeSelectionState, shortRanges } from '../../date_picker/DateRangePicker'
import { useDataProvider } from '../../data_provider/StandardDataProvider'
import { useEffect } from 'react'
import { startOfDay, addDays  } from 'date-fns'
import { isSiteStandaloneBess, isSiteHybrid } from '../../../utils/assetFrameworkMacros'
import Panel from '../../panel/Panel'
import { useOutletContext } from 'react-router-dom'


export const PowerOverviewDirector = ({selectedSiteId, ...props}) => {

    const  { assetFrameworkServices } = useOutletContext()

    const selectedSite = assetFrameworkServices.getSites(selectedSiteId)

    const siteIsHybrid = isSiteHybrid(selectedSite)
    const siteIsStandalone = isSiteStandaloneBess(selectedSite)

    if (!siteIsHybrid && !siteIsStandalone) {
        return <Panel className="flow-vertical fill-parent">This dashboard is only available for sites with a battery.</Panel>
    }
    
    var buildChartConfig
    if (siteIsHybrid) buildChartConfig = buildHybridChartConfig
    if (siteIsStandalone) buildChartConfig = buildStandaloneChartConfig

    return (
        <PowerOverview
            selectedSiteId={selectedSiteId}
            buildChartConfig={buildChartConfig}
            {...props}
        />
    )

}


const PowerOverview = ({timezone, buildChartConfig, selectedSiteId}) => {

    const { authServices } = useOutletContext()

    const [startDate, endDate, setDateRange] = useDateRangeSelectionState({useUrlParam: false, initStartDate: startOfDay(addDays(new Date(), -2))})
    const dataProviderProps = useDataProvider({
        requestMethod: "POST",
        requestUrl: '/api/summary/performance_overview',
        onUnauthorized: authServices.logout,
        buildRequestBody: () => {
            return {
                siteId: selectedSiteId,
                startDate: startDate,
                endDate: endDate
            }
        }
    })

    useEffect(() => {
        dataProviderProps.refreshData()
    }, [selectedSiteId, startDate, endDate])

    return (
        <DataProviderChart
            dataProviderProps={dataProviderProps}
            timezone={timezone}
            buildChartConfig={buildChartConfig}
            renderTopBar={({...args}) => {
                return (
                    <TopBarVariant2
                        title="Performance Overview"
                        startDate={startDate}
                        endDate={endDate}
                        setDateRange={setDateRange}
                        DatePickerProps={{ranges: shortRanges}}
                        {...args}
                    />
                )
            }}
        />
    )
}
export default PowerOverviewDirector


const buildHybridChartConfig = (data) => {

    let poiSeries = createBasicSeries({
        data: data.poiRealPower,
        label: "POI Real Power",
        color: "rgb(41,128,185)",
        fillColor: "rgba(190,217,234,0.7)",
        axisUnits: "MW",
        valueDecimals: 2,
    })
    let pvSeries = createBasicSeries({
        data: data.pvRealPower,
        label: "PV Real Power",
        color: "rgb(241,196,15)",
        fillColor: "rgba(251,237,183,0.4)",
        axisUnits: "MW",
        valueDecimals: 2,
    })
    let bessSeries = createBasicSeries({
        data: data.bessRealPower,
        label: "BESS Real Power",
        color: "rgb(230,103,103)",
        fillColor: "rgba(247,209,209,0.4)",
        axisUnits: "MW",
        valueDecimals: 2,
    })
    let socSeries = createBasicSeries({
        type: "line",
        dashStyle: "Dot",
        data: data.stateOfCharge,
        label: "State of Charge",
        color: "black",
        opacity: 0.3,
        axisUnits: "MWh",
        valueDecimals: 2,
        yAxis: 1,
    })

    return {
        chart: {
            type: "area"
        },
        legend: {
            floating: false,
            verticalAlign: 'top',
            align: 'right',
        },
        plotOptions: {
            area: {
                boostThreshold: 0   // boosting this chart will mess with the transparent fill colors
            }
        },
        series: [poiSeries, pvSeries, bessSeries, socSeries],
        yAxis: [{title: {text: "Real Power"}, labels: {format: "{value} MW"}}, {title: {text: "SOC (MWh)"}, opposite: true}]
    }
}
const buildStandaloneChartConfig = (data) => {

    let bessSeries = createBasicSeries({
        data: data.bessRealPower,
        label: "BESS Real Power",
        color: "rgb(41,128,185)",
        fillColor: "rgba(190,217,234,0.7)",
        axisUnits: "MW",
        valueDecimals: 2,
    })
    let socSeries = createBasicSeries({
        type: "line",
        dashStyle: "Dot",
        data: data.stateOfCharge,
        label: "State of Charge",
        color: "black",
        opacity: 0.3,
        axisUnits: "MWh",
        valueDecimals: 2,
        yAxis: 1,
    })

    return {
        chart: {
            type: "area"
        },
        legend: {
            floating: false,
            verticalAlign: 'top',
            align: 'right',
        },
        plotOptions: {
            area: {
                boostThreshold: 0   // boosting this chart will mess with the transparent fill colors
            }
        },
        series: [bessSeries, socSeries],
        yAxis: [{title: {text: "Real Power"}, labels: {format: "{value} MW"}}, {title: {text: "SOC (MWh)"}, opposite: true}]
    }
}