import React, {Component} from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_more from 'highcharts/highcharts-more'
import solidGauge from "highcharts/modules/solid-gauge.js"
HC_more(Highcharts)
solidGauge(Highcharts)

class AvailabilityGauge extends Component {

    constructor(props) {
        super()
        this.state = {

        }
        
    }

    render() {

        var { height, width, value, title } = this.props

        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    chart: {
                        type: 'solidgauge',
                        height: height,
                        width: width,
                    },
                    title: {
                        text: null
                    },
                    credits: {
                        enabled: false
                    },
                    tooltip: {
                        enabled: false,
                    },
                    pane: {
                        center: ['47%', '85%'],
                        startAngle: -90,
                        endAngle: 90,
                        size: "140%",
                        background: [{ // Track for Move
                            shape: 'arc',
                            outerRadius: '87%',
                            innerRadius: '63%',
                            backgroundColor: Highcharts.color(Highcharts.getOptions().colors[0])
                                .setOpacity(0.3)
                                .get(),
                            borderWidth: 0
                        }]
                    },
                    yAxis: {
                        min: 0,
                        max: 100,
                        title: {
                            text: title,
                            y: -23
                        },
                        stops: [
                            [0.9, '#ff4e11'], // red
                            [0.96, '#fab733'],
                            [1, '#69b34c'], // green
                        ],
                        lineWidth: 0,
                        tickWidth: 0,
                        minorTickInterval: null,
                        labels: {
                            enabled: false
                        }
                    },
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                y: -15,
                                enabled: true,
                                borderWidth: 0,
                                useHTML: true,
                            },
                            linecap: 'round',
                            stickyTracking: false,
                            rounded: false
                        }
                    },
                    series: [{
                        name: 'Availability',
                        dataLabels: {
                            format: '{y} %'
                                //'<div style="text-align:center">' +
                                //'<span style="font-size:12px;text-decoration:none;">{y} %</span>' +
                                //'</div>'
                        },
                        data: [{
                            radius: '87%',
                            innerRadius: '63%',
                            y: value
                        }]
                    }]
                }}
                containerProps = {{
                    className: "",
                    style: {}
                }}
            />
        )
    }

}

export default AvailabilityGauge