import ExpressionEditorModal from "../../modal/ExpressionEditorModal"
import {HelpCobjectInherit, HelpCobjectType, HelpCobjectFunction, HelpCobjectCode, HelpCobjectRange, HelpCobjectRangedDataAccessMethod} from '../../help/descriptions/Cobject'

export var cobjectTableConfig = {
    inherit: () => {
        return {
            name: "Inherit",
            key: "cobjectInherit",
            cellType: "dropdown",
            cellOptions: {
                dropdownItems: [{
                    label: "true",
                    value: true,
                }, {
                    label: "false",
                    value: false,
                }]
            },
            colProps: {
                width: 150,
                resizable: false,
            },
            info: HelpCobjectInherit
        }
    },
    type: () => {
        return {
            name: "Type",
            key: "cobjectType",
            cellType: "dropdown",
            cellOptions: {
                dropdownItems: [{
                    label: "standard",
                    value: "standard",
                }, {
                    label: "ranged",
                    value: "ranged",
                }]
            },
            colProps: {
                width: 150,
                resizable: false,
            },
            info: HelpCobjectType
        }
    },
    function: () => {
        return {
            name: "Method",
            key: "cobjectFunction",
            cellType: "dropdown",
            cellOptions: {
                dropdownItems: [{
                    label: "evaluate",
                    value: "evaluate",
                }, {
                    label: "execute",
                    value: "execute",
                }]
            },
            colProps: {
                width: 150,
                resizable: false,
            },
            info: HelpCobjectFunction
        }
    },
    code: (props) => {
        return {
            name: "Code",
            key: "cobjectCode",
            cellType: "customRender",
            filterable: false,
            sortable: false,
            render: (rowData, onChange, updatedCode) => {
                let code = updatedCode
                return <ExpressionEditorModal 
                            {...props}
                            expression={code}
                            onClose={(newCode) => {
                                onChange({newData: newCode})
                            }}
                        />
            },
            colProps: {
                width: 200,
                resizable: true,
            },
            info: HelpCobjectCode,
        }
    },
    range: () => {
        return {
            name: "Range",
            key: "cobjectRange",
            cellType: "rangeSlider",
            cellOptions: {
                max: 24,
                min: -12,
                step: 1,
                label: "hours",
                getPrefix: (value) => {
                    return (value >= 0 ? "+" : "")
                }
            },
            colProps: {
                width: 150,
                resizable: true,
            },
            info: HelpCobjectRange,
        }
    },
    rangedDataAccessMethod: () => {
        return {
            name: "Ranged Data Access Method",
            key: "cobjectRangedDataAccessMethod",
            cellType: "dropdown",
            cellOptions: {
                dropdownItems: [{
                    label: "none",
                    value: null,
                }, {
                    label: "iterative",
                    value: "iterative",
                }, {
                    label: "inclusive",
                    value: "inclusive",
                }]
            },
            colProps: {
                width: 220,
                resizable: false,
            },
            info: HelpCobjectRangedDataAccessMethod,
        }
    },
}



/////////////////////////
// Column help statements
/////////////////////////