import { TAG_TYPES } from './palantirSchema'

/* URL Location Services to assist int reading and manipulating the URL search query parameters */

var DELIMITER = ","

function constructState(search) {
    var locationSearch = (search || window.location.search)
    var state = {}
    let params = locationSearch.slice(1).split('&')
    params.forEach(function(qstr) {
        qstr = qstr.split('=')
        state[decodeURIComponent(qstr[0])] = decodeURIComponent(qstr[1])
    })
    return state
}

function setParam(param, value, multi=false) {
    const url = new URL(window.location)
    const nullFlag = value===null
    if (!nullFlag) value = String(value)

    /* Handle url params that can be sequences */
    if (multi && !nullFlag) {
        /* Retrieve the current parameters */
        var curValue = getParam(param)
        var curValueList = curValue.split(DELIMITER)

        /* If the param to be set already exists, remove it. Else add it */
        var valIdx = curValueList.indexOf(value)
        if (valIdx > -1) {
            curValueList.splice(valIdx, 1)
            value = curValueList.join(DELIMITER)
        }
        else {
            /* Add new param to list of current params, delimited by the specified delimiter, if any params exist */
            if (curValue !== '') (value = curValue + DELIMITER + value)
        }
    }

    var newstate = (window.history.state || {})
            
    if (value) {
        newstate[param] = value
        url.searchParams.set(param, value)
        window.history.pushState(newstate, '', url)
    }
    else {            
        delete newstate[param]
        url.searchParams.delete(param)
        window.history.pushState(newstate, '', url)
    }        
}

function getParam(param, multi=false) {
    const state = constructState()
    var paramVal;
    if (!state) return "";
    (param in state ? paramVal = state[param] : paramVal = "")
    if (multi) {
        paramVal = paramVal.split(DELIMITER).filter(x=>x!=="")
    }
    return paramVal
}

/* Portfolios */
export function setPortfolio(portfolio) {
    setParam('portfolio', portfolio)
}
export function getPortfolio() {
    var portfolio = getParam('portfolio')
    portfolio = _formatStateVar(portfolio, true)
    return portfolio
}

/* Sites */
export function setSite(site) {
    setParam('site', site)
}
export function getSite() {
    var site = getParam('site')
    site = _formatStateVar(site, true)
    return site
}

/* Devices */
export function setDevices(devices) {
    if (devices instanceof Array) {
        setParam('devices', devices.join(DELIMITER), false)
    }
    else if (Number.parseInt(devices) || devices===null) {
        setParam('devices', devices, true)
    }
    else {
        throw "Devices must be an Array or Number type."
    }
}
export function getDevices() {
    var devices = getParam('devices', true)
    devices = _formatStateVar(devices, true)
    return devices
}

/* Device Group */
export function setDeviceGroup(deviceGroup) {
    setParam('deviceGroup', deviceGroup)
}
export function getDeviceGroup() {
    var deviceGroup = getParam('deviceGroup')
    deviceGroup = _formatStateVar(deviceGroup, true)
    return deviceGroup
}

/* Tags */
export function setTags(tags, type) {
    var paramId = _prefixParamIdWithTagType(type, "tags")
    if (tags instanceof Array) {
        setParam(paramId, tags.join(DELIMITER), false)
    }
    else if (Number.parseInt(tags) || tags===null) {
        setParam(paramId, tags, true)
    }
    else {
        throw "Tags must be an Array or Number type."
    }
}
export function getTags(type) {
    var paramId = _prefixParamIdWithTagType(type, "tags")
    var tags = getParam(paramId, true)
    tags = _formatStateVar(tags, true)
    return tags
}

/* Dimensions */
export function setDimensions(dimensionId, dimensionValue, type) {
    var paramId = _prefixParamIdWithTagType(type, "dims")
    setParam(paramId, `${dimensionId}.${dimensionValue}`, true)
}
export function getDimensions(type) {
    var paramId = _prefixParamIdWithTagType(type, "dims")
    var dimensions = getParam(paramId, true)
    dimensions = _formatStateVar(dimensions, false)
    if (dimensions) dimensions = _buildDimensions(dimensions)
    return dimensions
}
function _buildDimensions(rawDimensions) {
    var dimensions = {}
    rawDimensions.forEach(function(rawDimension) {
        let [dimId, dimValId] = rawDimension.split(".")
        dimId = Number(dimId)
        dimValId = Number(dimValId)
        if (dimensions[dimId]) {
            dimensions[dimId].push(dimValId)
        }
        else {
            dimensions[dimId] = [dimValId]
        }
    })
    return dimensions
}

/* Date */
export function setStartTime(start) {
    setParam('start', start)
}
export function getStartTime() {
    var start = getParam('start')
    start = _formatStateVar(start)
    return start
}

export function setEndTime(end) {
    setParam('end', end)
}
export function getEndTime() {
    var end = getParam('end')
    end = _formatStateVar(end)
    return end
}

/* Resolution */
export function setResolution(res) {
    setParam('res', res)
}
export function getResolution() {
    var res = getParam('res')
    res = _formatStateVar(res, true)
    return res
}

/* Internals */
function _formatStateVar(value, asNumber=false) {
    if (value === "" | value === []) {
        return null
    }
    if (asNumber) {
        if (Array.isArray(value)) {
            value = value.map(x => Number(x))
        }
        else {
            value = Number(value)
        }
    }
    return value
}
function _prefixParamIdWithTagType (type, suffix) {
    var paramId;
    if (type===TAG_TYPES.source) paramId = "s"+suffix
    else if (type===TAG_TYPES.interval) paramId = "i"+suffix
    else throw `Tag/Dimension type must be one of ${TAG_TYPES.source}, ${TAG_TYPES.interval}. Got ${type}.`
    return paramId
}