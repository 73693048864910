import '../../../App.css'
import './History.css'

import AssetSidebar, { useAssetSelectionState } from '../../menu/AssetMenu'
import DeviceMenu, { useDeviceSelectionState } from '../../menu/DeviceMenu'
import { TagDimensionMenu, useTagSelectionState, useLocalSettingShowOnlyHighPrioTagsState } from '../../menu/TagDimensionMenu'
import { TAG_TYPES } from '../../../utils/palantirSchema'
import HistoryContainer from './HistoryContainer'
import { useOutletContext } from 'react-router-dom'


const HistoryTab = ({services}) => {

    const { assetFrameworkServices, authServices } = useOutletContext()

    const [selectedPortfolioId, selectedSiteId, updatePortfolioId, updateSiteId] = useAssetSelectionState()
    const [selectedDeviceIds, setSelectedDeviceIds, selectedDeviceGroupId, setSelectedDeviceGroupId] = useDeviceSelectionState(selectedSiteId, assetFrameworkServices)
    const [showOnlyHighPrioTags, toggleShowOnlyHighPrioTags] = useLocalSettingShowOnlyHighPrioTagsState()
    const [
        visibleIntervalTags, visibleIntervalDimensions, selectedIntervalTagIds, selectedIntervalDimensionIds,
        updateSelectedIntervalTagIds, updateSelectedIntervalDimensionIds
    ] = useTagSelectionState(TAG_TYPES.interval, selectedDeviceIds, selectedDeviceGroupId, showOnlyHighPrioTags, assetFrameworkServices)
    const [
        visibleSourceTags, visibleSourceDimensions, selectedSourceTagIds, selectedSourceDimensionIds,
        updateSelectedSourceTagIds, updateSelectedSourceDimensionIds
    ] = useTagSelectionState(TAG_TYPES.source, selectedDeviceIds, selectedDeviceGroupId, showOnlyHighPrioTags, assetFrameworkServices)

    return (
        <div className="flow-horizontal fill-parent">
            <AssetSidebar 
                updateSelectedSite={updateSiteId} 
                updateSelectedPortfolio={updatePortfolioId}
                selectedSiteId={selectedSiteId}
                selectedPortfolioId={selectedPortfolioId}
                className="sidebar"
                menuProps={{
                    collapsable: true,
                }}
            />
            <DeviceMenu
                services={{assetFramework: assetFrameworkServices}} 
                onDeviceClick={setSelectedDeviceIds}
                selectedSiteId={selectedSiteId}
                selectedDeviceIds={selectedDeviceIds}
                selectedDeviceGroupId={selectedDeviceGroupId}
                setSelectedDeviceGroupId={setSelectedDeviceGroupId}
                className="sidebar"
                menuProps={{
                    collapsable: true,
                    includeQuickSelect: true
                }}
            />
            <div className="section-main flow-vertical fill-parent">
                <div className="section-panel flow-horizontal fill-parent">
                    <div className="flow-vertical" style={{minWidth: '225px', maxWidth: '225px'}}>
                        <TagDimensionMenu 
                            services={services}
                            selectedSiteId={selectedSiteId} 
                            selectedDeviceIds={selectedDeviceIds}
                            selectedDeviceGroupId={selectedDeviceGroupId}
                            visibleTags={visibleIntervalTags}
                            visibleDimensions={visibleIntervalDimensions}
                            selectedTagIds={selectedIntervalTagIds}
                            selectedDimensionIds={selectedIntervalDimensionIds}
                            updateSelectedTagIds={updateSelectedIntervalTagIds}
                            updateSelectedDimensionIds={updateSelectedIntervalDimensionIds}
                            showOnlyHighPrioTags={showOnlyHighPrioTags}
                            toggleShowOnlyHighPrioTags={toggleShowOnlyHighPrioTags}
                            tagType={TAG_TYPES.interval}
                            style={{marginBottom: '20px', height: '50%'}}
                            tagMenuTitle={"Interval Tags"}
                        />
                        <TagDimensionMenu 
                            services={services}
                            selectedSiteId={selectedSiteId} 
                            selectedDeviceIds={selectedDeviceIds}
                            selectedDeviceGroupId={selectedDeviceGroupId}
                            visibleTags={visibleSourceTags}
                            visibleDimensions={visibleSourceDimensions}
                            selectedTagIds={selectedSourceTagIds}
                            selectedDimensionIds={selectedSourceDimensionIds}
                            updateSelectedTagIds={updateSelectedSourceTagIds}
                            updateSelectedDimensionIds={updateSelectedSourceDimensionIds}
                            showOnlyHighPrioTags={showOnlyHighPrioTags}
                            toggleShowOnlyHighPrioTags={toggleShowOnlyHighPrioTags}
                            tagType={TAG_TYPES.source}
                            style={{height: '50%'}}
                            tagMenuTitle={"Source Tags"}
                        />
                    </div>
                    <HistoryContainer 
                        services={{assetFramework: assetFrameworkServices, auth: authServices}} 
                        selectedSiteId={selectedSiteId} 
                        selectedDeviceIds={selectedDeviceIds}
                        selectedDeviceGroupId={selectedDeviceGroupId} 
                        selectedSourceTagIds={selectedSourceTagIds}
                        selectedSourceDimensionIds={selectedSourceDimensionIds}
                        selectedIntervalTagIds={selectedIntervalTagIds}
                        selectedIntervalDimensionIds={selectedIntervalDimensionIds}
                    />
                </div>
            </div>
        </div>
    )

}

export default HistoryTab