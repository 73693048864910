import './HelpPanel.css'
import {Component} from 'react'

import Panel from '../panel/Panel'
import { injectServices } from '../../utils/ServiceInjector'
import { reflowHighcharts } from '../../utils/Highcharts'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowClose, faQuestion, faArrowRight } from '@fortawesome/free-solid-svg-icons'

class HelpPanel extends Component {
    
    constructor(props) {
        super()
        this.state = {
            collapsed: true,
            helpItem: null,
            help: null,
        }

        this.noHelpFound = <Panel>No help is available for the item you have selected</Panel>
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.helpItem !== prevState.helpItem) {
            this.setState({
                collapsed: false
            })
        }
    }

    render() {

        console.log(this.state)

        var newServices = injectServices(this.props.services, "help", {
            setHelpConfig: this.setHelpConfig
        })

        var Help = this.state.help || this.noHelpFound
        if (!this.state.helpItem) {
            Help = <Panel>
                Click on a 
                <FontAwesomeIcon icon={faQuestion} size="lg" fixedWidth style={{margin: "10px 0"}} />
                to view helpful info.
            </Panel>
        }

        var HelpPanel;
        if (this.state.collapsed) {
            HelpPanel =  (
                <div className="help-panel collapsed" onClick={this.toggleCollapsed} >
                    <span><FontAwesomeIcon icon={faQuestion} size="lg" fixedWidth /></span>
                    <div className="prompt">
                        Click to expand
                    </div>
                </div>
            )
        }
        else {
            HelpPanel = (
                <div className="help-panel flow-vertical overflow-y">
                    <div className='flow-horizontal' style={{marginBottom: "10px", alignItems: "center"}}>
                        <div className="help-item">{this.state.helpItem}</div>
                        <span onClick={this.toggleCollapsed} ><FontAwesomeIcon icon={faArrowRight} size="lg" fixedWidth /></span>
                    </div>
                    <hr style={{width: "90%"}}></hr>
                    {Help}
                </div>
            )   
        }

        return (
            <div className="flow-horizontal fill-parent">
                {this.props.render(newServices)}
                {HelpPanel}
            </div>
        )

    }

    setHelpConfig = (helpItem, help) => {
        this.setState({
            helpItem: helpItem,
            help: help,
            collapsed: false,
        })
        reflowHighcharts()
    }

    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed
        })
        reflowHighcharts()
    }

}

export default HelpPanel