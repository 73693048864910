import React from 'react'

import { PalantirTextField } from '../../../components/input/Text'


const Notes = React.memo(function(props) {
        
    return (
        <div className="notes field-group" style={{marginTop: "30px"}}>
            <PalantirTextField
                label="Notes"
                value={props.plantNotes}
                fullWidth
                multiline
                variant="filled"
                rows={5}
                onChange={(x) => props.updatePlant(x)}
            />
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        prevProps.plantNotes===nextProps.plantNotes
    )
})
export default Notes