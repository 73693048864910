import '../Help.css'

export function HelpCode(props) {
    return (
        <div className="palantir-helpnote">
            <div className="title">Event Template Code</div>
            <hr></hr>
            <p>The template code is the name of the event type.</p>
            <p>This will serve as the event code for events associated with this template.</p>
            <p>For uniformity, convention is to format the code as all uppercase and separated by underscores, but this isn't enforced.</p>
        </div>
    )
}

export function HelpDescription(props) {
    return (
        <div className="palantir-helpnote">
            <div className="title">Event Template Description</div>
            <hr></hr>
            <p>This is a longer text description of the event type.</p>
            <p>This will serve as the description for events associated with this template.</p>
        </div>
    )
}