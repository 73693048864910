import PlantPanel from '../view_panel/plant/Plant'
import ProjectTaxPanel from '../view_panel/plant/ProjectTax'
import PersonnelPanel from '../view_panel/personnel/Personnel'
import EPCPanel from '../view_panel/contracts/epc/Epc'
import OfftakePanel from '../view_panel/contracts/offtake/Offtake'
import OandMPanel from '../view_panel/contracts/oandm/OandM'
import InterconnectionPanel from '../view_panel/contracts/interconnection/Interconnection'
import TaxEquityPanel from '../view_panel/contracts/tax_equity/TaxEquity'
import PermittingPanel from '../view_panel/contracts/permitting/Permitting'
import LandownerPanel from '../view_panel/contracts/landowner/Landowner'
import DebtPartnerPanel from '../view_panel/contracts/debt_partner/DebtPartner'
import EquipmentContractPanel from '../view_panel/contracts/equipment/Equipment'

import ProjectInverterPanel from '../view_panel/equipment/ProjectInverter';
import ProjectModulePanel from '../view_panel/equipment/ProjectModule';
import ProjectTransformerPanel from '../view_panel/equipment/ProjectTransformer';
import ProjectTrackerPanel from '../view_panel/equipment/ProjectTracker';
import ProjectBESSPanel from '../view_panel/equipment/ProjectBESS';
import ProjectBreakerPanel from '../view_panel/equipment/ProjectBreaker';
import ProjectTechnicalDueDiligence from '../view_panel/plant/TechnicalDueDiligence'
import ProjectTechnicalDataSiteChecklist from '../view_panel/plant/TechnicalDatasiteCheck'
import ProjectStageMilestoneMatrix from '../view_panel/plant/StageMilestoneMatrix'
import RiskRegisterChecklist from '../view_panel/plant/RiskRegister'
import OfftakeContractOffer from '../view_panel/contract_offers/OfftakeContractOffer'
import InterconnectionExpectedCostsPanel from '../view_panel/InterconnectionCosts'
import TemporalProjectPricingPanel from '../view_panel/plant/ProjectPricing'
import LessonLearnedPanel from '../view_panel/plant/LessonLearned'
import ProjectMilestoneDatesPanel from '../view_panel/plant/ProjectMilestoneDates'
import EnvironmentalAndPermittingChecklistPanel from '../view_panel/contracts/permitting/EnvironmentalAndPermittingChecklist'
import { DevelopmentReportCommentsPanel, ConstructionReportCommentsPanel } from '../view_panel/ReportingComments'

export class PanelConfig {
    constructor(key, label, endpoint, component, allowNullProject=false) {
        this.key = key
        this.label = label
        this.endpoint = endpoint
        this.component = component
        this.allowNullProject = allowNullProject
    }
}
export class ViewConfig {
    constructor(label, endpoint, components, allowNullProject=false) {
        this.key = label
        this.label = label
        this.endpoint = endpoint
        this.components = components.map(component => {
            return new PanelConfig(`${label} - ${component.label}`, component.label, component.endpoint, component.component, component.allowNullProject)
        })
        this.allowNullProject = allowNullProject
    }
}

const PanelConfigs = {
    plant: {
        label: "Plant",
        endpoint: "api/precious/table/plant",
        component: PlantPanel,
    },
    plantTax: {
        label: "Project Tax",
        endpoint: "api/precious/table/plant",
        component: ProjectTaxPanel
    },
    personnel: {
        label: "Personnel",
        endpoint: "api/precious/table/personnel",
        component: PersonnelPanel
    },
    epcContract: {
        label: "EPC",
        endpoint: "api/precious/table/epc_contract",
        component: EPCPanel
    }, 
    offtakeContract: {
        label: "Offtake",
        endpoint: "api/precious/table/offtake_contract",
        component: OfftakePanel
    },
    interconnectionContract: {
        label: "Interconnection",
        endpoint: "api/precious/table/interconnection_contract",
        component: InterconnectionPanel
    },
    permittingContract: {
        label: "Permitting",
        endpoint: "api/precious/table/permitting_contract",
        component: PermittingPanel
    },
    landownerContract: {
        label: "Landowner",
        endpoint: "api/precious/table/landowner_contract",
        component: LandownerPanel
    },
    taxEquityContract: {
        label: "Tax Equity",
        endpoint: "api/precious/table/tax_equity_contract",
        component: TaxEquityPanel
    },
    debtPartner: {
        label: "Debt Partner",
        endpoint: "api/precious/table/debt_partner_contract",
        component: DebtPartnerPanel
    },
    oandmContract: {
        label: "O&M",
        endpoint: "api/precious/table/oandm_contract",
        component: OandMPanel
    },
    projectInverter: {
        label: "Inverters",
        endpoint: "api/precious/table/project_inverter",
        component: ProjectInverterPanel
    },
    projectModule: {
        label: "Module",
        endpoint: "api/precious/table/project_module",
        component: ProjectModulePanel
    },
    projectTransformer: {
        label: "Transformer",
        endpoint: "api/precious/table/project_transformer",
        component: ProjectTransformerPanel
    },
    projectTracker: {
        label: "Tracker",
        endpoint: "api/precious/table/project_tracker",
        component: ProjectTrackerPanel
    },
    projectBess: {
        label: "BESS",
        endpoint: "api/precious/table/project_bess",
        component: ProjectBESSPanel
    },
    projectBreaker: {
        label: "Breaker",
        endpoint: "api/precious/table/project_breaker",
        component: ProjectBreakerPanel
    },
    technicalDueDiligence: {
        label: "Technical Due-Diligence",
        endpoint: "api/precious/table/technical_due_diligence",
        component: ProjectTechnicalDueDiligence
    },
    technicalDataSiteChecklist: {
        label: "Technical Datasite Checklist",
        endpoint: "api/precious/table/technical_datasite_checklist",
        component: ProjectTechnicalDataSiteChecklist
    },
    offtakeContractOffer: {
        label: "Offtake Offer",
        endpoint: "api/precious/table/offtake_contract_offer",
        component: OfftakeContractOffer
    },
    equipmentContract: {
        label: "Equipment Contract",
        endpoint: "api/precious/table/equipment_purchase_contract",
        component: EquipmentContractPanel
    },
    riskRegister: {
        label: "Risk Register",
        endpoint: "api/precious/table/risk_register",
        component: RiskRegisterChecklist
    },
    interconnectionPayments: {
        label: "Interconnection Expected Costs",
        endpoint: "api/precious/table/interconnection_expected_costs",
        component: InterconnectionExpectedCostsPanel
    },
    stageMilestoneMatrix: {
        label: "Stage Gate Matrix",
        endpoint: "api/precious/table/stage_milestone_matrix",
        component: ProjectStageMilestoneMatrix
    },
    temporalProjectPricing: {
        label: "Project Pricing",
        endpoint: "api/precious/table/temporal_project_pricing",
        component: TemporalProjectPricingPanel
    },
    lessonLearned: {
        label: "Lesson Learned",
        endpoint: "api/precious/table/lesson_learned",
        component: LessonLearnedPanel,
        allowNullProject: true,
    },
    projectMilestoneDates: {
        label: "Project Milestone Dates",
        endpoint: "api/precious/table/project_milestone_dates",
        component: ProjectMilestoneDatesPanel,
    },
    environmentalAndPermittingChecklist: {
        label: "Environmental and Permitting Checklist",
        endpoint: "api/precious/table/environmental_and_permitting_checklist",
        component: EnvironmentalAndPermittingChecklistPanel,
    },
    developmentReportingComments: {
        label: "Development Reporting",
        endpoint: "api/precious/table/report_comments",
        component: DevelopmentReportCommentsPanel,
    },
    constructionReportingComments: {
        label: "Construction Reporting",
        endpoint: "api/precious/table/report_comments",
        component: ConstructionReportCommentsPanel,
    }
}

export const StandAlonePlantPanelConfig = new PanelConfig(PanelConfigs.plant.label, PanelConfigs.plant.label, PanelConfigs.plant.endpoint, PanelConfigs.plant.component)


const DevconViewConfig = new ViewConfig(
    "Devcon",
    "api/precious/view/devcon",
    [
        PanelConfigs.plant, PanelConfigs.projectMilestoneDates, PanelConfigs.plantTax, PanelConfigs.personnel, PanelConfigs.stageMilestoneMatrix, PanelConfigs.riskRegister,
        PanelConfigs.epcContract, PanelConfigs.offtakeContract, PanelConfigs.interconnectionContract, PanelConfigs.oandmContract, PanelConfigs.permittingContract, PanelConfigs.environmentalAndPermittingChecklist,
        PanelConfigs.projectInverter, PanelConfigs.projectModule, PanelConfigs.projectTransformer, PanelConfigs.projectTracker, PanelConfigs.projectBess
    ]
)
const ContractViewConfig = new ViewConfig(
    "Contract",
    "api/precious/view/contracts",
    [
        PanelConfigs.plant, PanelConfigs.epcContract, PanelConfigs.offtakeContract, PanelConfigs.oandmContract,
        PanelConfigs.interconnectionContract, PanelConfigs.landownerContract, PanelConfigs.permittingContract,
        PanelConfigs.taxEquityContract, PanelConfigs.debtPartner, PanelConfigs.environmentalAndPermittingChecklist
    ]
)
const ProjectEquipmentViewConfig = new ViewConfig(
    "Project Equipment",
    "api/precious/view/project_equipment",
    [
        PanelConfigs.plant, PanelConfigs.projectInverter, PanelConfigs.projectModule, PanelConfigs.projectTransformer, PanelConfigs.projectTracker,
        PanelConfigs.projectBess, PanelConfigs.projectBreaker
    ]
)
const DevelopmentEngineeringViewConfig = new ViewConfig(
    "Development Engineering",
    "api/precious/view/technical_due_diligence",
    [
        PanelConfigs.plant, PanelConfigs.projectMilestoneDates, PanelConfigs.personnel, PanelConfigs.technicalDueDiligence, PanelConfigs.technicalDataSiteChecklist, PanelConfigs.riskRegister
    ]
)
const ContractOfferViewConfig = new ViewConfig(
    "Contract Offer",
    "api/precious/view/contract_offer",
    [
        PanelConfigs.plant, PanelConfigs.offtakeContractOffer
    ]
)
const EquipmentDeliveryViewConfig = new ViewConfig(
    "Equipment",
    "api/precious/view/equipment_delivery",
    [
        PanelConfigs.plant, PanelConfigs.equipmentContract, PanelConfigs.temporalProjectPricing
    ]
)

const InterconnectionViewConfig = new ViewConfig(
    "Interconnection",
    "api/precious/view/interconnection",
    [
        PanelConfigs.plant, PanelConfigs.interconnectionPayments
    ]
)

const LessonLearnedViewConfig = new ViewConfig(
    "Lesson Learned",
    "api/precious/view/lesson_learned",
    [
        PanelConfigs.plant, PanelConfigs.lessonLearned
    ],
    true
)

const ReportingViewConfig = new ViewConfig(
    "Reporting",
    "api/precious/view/reporting",
    [
        PanelConfigs.plant, PanelConfigs.developmentReportingComments, PanelConfigs.constructionReportingComments
    ],
    true
)

export const views = [DevconViewConfig, ContractViewConfig, ContractOfferViewConfig, ProjectEquipmentViewConfig, DevelopmentEngineeringViewConfig, InterconnectionViewConfig, EquipmentDeliveryViewConfig, LessonLearnedViewConfig, ReportingViewConfig]