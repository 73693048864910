import './DimensionSelectionModal.css'
import {Component} from 'react'

import {Button, Modal} from 'rsuite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'

import DeviceMenu from '../menu/DeviceMenu'

class DeviceMenuModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            selectedDeviceIds: props.selectedDeviceIds || {}
        };
    }
    close = (cancel) => {
        if (cancel) {
            this.setState({
                show: false,
                selectedDeviceIds: this.props.selectedDeviceIds || {}
            });
        }
        else {
            this.setState({
                show: false
            })
            if (this.props.onClose) this.props.onClose(this.state.selectedDeviceIds)
        }
    }
    open = () => {
        this.setState({ show: true });
    }
    render() {
        return (
            <div className="modal-container">
                <FontAwesomeIcon onClick={this.open} icon={faCog} style={{fontSize: '16px'}} fixedWidth className="fa-spin-hover" />

                <Modal show={this.state.show} onHide={() => this.close(true)}>
                <Modal.Header>
                    <Modal.Title>Select Your Devices</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DeviceMenu services={this.props.services} selectedSiteId={this.props.selectedSiteId} selectedDeviceIds={this.state.selectedDeviceIds} onDeviceClick={this.updateSelectedDeviceIds} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.close(false)} appearance="primary">
                    Ok
                    </Button>
                    <Button onClick={() => this.close(true)} appearance="subtle">
                    Cancel
                    </Button>
                </Modal.Footer>
                </Modal>
            </div>
        );
    }
    
    updateSelectedDeviceIds = (id) => {
        this.setState({
            selectedDeviceIds: id
        })
        return
        console.log(id)
        id = id[0]
        var selectedDeviceIds = Object.assign([], this.state.selectedDeviceIds)
        var curIdx = selectedDeviceIds.indexOf(id)
        if (curIdx!==-1) {
            selectedDeviceIds.splice(curIdx, 1)
        }
        else {
            selectedDeviceIds.push(id)
        }
        this.setState({
            selectedDeviceIds: selectedDeviceIds
        })
    }
}

  export default DeviceMenuModal