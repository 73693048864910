import { useState } from 'react'

import ViewPanel from '../ViewPanel'
import { 
    PalantirAutocomplete, PalantirSelector
} from '../../../components/input/SelectPicker'
import { Personnel, PersonnelProjectRelationships, PersonnelRelationshipContactLevels } from '../../table_configuration/Project';
import { useDBViewFormValidation } from '../../../hooks/databaseViewFormValidation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faQuestion, faX } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash'
import { Employee } from '../../table_configuration/Entity';
import { useChangeLog, injectedChangeLogIdCol } from '../../../hooks/changeLog'
import { AddFloaterButtonWithPrompt, DeleteFloaterButtonWithPrompt } from '../../../components/button/FloaterButtonWithPrompt'
import { ViewFormError } from '../../../utils/databaseAppUtils';

const plantIdCol = Personnel.columnSchema.plantId
const employeeIdCol = Personnel.columnSchema.employeeId


export default function PersonnelPanel(props) {
    
    const relationshipRecords = props.data[PersonnelProjectRelationships.buildId()]
    const contactLevelRecords = props.data[PersonnelRelationshipContactLevels.buildId()]
    var initialPersonnelRecords = props.data[Personnel.buildId()].filter(x => x[Personnel.columnSchema.plantId]===props.selectedPlantId) || []

    /*const validateCreatePersonnelRecord = (record, newRecord) => {
        // Ensure any previously added personnel records have been assigned an employee
        return record[employeeIdCol]!==null
    }*/
    const [personnelRecords, preparePersonnelLog, updateLog, addToLog, deleteFromLog, bulkOpOnLog, mergeAndResetLog] = useChangeLog(initialPersonnelRecords, Personnel)
    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()

    const addToLogPersonnelProxy = (relationship) => {
        let newRecord = Personnel.buildNewRecord()
        newRecord[plantIdCol] = props.selectedPlantId
        newRecord[Personnel.columnSchema.relationshipId] = relationship
        addToLog(newRecord)
    }

    const employees = props.data[Employee.buildId()]
    const employeeOptions = _.sortBy(employees.map(x => {
        return {
            label: Employee.buildFullName(x),
            id: x[Employee.columnSchema.employeeId]
        }
    }), x => x.label)
    const contactLevelOptions = contactLevelRecords.map(x => Object({label: x[PersonnelRelationshipContactLevels.columnSchema.name], value: x[PersonnelRelationshipContactLevels.columnSchema.oid]}))
    const relationshipMap = Object.fromEntries(relationshipRecords.map(x => [x[PersonnelProjectRelationships.columnSchema.oid], x[PersonnelProjectRelationships.columnSchema.name]]))

    const sectionsMap = {}
    _.sortBy(relationshipRecords, (x => x[PersonnelProjectRelationships.columnSchema.name])).forEach(x => sectionsMap[x[PersonnelProjectRelationships.columnSchema.oid]] = [])
    personnelRecords.forEach(personnelRecord => {
        let relationshipId = personnelRecord[Personnel.columnSchema.relationshipId]
        let section = sectionsMap[relationshipId]
        section.push(personnelRecord)
    })

    var sections = Object.entries(sectionsMap).map(entry => {
        let [relationshipTypeId, personnelRecords] = entry
        const personSections = personnelRecords.length>0 ? personnelRecords.map(personnelRecord => {

            // Person can be undefined when no employee has been selected for this record yet
            const person = employees.find(x => x[Employee.columnSchema.employeeId]===personnelRecord[Personnel.columnSchema.employeeId])
            let employeeName = person ? Employee.buildFullName(person) : null
            let employeeId = person ? person[Employee.columnSchema.employeeId] : null
            let employeeContactLevelId = personnelRecord[Personnel.columnSchema.contactLevelId]
            //let contactLevelIcon = (Personnel.options.contactLevel.find(x => x.value===employeeContactLevelId) || {}).icon || faQuestion

            const personnelErrorPath = [Personnel.buildId(), personnelRecord[injectedChangeLogIdCol]]
            const personnelError = errors.get(...personnelErrorPath)

            //let contactLevelOptions = Personnel.options.contactLevel

            return (
                <div key={employeeId} className="flow-horizontal" style={{marginTop: "20px"}}>
                    <DeleteFloaterButtonWithPrompt
                        onClick={() => {
                            removeErrors(personnelErrorPath)
                            deleteFromLog(personnelRecord)
                        }}
                        style={{marginTop: "15px"}}
                        height={14}
                        width={14}
                    />
                    {/*<div style={{margin: "23px 15px 0 10px", color: employeeContactLevel==="Leadership" ? "gold" : "blue"}}>
                        <FontAwesomeIcon icon={contactLevelIcon} />
                    </div>*/}
                    <PalantirAutocomplete
                        value={{label: employeeName, id: employeeId}}
                        label="Employee"
                        options={employeeOptions}
                        disableClearable
                        className="margined"
                        onUpdate={(newEmployee) => {
                            removeErrors(personnelErrorPath)
                            updateLog(personnelRecord, {[employeeIdCol]: newEmployee.id})
                        }}
                        variant="standard"
                        style={{marginTop: "0px", marginRight: "10px"}}
                        fullWidth
                        InputProps={personnelError ? {error: true, helperText: personnelError.message} : {}}
                    />
                    <PalantirSelector
                        label="Contact Level"
                        value={employeeContactLevelId}
                        onChange={(newContactLevel) => {
                            updateLog(personnelRecord, {[Personnel.columnSchema.contactLevelId]: newContactLevel})
                        }}
                        items={contactLevelOptions}
                        minWidth={120}
                        style={{marginTop: 0}}
                    />
                </div>
            )
        }) : [<div style={{marginTop: "5px", marginBottom: "10px"}}>No employees.</div>]

        return (
            <div className="field-group form-instance" style={{minWidth: "380px", maxWidth: "450px", flexGrow: 1, margin: "0 50px 40px 0"}}>
                <div className="header" >{relationshipMap[relationshipTypeId]}</div>
                {personSections}
                <AddFloaterButtonWithPrompt
                    onClick={() => addToLogPersonnelProxy(relationshipTypeId)}
                    labelContent={"Add new personnel"}
                    style={{marginTop: "15px"}}
                    height={14}
                    width={14}
                />
            </div>
        )
    })

    return (
        <ViewPanel
            services={props.services}
            title="Project Personnel"
            submitUrl='api/precious/table/personnel'
            verifySubmit={(payload) => {
                return verifyChangelogSubmission(
                    {
                        changeLog: payload.personnelChangeLog,
                        checks: [{
                            checkColumn: Personnel.columnSchema.employeeId,
                            checkFunction: "nullCheck",
                            errMessage: "Please select an employee.",
                            path: [
                                {name: Personnel.buildId(), type: "static"},
                                {name: injectedChangeLogIdCol, type: "eval"}
                            ]
                        }]
                    }
                )
            }}
            onSubmitSuccess={(response, requestPayload) => {
                props.handleUpdate(false)
                mergeAndResetLog()
                resetErrors()
            }}
            onSubmitError={null}
            buildSubmitPayload={() => {
                return {
                    personnelChangeLog: preparePersonnelLog(),
                }
            }}
        >
            <div className="section-holder flow-horizontal" style={{flexWrap: "wrap", overflowX: "auto"}}>
                {sections}
            </div>
        </ViewPanel>
    )
}