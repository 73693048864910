import { useRef, useState } from "react"
import _ from "lodash"

import { Alert } from "rsuite"

export const injectedChangeLogIdCol = "injectedChangeLogIdCol"

class ChangeLogValidationError {

    constructor(message) {
        this.message = message
    }

}

const injectChangeLogIds = (records) => {
    return records.map((x, idx) => {
        if (injectedChangeLogIdCol in x) throw "Input records already have a column with the same name as proposed injectable." 
        return Object.assign({}, x, {[injectedChangeLogIdCol]: idx})
    })
}

export const useChangeLog = (initialRecords, recordTypeTable, validateCreateFn) => {

    // Check that the injected ID is not present in any of the initial records
    // Don't want to do here as too expensive. Can create a proxy component that does it once on initial render?

    const [_log, setLog] = useState([])
    const [_records, setRecords] = useState(() => injectChangeLogIds(initialRecords))

    // Use refs so any updates are always made to the most recent state.
    // Is this wanted?
    const logRef = useRef()
    logRef.current = _log
    const recordsRef = useRef()
    recordsRef.current = _records

    /////////////////
    // Public Functions
    /////////////////

    const updateLogProxy = (priorRecord, updatedRecordFields) => {
        let logCopy = _cloneLog()
        try {
            updateLog(logCopy, priorRecord, updatedRecordFields)
            setLog(logCopy)
            return true
        } catch (e) {
            console.log(e)
            if (e instanceof ChangeLogValidationError) Alert.info(e.message, 6000)
            return false
        }
    }
    /**
     * Updates a record via the changelog
     * @param {Object} priorRecord the record state before it was edited, not the 'original/initial record' as in the record in it's initial state before ALL updates
     * Note that this parameter can contain stale values. If a component is memoized and makes a call to updateLog after another component has updated the value, this record will not reflect changes made by said component.
     * Ex.
     * Comp1 rendered with updateLog prop. The originalRecord passed in here is {a: 1, b: 2}.
     * Comp2 is rendered same as Comp1
     * Comp2 calls updateLog updating the record with {a: 2} to {a: 2, b: 2}
     * Comp1 does not rerender because it only displays the value of 'a'.
     * Comp1 calls updateLog to update the record with {b: 3} to {a: 1, b: 3}. Because Comp1 didn't rerender, it called updateLog with the originalRecord as {a: 1 ,b: 2}.
     * Because of this if a component does not render after updates made to a primary key column, it will attempt to use an originalRecord that no longer exists.
     * !!!!! So you need to ensure that whenever a record primary key col value is changed, all components that render from the same record and are memoized also render. !!!!!
     * @param {Object} updatedRecordFields the record object containing only fields that were updated
     * @returns 
     */
    const updateLog = (logCopy, priorRecord, updatedRecordFields) => {

        var matchingChangeIdx = _findChangeIndex(logCopy, priorRecord)
        var matchingChangeCopy = _.cloneDeep(logCopy[matchingChangeIdx])
        
        // Creates a new record containing all fields. This allows the caller to pass in only a subset of fields that were changed.
        // If there is an existing change, use it because the originalRecord passed in could be stale (see function description) else use the originalRecord passed to the function.
        var updatedRecord = matchingChangeIdx===-1 ? Object.assign({}, priorRecord, updatedRecordFields) : Object.assign(matchingChangeCopy.newRecord, updatedRecordFields)

        // Perform validation on the updated record
        let validationResult = _validateUpdate(_getRecords(logCopy), priorRecord, updatedRecord)
        if (!validationResult.result) {
            Alert.info(validationResult.message, 6000)
            return false
        }

        //let logCopy = _cloneLog()

        if (matchingChangeIdx===-1) {
            logCopy.push({
                newRecord: updatedRecord,
                originalRecord: priorRecord,
                instruction: "update"
            })
        }
        else {
            // A created record should still be created even if it is edited after creation
            if (matchingChangeCopy.instruction==="create" || matchingChangeCopy.instruction==="update") {
                matchingChangeCopy.newRecord = updatedRecord
                logCopy[matchingChangeIdx] = matchingChangeCopy
            }
            // This should not happen as deleted records should be filtered out when finding the match index
            else if (matchingChangeCopy.instruction==="delete") {
                console.log("Cant update a deleted record.")
                return false
            }
            else {
                console.log("Error updating record.")
                return false
            }
        }
    }
    const addToLogProxy = (recordset) => {
        let logCopy = _cloneLog()
        try {
            addToLog(logCopy, recordset)
            setLog(logCopy)
            return true
        } catch (e) {
            console.log(e)
            if (e instanceof ChangeLogValidationError) Alert.info(e.message, 6000)
            return false
        }
    }
    const addToLog = (log, recordset) => {

        const _addLogEntry = (_records, _newRecord) => {

            let validationResult = _validateUpdate(_records, {}, _newRecord)
            if (!validationResult.result) {
                throw new ChangeLogValidationError(validationResult.message)
            }

            log.push({
                newRecord: Object.assign({}, _newRecord, {[injectedChangeLogIdCol]: getUnusedInjectedId(_records)}),
                originalRecord: null,
                instruction: "create"
            })
        }

        let initialRecords = _getRecords(false)

        if (recordset instanceof Array) {
            // Each iteration adds a new record to the log
            // The log is reapplied to the initial records each time so that all new records can be validated against each other as well
            recordset.forEach(newRecord => {
                let records = _applyLog(log, initialRecords)
                _addLogEntry(records, newRecord)
            })
        }
        else if (recordset instanceof Object) {
            let records = _applyLog(log, initialRecords)
            _addLogEntry(records, recordset)
        }
    }
    const deleteFromLogProxy = (recordset) => {
        let logCopy = _cloneLog()
        try {
            deleteFromLog(logCopy, recordset)
            setLog(logCopy)
            console.log(logCopy)
            return true
        } catch (e) {
            if (e instanceof ChangeLogValidationError) Alert.info(e.message, 6000)
            return false
        }
    }
    const deleteFromLog = (log, recordset) => {

        const _deleteLogEntry = (_record) => {

            let matchingChangeIdx = _findChangeIndex(log, _record)
            let matchingChange = log[matchingChangeIdx]
    
            if (matchingChangeIdx===-1) {
                log.push({
                    newRecord: _record,
                    originalRecord: _record,
                    instruction: "delete"
                })
            }
            else {
                if (matchingChange.instruction==="create") {
                    log.splice(matchingChangeIdx, 1)
                }
                else if (matchingChange.instruction==="update") {
                    log[matchingChangeIdx].instruction = "delete"
                }
                // Instruction is set to delete or something else. Can't delete a deleted record so should never happen.
                else {
                    // Maybe validate record is not too long before adding to error message
                    throw new ChangeLogValidationError(`Could not delete the specified record: ${JSON.stringify(_record)}.`)
                }
            }

        }

        if (recordset instanceof Array) {
            recordset.forEach(x => _deleteLogEntry(x))
        }
        else if (recordset instanceof Object) {
            _deleteLogEntry(recordset)
        }
    }
    const bulkOperation = (operations) => {

        let logCopy = _cloneLog()

        try {
            operations.forEach(op => {
                
                let method = op.method
                let changes = op.changes
                console.log(op)
                switch (method) {
                    case "clear":
                        logCopy = []
                        break
                    case "insert":
                        if (changes.length>0) addToLog(logCopy, changes)
                        break
                    case "delete":
                        if (changes.length>0) deleteFromLog(logCopy, changes)
                        break
                    case "update":
                        if (changes.length>0) {
                            changes.forEach(change => {
                                updateLog(logCopy, ...change)
                            })
                        }
                        break
                    default:
                        throw "Operation method must be one of insert, delete, update, clear."
                }
                
            })
            setLog(logCopy)
        } catch (e) {
            if (e instanceof ChangeLogValidationError) Alert.info(e.message, 6000)
            else console.log(e)
        }
        return logCopy
    }

    const mergeAndResetLog = () => {
        var mergedRecords = _getRecords(_getLog())
        setRecords(mergedRecords)
        setLog([])
    }

    /////////////////////
    // Internal functions
    // *These should be purely functional; in the sense they should not directly or indirectly access or modify the state, only the parameters.
    ////////////////////

    const _validateUpdate = (existingRecordset, priorRecord, updatedRecord) => {

        // Only passes when an update is made to a PK field. If this is the case then the new PK field(s) could already exist in another record so check for duplicates.
        if (!_determineIfRecordsMatchPks(priorRecord, updatedRecord)) {
            let idxMatch = _findRecordIndex(existingRecordset, updatedRecord)
            if (idxMatch!==-1) {
                return {
                    result: false,
                    message: "You may not add duplicate instances of a record."
                }
            }
        }
        
        return {
            result: true,
            message: null
        }
    }

    /**
     * If such a change exists, find the corresponding change in the change log of a record by comparing a changes new record to the given record using the primary key fields of the classes recordTypeTable.
     * If no change is found, the record has not been updated by the user yet.
     * @param {Object} record 
     * @param {Boolean} ignoreDeleted 
     */
    const _findChangeIndex = (log, record, ignoreDeleted=true) => {
        return log.findIndex(change => {
            if (ignoreDeleted && change.instruction==="delete") return false   // don't match deleted records because it's too convoluted to perform updates on these

            return _determineIfRecordsMatchPks(change.newRecord, record)
        })
    }

    /**
     * Find a record matching the primary key fields of the input record, if one exists.
     */
    const _findRecordIndex = (recordset, record) => {
        return recordset.findIndex(iterRecord => {
            return _determineIfRecordsMatchPks(record, iterRecord)
        })
    }

    const getUnusedInjectedId = (records) => {
        var recordInjectedIds = records.map(x => x[injectedChangeLogIdCol])
        var max = Math.max(...recordInjectedIds)
        if (!isFinite(max)) max = 0
        return max+1
    }

    /**
     * Merges the change log with a copy of the recordset and returns the result.
     * @param {*} records 
     * @returns 
     */
    const _applyLog = (log, records) => {
        let newRecords = _.cloneDeep(records)
        log.forEach(change => {
            if (change.instruction==="create") {
                newRecords.push(change.newRecord)
            }
            else if (change.instruction==="update") {
                let matchingRecordIdx = _findRecordIndex(newRecords, change.originalRecord)
                // Check index is valid
                let matchingRecord = newRecords[matchingRecordIdx]
                newRecords[matchingRecordIdx] = Object.assign({}, matchingRecord, change.newRecord)
            }
            else if (change.instruction==="delete") {
                let matchingRecordIdx = _findRecordIndex(newRecords, change.originalRecord)
                // Check index is valid
                newRecords.splice(matchingRecordIdx, 1)
            }
        })
        return newRecords
    }

    /**
     * Determines if two records have identical value for all primary key fields
     * @param {*} r1 
     * @param {*} r2 
     * @returns 
     */
    const _determineIfRecordsMatchPks = (r1, r2) => {
        return recordTypeTable.identifiers.map(primaryKeyCol => r1[primaryKeyCol]===r2[primaryKeyCol]).reduce((a, b) => a&&b, true)
    }

    //////////////////////////////
    /// Private accessor functions
    //////////////////////////////

    /**
     * Return current recordset
     * @param {boolean} applyLog whether to apply the current change log to the recordset
     */
    const _getRecords = (log) => {
        if (log) return _applyLog(log, recordsRef.current)
        else return recordsRef.current
    }

    const prepareLog = (operations) => {
        let log
        if (operations) {
            log = bulkOperation(operations)
        }
        else log = _getLog()

        // Omit entries where the record values are unchanged.
        // This may happen if the user edits a value, then changes ot back to the original value. This should not be considered a change.
        log = log.filter(entry => {
            return !_.isEqual(entry.newRecord, entry.originalRecord) || entry.instruction==="delete"
        })
        
        //var log = _.cloneDeep(_getLog())
        /*log.map(entry => {
            delete entry.newRecord[injectedChangeLogIdCol]
        })*/
        return log
    }

    /**
     * Return current log
     */
    const _getLog = () => {
        return logRef.current
    }

    const _cloneLog = () => {
        return _.cloneDeep(_getLog())
    }

    return [
        _getRecords(_getLog()),
        prepareLog,
        updateLogProxy, addToLogProxy, deleteFromLogProxy, bulkOperation,
        mergeAndResetLog,
        () => setLog([]),
        (numPops=1) => {
            const log = _cloneLog()
            if (numPops===0) numPops = log.length
            for (let i=0; i < numPops; i++) {
                log.pop()
            }
            setLog(log)
        }
    ]

}