export function HelpInverterTable(props) {
    return (
        <div className="palantir-helpnote">
            <div className="title">Project Inverter</div>
            <hr></hr>
            <p>The Project Inverter table stores information on the inverters located at a plant.</p>
            <p>Each association contains information on the inverter type and the corresponding number of inverters on site.</p>
            <p>You may create multiple associations per plant, in the case where a plant has multiple inverter types.</p>
        </div>
    )
}