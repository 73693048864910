import { useState } from 'react';

import Dashboard from './MonitorDashboard'

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Text, ThemeIcon, Title } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBattery, faBattery4, faCube } from '@fortawesome/free-solid-svg-icons';


const DashboardList = () => {

    const [selectedDashboardId, setSelectedDashboardId] = useState()

    if (selectedDashboardId) {
        return <div className="flow-vertical fill-parent" style={{padding: "30px 20px 20px 80px"}}>
            <Dashboard
                onBack={() => setSelectedDashboardId(null)}
            />
        </div>
    }
    else {
        return (
            <div className="flow-horizontal" style={{padding: "30px 20px 20px 80px"}}>
                <PreConfiguredMonitorDashboardCard
                    onSelect={() => setSelectedDashboardId(1)}
                />
            </div>
        )
    }
}



const PreConfiguredMonitorDashboardCard = ({onSelect}) => {

    return (
        <Card variant="outlined" onClick={onSelect} style={{"cursor": "pointer", maxWidth: "400px"}}>
            <CardContent>
                <div className="flow-horizontal align-bl" style={{marginBottom: "15px"}}>
                    <ThemeIcon>
                        <FontAwesomeIcon icon={faBattery4} />
                    </ThemeIcon>
                    <Title order={5} ml={6}>BESS Projects Realtime Overview</Title>
                </div>
                <Text size="sm">
                    Shows important real-time monitoring metrics for all BESS projects. Also displays a list of open and recently closed BESS events.
                </Text>
            </CardContent>
        </Card>
    )
}

export default DashboardList