import React from 'react'

import { PalantirTextField } from '../../../components/input/Text'
import { Plant } from '../../table_configuration/Project'
import _ from 'lodash'

const plantSchema = Plant.columnSchema


const Identification = React.memo(function(props) {

    let err = props.errors.get("plantName")
    let plantNameErrProps = err ? {error: true, helperText: "Please enter a name"} : {}
        
    return (
        <div id="plant-identification" className={"field-group " + (props.className || "")}>
            <div className="header">Identification</div>
            <PalantirTextField
                label="Plant ID"
                helperText="A unique ID associated with the plant."
                value={props.plantId}
                sx={{width: "350px"}}
                required
                disabled
            />
            <PalantirTextField
                label="Plant Name"
                value={props.plantName}
                required
                {...plantNameErrProps}
                onChange={(x) => props.updatePlant({[plantSchema.plantName]: x})}
            />
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        prevProps.plantId===nextProps.plantId &&
        prevProps.plantName===nextProps.plantName &&
        _.isEqual(prevProps.errors, nextProps.errors)
    )
})
export default Identification