import { useState } from 'react'
import { DeleteFloaterButtonWithPrompt } from '../../../components/button/FloaterButtonWithPrompt'
import { PalantirTextField, makeTextMaskNumber, PalantirDispatchedTextField } from '../../../components/input/Text'
import {
    PalantirSelector,
    PalantirAutocomplete
} from '../../../components/input/SelectPicker'
import { booleanOptions } from '../../table_configuration/CommonColumns'
import { equipmentStatusOptions } from '../../table_configuration/ProjectEquipment'
import { HelpProjectEquipmentEPCContract } from '../../../components/help/precious/Columns'
import _ from 'lodash'
import { EPCContract } from '../../table_configuration/Contract'

export const ProjectEquipmentHeader = ({ProjectEquipmentTable, removeErrorsOnDelete, deleteFromEquipmentGroupLog}) => {

    return (
        <div className="header">
            <span>{ProjectEquipmentTable.displayNameSingular}</span>
            <DeleteFloaterButtonWithPrompt
                onClick={() => {
                    if (removeErrorsOnDelete) removeErrorsOnDelete()
                    deleteFromEquipmentGroupLog()
                }}
                labelContent={`Delete this ${ProjectEquipmentTable.displayNameSingular} group`}
                height={18}
                width={18}
                style={{float: "right"}}
            />
        </div>
    )
}

export const ProjectEquipmentGroupId = ({ProjectEquipmentTable, value, style, ...props}) => {
    return (
        <PalantirTextField
            label={`${ProjectEquipmentTable.displayNameSingular} Group ID`}
            helperText={`A unique ID associated with this group of ${ProjectEquipmentTable.displayNamePlural}.`}
            value={value}
            sx={Object.assign({width: "340px", marginBottom: "28px", flexShrink: 1}, style || {})}
            required
            disabled
            {...props}
        />
    )
}


const TextMaskEquipmentCount = makeTextMaskNumber({
    min: 0
})

export const ProjectEquipmentCount = ({ProjectEquipmentTable, label, equipmentGroup, equipmentGroupCountCol, updateProjectEquipmentLog, style}) => {
    return (
        <PalantirTextField
            label={label || `${ProjectEquipmentTable.displayNameSingular} Count`}
            value={equipmentGroup[equipmentGroupCountCol]}
            InputProps={{
                inputComponent: TextMaskEquipmentCount
            }}
            onChange={(x) => updateProjectEquipmentLog({[equipmentGroupCountCol]: x})}
            style={Object.assign({minWidth: "80px", marginBottom: "20px"}, style || {})}
        />
    )
}

export const ProjectEquipmentSpec = ({
    equipmentGroup, equipmentSpecRecords, updateEquipmentGroupLog, EquipmentComponents, equipmentType,
    projectEquipmentSpecIdCol, equipmentSpecIdCol, manufacturerCol, modelCol,
    errors, equipmentSpecErrorPath, removeEquipmentSpecError
}) => {

    const originalEquipmentSpec = equipmentSpecRecords.find(x => x[equipmentSpecIdCol]===equipmentGroup[projectEquipmentSpecIdCol]) || {}

    const originalEquipmentSpecManufacturer = originalEquipmentSpec[manufacturerCol]
    const originalEquipmentSpecModel = originalEquipmentSpec[modelCol]

    const [selectedEquipmentSpec, setSelectedEquipmentSpec] = useState(originalEquipmentSpec)
    const [selectedManufacturer, setSelectedManufacturer] = useState(originalEquipmentSpecManufacturer)
    const [selectedModel, setSelectedModel] = useState(originalEquipmentSpecModel)

    const uniqueManufacturers = _.uniq(equipmentSpecRecords.map(x => x[manufacturerCol])).map(manufacturer => Object({label: manufacturer, id: manufacturer}))
    // Manufacturer/model uniqueness is a constraint in database so don't need to filter unique models here
    const uniqueManufacturerModels = equipmentSpecRecords.filter(x => x[manufacturerCol]===selectedManufacturer).map(spec => Object({label: spec[modelCol], id: spec[modelCol]}))

    const equipmentSpecError = errors.get(...equipmentSpecErrorPath)
    const manufacturerError = equipmentSpecError && !selectedManufacturer
    const modelError = equipmentSpecError && !selectedModel

    return (
        <div className="form-sub-instance project-equipment-spec" style={{minWidth: "200px", width: "380px", flexShrink: 1, flexWrap: "wrap", marginRight: "20px", height: "fit-content"}}>
            <PalantirTextField
                label={equipmentType + " Spec ID"}
                value={selectedEquipmentSpec[equipmentSpecIdCol]}
                disabled
                style={{paddingRight: "20px", width: "100%"}}
            />
            <PalantirAutocomplete
                label={equipmentType + " Manufacturer"}
                value={{label: selectedManufacturer, id: selectedManufacturer}}
                options={uniqueManufacturers}
                onUpdate={(x) => {
                    setSelectedManufacturer(x.id)
                    setSelectedEquipmentSpec({})
                    setSelectedModel(null)
                    updateEquipmentGroupLog({[projectEquipmentSpecIdCol]: null})
                }}
                InputProps={{
                    required: true,
                    error: manufacturerError,
                    helperText: manufacturerError ? "Please select a manufacturer" : null
                }}
                disableClearable
            />
            <PalantirAutocomplete
                label={equipmentType + " Model"}
                value={{label: selectedModel, id: selectedModel}}
                options={uniqueManufacturerModels}
                disableClearable
                onUpdate={(x) => {
                    const newSelectedModel = x.id
                    const newSelectedEquipmentSpec = equipmentSpecRecords.find(spec => spec[manufacturerCol]===selectedManufacturer && spec[modelCol]===newSelectedModel) || {}
                    const newSelectedEquipmentSpecId = newSelectedEquipmentSpec[equipmentSpecIdCol]
                    removeEquipmentSpecError()
                    setSelectedEquipmentSpec(newSelectedEquipmentSpec)
                    setSelectedModel(newSelectedModel)
                    updateEquipmentGroupLog({[projectEquipmentSpecIdCol]: newSelectedEquipmentSpecId})
                }}
                InputProps={{
                    required: true,
                    error: modelError,
                    helperText: modelError ? "Please select a model." : null
                }}
            />
            {EquipmentComponents && EquipmentComponents.map(c => c(selectedEquipmentSpec))}
        </div>
    )
}

export const ProjectEquipmentEPCContract = ({equipmentContractIdCol, equipmentGroupEPCContractId, epcContracts, updateEquipmentGroup, services}) => {

    const epcContract = epcContracts.find(x => x[EPCContract.columnSchema.contractId]===equipmentGroupEPCContractId) || {}

    return (
        <PalantirAutocomplete
            label="EPC Contract"
            value={{label: epcContract[EPCContract.columnSchema.contractName], id: epcContract[EPCContract.columnSchema.contractId]}}
            options={epcContracts.map(contract => {
                return {
                    label: contract[EPCContract.columnSchema.contractName],
                    id: contract[EPCContract.columnSchema.contractId]
                }
            })}
            onUpdate={(x) => updateEquipmentGroup({[equipmentContractIdCol]: x.id})}
            HelpProps={{
                title: "Equipment Group EPC Contract",
                item: <HelpProjectEquipmentEPCContract />
            }}
            services={services}
        />
    )
}

export const ProjectEquipmentNotes = ({value, notesColumn, updateProjectEquipment}) => {
    return (
        <PalantirDispatchedTextField
            label="Notes"
            value={value}
            multiline
            rows={4}
            fullWidth
            variant="filled"
            onChange={(x) => updateProjectEquipment({[notesColumn]: x})}
        />
    )
}

export const ProjectEquipmentOwnerProvided = ({equipmentGroup, ownerProvidedCol, updateEquipmentGroup}) => {
    return (
        <PalantirSelector
            label="Owner Provided"
            value={equipmentGroup[ownerProvidedCol]}
            onChange={(x) => updateEquipmentGroup({[ownerProvidedCol]: x})}
            items={[{
                label: "Yes", value: true
            }, {
                label: "No", value: false
            }, {
                label: "TBD", value: null
            }]}
        />
    )
}

export const ProjectEquipmentStatus = ({equipmentGroup, statusCol, updateEquipmentGroup}) => {
    return (
        <PalantirSelector
            label="Status"
            value={equipmentGroup[statusCol]}
            onChange={(x) => updateEquipmentGroup({[statusCol]: x})}
            items={equipmentStatusOptions}
        />
    )
}