import {Component} from 'react'

import TemplateConfigTable from './EventTemplateConfigTable'
import SectionHeader from '../../../section/SectionHeader'
import {HelpEventTemplateConfiguration} from '../../../help/descriptions/tabs/Configuration'
import HelpPanel from '../../../help/HelpPanel'
import { useOutletContext } from 'react-router-dom'
import { getSite } from '../../../../utils/UrlLocation'

const EventTemplateConfiguration = () => {

    const {assetFrameworkServices, authServices} = useOutletContext()

    const selectedSiteId = getSite()

    const services = {assetFramework: assetFrameworkServices, auth: authServices}


    const Tab = <HelpPanel
                    services={services}
                    render={(newServices) => {
                        return (
                            <div className="section-main flow-vertical fill-parent">
                                <SectionHeader services={newServices} sectionTitle="Event Template Configuration" sectionDescription={<HelpEventTemplateConfiguration />} />
                                <TemplateConfigTable services={newServices} selectedSiteId={selectedSiteId} />
                            </div>
                        )
                    }}
                />

    return Tab
        
}

export default EventTemplateConfiguration