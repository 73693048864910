import './MarketTab.css'
import React, {Component, useState} from 'react'

import AssetMenu from '../../menu/AssetMenu'
import Navbar from '../../navbar/Navbar'
import SummaryContent from "./summary/SummaryContent";
import RSATContent from './rsat/RSATContent'
import TableContent from './table/TableContent'
import RegulationContent from './regulation/RegulationContent'
import ForecastsContent from './forecasts/ForecastsContent'

import { getPortfolio, setPortfolio, getSite, setSite } from '../../../utils/UrlLocation'
import { Outlet, useOutletContext } from 'react-router-dom';

const MarketTab = (props) => {

    const { assetFrameworkServices, authServices } = useOutletContext()
    const services = {assetFramework: assetFrameworkServices, auth: authServices}
    
    const [selectedPortfolioId, setSelectedPortfolioId] = useState(getPortfolio())
    const [selectedSiteId, setSelectedSiteId] = useState(getSite())

    const updateSelectedSiteId = (id) => {
        setSite(id)
        setPortfolio(null)
        setSelectedPortfolioId(null)
        setSelectedSiteId(id)
    }

    const updateSelectedPortfolioId = (id) => {
        setPortfolio(id)
        setSite(null)
        setSelectedPortfolioId(id)
        setSelectedSiteId(null)
    }


    return (
        <div className="flow-horizontal hide-overflow fill-parent">
            <AssetMenu 
                services={services}
                updateSelectedSite={updateSelectedSiteId} 
                updateSelectedPortfolio={updateSelectedPortfolioId}
                selectedSiteId={selectedSiteId}
                selectedPortfolioId={selectedPortfolioId}
                className="sidebar"
                menuProps={{
                    collapsable: true,
                }}
            />
            <div className="flow-vertical fill-parent">
                <Navbar 
                    services={services}
                    defaultURL="overview"
                    items={[
                        {
                            label: "Overview",
                            url: "",
                            exact: true,
                            component: SummaryContent,
                            props: {
                                services: services,
                                selectedSiteId: selectedSiteId
                            }
                        },
                        {
                            label: "Table",
                            url: "table",
                            component: TableContent,
                            props: {
                                services: services,
                                selectedSiteId: selectedSiteId
                            }
                        },
                        {
                            label: "Regulation",
                            url: "regulation",
                            component: RegulationContent,
                            props: {
                                services: services,
                                selectedSiteId: selectedSiteId
                            }
                        },
                        {
                            label: "RSAT",
                            url: "rsat",
                            component: RSATContent,
                            props: {
                                services: services,
                                selectedSiteId: selectedSiteId
                            }
                        },
                        {
                            label: "Forecast",
                            url: "forecast",
                            component: ForecastsContent,
                            props: {
                                services: services,
                                selectedSiteId: selectedSiteId
                            }
                        }
                    ]}
                />
                <Outlet context={{ assetFrameworkServices, authServices }} />
            </div>
        </div>
    )

}

export default MarketTab