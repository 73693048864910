import {Component} from 'react'

import DataProvider from '../../../data_provider/StandardDataProvider'

class KPIContainer extends Component {
    constructor(props) {
        super()
        this.state = {}
    }

    render() {
        return (
            <div className="flow-vertical section-panel fill-parent">
                <div className="section-header">
                    <div className="title">Revenue</div>
                </div>
                <DataProvider
                    services={this.props.services}
                    selectedSiteId={this.props.selectedSiteId}
                    selectedStartDate={this.props.selectedStartDate}
                    selectedEndDate={this.props.selectedEndDate}
                    selectedResolution={this.props.selectedResolution}
                    queryProps={["selectedSiteId", "selectedStartDate", "selectedEndDate", "selectedResolution"]}
                    queryUrl={'/api/market/kpis'}
                    render={this.getComponent}
                />
            </div>
        )
    }

    getComponent = (data, dataRequestId) => {

        var numToLocalString = (x) => x.toLocaleString("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 1})

        return (
            <div className="flow-horizontal" style={{fontSize: '14px', justifyContent: 'space-evenly', alignItems: 'center'}}>
                <div style={{flexBasis: 0}}>
                    <span>Total </span>
                    <span style={{fontSize: '12px'}}>
                        {numToLocalString(data.revenue.sum.total)}
                    </span>
                </div>
                <div style={{flexBasis: 0}}>=</div>
                <div style={{flexBasis: 0}}>
                    <span>Realized </span>
                    <span style={{fontSize: '12px'}}>
                        {numToLocalString(data.revenue.sum.realized)}
                    </span>
                </div>
                <div style={{flexBasis: 0}}>+</div>
                <div style={{flexBasis: 0}}>
                    <span>Outstanding </span>
                    <span style={{fontSize: '12px'}}>
                        {numToLocalString(data.revenue.sum.outstanding)}
                    </span>
                </div>
            </div>
        )

    }

}

export default KPIContainer