import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';

import reportWebVitals from './reportWebVitals';
import axios from 'axios'

import AppSelector from './components/navigation/AppSelector';
import AppAuthHandler from './components/authentication/AuthHandler'

import { createBrowserRouter, Navigate, redirect, RouterProvider } from 'react-router-dom';
import ErrorPage from './components/navigation/RouterError'

import App from './App';
import DatabaseApp from './DatabaseApp'

import HomeTab from './components/tabs/home/HomeTab'
import HistoryTab from './components/tabs/history/History'
import MonitorTab from './components/tabs/monitor/MonitorTab'
import MarketTab from './components/tabs/market/MarketTab'
import EventsTab from './components/tabs/events/Events'
import ConfigureTab from './components/tabs/configure/ConfigureTab'
import SummaryContent from './components/tabs/market/summary/SummaryContent';
import RSATContent from './components/tabs/market/rsat/RSATContent'
import TableContent from './components/tabs/market/table/TableContent'
import RegulationContent from './components/tabs/market/regulation/RegulationContent'
import ForecastsContent from './components/tabs/market/forecasts/ForecastsContent'
import EventBlueprintConfiguration from './components/tabs/configure/events/EventBlueprintConfiguration';
import EventTemplateConfiguration from './components/tabs/configure/events/EventTemplateConfiguration';
import TagBlueprintConfiguration from './components/tabs/configure/tags/TagBlueprintConfiguration';

import { HomeTab as DatabaseHomeTab } from './precious_components/HomeTab'
import { InverterManagementTab } from './precious_components/InverterWarehouse';
import { PowerBITab } from './precious_components/PowerBI';



if (process.env.NODE_ENV!=="development") {
    let proxy = process.env.REACT_APP_SERVER_PROXY
    if (!proxy) throw new Error("REACT_APP_SERVER_PROXY must be specified when creating non-development build.")
    axios.defaults.baseURL = proxy
}

const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <AppAuthHandler />
      ),
      //errorElement: <ErrorPage />,
      children: [{
            index: true,
            element: <AppSelector />
        }, {
            path: "precious",
            element: <Navigate to="/palantir" replace={true} />
        }, {
            path: "palantir",
            element: <DatabaseApp />,
            children: [{
                index: true,
                element: <DatabaseHomeTab />
            }, {
                path: "inverter_management",
                element: <InverterManagementTab />
            }, {
                path: "bi",
                element: <PowerBITab />
            }]
        }, {
            path: "pharos",
            element: <App />,
            children: [{
                index: true,
                element: <HomeTab />
            }, {
                path: "history",
                element: <HistoryTab />
            }, {
                path: "monitor",
                element: <MonitorTab />
            }, {
                path: "market",
                element: <MarketTab />,
                children: [{
                    index: true,
                    element: <SummaryContent />
                }, {
                    path: "table",
                    element: <TableContent />
                }, {
                    path: "regulation",
                    element: <RegulationContent />
                }, {
                    path: "rsat",
                    element: <RSATContent />
                }, {
                    path: "forecast",
                    element: <ForecastsContent />
                }]
            }, {
                path: "events",
                element: <EventsTab />
            }, {
                path: "configure",
                element: <ConfigureTab />,
                children: [{
                    index: true,
                    element: <EventBlueprintConfiguration />
                }, {
                    path: "event_templates",
                    element: <EventTemplateConfiguration />
                }, {
                    path: "tag_blueprints",
                    element: <TagBlueprintConfiguration />
                }]
            }]
        }
      ]
    },
]);

ReactDOM.render( 
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();