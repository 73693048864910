import React, {Component} from 'react'
import { formatDate } from '../../utils/Dates'
import { DatePicker } from 'rsuite';


class SuperDateTimePicker extends Component {

    constructor(props) {
        super()
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.date===this.props.date) {
            return false
        }
        return true
    }

    render() {
        return (
            <DatePicker
                defaultValue={new Date(this.props.date)}
                format={"YYYY-MM-DD HH:mm:ss"}
                onChange={this.callback.bind(this)}
                cleanable={false}
                appearance="subtle"
                hideSeconds={seconds => true}
                hideMinutes={minutes => true}
                {...this.props}
            />
        )
    }

    callback(date, event) {
        date = formatDate(date)
        this.props.onValueChange(date)
    }

}

export default SuperDateTimePicker