import '../Help.css'

import { OptionNav } from '../../navigation/SimpleNavs'
import { IntervalTagPopover, IntervalPopover } from './PalantirTerminology'

export function HelpCobjectInherit(props) {
    return (
        <div className="palantir-helpnote">
            <div className="title">{"Evaluation > Inherit"}</div>
            <hr></hr>
            <p>
                The inherit property determines whether or not the remaining evaluation properties are inherited from the attached template. 
                If true, the template evaluation properties will <strong>override</strong> the configured blueprint evaluation properties when the blueprint is evaluated.
            </p>
            <hr></hr>
            <p className="header">Example</p>
            <p>The blueprint in question has the following evaluation properties</p>
            <table className="table section">
                <thead>
                    <tr>
                        <th>Field</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="highlighted">
                        <td>Inherit</td>
                        <td>True</td>
                    </tr>
                    <tr>
                        <td>Type</td>
                        <td>Standard</td>
                    </tr>
                    <tr>
                        <td>Method</td>
                        <td>Evaluate</td>
                    </tr>
                    <tr>
                        <td>Code</td>
                        <td>Random Code xyz</td>
                    </tr>
                    <tr>
                        <td>Range</td>
                        <td>Disabled</td>
                    </tr>
                    <tr>
                        <td>Ranged Data Access Method</td>
                        <td>None</td>
                    </tr>
                </tbody>
            </table>
            <p>The blueprint is associated with a template that has the following evaluation properties</p>
            <table className="table section">
                <thead>
                    <tr>
                        <th>Field</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Type</td>
                        <td>Ranged</td>
                    </tr>
                    <tr>
                        <td>Method</td>
                        <td>Evaluate</td>
                    </tr>
                    <tr>
                        <td>Code</td>
                        <td>Random Code abc</td>
                    </tr>
                    <tr>
                        <td>Range</td>
                        <td>[0,12]</td>
                    </tr>
                    <tr>
                        <td>Ranged Data Access Method</td>
                        <td>Iterative</td>
                    </tr>
                </tbody>
            </table>
            <p>
                When the blueprint is evaluated, it will run as a ranged blueprint evaluating code "Random Code abc".
                These properties were inherited from the attached template.
            </p>
        </div>
    )
}



export function HelpCobjectType(props) {

    const Interval = () => <IntervalPopover placement="left">interval</IntervalPopover>
    const SampleDataset = () => <div className="centered"><table className="table section">
        <thead>
            <tr>
                <td>index</td>
                <td>ac_power</td>
                <td>price</td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>4:35PM</td>
                <td>100</td>
                <td>5</td>
            </tr>
            <tr>
                <td>4:40PM</td>
                <td>1000</td>
                <td>50</td>
            </tr>
            <tr>
                <td>4:45PM</td>
                <td>10000</td>
                <td>500</td>
            </tr>
            <tr>
                <td>...</td>
                <td>...</td>
                <td>...</td>
            </tr>
            <tr>
                <td>8:30PM</td>
                <td>100000</td>
                <td>5000</td>
            </tr>
        </tbody>
    </table></div>

    const StandardSection = () => (
        <div className="section">
            <p>A standard blueprint is provided access to data from the most recent <Interval />.</p>
            <p>It is only evaluated once (for each device the blueprint is applied to) for this set of data.</p>
            <p>The provided data is described below.</p>
            <ul>
                <li>
                    The current [and previous] <IntervalTagPopover placement="left">interval tag value</IntervalTagPopover> for any device and tag. 
                    <i> Note that <strong>event blueprints</strong> do not have access to the previous interval value</i>.
                </li>
                <li>The cached raw data for the current <Interval />. This contains all the raw data that has been collected since the last <Interval />.</li>
                <li>Metadata for all devices, as well as the blueprint site.</li>
                <li>
                    The current <Interval /> time. This is the time at which the blueprint is being evaluated. 
                    This is useful for ensuring an event is only triggered during certain times of the day.
                </li>
            </ul>
            <p>All the above values are saved to variables that are accessable from the blueprint code at runtime.</p>
            <p>
                For example, the value of the current <i>ac_power</i> for a specific meter would be saved as the variable <i>__meter_x_ac_power</i>.
                The blueprint could then use that value by referencing the variable <i>__meter_x_ac_power</i> in the blueprint code, and the appropriate value would be substituted in its place.
            </p>
            <p>For details on how to access these variables, refer to the blueprint code help panel.</p>
        </div>
    )

    const RangedSection = () => (
        <div className="section">
            <p>A ranged blueprint is provided access to all data within the blueprint's specified evaluation range, allowing access to historical and forecasted data.</p>
            <p>An example dataset provided to a ranged blueprint is shown below.</p>
            <SampleDataset />
            <p>The method in which the dataset is processed by the blueprint is controlled by the blueprint 'Ranged Data Access Method' option.</p>
            <p>Visit the help note for the blueprint 'Range' and 'Ranged Data Access Method' for more information on these options.</p>
        </div>
    )

    const options = {"Standard": StandardSection, "Ranged": RangedSection}

    return (
        <div className="palantir-helpnote">
            <div className="title">{"Evaluation > Type"}</div>
            <hr></hr>
            <p>The evaluation type property defines how the blueprint itself is run and what data it is given access to.</p>
            <p>There are two options here.</p>
            <div className="section"></div>
            <OptionNav options={options} />
        </div>
    )
}

export function HelpCobjectFunction(props) {

    const EvaluateSection = () => (
        <div className="section">
            <p>This option will satisfy most use cases that require simple conditional checks on threshold values.</p>
            <p>This option will run your blueprint code as an expression that evaluates to a single value. This value will be used as the blueprint output.</p>
            <p>The following are examples of valid expressions.</p>
            <ul>
                <li><pre className="code">0 + 0 + 7</pre></li>
                <li><pre className="code">19 * 9 + 8 + 0 + 4 * 29</pre></li>
                <li><pre className="code">{"9 > 2 and 2/3 < 200"}</pre></li>
            </ul>
            <p>These statements all run a set of conditions and evaluate to a single value</p>
        </div>
    )

    const ExecuteSection = () => (
        <div className="section">
            <p>This option will allow you to run arbitrary python code. The code can be any and all valid python code.</p>
            <p>You must save the value you wish to use as the blueprint output to the variable '<i>palantir_output</i>'.</p>
            <p className="footnote">
                * Additionally, for event blueprints, you may save other variables to the variable 'palantir_context' which will be saved with the event when it is created.
                The context object is then made available to view along with the created event. This is useful for saving relevant tag values that caused the blueprint to trigger.
            </p>
            <p>
                Since the output/context values are being stored with the event, they can not be python specific objects.
                Objects must be converted to native data types (int, float, boolean, ...), 
                although most numpy datatypes are automatically converted for you (all values accessed from dataframes are numpy types).
            </p>
            <p>The python libraries made available to import are below.</p>
            <ol>
                <li>All builtins</li>
                <li>Pandas</li>
                <li>Numpy</li>
            </ol>
            <p className="header-2">Example</p>
            <p>A valid executable statement is shown below</p>
            <pre className="code">
                <div>gandalf = 9;</div>
                <div>saruman = 8;</div>
                <div>{"is_gandalf_the_best = gandalf > saruman"}</div>
                <div>palantir_output = is_gandalf_the_best</div>
                <div>palantir_context = {"{"}</div>
                <div>  'value_of_gandalf_at_trigger': gandalf,</div>
                <div>  'value_of_saruman_at_trigger': saruman,</div>
                <div>{"}"}</div>
            </pre>
        </div>
    )

    const options = {"Evaluate": EvaluateSection, "Execute": ExecuteSection}

    return (
        <div className="palantir-helpnote">
            <div className="title">{"Evaluation > Method"}</div>
            <hr></hr>
            <p>The evaluation method property defines how the evaluation code is run in the backend, or more simply, how you should structure your code.</p>
            <p>There are two options here.</p>
            <div className="section"></div>
            <OptionNav options={options} />
        </div>
    )
}

export function HelpCobjectRange(props) {
    return (
        <div className="palantir-helpnote">
            <div className="title">{"Evaluation > Range"}</div>
            <hr></hr>
            <p>The range property of a blueprint only applies when the blueprint type is 'ranged'.</p>
            <p>This property allows you to specify the range of data the blueprint will have access to on evaluation.</p>
            <p>The range consists of an upper and lower bounds. The bounds values represent a time delta in hours relative to the evaluation time of the blueprint.</p>
            <p>A range of [0,0] will be treated as disabled, as this will not return any data.</p>
            <p className="header-2">Example</p>
            <p>A blueprint has a range of -3 to +8</p>
            <p>The blueprint is evaluated at 5:20AM</p>
            <p>The blueprint will be given access to data from 2:20AM - 1:20PM. This time range measures from 3 hours before the evaluation time to 8 hours after.</p>
            <hr></hr>
            <p className="footnote">
                * Note: the min and max range limits are set at -12 and +24 respectively. 
                This effectively means you have access to 12 hours of the most recent historical data, and 24 hours of forecasted data.
            </p>
        </div>
    )
}

export function HelpCobjectRangedDataAccessMethod(props) {

    const Interval = () => <IntervalPopover placement="left">interval</IntervalPopover>
    const SampleDataset = () => <div className="centered"><table className="table section">
        <thead>
            <tr>
                <td>index</td>
                <td>ac_power</td>
                <td>price</td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>4:35PM</td>
                <td>100</td>
                <td>5</td>
            </tr>
            <tr>
                <td>4:40PM</td>
                <td>1000</td>
                <td>50</td>
            </tr>
            <tr>
                <td>4:45PM</td>
                <td>10000</td>
                <td>500</td>
            </tr>
            <tr>
                <td>...</td>
                <td>...</td>
                <td>...</td>
            </tr>
            <tr>
                <td>8:30PM</td>
                <td>100000</td>
                <td>5000</td>
            </tr>
        </tbody>
    </table></div>

    const IterativeSection = () => (
        <div className="section">
            <p>
                This option will provide access to the dataset one <Interval />, or row, at a time, for each interval in the dataset.
                It will evaluate the blueprint code once for the <Interval />, using the values of the provided tags in that interval.
            </p>
            <p>You can think of this as a standard blueprint that evaluates once for each <Interval /> available, where the scope is set to that interval's data.</p>
            <p className="header-2">Example</p>
            <p>Considering the dataset provided above, which is also displayed below for convenience.</p>
            <SampleDataset />
            <p>
                As each <Interval /> is 5 minutes, there are 12 * 4 = 48 intervals in the dataset, so the blueprint code will be evaluated 48 times, once for each interval.
            </p>
            <p>The blueprint code is as follows.</p>
            <pre className="code">
                {"ac_power + price > 10000"}
            </pre>
            <ol>
                <li>First, data at 4:35 will be evaluated. On this evaluation, the value of <i>ac_power</i> and <i>price</i> variables will be set to 100 and 5 respectively. The condition evaluates to false.</li>
                <li>Next, 4:40 data. On this evaluation, <i>ac_power</i> and <i>price</i> are set to 1000 and 50 respectively. The condition is again false.</li>
                <li>Next up is 4:45 data. Here, <i>ac_power</i> and <i>price</i> are set to 10,000 and 50. {"10,000 + 500 > 10,000"} evaluates to true, so the output will be true here.</li>
                <li>This process repeats for each <Interval />, or row, of the data.</li>
            </ol>
            <p className="header-2">Access to current time variable</p>
            <p>
                In a standard blueprint, the evaluation time is provided as a variable you can reference in the blueprint code. 
                The same variable is provided here, with the difference that its value is set to the timestamp of the interval being evaluated.
            </p>
            <p>
                In step 1 above, the value of this variable would be set to 4:35. In step 2 it would be set to 4:40, step 3 to 4:45, and so on.
            </p>
        </div>
    )

    const Inclusive = () => (
        <div className="section">
            <p>This option will provide access to the all data at once, resulting in a single evaluation of the blueprint code.</p>
            <p>
                The dataset is provided as a Pandas DataFrame, a python data structure, to the blueprint code. 
                A DataFrame is similar to an Excel sheet, organized via rows and columns, and is structured exactly as the example dataset above.
            </p>
            <p>In the example above, the entire table of data would be accessable from the blueprint code.</p>
            <p>You would then be free to access it and perform calculations or aggregations.</p>
            <p className="header-2">Access to current time variable</p>
            <p>Again, access to the blueprint evaluation time is provided here, exactly the same as in a standard blueprint.</p>
        </div>
    )

    const options = {"Iterative": IterativeSection, "Inclusive": Inclusive}

    return (
        <div className="palantir-helpnote">
            <div className="title">{"Evaluation > Ranged Data Access Method"}</div>
            <hr></hr>
            <p>This property only applies when the blueprint type is set to ranged.</p>
            <p>This property controls how the dataset provided to the blueprint is processed.</p>
            <p>There are two options to configure here.</p>
            <p>An example dataset that would be provided to a ranged blueprint is show below.</p>
            <SampleDataset />
            <p className="footnote">* The data provided to the blueprint is interval ending, meaning 4:35 represents aggregated raw data from 4:30-4:35.</p>
            <div className="section"></div>
            <OptionNav options={options} />
        </div>
    )
}

export function HelpCobjectCode(props) {

    const References = () => {

        const Syntax = () => {

            return (
                <div className="section">
                    <p>References must always be prefixed by two dollar signs <pre className="code">$$</pre>.</p>
    
                    <p>Depending on the blueprint type and data access method, if applicable, the </p>
                    <p>An expression has access to the current values of all devices belonging to the same site.</p>
                    <p>To access a value, you will use what is called a directive. To declare a directive, prefix it with $$ syntax.</p>
                    <p>Within a directive, you will access values by first accessing a device (optional), then a tag, then the dimensions (if applicable)</p>
                    <p>To access a device use the syntax <i>$device.{"{deviceId}"}</i></p>
                    <p>To access a tag use the syntax <i>$tag.{"{tagId}"}</i></p>
                    <p>To access a dimension value use the syntax <i>$dk.{"{dimensionKeyId}"}$dv.{"{dimensionValueId}"}</i></p>
                    <p>The following is an example of a complete directive:</p>
                    <p><i>$$device.1$tag.21$dk.1$dv.2</i></p>
                    <p>This directive will access the current value of tag 21 with dimension 1 value 2 on device 1</p>
                    <p>Note how it begins with $$</p>
                    <hr></hr>
                    <p>You may omit a device from a directive, specifying only the tag. This will attempt to reference the device which is being evaluated.</p>
                    <p>For example, if evaluating 3 meters: meter 1,2 and 3, you may reference a tag that is common among them (eg. AC_POWER).</p>
                    <p>As the expression is evaluated for each meter, the directive referencing AC_POWER will refer to the value of the respective meter.</p>
                    <p>The appropriate expression would be $$tag.<i>{"{acPowerId}"}</i></p>
                    <p>This expression would be evaluated once for each of meter 1,2, and 3.</p>
                    <p>When being evaluated for meter 1, the meter 1 AC_POWER tag would be used. When evaluated for meter 2, the meter 2 AC_POWER tag would be used. And so on...</p>
                    <hr></hr>
                </div>
            )
        }
    
        const Examples = () => {
            return (
                <div className="section">
                    <div className="section"></div>
                    <pre className="code">$$device.2$tag.6 == 10</pre>
                    <p>Access the value of tag 6 of device 2, and checks for equality.</p>
                    <hr></hr>
                    <pre className="code">$$device.1$tag.2$dk.1$dv.1$dk.2$dv.3$dk.3$dv.1</pre>
                    <p>Access the value of tag 2 of device 1 with dimension 1 = value 2, dimension 2 = value 3, and dimension 3 = value 1</p>
                    <hr></hr>
                    <pre className="code">$$tag.2</pre>
                    <p>Access the value of tag 2 of the current device being evaluated</p>
                    <p>If the blueprint is being evaluated for 3 meters, on each evaluation, this will reference the value fo tag 2 for the respective meter.</p>
                    <hr></hr>
                    <pre className="code">$$now or $$now.hour</pre>
                    <p>Accesses the evaluation time, as well as the evaluation hour. The value may differ depending on the blueprint evaluation type. Check the info panel for the blueprint type.</p>
                </div>
            )
        }

        const options = {"Syntax": Syntax, "Examples": Examples}

        return (
            <div className="section">
                <p>Within the blueprint code, you are provided access to tag data via references.</p>
                <p>All references will contain a reference to a tag</p>
                <p>These references can be one of two types.</p>
                <ol>
                    <li>Dependent references contain a reference to a specific device via its internal id.</li>
                    <li>Independent references contain a reference to a</li>
                </ol>
                <OptionNav options={options} />
            </div>
        )
    }

    const options = {"References": References}

    return (
        <div className="palantir-helpnote">
            <div className="title">{"Evaluation > Code"}</div>
            <hr></hr>
            <p>
                This property contains the code that is executed by the blueprint.
                The actions taken by the blueprint will depend on the output of this code.
            </p>
            <p>The code must be in the python programming language. The structure of the code is dependent on the blueprint execution method.</p>
            
            <OptionNav options={options} />     
        </div>
    )
}