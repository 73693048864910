import { schemas } from '../Constants'
import { transformDisplayValue, buildDropdownItems } from '../../utils/databaseAppUtils'
import { Plant } from './Project'
import Table from './BaseTable'
import { makePlantId, makeNotes } from './CommonColumns'
import { contractStatusOptions, makeContractID, makeContractName, buildGetReferencedPlantId } from './Contract'
import { HelpLLCTable } from '../help_panels/Entity'
import { makeLLCId, makeLLCName, makeLLCDescription } from './CommonColumns'
import { injectCounterpartyName, Bank } from './Counterparty'
import { validateEntity, Counterparty } from './Entity'


const partnershipTypeOptions = [{
    label: "Debt Partner", value: "Debt Partner"
}, {
    label: "LC Lender", value: "LC Lender"
}]

class DebtPartnerPlantLLCTable extends Table {

    constructor() {

        var columnSchema = {
            debtPartnerId: "debt_partner_id",
            debtPartnerName: "debt_partner_name",
            description: "description",
        }

        var schemaName = schemas.llc
        var tableName = "debt_partner"

        var displayNameSingular = "Project Debt Partner LLC"
        var displayNamePlural = "Project Debt Partner LLCs"

        var pkUidColumn = columnSchema.debtPartnerId
        var identifiers = [columnSchema.debtPartnerId]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpLLCTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        var debtPartnerLLCRecords = this.findTableObjectRecords(data, this)

        var validateDebtPartnerLLC = (debtPartnerLLCRecord) => validateEntity(debtPartnerLLCRecords, debtPartnerLLCRecord, this.columnSchema.debtPartnerId, this.columnSchema.debtPartnerName, this.displayNameSingular)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: validateDebtPartnerLLC,
            validateUpdateItem: validateDebtPartnerLLC,
            tableConfig: {
                columns: [
                    makeLLCId(this.columnSchema.debtPartnerId),
                    makeLLCName(this.columnSchema.debtPartnerName, this.displayNameSingular),
                    makeLLCDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var DebtPartnerPlantLLC = new DebtPartnerPlantLLCTable()

class PlantDebtPartnerJunctionTable extends Table {

    constructor() {

        var columnSchema = {
            debtPartnerId: "plant_debt_partner_llc_id",
            bankId: "bank_id",
            notes: "notes"
        }

        var schemaName = schemas.llc
        var tableName = "debt_partner_junction"

        var displayNameSingular = "Project Debt Partner Link"
        var displayNamePlural = "Project Debt Partner Links"

        var pkUidColumn = null
        var identifiers = [columnSchema.debtPartnerId, columnSchema.bankId]
        var dependencies = [DebtPartnerPlantLLC, Bank]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = true
    }

    buildHelpPanel = () => <HelpLLCTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        var debtPartnerLLCRecords = this.findTableObjectRecords(data, DebtPartnerPlantLLC)
        var bankRecords = this.findTableObjectRecords(data, Bank)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)


        var debtPartnerIdCol =  DebtPartnerPlantLLC.columnSchema.debtPartnerId
        var debtPartnerNameCol =  DebtPartnerPlantLLC.columnSchema.debtPartnerName
        var bankIdCol = Bank.columnSchema.bankId
        var bankNameCol = "bankName"

        bankRecords = injectCounterpartyName(bankRecords, counterpartyRecords, bankIdCol, bankNameCol)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    {
                        name: "Project Debt Partner LLC",
                        key: this.columnSchema.debtPartnerId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(debtPartnerLLCRecords, value, debtPartnerIdCol, debtPartnerNameCol),
                            dropdownItems: buildDropdownItems(debtPartnerLLCRecords, debtPartnerIdCol, debtPartnerNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    }, {
                        name: "Bank",
                        key: this.columnSchema.bankId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(bankRecords, value, bankIdCol, bankNameCol),
                            dropdownItems: buildDropdownItems(bankRecords, bankIdCol, bankNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    }, {
                        name: "Notes",
                        key: this.columnSchema.notes,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            flexGrow: 1,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export var PlantDebtPartnerJunction = new PlantDebtPartnerJunctionTable()

class DebtPartnerContractTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            contractName: "contract_name",
            plantId: "plant_id",
            debtPartnerLLCId: "debt_partner_llc_id",
            status: "status",
            effectiveDate: "effective_date",
            endDate: "end_date",
            termYears: "term_years",
            expectedFinancialCloseDate: "expected_financial_close_date",
            actualFinancialCloseDate: "actual_financial_close_date",
            sharepointLink: "sharepoint_link",
            notes: "notes",
        }

        var schemaName = schemas.contract
        var tableName = "debt_partner"

        var displayNameSingular = "Debt Partner Contract"
        var displayNamePlural = "Debt Partner Contracts"

        var pkUidColumn = columnSchema.contractId
        var identifiers = [columnSchema.contractId]
        var dependencies = [Plant, DebtPartnerPlantLLC]
        
        super(
            schemaName, tableName,
            columnSchema,
            displayNameSingular, displayNamePlural,
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = true
        this.initOptions()
    }

    initOptions = () => {
        this.options = {
            status: [{
                label: "Not Started", value: "Not Started"
            }, {
                label: "In Progress", value: "In Progress"
            }, {
                label: "Executed", value: "Executed"
            }]
        }
    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)
        var llcRecords = this.findTableObjectRecords(data, DebtPartnerPlantLLC)
        var llcIdCol = DebtPartnerPlantLLC.columnSchema.debtPartnerId
        var llcNameCol = DebtPartnerPlantLLC.columnSchema.debtPartnerName

        const validateContract = (contract) => {
            if (!contract[this.columnSchema.plantId]) return {pass: false, message: "Please give the contract a plant."}
            if (!contract[this.columnSchema.contractName]) return {pass: false, message: "Please give the contract a unique name."}
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateContract,
            validateUpdateItem: validateContract,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeContractID(this.columnSchema.contractId),
                    makeContractName(this.columnSchema.contractName),
                    makePlantId(plantRecords, Plant.columnSchema.plantId, Plant.columnSchema.plantName),
                    {
                        name: "Debt Partner LLC",
                        key: this.columnSchema.debtPartnerLLCId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(llcRecords, value, llcIdCol, llcNameCol),
                            dropdownItems: buildDropdownItems(llcRecords, llcIdCol, llcNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Status",
                        key: this.columnSchema.status,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.status,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Effective Date",
                        key: this.columnSchema.effectiveDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "End Date",
                        key: this.columnSchema.endDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Term (years)",
                        key: this.columnSchema.termYears,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Expected Financial Close Date",
                        key: this.columnSchema.expectedFinancialCloseDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Actual Financial Close Date",
                        key: this.columnSchema.actualFinancialCloseDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Sharepoint Folder Link",
                        key: this.columnSchema.sharepointLink,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes),
                ]
            },
        }
    }

}

export var DebtPartnerContract = new DebtPartnerContractTable()

class ContractDebtPartnerCounterpartiesTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            bankId: "bank_id",
            financingType: "financing_type",
            financingStatus: "financing_status",
            notes: "notes"
        }

        var schemaName = schemas.contract
        var tableName = "debt_partner_counterparties"

        var displayNameSingular = "Contract Counterparty"
        var displayNamePlural = "Contract Counterparties"

        var pkUidColumn = null
        var identifiers = [columnSchema.contractId, columnSchema.bankId, columnSchema.financingType]
        var dependencies = [DebtPartnerContract, Bank]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = true
        this.initOptions()
    }

    initOptions = () => {
        this.options = {
            status: [{
                label: "Not Started", value: "Not Started"
            }, {
                label: "In Progress", value: "In Progress"
            }, {
                label: "Completed", value: "Completed"
            }],
            type: [{
                label: "Construction", value: "Construction"
            }, {
                label: "Permanent", value: "Permanent"
            }, {
                label: "LC Lender", value: "LC Lender"
            }]
        }
    }

    buildHelpPanel = () => <HelpLLCTable type={this.displayNameSingular} />

    getReferencedPlantId = buildGetReferencedPlantId(this, DebtPartnerContract)

    buildTableProps = (data) => {

        var contractRecords = this.findTableObjectRecords(data, DebtPartnerContract)
        var bankRecords = this.findTableObjectRecords(data, Bank)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        var contractIdCol = DebtPartnerContract.columnSchema.contractId
        var contractNameCol = DebtPartnerContract.columnSchema.contractName
        var bankIdCol =  Bank.columnSchema.bankId
        var bankNameCol =  "bankName"

        bankRecords = injectCounterpartyName(bankRecords, counterpartyRecords, bankIdCol, bankNameCol)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    {
                        name: "Contract",
                        key: this.columnSchema.contractId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contractRecords, value, contractIdCol, contractNameCol),
                            dropdownItems: buildDropdownItems(contractRecords, contractIdCol, contractNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Debt Partner Bank",
                        key: this.columnSchema.bankId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(bankRecords, value, bankIdCol, bankNameCol),
                            dropdownItems: buildDropdownItems(bankRecords, bankIdCol, bankNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Financing Type",
                        key: this.columnSchema.financingType,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.type,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Financing Status",
                        key: this.columnSchema.financingStatus,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.status,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes, 1),
                ]
            },
        }
    }

}
export var ContractDebtPartnerCounterparties = new ContractDebtPartnerCounterpartiesTable()