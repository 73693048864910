import {Component} from 'react'

import _ from 'lodash'

import Panel from '../../../panel/Panel'
import ModalWrapper from '../../../modal/ModalWrapper'
import GenericFormModal from '../../../modal/GenericFormModal'
import DeviceSelectionModal from '../../../modal/DeviceSelectionModal'
import EventTemplateModal from '../../../modal/EventTemplateModal'
import CRUDTable from '../../../table/CRUDTable'
import {cobjectTableConfig} from '../../../table/config_templates/cobject'

class BlueprintConfigTable extends Component {

    constructor(props) {
        super()
        this.state = {
            
        }
    }

    componentDidMount() {
        
    }

    render() {
        console.log("Rendering ev bp table")

        if (!this.props.selectedSiteId) {
            return <Panel className="section-main">Please select a site</Panel>
        }

        var data = this.props.services.assetFramework.getEventBlueprints().filter(x => x.assets.site===this.props.selectedSiteId)
        var _this = this

        return (
            <CRUDTable 
                services={this.props.services}
                partitionKey={"event-blueprint"}
                initialItems={data}
                itemType="Event Blueprint"
                setHelpPanel={this.props.setHelpPanel}
                itemSchema={this.getBlueprintSchema()}
                flattenItem={this.flattenBlueprint}
                structureItem={this.structureBlueprint}
                rowOptions={[
                    {
                        label: "Duplicate",
                        callback: (blueprint) => console.log(blueprint)
                    }
                ]}
                tableConfig={{
                    columns: [
                        {
                            name: "Id",
                            key: "_id",
                            cellType: "standard",
                            filterable: true,
                            sortable: true,
                            colProps: {
                                width: 70,
                                resizable: true,
                                fixed: "left",
                            },
                        },
                        {
                            name: "Enabled",
                            key: "enabled",
                            cellType: "dropdown",
                            cellOptions: {
                                dropdownItems: [{
                                    label: "true",
                                    value: true,
                                }, {
                                    label: "false",
                                    value: false,
                                }]
                            },
                            colProps: {
                                width: 150,
                                resizable: false,
                            }
                        },
                        {
                            name: "Template",
                            key: "templateCode",
                            cellType: "customRender",
                            render: (rowData, onChange) => {
                                return <EventTemplateModal
                                            services={this.props.services}
                                            selectedTemplateId={rowData.templateId}
                                            onClose={(selectedTemplateId) => {
                                                if (!selectedTemplateId) return
                                                var templateCode = _this.props.services.assetFramework.getEventTemplates().find(x => x._id===selectedTemplateId).code
                                                console.log(selectedTemplateId, templateCode)
                                                //onChange({newData: templateCode, colKey: "templateCode"})
                                                onChange({newData: selectedTemplateId, colKey: "templateId"})
                                            }}
                                        />
                            },
                            colProps: {
                                width: 200,
                                resizable: true,
                                sortable: false,
                            },
                        },
                        {
                            colGroup: true,
                            header: "Assets",
                            columns: [
                                {
                                    name: "Site",
                                    key: "siteName",
                                    cellType: "standard",
                                    filterable: false,
                                    sortable: false,
                                    colProps: {
                                        width: 150,
                                        resizable: true,
                                    },
                                },
                                {
                                    name: "Devices",
                                    key: "deviceIds",
                                    cellType: "customRender",
                                    render: (rowData, onChange) => {
                                        return <DeviceSelectionModal 
                                                    services={this.props.services} 
                                                    selectedSiteId={rowData.siteId} 
                                                    selectedDeviceIds={rowData.deviceIds}
                                                    onClose={(selectedDeviceIds) => {
                                                        onChange({newData: selectedDeviceIds})
                                                    }}
                                                />
                                    },
                                    colProps: {
                                        width: 150,
                                        resizable: true,
                                        sortable: false,
                                    },
                                },
                                {
                                    name: "Device Type",
                                    key: "deviceTypeId",
                                    cellType: "customRender",
                                    render: (rowData, onChange) => {
                                        var devices = this.props.services.assetFramework.getDevices().filter(x => x.site_id === rowData.siteId)
                                        var uniqDeviceTypeIds = _.uniq(devices.map(x => x.device_type))
                                        var deviceTypes = this.props.services.assetFramework.getDeviceTypes().filter(x => uniqDeviceTypeIds.includes(x._id))
                                        deviceTypes = [{_id: null, id: "None"}].concat(deviceTypes)

                                        return <ModalWrapper 
                                                    services={this.props.services} 
                                                    createSkeleton={(item, selectedItem) => {
                                                        return {
                                                            "key": item._id,
                                                            "label": item.id,
                                                            "selectable": true,
                                                            "meta": item,
                                                            "active": selectedItem === item._id,
                                                        }
                                                    }}
                                                    onSubmit={(selectedItem) => {
                                                        onChange({newData: selectedItem})
                                                    }}
                                                    items={deviceTypes}
                                                    selectedItems={null}
                                                    updateState={(curState, newItem) => {
                                                        return newItem
                                                    }}
                                                    menuProps={{
                                                        headerName: "Device Types",
                                                        retrievePayloadFunction: (x) => x.key,
                                                        className: "",
                                                        includeRadio: true,
                                                    }}
                                                />
                                    },
                                    colProps: {
                                        width: 130,
                                        resizable: true,
                                        sortable: false,
                                    },
                                }
                            ]
                        },
                        {
                            colGroup: true,
                            header: "Evaluation",
                            columns: [
                                cobjectTableConfig.inherit(),
                                cobjectTableConfig.type(),
                                cobjectTableConfig.function(),
                                cobjectTableConfig.code({services: this.props.services, selectedSiteId: this.props.selectedSiteId}),
                                cobjectTableConfig.range(),
                                cobjectTableConfig.rangedDataAccessMethod(),
                            ]
                        },
                        {
                            colGroup: true,
                            header: "Notification",
                            columns: [
                                {
                                    name: "Notification Enabled",
                                    key: "notificationEnabled",
                                    cellType: "dropdown",
                                    cellOptions: {
                                        dropdownItems: [{
                                            label: "true",
                                            value: true,
                                        }, {
                                            label: "false",
                                            value: false,
                                        }]
                                    },
                                    colProps: {
                                        width: 175,
                                        resizable: false,
                                    }
                                },
                                {
                                    name: "Notification Method",
                                    key: "notificationMethod",
                                    cellType: "dropdown",
                                    cellOptions: {
                                        dropdownItems: [{
                                            label: "none",
                                            value: null,
                                        },{
                                            label: "email",
                                            value: "email",
                                        }, {
                                            label: "text",
                                            value: "text",
                                        }],
                                    },
                                    colProps: {
                                        width: 175,
                                        resizable: false,
                                    }
                                },
                                {
                                    name: "Notification Cooldown",
                                    key: "notificationCooldown",
                                    cellType: "slider",
                                    cellOptions: {
                                        max: 24,
                                        min: 0,
                                        step: 1,
                                        label: "hours"
                                    },
                                    colProps: {
                                        width: 185,
                                        resizable: true,
                                    }
                                },
                                {
                                    name: "Notification Emails (Primary)",
                                    key: "notificationEmailsPrimary",
                                    cellType: "customRender",
                                    render: (rowData, onChange) => {
                                        var emails = rowData.notificationEmailsPrimary || []
                                        return <GenericFormModal 
                                                    services={this.props.services} 
                                                    initialFields={emails}
                                                    title="Enter Primary Emails"
                                                    onClose={(selectedEmails) => {
                                                        onChange({newData: selectedEmails})
                                                    }}
                                                />
                                    },
                                    colProps: {
                                        width: 250,
                                        resizable: true,
                                    }
                                },
                                {
                                    name: "Notification Emails (Secondary)",
                                    key: "notificationEmailsSecondary",
                                    cellType: "customRender",
                                    render: (rowData, onChange) => {
                                        var emails = rowData.notificationEmailsSecondary || []
                                        return <GenericFormModal 
                                                    services={this.props.services} 
                                                    initialFields={emails}
                                                    title="Enter Secondary Emails"
                                                    onClose={(selectedEmails) => {
                                                        onChange({newData: selectedEmails})
                                                    }}
                                                />
                                    },
                                    colProps: {
                                        width: 250,
                                        resizable: true,
                                    }
                                },
                            ]
                        },
                    ]
                }}
                endpoints={{
                    create: "/api/events/blueprint/create",
                    update: "/api/events/blueprint/update",
                    delete: "/api/events/blueprint/delete"
                }}
                onCreateSuccess={(newBlueprint) => {
                    this.props.services.assetFramework.addEventBlueprint(newBlueprint)
                }}
                onUpdateSuccess={(updatedBlueprint) => {
                    this.props.services.assetFramework.updateEventBlueprint(updatedBlueprint)
                }}
                onDeleteSuccess={(deletedBlueprint) => {
                    this.props.services.assetFramework.deleteEventBlueprint(deletedBlueprint)
                }}
                validateNewItem={(newItem) => {
                    var isCodeEmpty = newItem.code === ""
                    var isDescriptionEmpty = newItem.description === ""
                    if (isCodeEmpty || isDescriptionEmpty) {
                        return {pass: false, message: "Please fill in event code and description fields"}
                    }
                    return {pass: true}
                }}
            />
        );
        
    }

    getBlueprintSchema = () => {
        var selectedSite = this.props.services.assetFramework.getSites().find(x => x._id===this.props.selectedSiteId)
        var schema = {
            _id: null,
            enabled: true,
            siteId: this.props.selectedSiteId,
            siteName: selectedSite?.name || "N/A",
            deviceIds: [],
            deviceTypeId: null,
            templateId: null,
            templateCode: null,

            cobjectInherit: false,
            cobjectType: "standard",
            cobjectFunction: "evaluate",
            cobjectCode: "",
            cobjectRange: [0,0],
            cobjectRangedDataAccessMethod: null,

            notificationEnabled: false,
            notificationMethod: null,
            notificationCooldown: 0,
            notificationEmailsPrimary: [],
            notificationEmailsSecondary: [],
        }
        return schema
    }

    flattenBlueprint = (bp) => {
        var site = this.props.services.assetFramework.getSites().find(x => x._id===bp.assets?.site) || {}
        var eventTemplate = this.props.services.assetFramework.getEventTemplates().find(x => x._id===bp.template) || {}
        return {
            _id: bp._id,
            enabled: bp.enabled,
            siteId: bp.assets?.site,
            siteName: site?.name,
            deviceIds: bp.assets?.devices,
            deviceTypeId: bp.assets?.device_type,
            templateId: bp.template,
            templateCode: eventTemplate?.code,

            cobjectInherit: bp.cobject?.inherit,
            cobjectType: bp.cobject?.type,
            cobjectFunction: bp.cobject?.function,
            cobjectCode: bp.cobject?.code,
            cobjectRange: bp.cobject?.range,
            cobjectRangedDataAccessMethod: bp.cobject?.ranged_data_access_method,

            notificationEnabled: bp.notification?.enabled,
            notificationMethod: bp.notification?.method,
            notificationCooldown: bp.notification?.cooldown,
            notificationEmailsPrimary: bp.notification?.emails?.primary,
            notificationEmailsSecondary: bp.notification?.emails?.secondary
        }
    }

    structureBlueprint = (bpFlat) => {
        return {
            _id: bpFlat._id,
            enabled: bpFlat.enabled,
            template: bpFlat.templateId,
            assets: {
                site: bpFlat.siteId,
                devices: bpFlat.deviceIds,
                device_type: bpFlat.deviceTypeId,
            },
            cobject: {
                inherit: bpFlat.cobjectInherit,
                type: bpFlat.cobjectType,
                function: bpFlat.cobjectFunction,
                code: bpFlat.cobjectCode,
                range: bpFlat.cobjectRange,
                ranged_data_access_method: bpFlat.cobjectRangedDataAccessMethod
            },
            notification: {
                enabled: bpFlat.notificationEnabled,
                method: bpFlat.notificationMethod,
                cooldown: bpFlat.notificationCooldown,
                emails: {
                    primary: bpFlat.notificationEmailsPrimary,
                    secondary: bpFlat.notificationEmailsSecondary,
                }
            },
        }
    }

}

export default BlueprintConfigTable