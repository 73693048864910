import './RangeSliderCell.css'

import { useInputDispatch } from '../../../hooks/inputDispatch'

import { Table, RangeSlider } from 'rsuite'
const { Cell } = Table

export default function RangeSliderCell({rowData, dataIdKey, dataKey, onChange, cellOptions, isEditingFunction, ...props }) {

    var dispatch = useInputDispatch(10)

    var itemId = rowData[dataIdKey]

    var isEditing = isEditingFunction(itemId)

    var value = rowData[dataKey]
    var displayValue = "Disabled";
    var isDisabled = true
    if (Array.isArray(value)) {
        if (value.length===2) {
            isDisabled = value[0] === 0 && value[1] === 0
            var valueOnePrefix = cellOptions.getPrefix(value[0])
            var valueTwoPrefix = cellOptions.getPrefix(value[1])
            displayValue = (isDisabled ? "Disabled" : `${valueOnePrefix}${value[0]} to ${valueTwoPrefix}${value[1]} ${cellOptions.label}`)
        }
    }

    if (isDisabled) value = [0,0]

    var styles = (isEditing ? {textAlign: "center", position: "relative", top: "-5px"} : {})

    return (
        <Cell {...props} className={isEditing ? 'table-content-editing' : ''}>
            <div className="table-content-edit-span" style={styles}>{displayValue}</div>
            {isEditing && 
                <RangeSlider 
                    defaultValue={value}
                    max={cellOptions.max}
                    min={cellOptions.min}
                    step={cellOptions.step || 1}
                    tooltip={false}
                    onChange={event => {
                        let [v1, v2] = event
                        if (v1===value[0] && v2===value[1]) return
                        if(onChange) dispatch(() => onChange(rowData.pltr_table_id, dataKey, [v1, v2]));
                    }}
                />
            }
        </Cell>
    );
};