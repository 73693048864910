import {Component, useState} from 'react'

import DatePicker from '../../../date_picker/DateRangePicker'
import Panel from '../../../panel/Panel'
import StandardDataProvider from '../../../data_provider/StandardDataProvider'

import { formatDate } from '../../../../utils/Dates'
import { startOfDay, endOfDay } from 'date-fns'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { Whisper, Tooltip } from 'rsuite'

import MarketTable from './MarketTable'
import { useOutletContext } from 'react-router-dom'
import { getSite } from '../../../../utils/UrlLocation'

const SummaryContent = () => {

    const {assetFrameworkServices, authServices} = useOutletContext()

    const [selectedStartDate, setSelectedStartDate] = useState(formatDate(startOfDay(new Date())))
    const [selectedEndDate, setSelectedEndDate] = useState(formatDate(endOfDay(new Date())))

    const selectedSiteId = getSite()
    console.log(selectedSiteId)

    const services = {assetFramework: assetFrameworkServices, auth: authServices}

    const updateSelectedDates = (start, end) => {
        setSelectedStartDate(start)
        setSelectedEndDate(end)
    }


    if (!selectedSiteId) {
        return <Panel className="section-main">Please select a site</Panel>
    }

    // Tab is only available for sites with a battery
    var selectedSite = assetFrameworkServices.getSites().find(x => x._id===selectedSiteId)
    if (!["stand_alone_storage", "hybrid_solar_plus_storage", "storage", "hybrid_solar_storage"].includes(selectedSite.meta.technology)){
        return <Panel>This dashboard is only available for sites with a battery</Panel>
    }

    var MarketTableDataProvider = <StandardDataProvider
                                        services={services}
                                        selectedSiteId={selectedSiteId}
                                        selectedStartDate={selectedStartDate}
                                        selectedEndDate={selectedEndDate}
                                        queryProps={["selectedSiteId", "selectedStartDate", "selectedEndDate"]}
                                        queryUrl={'/api/market/table'}
                                        render={(data) => <MarketTable services={services} data={data} />}
                                    />

    const tooltip = <Tooltip>
                        <p style={{textAlign: 'left'}}>Table header numbers represent hours of the day. Note that these are hours <i>ending</i>.</p>
                        <p style={{textAlign: 'left'}}>All values are averaged over the time interval, except for Revenue which is summed.</p>
                        <p style={{textAlign: 'left'}}>Note that you may select a date range greater than 1 day, in which values will be averaged over the range.</p>
                        <div style={{marginTop: '10px'}}>
                            <div style={{display: 'flex', margin: '0 0 5px 0'}}>
                                <span style={{backgroundColor: 'var(--light)', height: '20px', width: '40px', display: 'block', marginRight: '5px'}}></span><span>Forecast</span>
                            </div>
                            <div style={{display: 'flex', margin: '0 0 5px 0'}}>
                                <span style={{backgroundColor: 'var(--accent)', height: '20px', width: '40px', display: 'block', marginRight: '5px'}}></span><span>Current</span>
                            </div>
                            <div style={{display: 'flex', margin: '0 0 5px 0'}}>
                                <span style={{backgroundColor: 'var(--light-dark)', height: '20px', width: '40px', display: 'block', marginRight: '5px'}}></span><span>Actual</span>
                            </div>
                        </div>
                    </Tooltip>

    return (
        <div className="section-main flow-vertical fill-parent">
            <div className="flow-horizontal flex-fixed" style={{alignItems: 'center'}}>
                <div className="fill-parent"></div>
                <span style={{flexShrink: 0, fontSize: '1.4em'}}>Market Table</span>
                <div className="fill-parent"></div>
            </div>
            <div className="flow-horizontal flex-fixed" style={{alignItems: 'center'}}>
                <div className="fill-parent"></div>
                <DatePicker 
                    services={services}
                    startDate={selectedStartDate}
                    endDate={selectedEndDate}
                    onValueChange={updateSelectedDates}
                />
                <div className="fill-parent"></div>
                <Whisper placement="leftStart" trigger="hover" speaker={tooltip}>
                    <AiOutlineQuestionCircle size={'3em'} />
                </Whisper>
            </div>
            {MarketTableDataProvider}
        </div>
    )

}

export default SummaryContent