import './TaxEquity.css'
import React, { useState } from 'react'

import ViewPanel from '../../ViewPanel'
import { 
    PalantirSelector,
    PalantirDatePicker,
    PalantirBooleanSelector
} from '../../../../components/input/SelectPicker'
import { PalantirTextField, PalantirDispatchedTextField, makeTextMaskNumber } from '../../../../components/input/Text'
import { TaxEquityContract, TaxEquityContractCounterparties, TaxEquityLLCBanks, TaxEquityLLC } from '../../../table_configuration/TaxEquityPartner'
import { useChangeLog, injectedChangeLogIdCol } from '../../../../hooks/changeLog'
import { useDBViewFormValidation } from '../../../../hooks/databaseViewFormValidation'
import { AddFloaterButtonWithPrompt } from '../../../../components/button/FloaterButtonWithPrompt'
import ContractCounterparties from '../ContractCounterparties'
import { Bank } from '../../../table_configuration/Counterparty'
import { Counterparty } from '../../../table_configuration/Entity'
import ContractHeader from '../ContractHeader'
import ContractIdentification from '../ContractIdentification'
import { getContractCounterpartyData, validateCreateContractCounterparty, buildContractNameErrorConfig, buildContractDependencyErrorPath } from '../utils'
import { generateUUID, ErrorRND, ViewFormError } from '../../../../utils/databaseAppUtils'
import { LLCWithCounterpartyFilter } from '../ContractBaseFields'

import _ from 'lodash'
import { Plant } from '../../../table_configuration/Project'

const injectedCommercialOperationDateCurrent = "injectedCODCurrent"
const taxEquityIdCol = TaxEquityContractCounterparties.columnSchema.taxEquityId
const injectedTaxEquityName = "taxEquityName"

const TextMaskTermYears = makeTextMaskNumber({
    min: 0
})

/**
 * Proxy component for TaxEquityPanel.
 * @param {*} props 
 * @returns 
 */
export default function TaxEquityPanelProxy(props) {

    const contractRecords = props.data[TaxEquityContract.buildId()]
    const contractCounterpartyRecords = props.data[TaxEquityContractCounterparties.buildId()]
    const taxEquityLLCRecords = props.data[TaxEquityLLC.buildId()]
    const taxEquityLLCBankRecords = props.data[TaxEquityLLCBanks.buildId()]
    const bankRecords = props.data[Bank.buildId()]

    const [filteredToPlantContractRecords, filteredContractCounterpartyRecords] = getContractCounterpartyData(
        contractRecords, contractCounterpartyRecords, bankRecords,
        props.selectedPlantId,
        TaxEquityContract.columnSchema.contractId, TaxEquityContract.columnSchema.plantId,
        TaxEquityContractCounterparties.columnSchema.contractId, TaxEquityContractCounterparties.columnSchema.bankId, injectedTaxEquityName,
    )

    return (
        <TaxEquityPanel
            taxEquityContractRecords={filteredToPlantContractRecords}
            taxEquityContractCounterpartyRecords={filteredContractCounterpartyRecords}
            allBankRecords={bankRecords}
            taxEquityLLCRecords={taxEquityLLCRecords}
            taxEquityLLCBankRecords={taxEquityLLCBankRecords}
            validateCreateContractCounterparty={(record, newRecord) => validateCreateContractCounterparty(TaxEquityContractCounterparties, TaxEquityContractCounterparties.columnSchema.bankId, record, newRecord)}
            {...props}
        />
    )

}

/**
 * Main stateful TaxEquityPanel component.
 * @param {*} props 
 * @returns 
 */
function TaxEquityPanel(props) {

    const [
        taxEquityContractRecords, prepareContractLog,
        updateContractLog, addToContractLog, deleteFromContractLog, bulkOpOnContractLog, mergeAndResetContractLog
    ] = useChangeLog(props.taxEquityContractRecords, TaxEquityContract)
    const [
        taxEquityContractCounterpartiesRecords, prepareCounterpartyLog,
        updateCounterpartyLog, addToCounterpartyLog, deleteFromCounterpartyLog, bulkOpOnCounterpartyLog, mergeAndResetCounterpartyLog
    ] = useChangeLog(props.taxEquityContractCounterpartyRecords, TaxEquityContractCounterparties, props.validateCreateContractCounterparty)
    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()

    const addToContractLogProxy = () => {
        let contract = TaxEquityContract.buildNewRecord()
        contract[TaxEquityContract.columnSchema.contractId] = generateUUID()
        contract[TaxEquityContract.columnSchema.plantId] = props.selectedPlantId
        addToContractLog(contract)
    }
    const deleteFromContractLogProxy = (taxEquityContract) => {
        // Delete all counterparty records associated with this contract, if any
        let contractId = taxEquityContract[TaxEquityContract.columnSchema.contractId]
        let counterpartiesInDeletedContract = taxEquityContractCounterpartiesRecords.filter(record => record[TaxEquityContractCounterparties.columnSchema.contractId]===contractId)
        deleteFromCounterpartyLog(counterpartiesInDeletedContract)
        deleteFromContractLog(taxEquityContract)
        removeErrors([contractId])
    }

    const taxEquityContractComponents = taxEquityContractRecords.map(taxEquityContract => {
        let contractId = taxEquityContract[TaxEquityContract.columnSchema.contractId]
        let taxEquityCounterparties = taxEquityContractCounterpartiesRecords.filter(x => x[TaxEquityContractCounterparties.columnSchema.contractId]===contractId)
        return (
            <TaxEquityContractForm
                key={contractId}
                taxEquityContract={taxEquityContract}
                contractId={contractId}

                allTaxEquityLLCs={props.taxEquityLLCRecords}
                allBankRecords={props.allBankRecords}
                taxEquityCounterparties={taxEquityCounterparties}
                taxEquityLLCBankRecords={props.taxEquityLLCBankRecords}

                updateContract={(update) => updateContractLog(taxEquityContract, update)}
                updateContractCounterparty={updateCounterpartyLog}
                addToCounterpartyLog={() => {
                    let contractCounterparty = TaxEquityContractCounterparties.buildNewRecord()
                    contractCounterparty[TaxEquityContractCounterparties.columnSchema.contractId] = contractId
                    addToCounterpartyLog(contractCounterparty)
                }}
                deleteFromContractLog={() => deleteFromContractLogProxy(taxEquityContract)}
                deleteFromCounterpartyLog={deleteFromCounterpartyLog}
                errors={errors}
                setErrors={setErrors}
                removeErrors={removeErrors}
            />
        )
    })

    return (
        <ViewPanel
            services={props.services}
            title="TaxEquity Contract"
            submitUrl='api/precious/table/tax_equity_contract'
            verifySubmit={(payload) => {
                return verifyChangelogSubmission(
                    {
                        changeLog: payload.taxEquityContractChangelog,
                        checks: [buildContractNameErrorConfig(TaxEquityContract)]
                    }, {
                        changeLog: payload.taxEquityContractCounterpartyChangelog,
                        checks: [{
                            checkColumn: TaxEquityContractCounterparties.columnSchema.bankId,
                            checkFunction: "nullCheck",
                            errMessage: "Please select a bank.",
                            path: buildContractDependencyErrorPath(TaxEquityContractCounterparties, TaxEquityContractCounterparties.columnSchema.bankId),
                        }]
                    }
                )
            }}
            onSubmitSuccess={(response, requestPayload) => {
                props.handleUpdate(false)
                resetErrors()
                mergeAndResetContractLog()
                mergeAndResetCounterpartyLog()
            }}
            onSubmitError={null}
            buildSubmitPayload={() => {
                return {
                    taxEquityContractChangelog: prepareContractLog(),
                    taxEquityContractCounterpartyChangelog: prepareCounterpartyLog(),
                }
            }}
        >
            {taxEquityContractComponents}
            <AddFloaterButtonWithPrompt
                onClick={addToContractLogProxy}
                labelContent="Add new contract"
                height={18}
                width={18}
            />
        </ViewPanel>
    )
}

/**
 * 
 */
const TaxEquityContractForm = React.memo(function({
    taxEquityContract, contractId, taxEquityCounterparties, allTaxEquityLLCs, taxEquityLLCBankRecords, allBankRecords,
    updateContract, updateContractCounterparty, addToCounterpartyLog, deleteFromCounterpartyLog, deleteFromContractLog, errors, setErrors, removeErrors
}) {

    const taxEquityLLCId = taxEquityContract[TaxEquityContract.columnSchema.taxEquityLLCId]

    // These are the banks that belong to the selected debt partner llc
    const availableBankJunctionRecords = taxEquityLLCBankRecords.filter(r => r[TaxEquityLLCBanks.columnSchema.taxEquityId]===taxEquityLLCId)
    const availableBankIds = availableBankJunctionRecords.map(x => x[TaxEquityLLCBanks.columnSchema.bankId])
    const availableBanks = allBankRecords.filter(bank => availableBankIds.includes(bank[Counterparty.columnSchema.counterpartyId]))
    
    return (
        <div className="field-group form-instance" style={{scroll: "auto", marginBottom: "100px", minWidth: "1200px"}}>
            <ContractHeader
                contractName={taxEquityContract[TaxEquityContract.columnSchema.contractName]}
                deleteFromContractLog={deleteFromContractLog}
            />
            <ContractIdentification
                ContractTable={TaxEquityContract}
                contract={taxEquityContract}
                contractIdCol={TaxEquityContract.columnSchema.contractId}
                contractNameCol={TaxEquityContract.columnSchema.contractName}
                contractPlantIdCol={TaxEquityContract.columnSchema.plantId}
                onContractNameChange={(x) => updateContract({[TaxEquityContract.columnSchema.contractName]: x})}
                errors={errors}
                removeErrors={removeErrors}
            />
            <PalantirSelector
                label="Status"
                value={taxEquityContract[TaxEquityContract.columnSchema.status]}
                items={TaxEquityContract.options.status}
                onChange={(x) => updateContract({[TaxEquityContract.columnSchema.status]: x})}
                style={{marginRight: "20px"}}
            />
            <PalantirDatePicker
                label="Effective Date"
                value={taxEquityContract[TaxEquityContract.columnSchema.effectiveDate]}
                onChange={(date) => updateContract({[TaxEquityContract.columnSchema.effectiveDate]: date})}
                style={{marginRight: "15px", marginBottom: "15px"}}
            />
            <PalantirDatePicker
                label="End Date"
                value={taxEquityContract[TaxEquityContract.columnSchema.endDate]}
                onChange={(date) => updateContract({[TaxEquityContract.columnSchema.endDate]: date})}
                style={{marginRight: "15px", marginBottom: "15px"}}
            />
            <br></br>
            <PalantirDispatchedTextField
                label="Notes"
                value={taxEquityContract[TaxEquityContract.columnSchema.notes]}
                multiline
                rows={3}
                //fullWidth
                variant="filled"
                onChange={(x) => updateContract({[TaxEquityContract.columnSchema.notes]: x})}
                style={{marginTop: "15px", marginBottom: "15px"}}
            />
            <div>
                <div className="header">Tax Equity</div>
                <div className="flow-horizontal" style={{flexWrap: "wrap"}}>
                    <div style={{flexShrink: 0}}>
                        <PalantirDatePicker
                            label="Expected Financial Close Date"
                            value={taxEquityContract[TaxEquityContract.columnSchema.expectedFinancialCloseDate]}
                            onChange={(date) => updateContract({[TaxEquityContract.columnSchema.expectedFinancialCloseDate]: date})}
                            style={{marginRight: "15px", marginBottom: "15px"}}
                        />
                        <PalantirDatePicker
                            label="Actual Financial Close Date"
                            value={taxEquityContract[TaxEquityContract.columnSchema.actualFinancialCloseDate]}
                            onChange={(date) => updateContract({[TaxEquityContract.columnSchema.actualFinancialCloseDate]: date})}
                            style={{marginRight: "15px", marginBottom: "15px"}}
                        />
                    </div>
                    <div style={{flexShrink: 0}}>
                        <PalantirDatePicker
                            label="Expected Flip Date"
                            value={taxEquityContract[TaxEquityContract.columnSchema.expectedFlipDate]}
                            onChange={(date) => updateContract({[TaxEquityContract.columnSchema.expectedFlipDate]: date})}
                            style={{marginRight: "15px", marginBottom: "15px"}}
                        />
                        <PalantirDatePicker
                            label="Actual Flip Date"
                            value={taxEquityContract[TaxEquityContract.columnSchema.actualFlipDate]}
                            onChange={(date) => updateContract({[TaxEquityContract.columnSchema.actualFlipDate]: date})}
                            style={{marginRight: "15px", marginBottom: "15px"}}
                        />
                    </div>
                    <div style={{flexShrink: 0}}>
                        <PalantirDatePicker
                            label="Expected Buy Out Date"
                            value={taxEquityContract[TaxEquityContract.columnSchema.expectedBuyOutDate]}
                            onChange={(date) => updateContract({[TaxEquityContract.columnSchema.expectedBuyOutDate]: date})}
                            style={{marginRight: "15px", marginBottom: "15px"}}
                        />
                        <PalantirDatePicker
                            label="Actual Buy Out Date"
                            value={taxEquityContract[TaxEquityContract.columnSchema.actualBuyOutDate]}
                            onChange={(date) => updateContract({[TaxEquityContract.columnSchema.actualBuyOutDate]: date})}
                            style={{marginRight: "15px", marginBottom: "15px"}}
                        />
                    </div>
                </div>
                <div style={{marginBottom: "15px"}}>
                    <PalantirTextField
                        label="Sharepoint Contract Folder Link"
                        value={taxEquityContract[TaxEquityContract.columnSchema.sharepointLink]}
                        helperText="This should be the folder link, not the file."
                        onChange={(x) => updateContract({[TaxEquityContract.columnSchema.sharepointLink]: x})}
                    />
                    <div style={{marginTop: "4px"}}><a href={taxEquityContract[TaxEquityContract.columnSchema.sharepointLink]} target="_blank">Click here to visit Sharepoint link</a></div>
                </div>
                <LLCWithCounterpartyFilter
                    contractId={contractId}
                    contractRecord={taxEquityContract}
                    contractLLCIdCol={TaxEquityContract.columnSchema.taxEquityLLCId}
                    ContractCounterpartyTable={TaxEquityContractCounterparties}
                    contractCounterpartyRecords={taxEquityCounterparties}
                    contractCounterpartyIdCol={TaxEquityContractCounterparties.columnSchema.bankId}
                    llcIdCol={TaxEquityLLC.columnSchema.taxEquityId}
                    llcNameCol={TaxEquityLLC.columnSchema.taxEquityName}
                    allLLCRecords={allTaxEquityLLCs}
                    llcCounterpartyJunctionRecords={taxEquityLLCBankRecords}
                    llcCounterpartyJunctionLLCIdCol={TaxEquityLLCBanks.columnSchema.taxEquityLLCId}
                    llcCounterpartyJunctionCounterpartyIdCol={TaxEquityLLCBanks.columnSchema.bankId}
                    llcLabel="Tax Equity LLC"
                    counterpartyLabel="Bank"
                    errors={errors}
                    setErrors={setErrors}
                    updateContract={updateContract}
                />
                <ContractCounterparties
                    ContractCounterpartyTable={TaxEquityContractCounterparties}
                    contract={taxEquityContract}
                    contractIdCol={TaxEquityContract.columnSchema.contractId}
                    contractCounterpartyIdCol={TaxEquityContractCounterparties.columnSchema.bankId}
                    contractCounterpartyNameCol={injectedTaxEquityName}
                    contractCounterpartyNotesCol={TaxEquityContractCounterparties.columnSchema.notes}
                    allCounterpartyRecords={availableBanks}
                    filteredContractCounterpartyRecords={taxEquityCounterparties}
                    deleteFromCounterpartyLog={deleteFromCounterpartyLog}
                    addToCounterpartyLog={addToCounterpartyLog}
                    updateContractCounterparty={updateContractCounterparty}
                    SupplementalComponent={({contractCounterpartyRecord}) => {
                        return (
                            <PalantirSelector
                                label="Financing Status"
                                value={contractCounterpartyRecord[TaxEquityContractCounterparties.columnSchema.financingStatus]}
                                items={TaxEquityContractCounterparties.options.status}
                                onChange={(x) => updateContractCounterparty(contractCounterpartyRecord, {[TaxEquityContractCounterparties.columnSchema.financingStatus]: x})}
                            />
                        )
                    }}
                    counterpartyType="Tax Equity"
                    counterpartyTypePlural="Tax Equity"
                    orientation="horizontal"
                    excludeHeader={true}
                    errors={errors}
                    removeErrors={removeErrors}
                    style={{paddingBottom: "20px"}}
                />
            </div>
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        _.isEqual(prevProps.taxEquityContract, nextProps.taxEquityContract) &&
        _.isEqual(prevProps.taxEquityCounterparties, nextProps.taxEquityCounterparties) &&
        _.isEqual(prevProps.errors, nextProps.errors)
    )
})