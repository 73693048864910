import './TagMenu.css'
import React, {Component} from 'react'

import MenuContainer, { ITEM_SELECTION_TYPES } from '../menu/MenuContainer'
import { Switch } from '@mantine/core';


class TagMenu extends Component {

    constructor(props) {
        super()
        this.state = {
            
        }
    }

    render() {

        var selectedTagIds = this.props.selectedTagIds

        var tagMenuItems = this.props.tags.map(x => Object({
            "key": x._id,
            "label": x.name,
            "meta": x,
            "selectable": true,
            "active": selectedTagIds.includes(x["_id"]),
        }))

        return(
            <MenuContainer
                headerName={this.props.menuTitle || "Tag Menu"}
                menuSkeleton={tagMenuItems} 
                leafNodeClick={this.onTagClick}
                className={"tag-menu"+(this.props.className || "")}
                itemSelectionType={ITEM_SELECTION_TYPES.checkbox}
                searchable
                style={this.props.style || {}}
                peripheralsConfig={{
                    enabled: true,
                    disableCheckAllOption: true,
                    disableUncheckAllOption: true,
                    options: [{
                        name: "Uncheck All",
                        callback: this.props.toggleShowOnlyHighPrioTags,
                        content: <Switch checked={this.props.showOnlyHighPrioTags} label="Show only high prio" />
                    }]
                }}
            />
        )
    }

    onTagClick = (menuItem) => {
        const tagId = menuItem.meta["_id"]
        const selectedTagIds = Object.assign([], this.props.selectedTagIds)
        var curIdx = selectedTagIds.indexOf(tagId)
        curIdx===-1 ? selectedTagIds.push(tagId) : selectedTagIds.splice(curIdx, 1)
        this.props.onTagClick(selectedTagIds, tagId)
    }

}

export default TagMenu