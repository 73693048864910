import './login.css'
import React, { useState } from 'react';
import { BackgroundImage, Button, Card, Alert, TextInput } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { prefixPathWithAPIDomain } from '../../utils/image'


const LoginForm = ({login, loginFailed, loginFailedReason}) => {

    const [username, setUsername] = useState(null)
    const [password, setPassword] = useState(null)


    const generateLoginFailedText = (loginFailedReason) => {
        if (loginFailedReason==="auth") return "Invalid username and password combination."
        else return "Sorry. Your login attempt has failed. Please contact an admin."
    }

    return (
        <BackgroundImage
            src={prefixPathWithAPIDomain("/static/login1.jpg")}
            className="login-bg-image flow-vertical fill-parent"
        >
            <Card className="login-modal flow-vertical" size="sm">
                <div className="logo-image-container">
                    <img className="logo-image" src={prefixPathWithAPIDomain("/static/arevon_logo_large.png")}></img>
                </div>
                <div className="login-greeting">Welcome to Palantir!</div>

                {loginFailed &&
                    <Alert color="red" className="login-error-alert">
                        {generateLoginFailedText(loginFailedReason)}
                    </Alert>
                }
                <TextInput
                    label="Username"
                    type="email"
                    className="login-username"
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                />
                <TextInput
                    label="Password"
                    type="password"
                    className="login-password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    mt={15}
                />
                
                <Button
                    size="lg"
                    className="login-btn"
                    rightIcon={<FontAwesomeIcon icon={faArrowCircleRight} />}
                    onClick={() => login(username, password)}
                >
                    Login
                </Button>
            </Card>
        </BackgroundImage>
    )
}

export default LoginForm