import { schemas } from '../Constants'
import { transformDisplayValue, buildDropdownItems } from '../../utils/databaseAppUtils'
import Table from './BaseTable'
import { makePlantId, makeNotes, makeEntityId, booleanOptions } from './CommonColumns'
import { 
    injectCounterpartyName, 
    Developer, EPC, Interconnection, OEM, OandM, Offtake, SchedulingCoordinator, Permitter, Landowner
} from './Counterparty'
import { Contact, Counterparty } from './Entity'
import { LandownerLLC, OandMLLC } from './LLC'
import { Plant } from './Project'


export const contractStatusOptions = [{
    label: "Executed",
    value: "Complete"
}, {
    label: "Started",
    value: "Started",
}]

export const makeContractID = (key) => {
    return {
        name: "ID",
        key: key,
        cellType: "display",
        filterable: true,
        sortable: true,
        colProps: {
            width: 100,
            fixed: "left",
            resizable: true,
        },
    }
}
export const makeContractName = (key) => {
    return {
        name: "Name",
        key: key,
        cellType: "editable",
        filterable: true,
        sortable: true,
        colProps: {
            width: 200,
            fixed: "left",
            resizable: true,
        },
    }
}
export const buildGetReferencedPlantId = (sourceTable, contractTable) => {
    return (record, data) => {
        var contractRecords = sourceTable.findTableObjectRecords(data, contractTable)
        var contract = contractRecords.find(x => x[contractTable.columnSchema.contractId]===record[sourceTable.columnSchema.contractId]) || {}
        return contract[contractTable.columnSchema.plantId]
    }
}


class DeveloperContractTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            contractName: "contract_name",
            plantId: "plant_id",
            sharepointLink: "sharepoint_link",
            notes: "notes",
        }

        var schemaName = schemas.contract
        var tableName = "developer"

        var displayNameSingular = "Developer Contract"
        var displayNamePlural = "Developer Contracts"

        var pkUidColumn = columnSchema.contractId
        var identifiers = [columnSchema.contractId]
        var dependencies = [Plant]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)

        const validateContract = (contract) => {
            if (!contract[this.columnSchema.plantId]) return {pass: false, message: "Please give the contract a plant."}
            if (!contract[this.columnSchema.contractName]) return {pass: false, message: "Please give the contract a unique name."}
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateContract,
            validateUpdateItem: validateContract,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeContractID(this.columnSchema.contractId),
                    makeContractName(this.columnSchema.contractName),
                    makePlantId(plantRecords, Plant.columnSchema.plantId, Plant.columnSchema.plantName, false), {
                        name: "Sharepoint Folder Link",
                        key: this.columnSchema.sharepointLink,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes, 1),
                ]
            },
        }
    }

}
export const DeveloperContract = new DeveloperContractTable()

class DeveloperCounterpartiesContractTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            developerId: "developer_id",
            notes: "notes",
        }

        var schemaName = schemas.contract
        var tableName = "developer_counterparties"

        var displayNameSingular = "Developer Counterparty"
        var displayNamePlural = "Developer Counterparties"

        var pkUidColumn = null
        var identifiers = [columnSchema.contractId, columnSchema.developerId]
        var dependencies = [DeveloperContract, Developer]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

    }

    getReferencedPlantId = buildGetReferencedPlantId(this, DeveloperContract)

    buildTableProps = (data) => {

        var contractRecords = this.findTableObjectRecords(data, DeveloperContract)
        var developerRecords = this.findTableObjectRecords(data, Developer)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        var contractIdCol = DeveloperContract.columnSchema.contractId
        var contractNameCol = DeveloperContract.columnSchema.contractName
        var developerIdCol = Developer.columnSchema.developerId
        var developerNameCol = "developerName"

        developerRecords = injectCounterpartyName(developerRecords, counterpartyRecords, developerIdCol, developerNameCol)

        const validateContract = (contract) => {
            if (!contract[this.columnSchema.contractId]) return {pass: false, message: "Please add a contract reference."}
            if (!contract[this.columnSchema.developerId]) return {pass: false, message: "Please add a developer reference."}
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateContract,
            validateUpdateItem: validateContract,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    {
                        name: "Contract",
                        key: this.columnSchema.contractId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contractRecords, value, contractIdCol, contractNameCol),
                            dropdownItems: buildDropdownItems(contractRecords, contractIdCol, contractNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 350,
                            resizable: true,
                        }
                    },
                    {
                        name: "Developer",
                        key: this.columnSchema.developerId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(developerRecords, value, developerIdCol, developerNameCol),
                            dropdownItems: buildDropdownItems(developerRecords, developerIdCol, developerNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes),
                ]
            },
        }
    }

}
export const DeveloperCounterpartiesContract = new DeveloperCounterpartiesContractTable()

class EPCContractTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            contractName: "contract_name",
            plantId: "plant_id",
            status: "status",
            effectiveDate: "effective_date",
            endDate: "end_date",
            termYears: "term_years",
            ownerProvidedEquipment: "owner_provided_equipment",
            rfpStartCurrent: "rfp_start__current",
            rfpStartBudgeted: "rfp_start__budgeted",
            rfpEndCurrent: "rfp_end__current",
            rfpEndBudgeted: "rfp_end__budgeted",
            fntpCurrent: "final_notice_to_proceed__current",
            fntpBudgeted: "final_notice_to_proceed__budgeted",
            startOfConstructionCurrent: "start_of_construction__current",
            startOfConstructionBudgeted: "start_of_construction__budgeted",
            mechanicalCompletionCurrent: "mechanical_completion__current",
            mechanicalCompletionBudgeted: "mechanical_completion__budgeted",
            mechanicalCompletionGuaranteed: "mechanical_completion__guaranteed",
            substantialCompletionCurrent: "substantial_completion__current",
            substantialCompletionBudgeted: "substantial_completion__budgeted",
            substantialCompletionGuaranteed: "substantial_completion__guaranteed",
            finalCompletionCurrent: "final_completion__current",
            finalCompletionBudgeted: "final_completion__budgeted",
            finalCompletionGuaranteed: "final_completion__guaranteed",
            placedInServiceCurrent: "placed_in_service__current",
            placedInServiceBudgeted: "placed_in_service__budgeted",
            placedInServiceGuaranteed: "placed_in_service__guaranteed",
            sharepointLink: "sharepoint_link",
            notes: "notes",
        }

        var schemaName = schemas.contract
        var tableName = "epc"

        var displayNameSingular = "EPC Contract"
        var displayNamePlural = "EPC Contracts"

        var pkUidColumn = columnSchema.contractId
        var identifiers = [columnSchema.contractId]
        var dependencies = [Plant]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.initOptions()
    }

    buildNewRecord() {
        var newRecord = super.buildNewRecord.call(this)
        newRecord[this.columnSchema.ownerProvidedEquipment] = 0
        return newRecord
    }

    initOptions = () => {
        this.options = {
            status: [{
                label: "Not Started", value: "Not Started"
            }, {
                label: "RFI", value: "RFI"
            }, {
                label: "RFP", value: "RFP"
            }, {
                label: "In Negotiation", value: "In Negotiation"
            }, {
                label: "Executed", value: "Executed"
            }, {
                label: "Started (Depracated)", value: "Started"
            }, {
                label: "Complete (Depracated)", value: "Complete"
            }],
            ownerProvidedEquipment: booleanOptions
        }
    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)

        const validateContract = (contract) => {
            if (!contract[this.columnSchema.plantId]) return {pass: false, message: "Please give the contract a plant."}
            if (!contract[this.columnSchema.contractName]) return {pass: false, message: "Please give the contract a unique name."}
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateContract,
            validateUpdateItem: validateContract,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeContractID(this.columnSchema.contractId),
                    makeContractName(this.columnSchema.contractName),
                    makePlantId(plantRecords, Plant.columnSchema.plantId, Plant.columnSchema.plantName, false),
                    {
                        name: "Status",
                        key: this.columnSchema.status,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.status,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Term (years)",
                        key: this.columnSchema.termYears,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: false,
                        sortable: false,
                        colProps: {
                            width: 100,
                        }
                    }, {
                        name: "Effective Date",
                        key: this.columnSchema.effectiveDate,
                        cellType: "date",
                        cellOptions: {
                            timezone: "UTC",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "End Date",
                        key: this.columnSchema.endDate,
                        cellType: "date",
                        cellOptions: {
                            timezone: "UTC",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Owner Provided Equipment",
                        key: this.columnSchema.ownerProvidedEquipment,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "RFP Start Date (Current)",
                        key: this.columnSchema.rfpStartCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "RFP Start Date (Budgeted)",
                        key: this.columnSchema.rfpStartBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "RFP End Date (Current)",
                        key: this.columnSchema.rfpEndCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "RFP End Date (Budgeted)",
                        key: this.columnSchema.rfpEndBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "FNTP Date (Current)",
                        key: this.columnSchema.fntpCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "FNTP Date (Budgeted)",
                        key: this.columnSchema.fntpBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Start of Construction (Current)",
                        key: this.columnSchema.startOfConstructionCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Start of Construction (Budgeted)",
                        key: this.columnSchema.startOfConstructionBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Mechanical Completion (Current)",
                        key: this.columnSchema.mechanicalCompletionCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Mechanical Completion (Budgeted)",
                        key: this.columnSchema.mechanicalCompletionBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Mechanical Completion (Guaranteed)",
                        key: this.columnSchema.mechanicalCompletionGuaranteed,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Substantial Completion (Current)",
                        key: this.columnSchema.substantialCompletionCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Substantial Completion (Budgeted)",
                        key: this.columnSchema.substantialCompletionBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Substantial Completion (Guaranteed)",
                        key: this.columnSchema.substantialCompletionGuaranteed,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Final Completion (Current)",
                        key: this.columnSchema.finalCompletionCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Final Completion (Budgeted)",
                        key: this.columnSchema.finalCompletionBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Final Completion (Guaranteed)",
                        key: this.columnSchema.finalCompletionGuaranteed,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Placed in Service (Current)",
                        key: this.columnSchema.placedInServiceCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Placed in Service (Budgeted)",
                        key: this.columnSchema.placedInServiceBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Placed in Service (Guaranteed)",
                        key: this.columnSchema.placedInServiceGuaranteed,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Sharepoint Folder Link",
                        key: this.columnSchema.sharepointLink,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes),
                ]
            },
        }
    }

}
export const EPCContract = new EPCContractTable()

class EPCCounterpartiesContractTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            epcId: "epc_id",
            bidderStatus: "bidder_status",
            notes: "notes",
        }

        var schemaName = schemas.contract
        var tableName = "epc_counterparties"

        var displayNameSingular = "EPC Counterparty"
        var displayNamePlural = "EPC Counterparties"

        var pkUidColumn = null
        var identifiers = [columnSchema.contractId, columnSchema.epcId]
        var dependencies = [EPCContract, EPC]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.initOptions()
    }

    initOptions = () => {
        this.options = {
            bidderStatus: [{
                label: "Bidder", value: "Bidder"
            }, {
                label: "Selected", value: "Selected"
            }]
        }
    }

    getReferencedPlantId = buildGetReferencedPlantId(this, EPCContract)

    buildTableProps = (data) => {

        var contractRecords = this.findTableObjectRecords(data, EPCContract)
        var epcRecords = this.findTableObjectRecords(data, EPC)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        var contractIdCol = EPCContract.columnSchema.contractId
        var contractNameCol = EPCContract.columnSchema.contractName
        var epcIdCol = EPC.columnSchema.epcId
        var epcNameCol = "epcName"

        epcRecords = injectCounterpartyName(epcRecords, counterpartyRecords, epcIdCol, epcNameCol)

        const validateContract = (contract) => {
            if (!contract[this.columnSchema.contractId]) return {pass: false, message: "Please add a contract reference."}
            if (!contract[this.columnSchema.epcId]) return {pass: false, message: "Please add an EPC reference."}
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateContract,
            validateUpdateItem: validateContract,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    {
                        name: "Contract",
                        key: this.columnSchema.contractId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contractRecords, value, contractIdCol, contractNameCol),
                            dropdownItems: buildDropdownItems(contractRecords, contractIdCol, contractNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 350,
                            resizable: true,
                        }
                    }, {
                        name: "EPC",
                        key: this.columnSchema.epcId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(epcRecords, value, epcIdCol, epcNameCol),
                            dropdownItems: buildDropdownItems(epcRecords, epcIdCol, epcNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    }, {
                        name: "Bidder Status",
                        key: this.columnSchema.bidderStatus,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.bidderStatus
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes),
                ]
            },
        }
    }

}
export const EPCCounterpartiesContract = new EPCCounterpartiesContractTable()

class InterconnectionContractTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            contractName: "contract_name",
            plantId: "plant_id",
            status: "status",
            queueId: "queue_id",
            ISOAccountingId: "iso_accounting_id",
            ISOApplicationSubmissionId: "iso_application_submission_id",
            transmissionOwnerCompletionDate: "transmission_owner_completion_date",
            siteExclusivityStatus: "site_exclusivity_status",
            sharedFacilityAgreementRequired: "shared_facility_agreement_required",
            sharedFacilityNotes: "shared_facility_notes",
            nativeOrAttainingBalancingAuth: "native_or_attaining_balancing_auth",
            effectiveDate: "effective_date",
            interconnectionCOD: "interconnection_cod",
            applicationCOD: "application_cod",
            CODCliffDate: "cod_cliff_date",
            poiLocation: "poi_location",
            poiLatitude: "poi_latitude",
            poiLongitude: "poi_longitude",
            poiMaxACInjectionMW: "poi_max_ac_injection__mw",
            poiDeliverableCapacityMW: "poi_deliverable_capacity__mw",
            poiGridVoltage: "poi_grid_voltage__kv",
            powerFactorRequirements: "power_factor_requirements",
            genTieLength: "gen_tie_length",
            sharepointLink: "sharepoint_link",
            notes: "notes",
        }

        var schemaName = schemas.contract
        var tableName = "interconnection"

        var displayNameSingular = "Interconnection Contract"
        var displayNamePlural = "Interconnection Contracts"

        var pkUidColumn = columnSchema.contractId
        var identifiers = [columnSchema.contractId]
        var dependencies = [Plant]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.initOptions()
    }

    initOptions = () => {
        this.options = {
            status: [{
                label: "No Queue Position", value: "No Queue Position"
            }, {
                label: "Interconnection Request", value: "Interconnection Request"
            }, {
                label: "Feasibility Study", value: "Feasibility Study"
            }, {
                label: "System Impact Study", value: "System Impact Study"
            }, {
                label: "Facility Study", value: "Facility Study"
            }, {
                label: "Draft LGIA", value: "Draft LGIA"
            }, {
                label: "LGIA", value: "LGIA"
            }, {
                label: "Withdrawn", value: "Withdrawn"
            }],
            exclusivityStatus: [{
                label: "Site Control Evidence Provided", value: "Site Control Evidence Provided"
            }, {
                label: "Financial Deposit", value: "Financial Deposit"
            }],
            nativeOrAttainingBalancingAuth: [{
                label: "Pseudo Tie", value: "Pseudo Tie"
            }, {
                label: "Dynamic Schedule", value: "Dynamic Schedule"
            }, {
                label: "Native Balancing Authority", value: "Native Balancing Authority"
            }]
        }
    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)

        const validateContract = (contract) => {
            if (!contract[this.columnSchema.plantId]) return {pass: false, message: "Please give the contract a plant."}
            if (!contract[this.columnSchema.contractName]) return {pass: false, message: "Please give the contract a unique name."}
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateContract,
            validateUpdateItem: validateContract,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeContractID(this.columnSchema.contractId),
                    makeContractName(this.columnSchema.contractName),
                    makePlantId(plantRecords, Plant.columnSchema.plantId, Plant.columnSchema.plantName, false),
                    {
                        name: "Status",
                        key: this.columnSchema.status,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.status,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Effective Date",
                        key: this.columnSchema.effectiveDate,
                        cellType: "date",
                        cellOptions: {
                            timezone: "UTC",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Interconnection COD",
                        key: this.columnSchema.interconnectionCOD,
                        cellType: "date",
                        cellOptions: {
                            timezone: "UTC",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Application COD",
                        key: this.columnSchema.applicationCOD,
                        cellType: "date",
                        cellOptions: {
                            timezone: "UTC",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "COD Cliff Date",
                        key: this.columnSchema.CODCliffDate,
                        cellType: "date",
                        cellOptions: {
                            timezone: "UTC",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Queue ID",
                        key: this.columnSchema.queueId,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "ISO Accounting ID",
                        key: this.columnSchema.ISOAccountingId,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "ISO Application ID",
                        key: this.columnSchema.ISOApplicationSubmissionId,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "POI Location",
                        key: this.columnSchema.poiLocation,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "POI Latitude",
                        key: this.columnSchema.poiLatitude,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: false,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "POI Longitude",
                        key: this.columnSchema.poiLongitude,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: false,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "POI Max AC Injection (MW)",
                        key: this.columnSchema.poiMaxACInjectionMW,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: false,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "POI Deliverable Capacity (MW)",
                        key: this.columnSchema.poiDeliverableCapacityMW,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: false,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "POI Grid Voltage (kV)",
                        key: this.columnSchema.poiGridVoltage,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: false,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Power Factor Requirements",
                        key: this.columnSchema.powerFactorRequirements,
                        cellType: "editable",
                        filterable: true,
                        sortable: false,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Gen Tie Length",
                        key: this.columnSchema.genTieLength,
                        cellType: "editable",
                        filterable: false,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Transmission Owner Completion Date",
                        key: this.columnSchema.transmissionOwnerCompletionDate,
                        cellType: "date",
                        cellOptions: {
                            timezone: "UTC",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Site Exclusivity Status",
                        key: this.columnSchema.siteExclusivityStatus,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.exclusivityStatus,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Shared Facility Agreement Required",
                        key: this.columnSchema.sharedFacilityAgreementRequired,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Shared Facility Notes",
                        key: this.columnSchema.sharedFacilityNotes,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Native or Attaining Balancing Authority",
                        key: this.columnSchema.nativeOrAttainingBalancingAuth,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.nativeOrAttainingBalancingAuth,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Sharepoint Folder Link",
                        key: this.columnSchema.sharepointLink,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes),
                ]
            },
        }
    }

}
export var InterconnectionContract = new InterconnectionContractTable()

class InterconnectionCounterpartiesContractTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            interconnectionId: "interconnection_id",
            relationship: "relationship",
            notes: "notes",
        }

        var schemaName = schemas.contract
        var tableName = "interconnection_counterparties"

        var displayNameSingular = "Interconnection Counterparty"
        var displayNamePlural = "Interconnection Counterparties"

        var pkUidColumn = null
        var identifiers = [columnSchema.contractId, columnSchema.interconnectionId, columnSchema.relationship]
        var dependencies = [InterconnectionContract, Interconnection]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.initOptions()
    }

    getReferencedPlantId = buildGetReferencedPlantId(this, InterconnectionContract)

    initOptions = () => {
        this.options = {
            relationship: [{
                label: "ISO", value: "ISO"
            }, {
                label: "Interconnection", value: "Interconnection"
            }, {
                label: "Transmission/Distribution Facility Owner", value: "Transmission/Distribution Facility Owner"
            }, {
                label: "Transmission/Distribution Service Owner", value: "Transmission/Distribution Service Owner"
            }, {
                label: "Transmission Operator", value: "Transmission Operator"
            }, {
                label: "Balancing Authority", value: "Balancing Authority"
            }, {
                label: "Transmission Planner", value: "Transmission Planner"
            }, {
                label: "Reliability Coordinator", value: "Reliability Coordinator"
            }, {
                label: "Planning Coordinator", value: "Planning Coordinator"
            }]
        }
    }

    buildTableProps = (data) => {

        var contractRecords = this.findTableObjectRecords(data, InterconnectionContract)
        var interconnectionRecords = this.findTableObjectRecords(data, Interconnection)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        var contractIdCol = InterconnectionContract.columnSchema.contractId
        var contractNameCol = InterconnectionContract.columnSchema.contractName
        var interconnectionIdCol = Interconnection.columnSchema.interconnectionId
        var interconnectionNameCol = "interconnectionName"

        interconnectionRecords = injectCounterpartyName(interconnectionRecords, counterpartyRecords, interconnectionIdCol, interconnectionNameCol)

        const validateContract = (contract) => {
            if (!contract[this.columnSchema.contractId]) return {pass: false, message: "Please add a contract reference."}
            if (!contract[this.columnSchema.interconnectionId]) return {pass: false, message: "Please add an Interconnection reference."}
            if (!contract[this.columnSchema.relationship]) return {pass: false, message: "Please add an relationship."}
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateContract,
            validateUpdateItem: validateContract,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    {
                        name: "Contract",
                        key: this.columnSchema.contractId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contractRecords, value, contractIdCol, contractNameCol),
                            dropdownItems: buildDropdownItems(contractRecords, contractIdCol, contractNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 350,
                            resizable: true,
                        }
                    },
                    {
                        name: "Interconnection Counterparty",
                        key: this.columnSchema.interconnectionId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(interconnectionRecords, value, interconnectionIdCol, interconnectionNameCol),
                            dropdownItems: buildDropdownItems(interconnectionRecords, interconnectionIdCol, interconnectionNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    }, {
                        name: "Relationship",
                        key: this.columnSchema.relationship,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.relationship
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes),
                ]
            },
        }
    }

}
export var InterconnectionCounterpartiesContract = new InterconnectionCounterpartiesContractTable()

class LandContractTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            contractName: "contract_name",
            plantId: "plant_id",
            siteControlStatus: "site_control_status",
            effectiveDate: "effective_date",
            endDate: "end_date",
            termYears: "term_years",
            ownership: "ownership",
            acres: "acres",
            renewalMechanism: "renewal_mechanism",
            baseRent: "base_rent_monthly",
            escalationRate: "escalation_rate",
            propertyTaxResponsibility: "property_tax_responsibility",
            feeType: "fee_type",
            paymentFrequency: "payment_frequency",
            sharepointLink: "sharepoint_link",
            notes: "notes"
        }

        var schemaName = schemas.contract
        var tableName = "land"

        var displayNameSingular = "Landowner Contract"
        var displayNamePlural = "Landowner Contracts"

        var pkUidColumn = columnSchema.contractId
        var identifiers = [columnSchema.contractId]
        var dependencies = [Plant]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
            this.initOptions()
    }

    initOptions = () => {
        this.options = {
            ownership: [{
                label: "Leased", value: "Leased",
            }, {
                label: "Owned/Leased", value: "Owned/Leased",
            }, {
                label: "Owned", value: "Owned",
            }]
        }
    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)

        const validateContract = (contract) => {
            if (!contract[this.columnSchema.plantId]) return {pass: false, message: "Please give the contract a plant."}
            if (!contract[this.columnSchema.contractName]) return {pass: false, message: "Please give the contract a unique name."}
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateContract,
            validateUpdateItem: validateContract,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeContractID(this.columnSchema.contractId),
                    makeContractName(this.columnSchema.contractName),
                    makePlantId(plantRecords, Plant.columnSchema.plantId, Plant.columnSchema.plantName, false),
                    {
                        name: "Status",
                        key: this.columnSchema.siteControlStatus,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Effective Date",
                        key: this.columnSchema.effectiveDate,
                        cellType: "date",
                        cellOptions: {
                            timezone: "UTC",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "End Date",
                        key: this.columnSchema.endDate,
                        cellType: "date",
                        cellOptions: {
                            timezone: "UTC",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Term (years)",
                        key: this.columnSchema.termYears,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: false,
                        sortable: false,
                        colProps: {
                            width: 100,
                        }
                    }, {
                        name: "Ownership Type",
                        key: this.columnSchema.ownership,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.ownership
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Acres",
                        key: this.columnSchema.acres,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 100,
                        }
                    }, {
                        name: "Renewal Mechanism",
                        key: this.columnSchema.renewalMechanism,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Base Rent (monthly)",
                        key: this.columnSchema.baseRent,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Escalation Rate",
                        key: this.columnSchema.escalationRate,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Property Tax Responsibility",
                        key: this.columnSchema.propertyTaxResponsibility,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Fee Type",
                        key: this.columnSchema.feeType,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Payment Frequency",
                        key: this.columnSchema.paymentFrequency,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Sharepoint Folder Link",
                        key: this.columnSchema.sharepointLink,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes),
                ]
            },
        }
    }

}
export var LandContract = new LandContractTable()

class LandLLCsContractTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            landownerId: "landowner_llc_id",
            notes: "notes",
        }

        var schemaName = schemas.contract
        var tableName = "land_llcs"

        var displayNameSingular = "Land Contract LLC"
        var displayNamePlural = "Land Contract LLCs"

        var pkUidColumn = null
        var identifiers = [columnSchema.contractId, columnSchema.landownerId]
        var dependencies = [LandContract, LandownerLLC]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

    }

    getReferencedPlantId = buildGetReferencedPlantId(this, LandContract)

    buildTableProps = (data) => {

        var contractRecords = this.findTableObjectRecords(data, LandContract)
        var landLLCRecords = this.findTableObjectRecords(data, LandownerLLC)

        var contractIdCol = LandContract.columnSchema.contractId
        var contractNameCol = LandContract.columnSchema.contractName
        var landLLCIdCol = LandownerLLC.columnSchema.landownerLLCId
        var landLLCNameCol = LandownerLLC.columnSchema.landownerLLCName

        const validateContract = (contract) => {
            if (!contract[this.columnSchema.contractId]) return {pass: false, message: "Please add a contract reference."}
            if (!contract[this.columnSchema.landownerId]) return {pass: false, message: "Please add a Landowner reference."}
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateContract,
            validateUpdateItem: validateContract,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    {
                        name: "Contract",
                        key: this.columnSchema.contractId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contractRecords, value, contractIdCol, contractNameCol),
                            dropdownItems: buildDropdownItems(contractRecords, contractIdCol, contractNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 350,
                            resizable: true,
                        }
                    }, {
                        name: "Landowner",
                        key: this.columnSchema.landownerId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(landLLCRecords, value, landLLCIdCol, landLLCNameCol),
                            dropdownItems: buildDropdownItems(landLLCRecords, landLLCIdCol, landLLCNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes),
                ]
            },
        }
    }

}
export var LandLLCsContract = new LandLLCsContractTable()

class LandContractLandownersTable extends Table {

    constructor() {

        var columnSchema = {
            oid: "oid",
            contractId: "contract_id",
            landownerContactId: "landowner_contact_id",
            notes: "notes",
        }

        var schemaName = schemas.contract
        var tableName = "land_landowners"

        var displayNameSingular = "Land Contract Landowner"
        var displayNamePlural = "Land Contract Landowners"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [LandContract, Contact]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural,
            identifiers, dependencies, pkUidColumn
        )

    }

    getReferencedPlantId = buildGetReferencedPlantId(this, LandContract)

    buildTableProps = (data) => {

        var contractRecords = this.findTableObjectRecords(data, LandContract)
        var contactRecords = this.findTableObjectRecords(data, Contact)

        var contractIdCol = LandContract.columnSchema.contractId
        var contractNameCol = LandContract.columnSchema.contractName
        var landownerIdCol = Contact.columnSchema.contactId
        var landownerNameCol = Contact.columnSchema.name

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid),
                    {
                        name: "Contract",
                        key: this.columnSchema.contractId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contractRecords, value, contractIdCol, contractNameCol),
                            dropdownItems: buildDropdownItems(contractRecords, contractIdCol, contractNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 350,
                            resizable: true,
                        }
                    }, {
                        name: "Landowner",
                        key: this.columnSchema.landownerContactId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contactRecords, value, landownerIdCol, landownerNameCol),
                            dropdownItems: buildDropdownItems(contactRecords, landownerIdCol, landownerNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes),
                ]
            },
        }
    }

}
export var LandContractLandowners = new LandContractLandownersTable()

class LandContractCounterpartiesTable extends Table {

    constructor() {

        var columnSchema = {
            oid: "oid",
            contractId: "contract_id",
            landownerId: "landowner_id",
            notes: "notes",
        }

        var schemaName = schemas.contract
        var tableName = "land_counterparties"

        var displayNameSingular = "Land Contract Counterparty"
        var displayNamePlural = "Land Contract Counterparties"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [LandContract, Landowner]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural,
            identifiers, dependencies, pkUidColumn
        )

    }

    getReferencedPlantId = buildGetReferencedPlantId(this, LandContract)

    buildTableProps = (data) => {

        var contractRecords = this.findTableObjectRecords(data, LandContract)
        var landownerRecords = this.findTableObjectRecords(data, Landowner)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        var contractIdCol = LandContract.columnSchema.contractId
        var contractNameCol = LandContract.columnSchema.contractName
        var landownerIdCol = Landowner.columnSchema.landownerId
        var landownerNameCol = "landownerName"

        landownerRecords = injectCounterpartyName(landownerRecords, counterpartyRecords, landownerIdCol, landownerNameCol)

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid),
                    {
                        name: "Contract",
                        key: this.columnSchema.contractId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contractRecords, value, contractIdCol, contractNameCol),
                            dropdownItems: buildDropdownItems(contractRecords, contractIdCol, contractNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 350,
                            resizable: true,
                        }
                    }, {
                        name: "Landowner Counterparty",
                        key: this.columnSchema.landownerId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(landownerRecords, value, landownerIdCol, landownerNameCol),
                            dropdownItems: buildDropdownItems(landownerRecords, landownerIdCol, landownerNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes),
                ]
            },
        }
    }

}
export var LandContractCounterparties = new LandContractCounterpartiesTable()

class LTSAContractTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            contractName: "contract_name",
            plantId: "plant_id",
            sharepointLink: "sharepoint_link",
            notes: "notes"
        }

        var schemaName = schemas.contract
        var tableName = "ltsa"

        var displayNameSingular = "LTSA Contract"
        var displayNamePlural = "LTSA Contracts"

        var pkUidColumn = columnSchema.contractId
        var identifiers = [columnSchema.contractId]
        var dependencies = [Plant]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)

        const validateContract = (contract) => {
            if (!contract[this.columnSchema.plantId]) return {pass: false, message: "Please give the contract a plant."}
            if (!contract[this.columnSchema.contractName]) return {pass: false, message: "Please give the contract a unique name."}
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateContract,
            validateUpdateItem: validateContract,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeContractID(this.columnSchema.contractId),
                    makeContractName(this.columnSchema.contractName),
                    makePlantId(plantRecords, Plant.columnSchema.plantId, Plant.columnSchema.plantName), {
                        name: "Sharepoint Folder Link",
                        key: this.columnSchema.sharepointLink,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes, 1),
                ]
            },
        }
    }

}
export var LTSAContract = new LTSAContractTable()

class LTSACounterpartiesContractTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            oemId: "oem_id",
            notes: "notes",
        }

        var schemaName = schemas.contract
        var tableName = "ltsa_counterparties"

        var displayNameSingular = "LTSA Counterparty"
        var displayNamePlural = "LTSA Counterparties"

        var pkUidColumn = null
        var identifiers = [columnSchema.contractId, columnSchema.oemId]
        var dependencies = [LTSAContract, OEM]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

    }

    getReferencedPlantId = buildGetReferencedPlantId(this, LTSAContract)

    buildTableProps = (data) => {

        var contractRecords = this.findTableObjectRecords(data, LTSAContract)
        var oemRecords = this.findTableObjectRecords(data, OEM)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        var contractIdCol = LTSAContract.columnSchema.contractId
        var contractNameCol = LTSAContract.columnSchema.contractName
        var oemIdCol = OEM.columnSchema.oemId
        var oemNameCol = "oemName"

        oemRecords = injectCounterpartyName(oemRecords, counterpartyRecords, oemIdCol, oemNameCol)

        const validateContract = (contract) => {
            if (!contract[this.columnSchema.contractId]) return {pass: false, message: "Please add a contract reference."}
            if (!contract[this.columnSchema.oemId]) return {pass: false, message: "Please add an OEM reference."}
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateContract,
            validateUpdateItem: validateContract,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    {
                        name: "Contract",
                        key: this.columnSchema.contractId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contractRecords, value, contractIdCol, contractNameCol),
                            dropdownItems: buildDropdownItems(contractRecords, contractIdCol, contractNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 350,
                            resizable: true,
                        }
                    }, {
                        name: "OEM",
                        key: this.columnSchema.oemId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(oemRecords, value, oemIdCol, oemNameCol),
                            dropdownItems: buildDropdownItems(oemRecords, oemIdCol, oemNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes),
                ]
            },
        }
    }

}
export var LTSACounterpartiesContract = new LTSACounterpartiesContractTable()

class OandMContractTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            contractName: "contract_name",
            plantId: "plant_id",
            status: "status",
            termStartDate: "term_start_date",
            termEndDate: "term_end_date",
            termYears: "term_years",
            paymentTerms: "payment_terms",
            paymentFrequency: "payment_frequency",
            feeStructure: "fee_structure",
            fee: "fee__dollarperkw",
            annualEscalationRate: "annual_escalation_rate__percent",
            escalationDate: "escalation_date",
            escalationLanguage: "escalation_language",
            optionsToExtend: "options_to_extend",
            limitOfLiability: "limit_of_liability",
            terminationClause: "termination_clause",
            terminationFees: "termination_fees",
            terminationNoticeRequirements: "termination_notice_requirements",
            additionalFeeServiceAdder: "additional_fee_service_adder__percent",
            guaranteedAvailability: "guaranteed_availability__percent",
            liquidatedDamages: "liquidated_damages",
            sharepointLink: "sharepoint_link",
            notes: "notes"
        }

        var schemaName = schemas.contract
        var tableName = "oandm"

        var displayNameSingular = "O&M Contract"
        var displayNamePlural = "O&M Contracts"

        var pkUidColumn = columnSchema.contractId
        var identifiers = [columnSchema.contractId]
        var dependencies = [Plant]
        
        super(
            schemaName, tableName,
            columnSchema,
            displayNameSingular, displayNamePlural,
            identifiers, dependencies, pkUidColumn
        )
        this.initOptions()
    }

    initOptions() {
        this.options = {
            status: [{
                label: "Not Started", value: "Not Started"
            }, {
                label: "In Negotiation", value: "In Negotiation"
            }, {
                label: "Executed", value: "Executed"
            }, {
                label: "Expired", value: "Expired"
            }, {
                label: "Terminated", value: "Terminated"
            }],
            maintenanceLabor: [{
                label: "Covered Service", value: "Covered Service"
            }, {
                label: "Uncovered Service", value: "Uncovered Service"
            }],
            feeStructure: [{
                label: "Fixed", value: "Fixed"
            }, {
                label: "Schedule", value: "Schedule"
            }],
            paymentTerms: [{
                label: "Advanced", value: "Advanced"
            }, {
                label: "Arrears", value: "Arrears"
            }],
            paymentFrequency: [{
                label: "Annually", value: "Annually"
            }, {
                label: "Quarterly", value: "Quarterly"
            }, {
                label: "Monthly", value: "Monthly"
            },]
        }
    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)

        const validateContract = (contract) => {
            if (!contract[this.columnSchema.plantId]) return {pass: false, message: "Please give the contract a plant."}
            if (!contract[this.columnSchema.contractName]) return {pass: false, message: "Please give the contract a unique name."}
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateContract,
            validateUpdateItem: validateContract,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeContractID(this.columnSchema.contractId),
                    makeContractName(this.columnSchema.contractName),
                    makePlantId(plantRecords, Plant.columnSchema.plantId, Plant.columnSchema.plantName, false),
                    {
                        name: "Status",
                        key: this.columnSchema.status,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.status,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Term Start Date",
                        key: this.columnSchema.termStartDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Term End Date",
                        key: this.columnSchema.termEndDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Term (years)",
                        key: this.columnSchema.termYears,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Fee Structure",
                        key: this.columnSchema.feeStructure,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.feeStructure,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Payment Terms",
                        key: this.columnSchema.paymentTerms,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.paymentTerms,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Payment Frequency",
                        key: this.columnSchema.paymentFrequency,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.paymentFrequency,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Static Fee",
                        key: this.columnSchema.fee,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 180,
                            resizable: true,
                        }
                    }, {
                        name: "Annual Escalation Rate",
                        key: this.columnSchema.annualEscalationRate,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 180,
                            resizable: true,
                        }
                    }, {
                        name: "Escalation Date",
                        key: this.columnSchema.escalationDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 180,
                            resizable: true,
                        }
                    }, {
                        name: "Escalation Language",
                        key: this.columnSchema.escalationLanguage,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 180,
                            resizable: true,
                        }
                    }, {
                        name: "Options to Extend",
                        key: this.columnSchema.optionsToExtend,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 180,
                            resizable: true,
                        }
                    }, {
                        name: "Limit Of Liability",
                        key: this.columnSchema.limitOfLiability,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Termination Clause",
                        key: this.columnSchema.terminationClause,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 180,
                            resizable: true,
                        }
                    }, {
                        name: "Termination Fees",
                        key: this.columnSchema.terminationFees,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 180,
                            resizable: true,
                        }
                    }, {
                        name: "Termination Notice Requirements",
                        key: this.columnSchema.terminationNoticeRequirements,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    }, {
                        name: "Additional Fee Service Adder (%)",
                        key: this.columnSchema.additionalFeeServiceAdder,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 220,
                            resizable: true,
                        }
                    }, {
                        name: "Guaranteed Availability",
                        key: this.columnSchema.guaranteedAvailability,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Liquidated Damages",
                        key: this.columnSchema.liquidatedDamages,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Sharepoint Folder Link",
                        key: this.columnSchema.sharepointLink,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes, 0),
                ]
            },
        }
    }

}
export const OandMContract = new OandMContractTable()

class OandMContractFeeScheduleTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            startYear: "start_year",
            endYear: "end_year",
            fee: "fee__dollarperkw",
            notes: "notes"
        }

        var schemaName = schemas.contract
        var tableName = "oandm_fee_schedule"

        var displayNameSingular = "O&M Fee Schedule"
        var displayNamePlural = "O&M Fee Schedules"

        var pkUidColumn = null
        var identifiers = [columnSchema.contractId, columnSchema.startYear, columnSchema.endYear]
        var dependencies = [OandMContract]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

    }

    getReferencedPlantId = buildGetReferencedPlantId(this, OandMContract)

    buildTableProps = (data) => {

        var contractRecords = this.findTableObjectRecords(data, OandMContract)

        var contractIdCol = OandMContract.columnSchema.contractId
        var contractNameCol = OandMContract.columnSchema.contractName

        const validateSchedule = (schedule) => {
            if (!schedule[this.columnSchema.contractId]) return {pass: false, message: "Please add a contract reference."}
            if (!schedule[this.columnSchema.startYear] || !schedule[this.columnSchema.endYear]) return {pass: false, message: "Please add a start and end year."}
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateSchedule,
            validateUpdateItem: validateSchedule,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    {
                        name: "Contract",
                        key: this.columnSchema.contractId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contractRecords, value, contractIdCol, contractNameCol),
                            dropdownItems: buildDropdownItems(contractRecords, contractIdCol, contractNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 350,
                            resizable: true,
                        }
                    }, {
                        name: "Start Year",
                        key: this.columnSchema.startYear,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "End Year",
                        key: this.columnSchema.endYear,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Pricing",
                        key: this.columnSchema.fee,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true
                        }
                    }, {
                        name: "Notes",
                        key: this.columnSchema.notes,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true
                        }
                    }
                ]
            },
        }
    }

}
export const OandMContractFeeSchedule = new OandMContractFeeScheduleTable()

class OandMContractScopesTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            domain: "domain",
            scope: "scope",
            coverage: "coverage",
            notes: "notes"
        }

        var schemaName = schemas.contract
        var tableName = "oandm_scopes"

        var displayNameSingular = "O&M Scopes"
        var displayNamePlural = "O&M Scopes"

        var pkUidColumn = null
        var identifiers = [columnSchema.contractId, columnSchema.domain, columnSchema.scope]
        var dependencies = [OandMContract]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.initOptions()
    }

    initOptions = () => {
        this.options = {
            domains: [{
                label: "Operations", value: "Operations"
            }, {
                label: "Performance Engineering", value: "Performance Engineering"
            }, {
                label: "Maintenance", value: "Maintenance"
            }, {
                label: "Misc.", value: "Miscellaneous"
            }],
            scopes: {
                "Operations": [{
                        label: "Monitoring", value: "Monitoring"
                    }, {
                        label: "Dispatch", value: "Dispatch"
                    }, {
                        label: "Utility Interface", value: "Utility Interface"
                    }, {
                        label: "Control", value: "Control"
                }],
                "Performance Engineering": [{
                        label: "Analysis", value: "Analysis"
                    }, {
                        label: "Reporting", value: "Reporting"
                    }, {
                        label: "AC/DC Health Review", value: "AC/DC Health Review"
                    }, {
                        label: "Data Validation", value: "Data Validation"
                }],
                "Maintenance": [{
                        label: "Preventive Labor", value: "Preventive Labor"
                    }, {
                        label: "Corrective Labor", value: "Corrective Labor"
                    }],
                "Miscellaneous": [{
                        label: "Spare Parts", value: "Spare Parts"
                    }, {
                        label: "Warranty Management", value: "Warranty Management"
                    }, {
                        label: "Availability Guarantee", value: "Availability Guarantee"
                    }, {
                        label: "NERC Compliance GOP", value: "NERC Compliance GOP"
                    }, {
                        label: "NERC Compliance GO", value: "NERC Compliance GO"
                    }, {
                        label: "Vegetation Management", value: "Vegetation Management"
                    }, {
                        label: "Module/Tower Cleaning", value: "Module/Tower Cleaning"
                    }, {
                        label: "Trash Removal", value: "Trash Removal"
                    }, {
                        label: "O&M Building Maintenance", value: "O&M Building Maintenance"
                    }, {
                        label: "Security Monitoring", value: "Security Monitoring"
                    }]
            },
            coverage: [{
                label: "Covered", value: "Covered"
            }, {
                label: "Uncovered", value: "Uncovered"
            }, {
                label: "Additional Fee", value: "Additional Fee"
            }]
        }
    }

    getReferencedPlantId = buildGetReferencedPlantId(this, OandMContract)

    buildTableProps = (data) => {

        var contractRecords = this.findTableObjectRecords(data, OandMContract)

        var contractIdCol = OandMContract.columnSchema.contractId
        var contractNameCol = OandMContract.columnSchema.contractName

        const validateSchedule = (schedule) => {
            if (!schedule[this.columnSchema.contractId]) return {pass: false, message: "Please add a contract reference."}
            if (!schedule[this.columnSchema.domain]) return {pass: false, message: "Please select a domain."}
            if (!schedule[this.columnSchema.scope]) return {pass: false, message: "Please select a scope."}
            if (!schedule[this.columnSchema.coverage]) return {pass: false, message: "Please select a coverage type."}
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateSchedule,
            validateUpdateItem: validateSchedule,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    {
                        name: "Contract",
                        key: this.columnSchema.contractId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contractRecords, value, contractIdCol, contractNameCol),
                            dropdownItems: buildDropdownItems(contractRecords, contractIdCol, contractNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 350,
                            resizable: true,
                        }
                    }, {
                        name: "Domain",
                        key: this.columnSchema.domain,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.domains
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true
                        }
                    }, {
                        name: "Scope",
                        key: this.columnSchema.scope,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: (record) => {
                                return this.options.scopes[record[this.columnSchema.domain]] || []
                            }
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true
                        }
                    }, {
                        name: "Coverage",
                        key: this.columnSchema.coverage,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.coverage
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true
                        }
                    }, {
                        name: "Notes",
                        key: this.columnSchema.notes,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true
                        }
                    }
                ]
            },
        }
    }

}
export const OandMContractScopes = new OandMContractScopesTable()

class OandMCounterpartiesContractTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            oandmId: "oandm_id",
            notes: "notes",
        }

        var schemaName = schemas.contract
        var tableName = "oandm_counterparties"

        var displayNameSingular = "O&M Counterparty"
        var displayNamePlural = "O&M Counterparties"

        var pkUidColumn = null
        var identifiers = [columnSchema.contractId, columnSchema.oandmId]
        var dependencies = [OandMContract, OandM]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

    }

    getReferencedPlantId = buildGetReferencedPlantId(this, OandMContract)

    buildTableProps = (data) => {

        var contractRecords = this.findTableObjectRecords(data, OandMContract)
        var oandmRecords = this.findTableObjectRecords(data, OandM)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        var contractIdCol = OandMContract.columnSchema.contractId
        var contractNameCol = OandMContract.columnSchema.contractName
        var oandmIdCol = OandM.columnSchema.oandmId
        var oandmNameCol = "oandmName"

        oandmRecords = injectCounterpartyName(oandmRecords, counterpartyRecords, oandmIdCol, oandmNameCol)

        const validateContract = (contract) => {
            if (!contract[this.columnSchema.contractId]) return {pass: false, message: "Please add a contract reference."}
            if (!contract[this.columnSchema.oandmId]) return {pass: false, message: "Please add an O&M reference."}
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateContract,
            validateUpdateItem: validateContract,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    {
                        name: "Contract",
                        key: this.columnSchema.contractId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contractRecords, value, contractIdCol, contractNameCol),
                            dropdownItems: buildDropdownItems(contractRecords, contractIdCol, contractNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 350,
                            resizable: true,
                        }
                    },
                    {
                        name: "OandM",
                        key: this.columnSchema.oandmId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(oandmRecords, value, oandmIdCol, oandmNameCol),
                            dropdownItems: buildDropdownItems(oandmRecords, oandmIdCol, oandmNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes),
                ]
            },
        }
    }

}
export const OandMCounterpartiesContract = new OandMCounterpartiesContractTable()

class OfftakeContractTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            contractName: "contract_name",
            plantId: "plant_id",
            status: "status",
            termStartDate: "term_start_date",
            termEndDate: "term_end_date",
            termYears: "term_years",
            contractType: "contract_type",
            ppaCliffDate: "ppa_cliff_date",
            extensionTerms: "extension_terms",
            priceDollarpermwh: "price",
            pricingUnit: "pricing_unit",
            annualEscalationRate: "annual_escalation_rate",
            escalationDate: "escalation_date",
            contractualCOD: "contractual_cod",
            initialDeliveryDate: "initial_delivery_date",
            escalationLanguage: "escalation_language",
            todFactors: "tod_factors",
            capacityLimitMw: "capacity_limit__mw",
            annualCurtailmentAllowanceMwh: "annual_curtailment_allowance__mwh",
            creditSupportRequirement: "credit_support_requirement",
            productionGuarantee: "production_guarantee__mwh",
            environmentalAttributes: "environmental_attributes",
            offtakeMarket: "offtake_market",
            investmentGrade: "investment_grade",
            sharepointLink: "sharepoint_link",
            notes: "notes",
        }

        var schemaName = schemas.contract
        var tableName = "offtake"

        var displayNameSingular = "Offtake Contract"
        var displayNamePlural = "Offtake Contracts"

        var pkUidColumn = columnSchema.contractId
        var identifiers = [columnSchema.contractId]
        var dependencies = [Plant]
        
        super(
            schemaName, tableName,
            columnSchema,
            displayNameSingular, displayNamePlural,
            identifiers, dependencies, pkUidColumn
        )
        this.initOptions()
    }

    initOptions = () => {
        this.options = {
            status: [{
                label: "Lead", value: "Lead"
            }, {
                label: "Qualified Lead", value: "Qualified Lead"
            }, {
                label: "Modeling", value: "Modeling"
            }, {
                label: "Proposal", value: "Proposal"
            }, {
                label: "Shortlist", value: "Shortlist"
            }, {
                label: "Negotiations", value: "Negotiations"
            }, {
                label: "Executed - PPA", value: "Executed - PPA"
            }, {
                label: "Executed - BTA", value: "Executed - BTA"
            }, {
                label: "Executed - RA", value: "Executed - RA"
            }, {
                label: "Executed - Toll", value: "Executed - Toll"
            }, {
                label: "Executed - Other", value: "Executed - Other"
            }, {
                label: "Terminated", value: "Terminated"
            }, {
                label: "Complete (Depracated)", value: "Complete"
            }, {
                label: "Started (Depracated)", value: "Started"
            }],
            contractType: [{
                label: "PPA", value: "PPA"
            }, {
                label: "BTA", value: "BTA"
            }, {
                label: "RA", value: "RA"
            }, {
                label: "Hedge", value: "Hedge"
            }, {
                label: "Merchant", value: "Merchant"
            }, {
                label: "REC", value: "REC"
            }, {
                label: "BUA", value: "BUA"
            }, {
                label: "Tolling", value: "Tolling"
            }],
            offtakeMarket: [{
                label: "CAISO", value: "CAISO"
            }, {
                label: "MISO", value: "MISO"
            }, {
                label: "WECC", value: "WECC"
            }, {
                label: "PJM", value: "PJM"
            }, {
                label: "SERC", value: "SERC"
            }, {
                label: "OEISO", value: "OEISO"
            }, {
                label: "NYISO", value: "NYISO"
            }, {
                label: "ISONE", value: "ISONE"
            }, {
                label: "LADWP", value: "LADWP"
            }],
            investmentGrade: [{
                label: "High IG", value: "High IG"
            }, {
                label: "IG", value: "IG"
            }, {
                label: "Sub IG", value: "Sub IG"
            }]
        }
    }

    buildNewRecord = () => {
        var newRecord = super.buildNewRecord.call(this)
        newRecord[this.columnSchema.todFactors] = 0
        newRecord[this.columnSchema.environmentalAttributes] = 0
        newRecord[this.columnSchema.creditSupportRequirement] = 0
        return newRecord
    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)

        const validateContract = (contract) => {
            if (!contract[this.columnSchema.plantId]) return {pass: false, message: "Please give the contract a plant."}
            if (!contract[this.columnSchema.contractName]) return {pass: false, message: "Please give the contract a unique name."}
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateContract,
            validateUpdateItem: validateContract,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeContractID(this.columnSchema.contractId),
                    makeContractName(this.columnSchema.contractName),
                    makePlantId(plantRecords, Plant.columnSchema.plantId, Plant.columnSchema.plantName, false),
                    {
                        name: "status",
                        key: this.columnSchema.status,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.status,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Term Start Date",
                        key: this.columnSchema.termStartDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Term End Date",
                        key: this.columnSchema.termEndDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Term (years)",
                        key: this.columnSchema.termYears,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Contract Type",
                        key: this.columnSchema.contractType,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.contractType
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "PPA Cliff Date",
                        key: this.columnSchema.ppaCliffDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Initial Delivery Date",
                        key: this.columnSchema.initialDeliveryDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Extension Terms",
                        key: this.columnSchema.extensionTerms,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Price",
                        key: this.columnSchema.priceDollarpermwh,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Pricing Unit",
                        key: this.columnSchema.pricingUnit,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Annual Escalation Rate",
                        key: this.columnSchema.annualEscalationRate,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Escalation Date",
                        key: this.columnSchema.escalationDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Escalation Language",
                        key: this.columnSchema.escalationLanguage,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Contractual COD",
                        key: this.columnSchema.contractualCOD,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "TOD Factors",
                        key: this.columnSchema.todFactors,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Capacity Limit (MW)",
                        key: this.columnSchema.capacityLimitMw,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Annual Curtailment Allowance (MWh)",
                        key: this.columnSchema.annualCurtailmentAllowanceMwh,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 270,
                            resizable: true,
                        }
                    }, {
                        name: "Credit Support Requirement",
                        key: this.columnSchema.creditSupportRequirement,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    }, {
                        name: "Production Guarantee",
                        key: this.columnSchema.productionGuarantee,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Environmental Attributes",
                        key: this.columnSchema.environmentalAttributes,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Offtaker Market",
                        key: this.columnSchema.offtakeMarket,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.offtakeMarket
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    }, {
                        name: "Investment Grade",
                        key: this.columnSchema.investmentGrade,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.investmentGrade
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    }, {
                        name: "Sharepoint Folder Link",
                        key: this.columnSchema.sharepointLink,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes, 0),
                ]
            },
        }
    }

}
export const OfftakeContract = new OfftakeContractTable()

class OfftakeCreditRatingTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            agency: "agency",
            rating: "rating"
        }

        var schemaName = schemas.contract
        var tableName = "offtake_credit_rating"

        var displayNameSingular = "Offtake Contract Credit Rating"
        var displayNamePlural = "Offtake Contract Credit Ratings"

        var pkUidColumn = null
        var identifiers = [columnSchema.contractId, columnSchema.agency, columnSchema.rating]
        var dependencies = [OfftakeContract]
        
        super(
            schemaName, tableName,
            columnSchema,
            displayNameSingular, displayNamePlural,
            identifiers, dependencies, pkUidColumn
        )
        this.initOptions()
    }

    initOptions = () => {
        this.options = {
            agencies: [{label: "S&P", value: "S&P"}, {label: "Moodys", value: "Moodys"}, {label: "Fitch", value: "Fitch"}, {label: "Unavailable", value: "Unavailable"}],
            ratings: {
                "S&P": [{label: "AAA", value: "AAA"}, {label: "AA+", value: "AA+"}, {label: "AA", value: "AA"}, {label: "AA-", value: "AA-"},
                    {label: "A+", value: "A+"}, {label: "A", value: "A"}, {label: "A-", value: "A-"}, 
                    {label: "BBB+", value: "BBB+"}, {label: "BBB", value: "BBB"}, {label: "BBB-", value: "BBB-"}, 
                    {label: "BB+", value: "BB+"}, {label: "BB", value: "BB"}, {label: "BB-", value: "BB-"}, 
                    {label: "B+", value: "B+"}, {label: "B", value: "B"}, {label: "B-", value: "B-"}, 
                    {label: "CCC+", value: "CCC+"}, {label: "CCC", value: "CCC"}, {label: "CCC-", value: "CCC-"}, 
                    {label: "CC", value: "CC"}, {label: "SD", value: "SD"}, {label: "D", value: "D"}],
                "Moodys": [
                    {label: "Aaa", value: "Aaa"}, {label: "Aa1", value: "Aa1"}, {label: "Aa2", value: "Aa2"}, {label: "Aa3", value: "Aa3"},
                    {label: "A1", value: "A1"}, {label: "A2", value: "A2"}, {label: "A3", value: "A3"},
                    {label: "Baa1", value: "Baa1"}, {label: "Baa2", value: "Baa2"}, {label: "Baa3", value: "Baa3"},
                    {label: "Ba1", value: "Ba1"}, {label: "Ba2", value: "Ba2"}, {label: "Ba3", value: "Ba3"},
                    {label: "B1", value: "B1"}, {label: "B2", value: "B2"}, {label: "B3", value: "B3"},
                    {label: "Caa1", value: "Caa1"}, {label: "Caa2", value: "Caa2"}, {label: "Caa3", value: "Caa3"},
                    {label: "Ca", value: "Ca"}, {label: "C", value: "C"}],
                "Fitch": [
                    {label: "AAA", value: "AAA"}, {label: "AA+", value: "AA+"}, {label: "AA", value: "AA"}, {label: "AA-", value: "AA-"},
                    {label: "A+", value: "A+"}, {label: "A", value: "A"}, {label: "A-", value: "A-"}, 
                    {label: "BBB+", value: "BBB+"}, {label: "BBB", value: "BBB"}, {label: "BBB-", value: "BBB-"}, 
                    {label: "BB+", value: "BB+"}, {label: "BB", value: "BB"}, {label: "BB-", value: "BB-"}, 
                    {label: "B+", value: "B+"}, {label: "B", value: "B"}, {label: "B-", value: "B-"}, 
                    {label: "CCC+", value: "CCC+"}, {label: "CCC", value: "CCC"}, {label: "CCC-", value: "CCC-"},
                    {label: "CC", value: "CC"}, {label: "C", value: "C"}, {label: "DDD", value: "DDD"}, {label: "DD", value: "DD"}, {label: "D", value: "D"}],
                "Unavailable": [
                    {label: "Private", value: "Private"}, {label: "Missing", value: "Missing"}
                ]
            },
            // Used for MUI dropdown
            reformattedRatings: []
        }
        this.options.agencies.forEach(x => {
            this.options.reformattedRatings.push({subheader: true, label: x.label})
            this.options.reformattedRatings = this.options.reformattedRatings.concat(this.options.ratings[x.value].map(y => Object.assign({data: x.value}, y)))
        })
    }

    getReferencedPlantId = buildGetReferencedPlantId(this, OfftakeContract)

    buildTableProps = (data) => {

        var contractCratingRecords = this.findTableObjectRecords(data, OfftakeContract)

        var contractIdCol = OfftakeContract.columnSchema.contractId
        var contractNameCol = OfftakeContract.columnSchema.contractName

        const agencyCol = this.columnSchema.agency
        const ratingOptions = this.options.ratings

        const validateContract = (record) => {
            if (!record[this.columnSchema.contractId]) return {pass: false, message: "Please add a contract reference."}
            let agency = record[this.columnSchema.agency]
            if (!agency) return {pass: false, message: "Please add an agency."}
            if (!(this.options.ratings[agency] || []).map(x => x.value).includes(record[this.columnSchema.rating])) return {pass: false, message: "Please select a rating that is offered by the selected agency."}
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateContract,
            validateUpdateItem: validateContract,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    {
                        name: "Contract",
                        key: this.columnSchema.contractId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contractCratingRecords, value, contractIdCol, contractNameCol),
                            dropdownItems: buildDropdownItems(contractCratingRecords, contractIdCol, contractNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 350,
                            resizable: true,
                        }
                    }, {
                        name: "Agency",
                        key: this.columnSchema.agency,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.agencies
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    }, {
                        name: "Rating",
                        key: this.columnSchema.rating,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: (record) => {
                                return ratingOptions[record[agencyCol]] || []
                            }
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                ]
            },
        }
    }

}
export const OfftakeCreditRating = new OfftakeCreditRatingTable()

class OfftakeCounterpartiesContractTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            offtakeId: "offtaker_id",
            notes: "notes",
        }

        var schemaName = schemas.contract
        var tableName = "offtake_counterparties"

        var displayNameSingular = "Offtake Counterparty"
        var displayNamePlural = "Offtake Counterparties"

        var pkUidColumn = null
        var identifiers = [columnSchema.contractId, columnSchema.offtakeId]
        var dependencies = [OfftakeContract, Offtake]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

    }

    getReferencedPlantId = buildGetReferencedPlantId(this, OfftakeContract)

    buildTableProps = (data) => {

        var contractRecords = this.findTableObjectRecords(data, OfftakeContract)
        var offtakeRecords = this.findTableObjectRecords(data, Offtake)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        var contractIdCol = OfftakeContract.columnSchema.contractId
        var contractNameCol = OfftakeContract.columnSchema.contractName
        var offtakeIdCol = Offtake.columnSchema.offtakeId
        var offtakeNameCol = "offtakeName"

        offtakeRecords = injectCounterpartyName(offtakeRecords, counterpartyRecords, offtakeIdCol, offtakeNameCol)

        const validateContract = (contract) => {
            if (!contract[this.columnSchema.contractId]) return {pass: false, message: "Please add a contract reference."}
            if (!contract[this.columnSchema.offtakeId]) return {pass: false, message: "Please add an Offtaker reference."}
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateContract,
            validateUpdateItem: validateContract,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    {
                        name: "Contract",
                        key: this.columnSchema.contractId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contractRecords, value, contractIdCol, contractNameCol),
                            dropdownItems: buildDropdownItems(contractRecords, contractIdCol, contractNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 350,
                            resizable: true,
                        }
                    },
                    {
                        name: "Offtaker",
                        key: this.columnSchema.offtakeId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(offtakeRecords, value, offtakeIdCol, offtakeNameCol),
                            dropdownItems: buildDropdownItems(offtakeRecords, offtakeIdCol, offtakeNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes),
                ]
            },
        }
    }

}
export const OfftakeCounterpartiesContract = new OfftakeCounterpartiesContractTable()

class OfftakeSCCounterpartiesContractTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            schedulingCoordinatorId: "scheduling_coordinator_id",
            notes: "notes",
        }

        var schemaName = schemas.contract
        var tableName = "offtake_counterparties_sc"

        var displayNameSingular = "Scheduling Coordinator Counterparty"
        var displayNamePlural = "Scheduling Coordinator Counterparties"

        var pkUidColumn = null
        var identifiers = [columnSchema.contractId, columnSchema.schedulingCoordinatorId]
        var dependencies = [OfftakeContract, SchedulingCoordinator]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

    }

    getReferencedPlantId = buildGetReferencedPlantId(this, OfftakeContract)

    buildTableProps = (data) => {

        var contractRecords = this.findTableObjectRecords(data, OfftakeContract)
        var schedulingCoordinatorRecords = this.findTableObjectRecords(data, SchedulingCoordinator)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        var contractIdCol = OfftakeContract.columnSchema.contractId
        var contractNameCol = OfftakeContract.columnSchema.contractName
        var schedulingCoordinatorIdCol = SchedulingCoordinator.columnSchema.schedulerId
        var schedulingCoordinatorNameCol = "schedulingCoordinatorName"

        schedulingCoordinatorRecords = injectCounterpartyName(schedulingCoordinatorRecords, counterpartyRecords, schedulingCoordinatorIdCol, schedulingCoordinatorNameCol)

        const validateContract = (contract) => {
            if (!contract[this.columnSchema.contractId]) return {pass: false, message: "Please add a contract reference."}
            if (!contract[this.columnSchema.schedulingCoordinatorId]) return {pass: false, message: "Please add a Scheduling Coordinator reference."}
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateContract,
            validateUpdateItem: validateContract,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    {
                        name: "Contract",
                        key: this.columnSchema.contractId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contractRecords, value, contractIdCol, contractNameCol),
                            dropdownItems: buildDropdownItems(contractRecords, contractIdCol, contractNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 350,
                            resizable: true,
                        }
                    },
                    {
                        name: "Scheduling Coordinator",
                        key: this.columnSchema.schedulingCoordinatorId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(schedulingCoordinatorRecords, value, schedulingCoordinatorIdCol, schedulingCoordinatorNameCol),
                            dropdownItems: buildDropdownItems(schedulingCoordinatorRecords, schedulingCoordinatorIdCol, schedulingCoordinatorNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes),
                ]
            },
        }
    }

}
export const OfftakeSCCounterpartiesContract = new OfftakeSCCounterpartiesContractTable()

class PermittingContractTable extends Table {

    constructor() {

        var columnSchema = {
            oid: "oid",
            contractName: "contract_name",
            projectId: "project_id",
            status: "status",
            authorizingAgency: "authorizing_agency",
            triggerDescription: "trigger_description",
            responsibility: "responsibility",
            jurisdictionLevel: "jurisdiction_level",
            sharepointLink: "sharepoint_link"
        }

        var schemaName = schemas.contract
        var tableName = "permitting"

        var displayNameSingular = "Permit"
        var displayNamePlural = "Permitting"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [Plant, Counterparty]
        
        super(
            schemaName, tableName,
            columnSchema,
            displayNameSingular, displayNamePlural,
            identifiers, dependencies, pkUidColumn
        )
        this.initOptions()
    }

    initOptions = () => {
        this.options = {
            jurisdictionLevel: [{
                label: "Federal", value: "Federal"
            }, {
                label: "State", value: "State"
            }, {
                label: "County", value: "County"
            }, {
                label: "Township", value: "Township"
            }],
            status: [{
                label: "Not Started", value: "Not Started"
            }, {
                label: "In Progress", value: "In Progress"
            }, {
                label: "Completed", value: "Completed"
            }, {
                label: "TBD", value: "TBD"
            }, {
                label: "N/A", value: "N/A"
            }]
        }
    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)

        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)
        var counterpartyIdCol = Counterparty.columnSchema.counterpartyId
        var counterpartyNameCol = Counterparty.columnSchema.counterpartyName

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeContractID(this.columnSchema.oid),
                    makeContractName(this.columnSchema.contractName),
                    {
                        name: "Project",
                        key: this.columnSchema.projectId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(plantRecords, value, Plant.columnSchema.plantId, Plant.columnSchema.plantName),
                            dropdownItems: buildDropdownItems(plantRecords,  Plant.columnSchema.plantId, Plant.columnSchema.plantName)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    }, {
                        name: "Status",
                        key: this.columnSchema.status,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.status,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Jurisdiction Level",
                        key: this.columnSchema.jurisdictionLevel,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.jurisdictionLevel,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Authorizing Agency",
                        key: this.columnSchema.authorizingAgency,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Trigger Description",
                        key: this.columnSchema.triggerDescription,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Responsibility",
                        key: this.columnSchema.responsibility,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(counterpartyRecords, value, counterpartyIdCol, counterpartyNameCol),
                            dropdownItems: buildDropdownItems(counterpartyRecords,  counterpartyIdCol, counterpartyNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Sharepoint Folder Link",
                        key: this.columnSchema.sharepointLink,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}

export const PermittingContract = new PermittingContractTable()

class PermittingDatesContractTable extends Table {

    constructor() {

        var columnSchema = {
            oid: "oid",
            contractId: "contract_id",
            title: "title",
            startDate: "start_date",
            endDate: "end_date",
            status: "status",
            description: "description",
        }

        var schemaName = schemas.contract
        var tableName = "permitting_dates"

        var displayNameSingular = "Permitting Date"
        var displayNamePlural = "Permitting Dates"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [PermittingContract]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.initOptions()
        this.allowProjectDisplay = false
    }

    initOptions = () => {
        this.options = {
            status: [{
                label: "Not Started", value: "Not Started"
            }, {
                label: "In Progress", value: "In Progress"
            }, {
                label: "Completed", value: "Completed"
            }, {
                label: "TBD", value: "TBD"
            }]
        }
    }

    getReferencedPlantId = buildGetReferencedPlantId(this, PermittingContract)

    buildTableProps = (data) => {

        var contractRecords = this.findTableObjectRecords(data, PermittingContract)
        var contractIdCol = PermittingContract.columnSchema.oid
        var contractNameCol = PermittingContract.columnSchema.contractName

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Contract",
                        key: this.columnSchema.contractId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contractRecords, value, contractIdCol, contractNameCol),
                            dropdownItems: buildDropdownItems(contractRecords, contractIdCol, contractNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    }, {
                        name: "Title",
                        key: this.columnSchema.title,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Status",
                        key: this.columnSchema.status,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.status
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Start Date",
                        key: this.columnSchema.startDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "End Date",
                        key: this.columnSchema.endDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.description),
                ]
            },
        }
    }

}
export const PermittingDatesContract = new PermittingDatesContractTable()

export const PermittingCounterpartiesContract = {columnSchema: "test"}