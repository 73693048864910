import './Landowner.css'
import React, { useState } from 'react'

import ViewPanel from '../../ViewPanel'
import { 
    PalantirSelector,
    PalantirDatePicker,
    PalantirBooleanSelector
} from '../../../../components/input/SelectPicker'
import { PalantirTextField, PalantirDispatchedTextField, makeTextMaskNumber, makeTextMaskPercentage } from '../../../../components/input/Text'
import { LandContract, LandLLCsContract, LandContractLandowners, LandContractCounterparties } from '../../../table_configuration/Contract'
import { Landowner } from '../../../table_configuration/Counterparty'
import { useChangeLog, injectedChangeLogIdCol } from '../../../../hooks/changeLog'
import { useDBViewFormValidation } from '../../../../hooks/databaseViewFormValidation'
import { AddFloaterButtonWithPrompt } from '../../../../components/button/FloaterButtonWithPrompt'
import ContractCounterparties from '../ContractCounterparties'
import ContractHeader from '../ContractHeader'
import ContractIdentification from '../ContractIdentification'
import { getContractCounterpartyData, validateCreateContractCounterparty, buildContractNameErrorConfig } from '../utils'
import { generateUUID, ErrorRND, ViewFormError } from '../../../../utils/databaseAppUtils'

import _ from 'lodash'
import { Plant } from '../../../table_configuration/Project'
import { LandownerLLC } from '../../../table_configuration/LLC'
import { Counterparty, Contact } from '../../../table_configuration/Entity'

const landownerIdCol = LandLLCsContract.columnSchema.landownerId
const injectedLandownerName = "landownerName"

const TextMaskPercentage = makeTextMaskPercentage()
const TextMaskYears = makeTextMaskNumber()


/**
 * Proxy component for PermittingPanel.
 * @param {*} props 
 * @returns 
 */
export default function LandownerPanelProxy(props) {

    const contractRecords = props.data[LandContract.buildId()]
    const contractLLCRecords = props.data[LandLLCsContract.buildId()]
    const landownerLLCRecords = props.data[LandownerLLC.buildId()].map(x => Object.assign({}, x, {[Counterparty.columnSchema.counterpartyId]: x[LandownerLLC.columnSchema.landownerLLCId], [Counterparty.columnSchema.counterpartyName]: x[LandownerLLC.columnSchema.landownerLLCName]}))
    const contractContactRecords = props.data[LandContractLandowners.buildId()]
    const contractCounterpartyRecords = props.data[LandContractCounterparties.buildId()]
    const contactRecords = props.data[Contact.buildId()]
    const landownerRecords = props.data[Landowner.buildId()]

    const [filteredToPlantContractRecords, filteredContractLLCRecords] = getContractCounterpartyData(
        contractRecords, contractLLCRecords, landownerLLCRecords,
        props.selectedPlantId,
        LandContract.columnSchema.contractId, LandContract.columnSchema.plantId,
        LandLLCsContract.columnSchema.contractId, LandLLCsContract.columnSchema.landownerId, injectedLandownerName,
    )
    const [_, filteredContractCounterpartyRecords] = getContractCounterpartyData(
        contractRecords, contractCounterpartyRecords, landownerRecords,
        props.selectedPlantId,
        LandContract.columnSchema.contractId, LandContract.columnSchema.plantId,
        LandContractCounterparties.columnSchema.contractId, LandContractCounterparties.columnSchema.landownerId, injectedLandownerName,
    )
    const [___, filteredContractContactRecords] = getContractCounterpartyData(
        contractRecords, contractContactRecords, contactRecords,
        props.selectedPlantId,
        LandContract.columnSchema.contractId, LandContract.columnSchema.plantId,
        LandContractLandowners.columnSchema.contractId, LandContractLandowners.columnSchema.landownerContactId, injectedLandownerName,
    )
    // Inject contact name
    filteredContractContactRecords.forEach(record => {
        const match = contactRecords.find(x => x[Contact.columnSchema.contactId]===record[LandContractLandowners.columnSchema.landownerContactId])
        if (match) {
            record[injectedLandownerName] = match[Contact.columnSchema.name]
        }
    })

    return (
        <LandownerPanel
            landownerContractRecords={filteredToPlantContractRecords}
            landownerContractLLCRecords={filteredContractLLCRecords}
            landownerContractCounterpartyRecords={filteredContractCounterpartyRecords}
            landownerContractContactRecords={filteredContractContactRecords}
            landownerLLCRecords={landownerLLCRecords}
            contactRecords={contactRecords}
            landownerRecords={landownerRecords}
            {...props}
        />
    )

}

/**
 * Main stateful PermittingPanel component.
 * @param {*} props 
 * @returns 
 */
function LandownerPanel(props) {

    const [
        landownerContractRecords, prepareContractLog,
        updateContractLog, addToContractLog, deleteFromContractLog, bulkOpOnContractLog, mergeAndResetContractLog
    ] = useChangeLog(props.landownerContractRecords, LandContract)
    const [
        landownerContractLLCRecords, prepareLLCLog,
        updateLLCLog, addToLLCLog, deleteFromLLCLog, bulkOpOnLLCLog, mergeAndResetLLCLog
    ] = useChangeLog(props.landownerContractLLCRecords, LandLLCsContract)
    const [
        landownerContractContactRecords, prepareContactLog,
        updateContactLog, addToContactLog, deleteFromContactLog, bulkOpOnContactLog, mergeAndResetContactLog
    ] = useChangeLog(props.landownerContractContactRecords, LandContractLandowners)
    const [
        landownerContractCounterpartyRecords, prepareCounterpartyLog,
        updateCounterpartyLog, addToCounterpartyLog, deleteFromCounterpartyLog, bulkOpOnCounterpartyLog, mergeAndResetCounterpartyLog
    ] = useChangeLog(props.landownerContractCounterpartyRecords, LandContractCounterparties)

    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()

    const addToContractLogProxy = () => {
        let contract = LandContract.buildNewRecord()
        contract[LandContract.columnSchema.contractId] = generateUUID()
        contract[LandContract.columnSchema.plantId] = props.selectedPlantId
        addToContractLog(contract)
    }
    const deleteFromContractLogProxy = (landownerContract) => {
        // Delete all counterparty records associated with this contract, if any
        let contractId = landownerContract[LandContract.columnSchema.contractId]
        let LLCsInDeletedContract = landownerContractLLCRecords.filter(record => record[LandLLCsContract.columnSchema.contractId]===contractId)
        let counterpartiesInDeletedContract = landownerContractCounterpartyRecords.filter(record => record[LandContractCounterparties.columnSchema.contractId]===contractId)
        let contactsInDeletedContract = landownerContractContactRecords.filter(record => record[LandContractLandowners.columnSchema.contractId]===contractId)
        deleteFromLLCLog(LLCsInDeletedContract)
        deleteFromContactLog(contactsInDeletedContract)
        deleteFromCounterpartyLog(counterpartiesInDeletedContract)
        deleteFromContractLog(landownerContract)
        removeErrors([contractId])
    }

    const landownerContractComponents = landownerContractRecords.map(landownerContract => {
        let contractId = landownerContract[LandContract.columnSchema.contractId]
        let landownerLLCs = landownerContractLLCRecords.filter(x => x[LandLLCsContract.columnSchema.contractId]===contractId)
        let landownerCounterparties = landownerContractCounterpartyRecords.filter(x => x[LandContractCounterparties.columnSchema.contractId]===contractId)
        let landownerContacts = landownerContractContactRecords.filter(x => x[LandContractLandowners.columnSchema.contractId]===contractId)
        return (
            <LandContractForm
                key={contractId}
                landownerContract={landownerContract}
                contractId={contractId}
                allLandownerLLCs={props.landownerLLCRecords}
                filteredLandownerLLCs={landownerLLCs}
                allLandownerContacts={props.contactRecords}
                filteredLandownerContacts={landownerContacts}
                allLandownerCounterparties={props.landownerRecords}
                filteredLandownerCounterparties={landownerCounterparties}
                updateContract={(update) => updateContractLog(landownerContract, update)}
                updateContractLLC={updateLLCLog}
                updateContractContact={updateContactLog}
                updateContractCounterparty={updateCounterpartyLog}
                addToLLCLog={() => {
                    let contractLLC = LandLLCsContract.buildNewRecord()
                    contractLLC[LandLLCsContract.columnSchema.contractId] = contractId
                    addToLLCLog(contractLLC)
                }}
                addToCounterpartyLog={() => {
                    let contractCounterparty = LandContractCounterparties.buildNewRecord()
                    contractCounterparty[LandContractCounterparties.columnSchema.oid] = generateUUID()
                    contractCounterparty[LandContractCounterparties.columnSchema.contractId] = contractId
                    addToCounterpartyLog(contractCounterparty)
                }}
                addToContactLog={() => {
                    let contractContact = LandContractLandowners.buildNewRecord()
                    contractContact[LandContractLandowners.columnSchema.oid] = generateUUID()
                    contractContact[LandContractLandowners.columnSchema.contractId] = contractId
                    addToContactLog(contractContact)
                }}
                deleteFromContractLog={() => deleteFromContractLogProxy(landownerContract)}
                deleteFromLLCLog={deleteFromLLCLog}
                deleteFromCounterpartyLog={deleteFromCounterpartyLog}
                deleteFromContactLog={deleteFromContactLog}
                errors={errors}
                removeErrors={removeErrors}
            />
        )
    })

    return (
        <ViewPanel
            services={props.services}
            title="Permitting Contract"
            submitUrl='api/precious/table/landowner_contract'
            verifySubmit={(payload) => {
                return verifyChangelogSubmission(
                    {
                        changeLog: payload.landownerContractChangelog,
                        checks: [buildContractNameErrorConfig(LandContract)]
                    }, {
                        changeLog: payload.landownerContractLLCsChangelog,
                        checks: []
                    }, {
                        changeLog: payload.landownerContractCounterpartiesChangelog,
                        checks: []
                    }, {
                        changeLog: payload.landownerContractContactsChangelog,
                        checks: []
                    }
                )
            }}
            onSubmitSuccess={(response, requestPayload) => {
                props.handleUpdate(false)
                resetErrors()
                mergeAndResetContractLog()
                mergeAndResetLLCLog()
                mergeAndResetCounterpartyLog()
                mergeAndResetContactLog()
            }}
            onSubmitError={null}
            buildSubmitPayload={() => {
                return {
                    landownerContractChangelog: prepareContractLog(),
                    landownerContractLLCsChangelog: prepareLLCLog(),
                    landownerContractCounterpartiesChangelog: prepareCounterpartyLog(),
                    landownerContractContactsChangelog: prepareContactLog(),
                }
            }}
        >
            {landownerContractComponents}
            <AddFloaterButtonWithPrompt
                onClick={addToContractLogProxy}
                labelContent="Add new contract"
                height={18}
                width={18}
            />
        </ViewPanel>
    )
}

/**
 * 
 */
const LandContractForm = React.memo(function({
    landownerContract, contractId,
    allLandownerLLCs, filteredLandownerLLCs, allLandownerContacts, filteredLandownerContacts, allLandownerCounterparties, filteredLandownerCounterparties,
    updateContract, updateContractLLC, updateContractContact, updateContractCounterparty,
    addToLLCLog, addToContactLog, addToCounterpartyLog, deleteFromLLCLog, deleteFromContractLog, deleteFromContactLog, deleteFromCounterpartyLog, errors, removeErrors
}) {
    
    return (
        <div className="field-group form-instance" style={{scroll: "auto", marginBottom: "100px", minWidth: "1200px"}}>
            <ContractHeader
                contractName={landownerContract[LandContract.columnSchema.contractName]}
                deleteFromContractLog={deleteFromContractLog}
            />
            <ContractIdentification
                ContractTable={LandContract}
                contract={landownerContract}
                contractIdCol={LandContract.columnSchema.contractId}
                contractNameCol={LandContract.columnSchema.contractName}
                contractPlantIdCol={LandContract.columnSchema.plantId}
                onContractNameChange={(x) => updateContract({[LandContract.columnSchema.contractName]: x})}
                errors={errors}
                removeErrors={removeErrors}
            />
            <ContractCounterparties
                ContractCounterpartyTable={LandLLCsContract}
                contract={landownerContract}
                contractIdCol={LandContract.columnSchema.contractId}
                contractCounterpartyIdCol={LandLLCsContract.columnSchema.landownerId}
                contractCounterpartyNameCol={injectedLandownerName}
                contractCounterpartyNotesCol={LandLLCsContract.columnSchema.notes}
                allCounterpartyRecords={allLandownerLLCs}
                filteredContractCounterpartyRecords={filteredLandownerLLCs}
                deleteFromCounterpartyLog={deleteFromLLCLog}
                addToCounterpartyLog={addToLLCLog}
                updateContractCounterparty={updateContractLLC}
                orientation="horizontal"
                counterpartyType={"Landowner LLC"}
                counterpartyTypePlural={"Landowner LLCs"}
                errors={errors}
                removeErrors={removeErrors}
            />
            <ContractCounterparties
                ContractCounterpartyTable={LandContractLandowners}
                contract={landownerContract}
                contractIdCol={LandContract.columnSchema.contractId}
                contractCounterpartyIdCol={LandContractLandowners.columnSchema.landownerContactId}
                contractCounterpartyNameCol={injectedLandownerName}
                contractCounterpartyNotesCol={LandContractLandowners.columnSchema.notes}
                allCounterpartyRecords={allLandownerContacts}
                counterpartyIdCol={Contact.columnSchema.contactId}
                counterpartyNameCol={Contact.columnSchema.name}
                filteredContractCounterpartyRecords={filteredLandownerContacts}
                deleteFromCounterpartyLog={deleteFromContactLog}
                addToCounterpartyLog={addToContactLog}
                updateContractCounterparty={updateContractContact}
                orientation="horizontal"
                counterpartyType={"Landowner Contact"}
                counterpartyTypePlural={"Landowner Contacts"}
                errors={errors}
                removeErrors={removeErrors}
            />
            <ContractCounterparties
                ContractCounterpartyTable={LandContractCounterparties}
                contract={landownerContract}
                contractIdCol={LandContract.columnSchema.contractId}
                contractCounterpartyIdCol={LandContractCounterparties.columnSchema.landownerId}
                contractCounterpartyNameCol={injectedLandownerName}
                contractCounterpartyNotesCol={LandContractCounterparties.columnSchema.notes}
                allCounterpartyRecords={allLandownerCounterparties}
                counterpartyIdCol={Counterparty.columnSchema.counterpartyId}
                counterpartyNameCol={Counterparty.columnSchema.counterpartyName}
                filteredContractCounterpartyRecords={filteredLandownerCounterparties}
                deleteFromCounterpartyLog={deleteFromCounterpartyLog}
                addToCounterpartyLog={addToCounterpartyLog}
                updateContractCounterparty={updateContractCounterparty}
                orientation="horizontal"
                counterpartyType={"Landowner Counterparty"}
                counterpartyTypePlural={"Landowner Counterparties"}
                errors={errors}
                removeErrors={removeErrors}
            />
            <div className="header" style={{marginTop: "25px"}}>
                Contract Details
            </div>
            <div style={{marginBottom: "15px"}}>
                <PalantirTextField
                    label="Sharepoint Contract Folder Link"
                    value={landownerContract[LandContract.columnSchema.sharepointLink]}
                    helperText="This should be the folder link, not the file."
                    onChange={(x) => updateContract({[LandContract.columnSchema.sharepointLink]: x})}
                />
                <div style={{marginTop: "4px"}}><a href={landownerContract[LandContract.columnSchema.sharepointLink]} target="_blank">Click here to visit Sharepoint link</a></div>
            </div>
            <div style={{marginBottom: "20px"}}>
                <PalantirDatePicker
                    label="Effective Date"
                    value={landownerContract[LandContract.columnSchema.effectiveDate]}
                    onChange={(date) => updateContract({[LandContract.columnSchema.effectiveDate]: date})}
                    TextFieldProps={{style: {marginRight: "20px"}}}
                />
                <PalantirDatePicker
                    label="End Date"
                    value={landownerContract[LandContract.columnSchema.endDate]}
                    onChange={(date) => updateContract({[LandContract.columnSchema.endDate]: date})}
                    TextFieldProps={{style: {marginRight: "20px"}}}
                />
                <PalantirTextField
                    label="Term (Years)"
                    value={landownerContract[LandContract.columnSchema.termYears]}
                    InputProps={{
                        inputComponent: TextMaskYears
                    }}
                    onChange={(x) => updateContract({[LandContract.columnSchema.termYears]: x})}
                    style={{marginRight: "20px"}}
                />
                <PalantirTextField
                    label="Renewal Mechanism"
                    value={landownerContract[LandContract.columnSchema.renewalMechanism]}
                    onChange={(x) => updateContract({[LandContract.columnSchema.renewalMechanism]: x})}
                />
            </div>
            <div style={{marginBottom: "20px"}}>
                <PalantirTextField
                    label="Site Control Status (%)"
                    value={landownerContract[LandContract.columnSchema.siteControlStatus]}
                    onChange={(x) => updateContract({[LandContract.columnSchema.siteControlStatus]: x})}
                    InputProps={{
                        inputComponent: TextMaskPercentage
                    }}
                    style={{marginRight: "20px"}}
                />
                <PalantirSelector
                    label="Ownership"
                    value={landownerContract[LandContract.columnSchema.ownership]}
                    items={LandContract.options.ownership}
                    onChange={(x) => updateContract({[LandContract.columnSchema.ownership]: x})}
                    style={{marginRight: "20px"}}
                />
                <PalantirTextField
                    label="Acres"
                    value={landownerContract[LandContract.columnSchema.acres]}
                    InputProps={{
                        inputComponent: TextMaskYears
                    }}
                    onChange={(x) => updateContract({[LandContract.columnSchema.acres]: x})}
                />
            </div>
            <div style={{marginBottom: "20px"}}>
                <PalantirTextField
                    label="Base Monthly Rent ($)"
                    value={landownerContract[LandContract.columnSchema.baseRent]}
                    InputProps={{
                        inputComponent: TextMaskYears
                    }}
                    onChange={(x) => updateContract({[LandContract.columnSchema.baseRent]: x})}
                    style={{marginRight: "20px"}}
                />
                <PalantirTextField
                    label="Escalation Rate"
                    value={landownerContract[LandContract.columnSchema.escalationRate]}
                    onChange={(x) => updateContract({[LandContract.columnSchema.escalationRate]: x})}
                    style={{marginRight: "20px"}}
                />
                <PalantirTextField
                    label="Property Tax Responsibility"
                    value={landownerContract[LandContract.columnSchema.propertyTaxResponsibility]}
                    onChange={(x) => updateContract({[LandContract.columnSchema.propertyTaxResponsibility]: x})}
                    style={{marginRight: "20px"}}
                />
                <PalantirTextField
                    label="Fee Type"
                    value={landownerContract[LandContract.columnSchema.feeType]}
                    onChange={(x) => updateContract({[LandContract.columnSchema.feeType]: x})}
                    style={{marginRight: "20px"}}
                />
                <PalantirTextField
                    label="Payment Frequency"
                    value={landownerContract[LandContract.columnSchema.paymentFrequency]}
                    onChange={(x) => updateContract({[LandContract.columnSchema.paymentFrequency]: x})}
                />
            </div>
            <div>
                <PalantirDispatchedTextField
                    label="Notes"
                    value={landownerContract[LandContract.columnSchema.notes]}
                    multiline
                    rows={4}
                    fullWidth
                    variant="filled"
                    onChange={(x) => updateContract({[LandContract.columnSchema.notes]: x})}
                    style={{marginTop: "20px"}}
                />
            </div>
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        _.isEqual(prevProps.landownerContract, nextProps.landownerContract) &&
        _.isEqual(prevProps.filteredLandownerLLCs, nextProps.filteredLandownerLLCs) &&
        _.isEqual(prevProps.filteredLandownerCounterparties, nextProps.filteredLandownerCounterparties) &&
        _.isEqual(prevProps.filteredLandownerContacts, nextProps.filteredLandownerContacts) &&
        _.isEqual(prevProps.errors, nextProps.errors)
    )
})