

import { DataProviderChart } from '../../charts/BaseChart'
import { TopBarVariant1, TopBarVariant2 } from '../../charts/TopBars'
import { createBasicSeries } from '../../../utils/Highcharts'
import DatePicker, { useDateRangeSelectionState, longRanges } from '../../date_picker/DateRangePicker'
import { useDataProvider, DataProviderInterceptor } from '../../data_provider/StandardDataProvider'
import Highcharts from 'highcharts'
import { useEffect } from 'react'
import { startOfDay, addDays  } from 'date-fns'
import { Text } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useOutletContext } from 'react-router-dom'


const RTE = ({timezone, selectedSiteId}) => {

    const { authServices } = useOutletContext()

    const [startDate, endDate, setDateRange] = useDateRangeSelectionState({useUrlParam: false, initStartDate: startOfDay(addDays(new Date(), -6))})
    const dataProviderProps = useDataProvider({
        requestMethod: "POST",
        requestUrl: '/api/summary/bess_production_overview',
        onUnauthorized: authServices.logout,
        buildRequestBody: () => {
            return {
                siteId: selectedSiteId,
                startDate: startDate,
                endDate: endDate
            }
        }
    })

    useEffect(() => {
        dataProviderProps.refreshData()
    }, [selectedSiteId, startDate, endDate])

    const buildChartConfig = (data) => {

        let seriesData = Object.entries(data.batteryCyclesDaily).map(entry => {
            let [ts, cyclesValue] = entry
            let energyDeliveredValue = data.energyDeliveredDaily[ts]
            let energyConsumedValue = data.energyConsumedDaily[ts]
            return {
                x: Number(ts),
                y: cyclesValue,
                custom: {
                    data: {
                        energyDelivered: {
                            units: "MWh",
                            v: energyDeliveredValue
                        },
                        energyConsumed: {
                            units: "MWh",
                            v: energyConsumedValue
                        }
                    }
                }
            }
        })

        let cyclesSeries = createBasicSeries({
            data:  seriesData,
            label: "Battery Cycles",
            axisUnits: "cycles",
            tooltip: {
                valueDecimals: 2,
                pointFormatter: function(point) {
                    return `
                        <span style="color:${this.color}">●</span> ${this.series.name}: <b>${this.y.toFixed(2)} cycles</b> <br/>
                        Energy Delivered: <b>${(Math.round(this.custom.data.energyDelivered.v*100)/100).toLocaleString()} ${this.custom.data.energyDelivered.units}</b> <br/>
                        Energy Consumed: <b>${(Math.round(this.custom.data.energyConsumed.v*100)/100).toLocaleString()} ${this.custom.data.energyConsumed.units}</b> <br/>
                    
                    `
                },
            },
        })

        let series = [cyclesSeries]

        return {
            chart: {
                type: "column"
            },
            legend: {
                floating: true,
                verticalAlign: 'top',
                align: 'right',
            },
            series: series,
            yAxis: [{title: {text: "Cycles"}}]
        }
    }

    return (
        <div className="flow-horizontal fill-parent" >
            <div className="flow-vertical" style={{width: "100%", marginRight: '10px'}}>
                <DataProviderChart
                    dataProviderProps={dataProviderProps}
                    timezone={timezone}
                    buildChartConfig={buildChartConfig}
                    renderTopBar={({...args}) => {
                        return (
                            <TopBarVariant1
                                title="BESS Production Summary"
                                startDate={startDate}
                                endDate={endDate}
                                setDateRange={setDateRange}
                                createContent={(isDataReady, data) => {
                                    return (
                                        isDataReady ?
                                        <KPIPanel data={data} /> :
                                        null
                                    )
                                }}
                                style={{padding: "15px 15px 0 15px"}}
                                DatePickerProps={{ranges: longRanges}}
                                {...args}
                            />
                        )
                    }}
                />    
            </div>
            <div className="flow-vertical" style={{width: "0%", marginLeft: '10px'}}>
                <DataProviderInterceptor {...dataProviderProps} >
                    <KPIPanel data={dataProviderProps.data} />
                </DataProviderInterceptor>
            </div>
        </div>
    )
}
export default RTE

const KPIPanel = ({data}) => {

    const totalEnergyDeliveredOverPeriodStr = data.totalEnergyDeliveredOverPeriod.toLocaleString()
    const totalCyclesOverPeriodStr = data.totalCyclesOverPeriod.toFixed(2)
    const totalEnergyConsumedOverPeriodStr = data.totalEnergyConsumedOverPeriod.toLocaleString()
    const roundTripEficiencyOverPeriod = data.roundTripEficiencyOverPeriod*100
    const roundTripEficiencyOverPeriodStr = roundTripEficiencyOverPeriod.toFixed(2)
    const roundTripEficiencyNormOverPeriod = data.roundTripEficiencyNormalizedWithSOCOverPeriod*100
    const roundTripEficiencyNormOverPeriodStr = roundTripEficiencyNormOverPeriod.toFixed(2)

    const RTEColor = colorRuleProcessor(roundTripEficiencyOverPeriod, roundTripEfficiencyColorRuleset)
    const RTENormColor = colorRuleProcessor(roundTripEficiencyNormOverPeriod, roundTripEfficiencyColorRuleset)

    return (
        <>
            <div className="flow-vertical" style={{marginLeft: "auto", marginRight: "40px", flexShrink: 0}}>
                <Text size="lg" style={{margin: "auto"}}>Round Trip Efficiency</Text>
                <div className="flow-horizontal">
                    <div style={{marginRight: "10px"}}>
                        <div className="flow-horizontal" style={{alignItems: "baseline"}}>
                            <div style={{width: "0px"}}></div>
                            <Text size="xl" weight={500} style={{marginLeft: "auto"}}>{roundTripEficiencyOverPeriodStr} %</Text>
                        </div>
                        <div className="flow-horizontal" style={{alignItems: "center", width: "100%", marginBottom: "0px"}}>
                            <div style={{backgroundColor: RTEColor, height: "5px", borderRadius: "6px", width: `${Math.min(roundTripEficiencyOverPeriod, 100)}%`}}></div>
                            <div style={{backgroundColor: "lightgrey", height: "5px", borderRadius: "0 6px 6px 0", width: (100-Math.min(roundTripEficiencyOverPeriod, 100))+'%'}}></div>
                        </div>
                        <Text size="xs" style={{fontStyle: "italic"}}>*contractual</Text>
                    </div>
                    <div style={{marginLeft: "auto"}}>
                        <div className="flow-horizontal" style={{alignItems: "baseline"}}>
                            <Text size="xs" ></Text>
                            <Text size="xl" weight={500} style={{marginLeft: "auto"}}>{roundTripEficiencyNormOverPeriodStr} %</Text>
                        </div>
                        <div className="flow-horizontal" style={{alignItems: "center", width: "100%"}}>
                            <div style={{backgroundColor: RTENormColor, height: "5px", borderRadius: "6px", width: `${Math.min(roundTripEficiencyNormOverPeriod, 100)}%`}}></div>
                            <div style={{backgroundColor: "lightgrey", height: "5px", borderRadius: "0 6px 6px 0", width: (100-Math.min(roundTripEficiencyNormOverPeriod, 100))+'%'}}></div>
                        </div>
                        <Text size="xs" style={{fontStyle: "italic"}}>*normalized</Text>
                    </div>
                    </div>
            </div>
            <div className="flow-vertical" style={{flexShrink: 0}}>
                <div className="flow-horizontal" style={{alignItems: "baseline"}}>
                    <FontAwesomeIcon icon={faArrowLeft} color="green" style={{position: "relative", bottom: "2px"}} />
                    <Text size="xl" style={{marginLeft: "5px"}} weight={500} >{totalEnergyDeliveredOverPeriodStr}</Text>
                    <Text size="xs" style={{marginLeft: "5px", position: "relative", bottom: "6px"}} weight={400} >MWh</Text>
                    <Text size="md" style={{marginLeft: "5px"}} weight={400} > Delivered </Text>
                    <Text size="sm" style={{marginLeft: "5px"}} weight={500} > ({totalCyclesOverPeriodStr}</Text>
                    <Text size="sm" style={{marginLeft: "5px"}} weight={300} > cycles)</Text>
                </div>
                <div className="flow-horizontal" style={{alignItems: "baseline"}}>
                    <FontAwesomeIcon icon={faArrowRight} color="red" style={{position: "relative", bottom: "2px"}} />
                    <Text size="xl" style={{marginLeft: "5px"}} weight={500} >{totalEnergyConsumedOverPeriodStr}</Text>
                    <Text size="xs" style={{marginLeft: "5px", position: "relative", bottom: "6px"}} weight={400} >MWh</Text>
                    <Text size="md" style={{marginLeft: "5px"}} weight={400} > Consumed</Text>
                </div>
                <div
                    className="flow-horizontal"
                    style={{marginRight: "auto", alignItems: "center", fontStyle: "italic", flexShrink: 0}}
                >
                    <Text size="xs">* 1 Cycle =</Text>
                    <Text size="xs" style={{margin: "0 5px 0 5px"}}>{data.siteCapacityMWh.toFixed(0)}</Text>
                    <Text size="xs">MWh Discharged</Text>
                </div>
            </div>
        </>
    )
}

const roundTripEfficiencyColorRuleset = [{threshold: 86, color: "green"}, {threshold: 80, color: "orange"}, {threshold: 70, color: "red"}]
const colorRuleProcessor = (v, ruleset) => {
    let lastColor
    for (let rule of ruleset) {
        if (v>=rule.threshold) return rule.color
        lastColor = rule.color
    }
    return lastColor
}