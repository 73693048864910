import {Component} from 'react'

import Panel from '../../../panel/Panel'

import RegulationSetpointAdherenceContainer from './RegulationSetpointAdherenceContainer'
import AvailabilitySummaryContainer from './AvailabilitySummaryContainer'
import { isSiteBess } from '../../../../utils/assetFrameworkMacros'
import { useOutletContext } from 'react-router-dom'
import { getSite } from '../../../../utils/UrlLocation'

const RegulationContent = () => {

    const {assetFrameworkServices, authServices} = useOutletContext()

    const selectedSiteId = getSite()

    const services = {assetFramework: assetFrameworkServices, auth: authServices}

    if (!selectedSiteId) {
        return <Panel>Please select a site</Panel>
    }

    // Tab is only available for sites with a battery
    const selectedSite = assetFrameworkServices.getSites(selectedSiteId)
    if (!isSiteBess(selectedSite)){
        return <Panel>This dashboard is only available for sites with a battery system.</Panel>
    }

    return (
        <div className="section-main flow-horizontal fill-parent overflow-y">
            <div className="flow-vertical fill-parent" style={{width: "300px", minWidth: "300px", marginRight: "10px"}}>
                <AvailabilitySummaryContainer
                    services={services} 
                    selectedSiteId={selectedSiteId} 
                    style={{maxHeight: "270px", marginBottom: "10px"}} 
                />
                <div className="section-panel fill-parent"></div>
            </div>
            <RegulationSetpointAdherenceContainer
                services={services}
                selectedSiteId={selectedSiteId}
            />
        </div>
    )

}

export default RegulationContent