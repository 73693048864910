import React, {Component, useEffect} from 'react'

import { convertHighchartsSeriesJSONtoCSV } from '../data_exporter/csvFormatters'
import DataExporter from '../data_exporter/DataExporter'
import { createSeriesFromRawData, createBasicSeriesFromRawData, createYAxes, defaultOptions } from '../../utils/Highcharts'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Boost from 'highcharts/modules/boost'
import _ from 'lodash'
import { reShowHighcharts } from '../../utils/Highcharts'

import Panel from '../panel/Panel'
import { Loader } from 'rsuite'
Boost(Highcharts)


window.moment = moment
momentTZ()

class BaseChart extends Component {

    constructor(props) {
        super()

        this.state = {
            
        }
        this.defaultStyle = {'height': 'calc(100% - 40px)', 'overflow': 'hidden'}
        
    }

    componentDidMount() {
        reShowHighcharts()
    }

    /**
     * Only update when the input data changes
     * @param {*} prevProps 
     * @param {*} prevState 
     * @returns 
     */
    shouldComponentUpdate(prevProps, prevState) {
        //console.log("Should base chart update")
        //console.log(prevProps, this.props)

        // Highcharts mutates the yAxes so checking for equality on this will always return false, so only check on data
        //   the data provider should have a unique id generated when it requests data so we don't have to compare a huge object

        if (this.props.compareData) {
            if (_.isEqual(prevProps.data.series, this.props.data.series)) {
                return false
            }
            return true
        }
        if (this.props.dataRequestId===prevProps.dataRequestId && !this.props.forceRerender) {
            //return false //
            console.log("returning false 2")
        }
        return true
    }

    componentDidUpdate() {
        reShowHighcharts()
    }

    render() {

        console.log("Rendering")

        // Recreate chart options on every render
        var chartOptions = this.createChartOptions()

        return (
            <div className="fill-parent" style={{padding: "0px"}}>
                <DataExporter
                    title={"Data"}
                    generateData={() => convertHighchartsSeriesJSONtoCSV(chartOptions.series, this.props.timezone)}
                    MenuProps={{style: {float: "right", position: "relative", bottom: "30px"}}}
                />
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                    containerProps = {{
                        className: (this.props.className || ""),
                        style: Object.assign(this.defaultStyle, (this.props.style || {}))
                    }}
                    callback={(chart) => 5/*console.log(chart)*/}
                />
            </div>
        )
    }

    createChartOptions() {
        var chartDataOptions = this.createChartAxisAndSeriesOptions()
        var chartOptions = Highcharts.merge({
            time: {
                timezone: this.props.timezone,
            }
        }, defaultOptions, this.props.chartOptions, chartDataOptions)
        return chartOptions
    }

    createChartAxisAndSeriesOptions() {
        var series, yAxis;
        if (this.props.dataIsRaw) {
            var devices = this.props.services.assetFramework.getDevices()
            var tags = this.props.services.assetFramework.getTags()
            var dimensions = this.props.services.assetFramework.getDimensions()
            let [newSeries, newYAxis] = createSeriesFromRawData(this.props.data, devices, tags, dimensions)
            series = newSeries
            yAxis = newYAxis
        }
        else {
            if (this.props.provideChartComponents) {
                yAxis = this.props.data.yAxes
                series = this.props.data.series
            }
            else {
                yAxis = createYAxes(this.props.yAxes)
                series = createBasicSeriesFromRawData(this.props.data, this.props.yAxes.units, this.props.seriesValueDecimals)
            }
        }
        const options = {
            yAxis: yAxis,
            series: series,
        }
        if (series.length > 20) {
            options.tooltip = {shared: false}
            options.legend = {
                enabled: false
            }
        }
        return options
    }

}

export default BaseChart



const defaultStyle = {'height': 'calc(100% - 40px)', 'overflow': 'hidden'}

export const DataProviderChart = React.memo(({dataProviderProps, timezone, ...props}) => {

    useEffect(() => {
        reShowHighcharts()
    })

    var isDataReady = dataProviderProps.data!==null

    // Recreate chart options on every render
    var chartOptions = Highcharts.merge({
        time: {
            timezone: timezone,
        }
    }, defaultOptions, isDataReady ? props.buildChartConfig(dataProviderProps.data) : {})

    var content
    if (dataProviderProps.isLoading) {
        content = <Panel className="flow-vertical fill-parent" ><Loader size="sm" speed="fast" vertical content="loading.." /></Panel>
    }
    else if (dataProviderProps.isError) {
        content = <Panel className="flow-vertical fill-parent" >{dataProviderProps.errorMessage}</Panel>
    }
    else if (!isDataReady) {
        content = <Panel className="flow-vertical fill-parent">No Data.</Panel>
    }
    else {
        content = <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                        containerProps = {{
                            className: props.className || "",
                            style: Object.assign(defaultStyle, props.style || {})
                        }}
                        callback={(chart) => 0}
                    />
    }

    // Check if dataset is empty

    /*containerProps = {{
                            className: props.className || "",
                            style: Object.assign(defaultStyle, props.style || {})
                        }}*/

    /*var responseLength = data instanceof Array ? data.length : Object.keys(data).length
    if (responseLength === 0) {
        return <Panel className={className}>No data found</Panel>
    }*/

    const generateDownloadableData = () => convertHighchartsSeriesJSONtoCSV(chartOptions.series || [], timezone)

    return (
        <div className="flow-vertical fill-parent" style={{padding: "0px", height: "100%"}}>
            {
                props.renderTopBar ?
                props.renderTopBar({isDataReady, data: dataProviderProps.data, generateDownloadableData}) : 
                <div className="flow-horizontal" style={{alignItems: "end"}}>
                    {props.title && <div className="section-header">
                        <div className="title">{props.title}</div>
                    </div>}
                    {props.MenuComponent}
                    <DataExporter
                        title={"Data"}
                        generateData={generateDownloadableData}
                        IconProps={{style: {float: "right", position: "relative", marginBottom: "0px", color: "grey"}, size: "lg"}}
                    />
                </div>
            }
            {content}
        </div>
    )

}, (prevProps, nextProps) => {
    // If any of the below props change we should rerender
    return (
        prevProps.dataProviderProps.requestId===nextProps.dataProviderProps.requestId &&
        prevProps.dataProviderProps.isLoading===nextProps.dataProviderProps.isLoading &&
        prevProps.dataProviderProps.isError===nextProps.dataProviderProps.isError &&
        prevProps.dataProviderProps.errorMessage===nextProps.dataProviderProps.errorMessage &&
        prevProps.dataProviderProps.isDataReady===nextProps.dataProviderProps.isDataReady
    )
})