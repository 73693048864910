import './ProjectEquipment.css'
import React from 'react'
import { ProjectModule } from "../../table_configuration/ProjectEquipment"
import { ModuleSpec } from "../../table_configuration/EquipmentSpec"
import ViewPanel from '../ViewPanel'
import { generateUUID } from '../../../utils/databaseAppUtils'
import { useChangeLog, injectedChangeLogIdCol } from '../../../hooks/changeLog'
import { useDBViewFormValidation } from '../../../hooks/databaseViewFormValidation'
import { 
    ProjectEquipmentHeader, ProjectEquipmentGroupId, ProjectEquipmentSpec,
    ProjectEquipmentCount, ProjectEquipmentStatus, ProjectEquipmentEPCContract, ProjectEquipmentOwnerProvided, ProjectEquipmentNotes
} from './ProjectEquipment'
import { AddFloaterButtonWithPrompt } from '../../../components/button/FloaterButtonWithPrompt'
import { PalantirDatePicker } from '../../../components/input/SelectPicker'
import { makeTextMaskNumber, PalantirTextField } from '../../../components/input/Text'
import { EPCContract } from '../../table_configuration/Contract'
import _ from 'lodash'


const TextMaskInteger = makeTextMaskNumber({scale: 0})
const TextMaskNumber = makeTextMaskNumber({scale: 6})

export default function ProjectModuleProxy(props) {

    const moduleGroupRecords = props.data[ProjectModule.buildId()].filter(x => x[ProjectModule.columnSchema.plantId]===props.selectedPlantId)
    const moduleSpecRecords = props.data[ModuleSpec.buildId()]
    const epcContractRecords = props.data[EPCContract.buildId()].filter(x => x[EPCContract.columnSchema.plantId]===props.selectedPlantId)

    return (
        <ProjectModulePanel
            initialModuleGroupRecords={moduleGroupRecords}
            moduleSpecRecords={moduleSpecRecords}
            epcContractRecords={epcContractRecords}
            validateCreateModuleGroup={(record, newRecord) => true}
            {...props}
        />
    )

}

const ProjectModulePanel = ({initialModuleGroupRecords, moduleSpecRecords, epcContractRecords, ...props}) => {

    const [
        moduleGroupRecords, prepareModuleGroupLog,
        updateModuleGroupLog, addToModuleGroupLog, deleteFromModuleGroupLog, bulkOpOnModuleGroupLog, mergeAndResetModuleGroupLog
    ] = useChangeLog(initialModuleGroupRecords, ProjectModule)
    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()

    const addToModuleGroupLogProxy = () => {
        let moduleGroup = ProjectModule.buildNewRecord()
        moduleGroup[ProjectModule.columnSchema.moduleGroupId] = generateUUID()
        moduleGroup[ProjectModule.columnSchema.plantId] = props.selectedPlantId
        addToModuleGroupLog(moduleGroup)
    }
    const deleteFromModuleGroupLogProxy = (moduleGroup) => {
        deleteFromModuleGroupLog(moduleGroup)
    }

    const moduleGroupComponents = moduleGroupRecords.map(moduleGroup => {
        return (
            <ProjectModuleForm
                key={moduleGroup[ProjectModule.columnSchema.moduleGroupId]}
                moduleGroup={moduleGroup}
                moduleSpecRecords={moduleSpecRecords}
                epcContractRecords={epcContractRecords}
                updateModuleGroupLog={(update) => updateModuleGroupLog(moduleGroup, update)}
                deleteFromModuleGroupLog={() => deleteFromModuleGroupLogProxy(moduleGroup)}
                errors={errors}
                removeErrors={removeErrors}
                services={props.services}
            />
        )
    })

    return (
        <ViewPanel
            services={props.services}
            title="Project Module Groups"
            submitUrl='api/precious/table/project_module'
            verifySubmit={(payload) => {
                return verifyChangelogSubmission(
                    {
                        changeLog: payload.projectModuleChangelog,
                        checks: [{
                            checkColumn: ProjectModule.columnSchema.moduleSpecId,
                            checkFunction: "nullCheck",
                            errMessage: "Please select a module specification.",
                            path: [
                                {name: ProjectModule.columnSchema.moduleGroupId, type: "eval"},
                                {name: ProjectModule.buildId(), type: "static"},
                                {name: injectedChangeLogIdCol, type: "eval"}
                            ]
                        }]
                    }
                )
            }}
            onSubmitSuccess={(response, requestPayload) => {
                props.handleUpdate(false)
                resetErrors()
                mergeAndResetModuleGroupLog()
            }}
            onSubmitError={null}
            buildSubmitPayload={() => {
                return {
                    projectModuleChangelog: prepareModuleGroupLog(),
                }
            }}
        >
            {moduleGroupComponents}
            <AddFloaterButtonWithPrompt
                onClick={addToModuleGroupLogProxy}
                labelContent="Add new module group"
                height={18}
                width={18}
            />
        </ViewPanel>
    )
}

const ProjectModuleForm = React.memo(function({moduleGroup, moduleSpecRecords, epcContractRecords, updateModuleGroupLog, deleteFromModuleGroupLog, errors, removeErrors, services}) {

    const moduleGroupId = moduleGroup[ProjectModule.columnSchema.moduleGroupId]
    const moduleChangeLogId = moduleGroup[injectedChangeLogIdCol]    

    const moduleSpecErrorPath = [moduleGroupId, ProjectModule.buildId(), moduleChangeLogId]
    
    return (
        <div className="field-group form-instance project-equipment">
            <ProjectEquipmentHeader
                ProjectEquipmentTable={ProjectModule}
                deleteFromEquipmentGroupLog={deleteFromModuleGroupLog}
                removeErrorsOnDelete={() => removeErrors([moduleGroupId])}
            />
            <div className="flow-horizontal body">
                <div className="flow-vertical vertical-children-spacing equipment-meta-col">
                    <ProjectEquipmentGroupId
                        ProjectEquipmentTable={ProjectModule}
                        value={moduleGroupId}
                        style={{width: null}}
                    />
                    <ProjectEquipmentStatus
                        equipmentGroup={moduleGroup}
                        statusCol={ProjectModule.columnSchema.status}
                        updateEquipmentGroup={updateModuleGroupLog}
                    />
                    <ProjectEquipmentEPCContract
                        equipmentContractIdCol={EPCContract.columnSchema.contractId}
                        equipmentGroupEPCContractId={moduleGroup[ProjectModule.columnSchema.contractId]}
                        epcContracts={epcContractRecords}
                        updateEquipmentGroup={updateModuleGroupLog}
                        services={services}
                    />
                    <ProjectEquipmentOwnerProvided
                        equipmentGroup={moduleGroup}
                        ownerProvidedCol={ProjectModule.columnSchema.isOwnerProvided}
                        updateEquipmentGroup={updateModuleGroupLog}
                    />
                    <ProjectEquipmentCount
                        ProjectEquipmentTable={ProjectModule}
                        equipmentGroup={moduleGroup}
                        equipmentGroupCountCol={ProjectModule.columnSchema.moduleCount}
                        updateProjectEquipmentLog={updateModuleGroupLog}
                    />
                    <PalantirTextField
                        label="String Size"
                        value={moduleGroup[ProjectModule.columnSchema.stringSize]}
                        InputProps={{inputComponent: TextMaskNumber}}
                        onChange={(x) => updateModuleGroupLog({[ProjectModule.columnSchema.stringSize]: x})}
                    />
                    <PalantirTextField
                        label="String Count"
                        value={moduleGroup[ProjectModule.columnSchema.stringCount]}
                        InputProps={{inputComponent: TextMaskInteger}}
                        onChange={(x) => updateModuleGroupLog({[ProjectModule.columnSchema.stringCount]: x})}
                    />
                    <PalantirTextField
                        label="GCR"
                        value={moduleGroup[ProjectModule.columnSchema.gcr]}
                        InputProps={{inputComponent: TextMaskNumber}}
                        onChange={(x) => updateModuleGroupLog({[ProjectModule.columnSchema.gcr]: x})}
                    />
                    <ProjectEquipmentNotes
                        value={moduleGroup[ProjectModule.columnSchema.notes]}
                        notesColumn={ProjectModule.columnSchema.notes}
                        updateProjectEquipment={updateModuleGroupLog}
                    />
                </div>
                <div style={{marginRight: "30px"}}>
                    <div className="header">Module Spec</div>
                    <ProjectEquipmentSpec
                        equipmentGroup={moduleGroup}
                        equipmentSpecRecords={moduleSpecRecords}
                        updateEquipmentGroupLog={updateModuleGroupLog}
                        EquipmentComponents={[
                            (moduleSpec) => <PalantirTextField
                                key="pmax__w"
                                label="P Max (W)"
                                value={moduleSpec[ModuleSpec.columnSchema.pMaxW]}
                                disabled
                                style={{minWidth: "160px"}}
                            />
                        ]}
                        equipmentType="Module"
                        projectEquipmentSpecIdCol={ProjectModule.columnSchema.moduleSpecId}
                        equipmentSpecIdCol={ModuleSpec.columnSchema.moduleSpecId}
                        manufacturerCol={ModuleSpec.columnSchema.manufacturer}
                        modelCol={ModuleSpec.columnSchema.model}
                        errors={errors}
                        equipmentSpecErrorPath={moduleSpecErrorPath}
                        removeEquipmentSpecError={() => {
                            removeErrors(moduleSpecErrorPath)
                        }}
                    />
                </div>
                <div className="flow-vertical">
                    <div className="header">Dates</div>
                    <div className="flow-horizontal">
                        <div className="flow-vertical project-equipment-dates">
                            <PalantirDatePicker
                                label="Warranty Start"
                                value={moduleGroup[ProjectModule.columnSchema.warrantyStartDate]}
                                onChange={(date) => updateModuleGroupLog({[ProjectModule.columnSchema.warrantyStartDate]: date})}
                                style={{marginBottom: "30px"}}
                            />
                            <div className="header">Current Dates</div>
                            <PalantirDatePicker
                                label="Delivery Start"
                                value={moduleGroup[ProjectModule.columnSchema.deliveryStartDateCurrent]}
                                onChange={(date) => updateModuleGroupLog({[ProjectModule.columnSchema.deliveryStartDateCurrent]: date})}
                            />
                            <PalantirDatePicker
                                label="Delivery End"
                                value={moduleGroup[ProjectModule.columnSchema.deliveryEndDateCurrent]}
                                onChange={(date) => updateModuleGroupLog({[ProjectModule.columnSchema.deliveryEndDateCurrent]: date})}
                            />
                            <PalantirDatePicker
                                label="Purchase Order"
                                value={moduleGroup[ProjectModule.columnSchema.purchaseOrderDateCurrent]}
                                onChange={(date) => updateModuleGroupLog({[ProjectModule.columnSchema.purchaseOrderDateCurrent]: date})}
                            />
                        </div>
                        <div className="flow-vertical project-equipment-dates">
                            <PalantirDatePicker
                                label="Warranty End"
                                value={moduleGroup[ProjectModule.columnSchema.warrantyEndDate]}
                                onChange={(date) => updateModuleGroupLog({[ProjectModule.columnSchema.warrantyEndDate]: date})}
                                style={{marginBottom: "30px"}}
                            />
                            <div className="header">Budgeted Dates</div>
                            <PalantirDatePicker
                                label="Delivery Start"
                                value={moduleGroup[ProjectModule.columnSchema.deliveryStartDateBudgeted]}
                                onChange={(date) => updateModuleGroupLog({[ProjectModule.columnSchema.deliveryStartDateBudgeted]: date})}
                            />
                            <PalantirDatePicker
                                label="Delivery End"
                                value={moduleGroup[ProjectModule.columnSchema.deliveryEndDateBudgeted]}
                                onChange={(date) => updateModuleGroupLog({[ProjectModule.columnSchema.deliveryEndDateBudgeted]: date})}
                            />
                            <PalantirDatePicker
                                label="Purchase Order"
                                value={moduleGroup[ProjectModule.columnSchema.purchaseOrderDateBudgeted]}
                                onChange={(date) => updateModuleGroupLog({[ProjectModule.columnSchema.purchaseOrderDateBudgeted]: date})}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        _.isEqual(prevProps.moduleGroup, nextProps.moduleGroup) &&
        _.isEqual(prevProps.errors, nextProps.errors)
    )
})