import { useState } from 'react'

// Returns a function that can be used to dispatch a specified callback
// The dispatch initializes a timer for 500ms, at the end of which the callback is executed.
// Any new dispatches that are received before the timer expires will cancel the current timer and start a new one.
export function useInputDispatch(timeout=500) {

    var [inputTimerId, setInputTimerId] = useState(null)

    var dispatch = (cb) => {
        if (inputTimerId) {
            clearTimeout(inputTimerId)
        }
        setInputTimerId(setTimeout(() => {
            setInputTimerId(null)
            cb()
        }, timeout))
    }

    return dispatch
}