import './Menu.css'
import { Text, Menu } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash'


export const PalantirPopMenu = ({...props}) => {
    return (
        <Menu {...props}>
            {props.children}
        </Menu>
    )
}

export const PalantirPopMenuItem = ({...props}) => {
    return (
        <Menu.Item {...props} >
            {props.children}
        </Menu.Item>
    )
}

export const PalantirBasicCRUDMenu = ({selectedId, title, titleComponent, records, recordIdCol, recordNameCol, onSelect, isCreateActive, onAdd, iconFn, defaultSort=true, ...props}) => {

    let items = records
    if (defaultSort) items = _.sortBy(items, recordNameCol)
    items = items.map((record, idx) => {
        const recordId = record[recordIdCol]
        const recordName = record[recordNameCol]
        const props = {}
        if (iconFn) props.icon = iconFn(record)
        return (
            <PalantirMenuItem active={selectedId===recordId} onClick={() => onSelect(record)} {...props} >
                {recordName || <span style={{fontStyle: "italic"}}>*No Label</span>}
            </PalantirMenuItem>
        )
    })

    return (
        <PalantirMenu titleComponent={<PalantirMenuTitleWithCreate title={title} titleComponent={titleComponent} active={isCreateActive} onCreate={onAdd} />} {...props} >
            {items}
        </PalantirMenu>
    )
}

export const PalantirMenu = ({titleText, titleComponent, emptyMessage, children, ...props}) => {

    return (
        <div className="pltr-simple-menu panel flow-vertical" {...props} >
            {titleText && <div className="header"><Text weight={650} className="text">{titleText}</Text></div>}
            {titleComponent && <div className="header">{titleComponent}</div>}
            <div className="body fill-parent" style={{overflowY: "auto"}}>
                {children ? children : <Text  size="sm" style={{padding: "10px"}}>No items.</Text>}
            </div>
            <div className="footer"></div>
        </div>
    )
}

export const PalantirMenuItem = ({onClick, icon, active, ...props}) => {
    const itemClassName = "item flow-horizontal" + (active ? " active" : "")
    return (
        <div onClick={() => onClick ? onClick() : null} className="item-container">
            <div className={itemClassName}>
                {icon && <div className="icon">{icon}</div>}
                {props.children}
            </div>
        </div>
    )
}

export const PalantirMenuTitleWithCreate = ({title, titleComponent, active, onCreate}) => {
    return (
        <div className="flow-horizontal" style={{width: "100%", alignItems: "center"}}>
            {title ? <Text weight={650}>{title}</Text> : titleComponent}
            {onCreate ? <FontAwesomeIcon icon={faPlusCircle} onClick={onCreate} size="lg" className={"create-icon" + (active ? " active" : "")} style={{marginLeft: "auto"}} /> : null}
        </div>
    )
}


export const MenuItemDelete = ({onClick}) => {

    return (
        <Menu.Item
            icon={<FontAwesomeIcon size="sm" color="rgb(237 78 78)" icon={faTrash} />}
            onClick={onClick}
        >
            <Text size="xs">
                Delete
            </Text>
        </Menu.Item>
    )
}
