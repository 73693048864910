import {Component} from 'react'

import { tableGroups } from '../PreciousSchema'
import { views } from '../views/ViewSchema'
import MenuContainer from '../../components/menu/MenuContainer'


class TableMenu extends Component {

    constructor(props) {
        super()
        this.state = {

        }
    }

    shouldComponentUpdate(prevProps, prevState) {
        if (this.props.selectedTable!==prevProps.selectedTable) return true
        if (this.props.selectedView!==prevProps.selectedView) return true
        if (this.props.selectedEditMode!==prevProps.selectedEditMode) return true
        return false
    }

    setEditMode = () => {

    }

    render() {

        var menuSkeleton
        var itemClick

        if (this.props.selectedEditMode==="table") {
            menuSkeleton = this.buildTableHeirarchy()
            itemClick = this.props.setSelectedTable
        }
        else if (this.props.selectedEditMode==="view") {
            menuSkeleton = this.buildViewHeirarchy()
            itemClick = this.props.setSelectedView
        }

        return (
            <MenuContainer
                menuSkeleton={menuSkeleton}
                headerName={
                            <div className="flow-horizontal fill-parent" style={{justifyContent: "space-around"}}>
                                <div onClick={() => this.props.setEditMode("table")} className={this.props.selectedEditMode==="table" ? "edit-mode active" : "edit-mode"}>Tables</div>
                                <div onClick={() => this.props.setEditMode("view")} className={this.props.selectedEditMode==="view" ? "edit-mode active" : "edit-mode"}>Views</div>
                            </div>
                            }
                leafNodeClick={(menuItem) => itemClick(menuItem.cbData)}
                parentNodeClick={(menuItem) => itemClick(menuItem.cbData)}
                className="table-menu sidebar custom-dimensions fill-parent"
                searchable
            />
        )
    }

    buildViewHeirarchy = () => {

        return views.map(view => {

            const viewPanels = view.components
            const panelConfigs = viewPanels.map(panel => {

                return {
                    "key": panel.key,
                    "label": panel.label,
                    "cbData": panel,
                    "selectable": true,
                    "active": this.props.selectedView?.key===panel.key,
                    "children": null
                }
            })

            return {
                "key": view.key,
                "label": view.label,
                "cbData": view,
                "selectable": true,
                "active": this.props.selectedView?.key===view.key,
                "children": panelConfigs
            }
        })

    }

    buildTableHeirarchy = () => {
        return tableGroups.map(group => {

            let groupDisplayName = group.displayName
            let groupTables = group.tables

            const tableSkeletons = groupTables.map(table => {

                let schemaName = table.schemaName
                let tableName = table.tableName
                let tableDisplayName = table.displayNamePlural

                let isTableActive = this.props.selectedTable?.schemaName===schemaName && this.props.selectedTable?.tableName===tableName

                let HelpPanel = <table.buildHelpPanel />

                return {
                    "key": tableDisplayName,
                    "label": tableDisplayName,
                    "meta": HelpPanel,
                    "cbData": {
                        tableGroup: group,
                        table: table
                    },
                    "selectable": true,
                    "active": isTableActive,
                    "children": null
                }
            })

            return {
                "key": groupDisplayName,
                "label": groupDisplayName,
                "selectable": false,
                "active": false,
                "children": tableSkeletons
            }
        })
    }

}

export default TableMenu