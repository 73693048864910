import './AppSidebarMenu.css'

import { Palantooltip } from '../../components/tooltip/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faRightFromBracket, faCube, faArrowLeft, faWarehouse, faFolder, faChartArea, faXmark, faBars } from '@fortawesome/free-solid-svg-icons'
import { ActionIcon, Avatar, Text } from '@mantine/core';
import { Link, matchPath, NavLink, resolvePath, useLocation, useMatch, useOutletContext, useResolvedPath } from 'react-router-dom';
//import { ReactComponent as BlueprintIcon } from '../../svg/resource.svg'
import { LinkWithSParams } from '../../components/navigation/Link';
import { createContext, useContext, useState } from 'react';

const SidebarCollapsedContext = createContext(true);


export const AppSidebarMenu = () => {

    const [collapsed, setCollapsed] = useState(true)
    const toggleCollapsed = () => {
        setCollapsed((collapsed) => !collapsed)
    }

    const sidebarClassName = "user-menu-sidebar flow-vertical" + (collapsed ? " collapsed" : "")

    return (
        <SidebarCollapsedContext.Provider value={collapsed}>
            <div className={sidebarClassName}>

                <MenuItemContent
                    label={collapsed ? "Expand" : "Collapse"}
                    className="logout"
                    onClick={toggleCollapsed}
                    style={{cursor: "pointer"}}
                >
                    <MenuItemIconContainer>
                        <MenuItemIcon icon={faBars} />
                    </MenuItemIconContainer>
                </MenuItemContent>
                
                <Divider style={{marginTop: "5px"}} />

                <MenuLinkItem
                    label="Home"
                    link="/palantir"
                    exact
                    icon={<MenuItemIcon
                        icon={faHome}
                        iconColor="#6A68A2"
                    />}
                    expandedContent={<SidebarTextContent>Home</SidebarTextContent>}
                />

                <Divider />

                <MenuLinkItem
                    label="BI"
                    link="bi"
                    icon={<MenuItemIcon
                        icon={faChartArea}
                        iconColor="#D19B6B"
                    />}
                    expandedContent={<SidebarTextContent>BI</SidebarTextContent>}
                />
                <MenuLinkItem
                    label="Inverter Warehousing"
                    link="inverter_management"
                    icon={<MenuItemIcon
                        icon={faWarehouse}
                        iconColor="#AA554A"
                    />}
                    expandedContent={<SidebarTextContent>Inverter Warehousing</SidebarTextContent>}
                />
            </div>
        </SidebarCollapsedContext.Provider>
    )

}

const MenuLinkItem = ({link, icon, exact=false, matches, ...props}) => {
    const location = useLocation()
    const resolved = useResolvedPath(link);
    const match = useMatch({ path: resolved.pathname, end: exact });

    let isMatch = Boolean(match)

    if (matches && !isMatch) {
        matches.every(v => {
            let _match = matchPath({path: v, end: false}, location.pathname)
            if (_match) {
                isMatch = true
                return false
            }
            else return true
        })
    }

    return (
        <LinkWithSParams to={link}>
            <MenuItemContent className={`${isMatch ? 'active' : ''}`} {...props}>
                <ActionIcon className="nav-tab" variant="default" size="md">
                    {icon}
                </ActionIcon>
            </MenuItemContent>
        </LinkWithSParams>
    )
}
const MenuItemContent = ({label, className, expandedContent, children, ...props}) => {

    const collapsed = useContext(SidebarCollapsedContext);

    className = "action-cell" + (className ? ` ${className}` : "")
    return (
        <Palantooltip message={label} placement="right">
            <div className={className} {...props}>
                {children}
                {!collapsed ? <span className="expanded-content">{expandedContent}</span> : null}
            </div>
        </Palantooltip>
    )
}
const MenuItemIcon = ({icon, iconColor, ...props}) => {
    return (
        <FontAwesomeIcon icon={icon} size="lg" className="fa-icon" style={{color: iconColor}} {...props} />
    )
}

const MenuItemIconContainer = ({children, ...props}) => {

    return (
        <div style={{height: "28px", width: "28px", display: "flex", flexShrink: 0, alignItems: "center", justifyContent: "center"}} >
            {children}
        </div>
    )
}

const Divider = ({style={}}) => <hr style={Object.assign({width: "60%"}, style)}></hr>

const SidebarTextContent = ({children}) => {
    return (
        <Text size="sm" className="text" >
            {children}
        </Text>
    )
}