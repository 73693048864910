import { schemas } from '../Constants'
import { Plant } from './Project'
import Table from './BaseTable'
import { makeEntityId, makePlantId } from './CommonColumns'
import { buildDropdownItems, transformDisplayValue } from '../../utils/databaseAppUtils'
import { injectCounterpartyName, Interconnection } from './Counterparty'
import { Counterparty } from './Entity'
import { StateSelectionModal } from '../../components/modal/StateSelectionModal'



class InterconnectionExpectedCostsTable extends Table {

    constructor() {

        var columnSchema = {
            oid: "oid",
            name: "name",
            projectId: "project_id",
            queueSubmissionPayment: "queue_submission_payment",
            queueSubmissionPaymentDate: "queue_submission_payment_date",
            siteControlPayment: "site_control_payment",
            siteControlPaymentDate: "site_control_payment_date",
            numEffectiveEntities: "num_effective_entities",
            constructionAttachmentsFacilityPayment: "construction_attachments_facility_payment",
            constructionAttachmentsFacilityPaymentDate: "construction_attachments_facility_payment_date",
            constructionAttachmentsFacilitySecurityPosted: "construction_attachments_facility_security_posted",
            constructionAttachmentsFacilitySecurityPostedDate: "construction_attachments_facility_security_posted_date",
            constructionNetworkUpgradePayment: "construction_network_upgrade_payment",
            constructionNetworkUpgradePaymentDate: "construction_network_upgrade_payment_date",
            constructionNetworkUpgradeSecurityPosted: "construction_network_upgrade_security_posted",
            constructionNetworkUpgradeSecurityPostedDate: "construction_network_upgrade_security_posted_date",
            affectedSystemFacilityPayment: "affected_system_facility_payment",
            affectedSystemFacilityPaymentDate: "affected_system_facility_payment_date",
            affectedSystemFacilitySecurityPosted: "affected_system_facility_security_posted",
            affectedSystemFacilitySecurityPostedDate: "affected_system_facility_security_posted_date"
        }

        var schemaName = "interconnection"
        var tableName = "expected_costs"

        var displayNameSingular = "Interconnection Expected Costs"
        var displayNamePlural = "Interconnection Expected Costs"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [Plant]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }

    buildTableProps = (data) => {

        var projectRecords = this.findTableObjectRecords(data, Plant)
        var plantIdCol = Plant.columnSchema.plantId
        var plantNameCol = Plant.columnSchema.plantName

        return {
            itemSchema: this.buildNewRecord(),
            validateNewItem: null,
            validateUpdateItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Project",
                        key: this.columnSchema.projectId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(projectRecords, value, plantIdCol, plantNameCol),
                            dropdownItems: buildDropdownItems(projectRecords, plantIdCol, plantNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                            fixed: true
                        }
                    },
                    {
                        name: "Name",
                        key: this.columnSchema.name,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makePaymentColumnConfig("Queue Submission Payment", this.columnSchema.queueSubmissionPayment),
                    makeDateColumnConfig("Queue Submission Payment Date", this.columnSchema.queueSubmissionPaymentDate),
                    makePaymentColumnConfig("Site Control Payment", this.columnSchema.siteControlPayment),
                    makeDateColumnConfig("Site Control Payment Date", this.columnSchema.siteControlPaymentDate),
                    {
                        name: "Number of Effective Entities",
                        key: this.columnSchema.numEffectiveEntities,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                            min: 0,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makePaymentColumnConfig("Construction Attachments Facility Payment", this.columnSchema.constructionAttachmentsFacilityPayment),
                    makeDateColumnConfig("Construction Attachments Facility Payment Date", this.columnSchema.constructionAttachmentsFacilityPaymentDate),
                    makePaymentColumnConfig("Construction Attachments Facility Security Posted", this.columnSchema.constructionAttachmentsFacilitySecurityPosted),
                    makeDateColumnConfig("Construction Attachments Facility Security Posted Date", this.columnSchema.constructionAttachmentsFacilitySecurityPostedDate),
                    makePaymentColumnConfig("Construction Network Upgrade Payment", this.columnSchema.constructionNetworkUpgradePayment),
                    makeDateColumnConfig("Construction Network Upgrade Payment Date", this.columnSchema.constructionNetworkUpgradePaymentDate),
                    makePaymentColumnConfig("Construction Network Upgrade Security Posted", this.columnSchema.constructionNetworkUpgradeSecurityPosted),
                    makeDateColumnConfig("Construction Network Upgrade Security Posted Date", this.columnSchema.constructionNetworkUpgradeSecurityPostedDate),
                    makePaymentColumnConfig("Affected System Facility Payment", this.columnSchema.affectedSystemFacilityPayment),
                    makeDateColumnConfig("Affected System Facility Payment Date", this.columnSchema.affectedSystemFacilityPaymentDate),
                    makePaymentColumnConfig("Affected System Facility Security Posted", this.columnSchema.affectedSystemFacilitySecurityPosted),
                    makeDateColumnConfig("Affected System Facility Security Posted Date", this.columnSchema.affectedSystemFacilitySecurityPostedDate),
                    
                ]
            },
        }
    }

}

export const InterconnectionExpectedCosts = new InterconnectionExpectedCostsTable()

class InterconnectionStudyPhasePaymentTable extends Table {

    constructor() {

        var columnSchema = {
            oid: "oid",
            expectedCostsId: "expected_costs_id",
            paymentType: "payment_type",
            paymentAmount: "payment_amount",
            paymentDate: "payment_date"
        }

        var schemaName = "interconnection"
        var tableName = "study_phase_payments"

        var displayNameSingular = "Interconnection Expected Study Phase Payments"
        var displayNamePlural = "Interconnection Expected Study Phase Payments"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [InterconnectionExpectedCosts]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural,
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }

    buildTableProps = (data) => {

        var interconnectionExpectedCostRecords = this.findTableObjectRecords(data, InterconnectionExpectedCosts)
        const idCol = InterconnectionExpectedCosts.columnSchema.oid
        const nameCol = InterconnectionExpectedCosts.columnSchema.name

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Expected Costs Record",
                        key: this.columnSchema.expectedCostsId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(interconnectionExpectedCostRecords, value, idCol, nameCol),
                            dropdownItems: buildDropdownItems(interconnectionExpectedCostRecords, idCol, nameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Payment Type",
                        key: this.columnSchema.paymentType,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: InterconnectionStudyPhasePaymentEntityMapping.options.standardPaymentName
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    },
                    makePaymentColumnConfig("Payment Amount", this.columnSchema.paymentAmount),
                    makeDateColumnConfig("Payment Date", this.columnSchema.paymentDate)
                ]
            }
        }

    }

}
export const InterconnectionStudyPhasePayment = new InterconnectionStudyPhasePaymentTable()

class InterconnectionStudyPhasePaymentEntityMappingTable extends Table {

    constructor() {

        var columnSchema = {
            oid: "oid",
            interconnectionEntityId: "interconnection_entity_id",
            standardPaymentName: "standard_payment_name",
            entityPaymentName: "entity_payment_name"
        }

        var schemaName = "interconnection"
        var tableName = "study_phase_payment_entity_mapping"

        var displayNameSingular = "Interconnection Expected Study Phase Entity Mapping"
        var displayNamePlural = "Interconnection Expected Study Phase Entity Mappings"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [Interconnection]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural,
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
        this.initOptions()
    }

    initOptions() {
        this.options = {
            standardPaymentName: [{
                label: "Study Phase Payment 1", value: "Study Phase Payment 1"
            }, {
                label: "Study Phase Payment 2", value: "Study Phase Payment 2"
            }, {
                label: "Study Phase Payment 3", value: "Study Phase Payment 3"
            }, {
                label: "Study Phase Payment 4", value: "Study Phase Payment 4"
            }, {
                label: "Study Phase Payment 5", value: "Study Phase Payment 5"
            }]
        }
    }

    buildTableProps = (data) => {

        var interconnectionRecords = this.findTableObjectRecords(data, Interconnection)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)
        var interconnectionIdCol = Interconnection.columnSchema.interconnectionId
        var interconnectionNameCol = "interconnectionName"

        interconnectionRecords = injectCounterpartyName(interconnectionRecords, counterpartyRecords, interconnectionIdCol, interconnectionNameCol)

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Interconnection Counterparty",
                        key: this.columnSchema.interconnectionEntityId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(interconnectionRecords, value, interconnectionIdCol, interconnectionNameCol),
                            dropdownItems: buildDropdownItems(interconnectionRecords, interconnectionIdCol, interconnectionNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Standard Payment Name",
                        key: this.columnSchema.standardPaymentName,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.standardPaymentName
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Entity Payment Name",
                        key: this.columnSchema.entityPaymentName,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            }
        }

    }

}
export const InterconnectionStudyPhasePaymentEntityMapping = new InterconnectionStudyPhasePaymentEntityMappingTable()

class InterconnectionQueueSubmissionWindowTable extends Table {

    constructor() {

        var columnSchema = {
            oid: "oid",
            ISOId: "iso_id",
            states: "states",
            submissionWindow: "submission_window",
            comments: "comments"
        }

        var schemaName = "interconnection"
        var tableName = "interconnection_queue_submission_window"

        var displayNameSingular = "Interconnection Queue Submission Window"
        var displayNamePlural = "Interconnection Queue Submission Windows"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [Interconnection]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural,
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
        this.initOptions()
    }

    initOptions() {
        this.options = {
            states: [{
                label: "Study Phase Payment 1", value: "Study Phase Payment 1"
            }, {
                label: "Study Phase Payment 2", value: "Study Phase Payment 2"
            }, {
                label: "Study Phase Payment 3", value: "Study Phase Payment 3"
            }, {
                label: "Study Phase Payment 4", value: "Study Phase Payment 4"
            }, {
                label: "Study Phase Payment 5", value: "Study Phase Payment 5"
            }]
        }
    }

    buildTableProps = (data) => {

        var interconnectionRecords = this.findTableObjectRecords(data, Interconnection)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)
        var interconnectionIdCol = Interconnection.columnSchema.interconnectionId
        var interconnectionNameCol = "interconnectionName"

        interconnectionRecords = injectCounterpartyName(interconnectionRecords, counterpartyRecords, interconnectionIdCol, interconnectionNameCol)

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "ISO / RTO",
                        key: this.columnSchema.ISOId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(interconnectionRecords, value, interconnectionIdCol, interconnectionNameCol),
                            dropdownItems: buildDropdownItems(interconnectionRecords, interconnectionIdCol, interconnectionNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "States",
                        key: this.columnSchema.states,
                        cellType: "customRender",
                        render: (rowData, onChange) => {
                            return (
                                <StateSelectionModal
                                    initialStates={rowData[this.columnSchema.states]}
                                    onClose={(states) => {
                                        onChange({newData: states})
                                    }}
                                />
                            )
                            /*
                            return <EventTemplateModal
                                        services={this.props.services}
                                        selectedTemplateId={rowData.templateId}
                                        onClose={(selectedTemplateId) => {
                                            if (!selectedTemplateId) return
                                            var templateCode = _this.props.services.assetFramework.getEventTemplates().find(x => x._id===selectedTemplateId).code
                                            console.log(selectedTemplateId, templateCode)
                                            //onChange({newData: templateCode, colKey: "templateCode"})
                                            onChange({newData: selectedTemplateId, colKey: "templateId"})
                                        }}
                                    />*/
                        },
                        cellOptions: {
                            dropdownItems: this.options.states,
                            delimeter: ","
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Submission Window",
                        key: this.columnSchema.submissionWindow,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Comments",
                        key: this.columnSchema.comments,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            }
        }

    }

}
export const InterconnectionQueueSubmissionWindow = new InterconnectionQueueSubmissionWindowTable()

class InterconnectionNetworkUpgradesTable extends Table {

    constructor() {

        var columnSchema = {
            oid: "oid",
            source: "source",
            sourceDate: "source_date",
            ISORTO: "iso_rto",
            transmissionOwnerArea: "transmission_owner_area",
            queueId: "queue_id",
            kvClass: "kv_class",
            upgradeType: "upgrade_type",
            transmissionLine: "transmission_line",
            unitType: "unit_type",
            unitCt: "unit_ct",
            costDollars: "cost__dollars",
            notes: "notes",
        }

        var schemaName = "interconnection"
        var tableName = "network_upgrades"

        var displayNameSingular = "Interconnection Network Upgrades"
        var displayNamePlural = "Interconnection Network Upgrades"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [Interconnection]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural,
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
        this.initOptions()
    }

    initOptions() {
        this.options = {
            states: [{
                label: "Study Phase Payment 1", value: "Study Phase Payment 1"
            }, {
                label: "Study Phase Payment 2", value: "Study Phase Payment 2"
            }, {
                label: "Study Phase Payment 3", value: "Study Phase Payment 3"
            }, {
                label: "Study Phase Payment 4", value: "Study Phase Payment 4"
            }, {
                label: "Study Phase Payment 5", value: "Study Phase Payment 5"
            }]
        }
    }

    buildTableProps = (data) => {

        var interconnectionRecords = this.findTableObjectRecords(data, Interconnection)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)
        var interconnectionIdCol = Interconnection.columnSchema.interconnectionId
        var interconnectionNameCol = "interconnectionName"

        interconnectionRecords = injectCounterpartyName(interconnectionRecords, counterpartyRecords, interconnectionIdCol, interconnectionNameCol)

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Source",
                        key: this.columnSchema.source,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Source Date",
                        key: this.columnSchema.sourceDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "ISO / RTO",
                        key: this.columnSchema.ISORTO,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(interconnectionRecords, value, interconnectionIdCol, interconnectionNameCol),
                            dropdownItems: buildDropdownItems(interconnectionRecords, interconnectionIdCol, interconnectionNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Transmission Owner Area",
                        key: this.columnSchema.transmissionOwnerArea,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Queue ID",
                        key: this.columnSchema.queueId,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "kV Class",
                        key: this.columnSchema.kvClass,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Upgrade Type",
                        key: this.columnSchema.upgradeType,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Transmission Line",
                        key: this.columnSchema.transmissionLine,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Unit Type",
                        key: this.columnSchema.unitType,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Unit Ct.",
                        key: this.columnSchema.unitCt,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Total Cost ($)",
                        key: this.columnSchema.costDollars,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Notes",
                        key: this.columnSchema.notes,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            }
        }

    }

}
export const InterconnectionNetworkUpgrades = new InterconnectionNetworkUpgradesTable()



const makePaymentColumnConfig = (name, key) => {
    return {
        name: name,
        key: key,
        cellType: "editable",
        cellOptions: {
            type: "number",
            min: 0,
        },
        filterable: true,
        sortable: true,
        colProps: {
            width: 150,
            resizable: true,
        }
    }
}

const makeDateColumnConfig = (name, key) => {
    return {
        name: name,
        key: key,
        cellType: "date",
        filterable: true,
        sortable: true,
        colProps: {
            width: 150,
            resizable: true,
        }
    }
}