import '../../Help.css'

import { OptionNav } from '../../../navigation/SimpleNavs'

export function HelpForecastAnalyzer(props) {
    const Usage = () => (
        <div className="section">
            <p>Select the desired tag(s), and hover over the chart series to view the forecasts.</p>
            <p>
                When hovering over a 1 hour interval, the forecast that was made at the beginning
                 of the interval will be made visible, 
                 along with the historical data occuring before the interval.
            </p>
            <p className="footnote">
                Forecasts are snapshotted once an hour at the beginning of the hour (8:00, 9:00, ...).
            </p>
            <p>When hovering over a series, there are a few things to note:
                <ol>
                    <li>The solid portion of the series represents historical data.</li>
                    <li>
                        The highlighted (red) portion of the series represents the hour interval the cursor is hovering.
                        The beginning of the highlighted interval is when the forecast was made.
                    </li>
                    <li>
                        The dotted portion of the series represents the forecasted data.
                        This is the point-in-time forecast made during the beginning of the highlighted interval.
                    </li>
                </ol>
            </p>

            
        </div>
    )

    const Features = () => {
        return <div>
            <p className="section">Open the context menu by right clicking on the chart to operate the following functionality:</p>
            <div className="header-2">Freezing</div>
            <p className="section">Freezes all series in place, removing hover effects.</p>
            <p>This can be useful to check data values without changing the forecast.</p>
            <div className="header-2">Snapshotting</div>
            <p className="section">Creates a copy of all <i>visible</i> series and adds them as new, static series that <i>do not</i> change on hover.</p>
            <p>To remove a snapshot, left click the corresponding entry in the legend below the graph.</p>
            <p>This can be useful to compare multiple forecasts with eachother on the same chart.</p>
            <p className="footnote">* Chart performance may suffer when viewing multiple series or longer time ranges. Try disabling animations if this is the case.</p>
        </div>
    }

    const options = {"Usage": Usage, "Features": Features}

    return (
        <div className="palantir-helpnote">
            <div className="title">Forecast Analyzer</div>
            <hr></hr>
            <p>The forecast analyzer allows you to view historical, point-in-time forecasts with ease.</p>
            <OptionNav options={options} />
        </div>
    )
}