import './App.css';
import 'rsuite/lib/styles/index.less';

import React, {Component, useEffect, useRef, useState} from 'react';

import Navbar from './components/navbar/Navbar'
import HomeTab from './components/tabs/home/HomeTab'
import HistoryTab from './components/tabs/history/History'
import MonitorTab from './components/tabs/monitor/MonitorTab'
import MarketTab from './components/tabs/market/MarketTab'
import EventsTab from './components/tabs/events/Events'
import ConfigureTab from './components/tabs/configure/ConfigureTab'
import Panel from './components/panel/Panel'

import { injectServices } from './utils/ServiceInjector'
import { formatDate } from './utils/Dates';
import { list2Dict } from './utils/MiscUtils';
import { getStartTime, setStartTime, getEndTime, setEndTime, getResolution, setResolution } from './utils/UrlLocation'
import { startOfDay, endOfDay } from 'date-fns'
import axios from "axios";
import { Outlet, useOutletContext } from 'react-router-dom';


const App = () => {

    const { authServices } = useOutletContext()

    const assetFrameworkRef = useRef({
        portfolios: null,
        sites: null,
        devices: null,
        deviceTypes: null,
        deviceGroups: null,
        tags: null,
        alarms: null,
        dimensions: null,
    })
    const [isAssetFrameworkLoaded, setIsAssetFrameworkLoaded] = useState(false)
    const [errorLoadingAssetFramework, setErrorLoadingAssetFramework] = useState(false)

    useEffect(() => {
        setDefaultURLParams()

        axios.get('/api/asset_framework')
        .then(function(data) {
            setAssetFrameworkProxy(data.data)
            setIsAssetFrameworkLoaded(true)
            setErrorLoadingAssetFramework(false)
        })
        .catch(error => {
            if (error.response?.status === 401) {
                authServices.logout()
            }
            else {
                setIsAssetFrameworkLoaded(false)
                setErrorLoadingAssetFramework(true)
                console.log(error)
            }
        })
    }, [])

    const buildAssetFrameworkServices = () => {
        return {
            getPortfolios: getPortfolios,
            getSites: getSites,
            getDevices: getDevices,
            getDeviceTypes: getDeviceTypes,
            getDeviceGroup: getDeviceGroup,
            getDeviceGroups: getDeviceGroups,
            getDeviceGroupDeviceIds: getDeviceGroupDeviceIds,
            getTags: getTags,
            getDimensions: getDimensions,
            getAlarms: getAlarms,
            getEventBlueprints: getEventBlueprints,
            getEventTemplates: getEventTemplates,

            addEventTemplate: addEventTemplate,
            deleteEventTemplate: deleteEventTemplate,
            updateEventTemplate: updateEventTemplate,

            addEventBlueprint: addEventBlueprint,
            deleteEventBlueprint: deleteEventBlueprint,
            updateEventBlueprint: updateEventBlueprint,

            addDeviceTag: addDeviceTag,
            updateDeviceTag: updateDeviceTag,
            deleteDeviceTag: deleteDeviceTag,

            getSiteTimezone: getSiteTimezone,
        }
    }

    const getAssetFramework = () => {
        return assetFrameworkRef.current
    }
    
    const getPortfolios = () => {
        console.log(getAssetFramework())
        return getAssetFramework().portfolios
    }

    const getSites = (siteId) => {
        var sites = getAssetFramework().sites
        if (siteId) {
            sites = sites.find(x => x._id===siteId) || {}
        }
        return sites
    }

    const getDevices = (siteId, deviceIds) => {
        var allDevices = getAssetFramework().devices
        return allDevices.filter(x => {
            return (
                (siteId!==undefined ? x.site_id===siteId : true) &&
                (deviceIds!==undefined ? deviceIds.includes(x._id) : true)
            )
        })
    }
    
    const getDevice = (deviceId) => {
        var allDevices = getAssetFramework().devices
        return allDevices.find(x => deviceId===x._id)
    }

    const getDeviceGroups = (siteId) => {
        if (siteId) return getAssetFramework().deviceGroups.filter(x => x.site_id===siteId)
        else return getAssetFramework().deviceGroups
    }

    const getDeviceGroup = (deviceGroupId) => {
        return getAssetFramework().deviceGroups.find(x => x._id===deviceGroupId)
    }

    const getDeviceGroupDeviceIds = (deviceGroup) => {
        if (deviceGroup?.device_type_id) {
            return getDevices(deviceGroup.site_id).filter(x => x.device_type===deviceGroup.device_type_id)
        }
        else return getDevices(undefined, deviceGroup?.device_ids) || []
    }
    
    const getDeviceTypes = () => {
        return getAssetFramework().deviceTypes
    }

    const getTags = () => {
        return getAssetFramework().tags
    }

    const getAlarms = () => {
        return getAssetFramework().alarms
    }

    const getDimensions = () => {
        return getAssetFramework().dimensions
    }

    const getSiteTimezone = (siteId) => {
        return getSites(siteId)?.meta?.timezone
    }

    const getEventBlueprints = () => {
        return getAssetFramework().eventBlueprints
    }

    const getEventTemplates = () => {
        return getAssetFramework().eventTemplates
    }
    
    const addEventTemplate = (newTemplate) => {
        addAssetFrameworkItem(newTemplate, "eventTemplates")
    }

    const deleteEventTemplate = (deletedItem) => {
        deleteAssetFrameworkItem(deletedItem, "eventTemplates")
    }

    const updateEventTemplate = (updatedTemplate) => {
        updateAssetFrameworkItem(updatedTemplate, "eventTemplates")
    }

    const addEventBlueprint = (newBlueprint) => {
        addAssetFrameworkItem(newBlueprint, "eventBlueprints")
    }

    const deleteEventBlueprint = (deletedItem) => {
        deleteAssetFrameworkItem(deletedItem, "eventBlueprints")
    }

    const updateEventBlueprint = (updatedBlueprint) => {
        updateAssetFrameworkItem(updatedBlueprint, "eventBlueprints")
    }
        
    const getAssetFrameworkItem = (afItemKey, format) => {
        if (format==="raw") return getAssetFramework()[afItemKey].raw
        else if (format==="dict") return getAssetFramework()[afItemKey].dictionary
        else throw `Format must be one of 'raw', 'dict'. Got '${format}'.`
    }

    const addAssetFrameworkItem = (newItem, itemKey) => {
        getAssetFramework()[itemKey].push(newItem)
    }

    const deleteAssetFrameworkItem = (deletedItem, itemKey) => {
        var deletedItemId = deletedItem._id
        var items = getAssetFramework()[itemKey]
        var curIdx = items.findIndex(x => x._id===deletedItemId)
        if (curIdx===-1) return false
        deletedItem = items.splice(curIdx, 1)
        return deletedItem
    }

    const updateAssetFrameworkItem = (updatedItem, itemKey) => {
        var items = getAssetFramework()[itemKey]
        var itemId = updatedItem._id
        var curIdx = items.findIndex(x => x._id===itemId)
        if (curIdx===-1) return false
        items[curIdx] = updatedItem
    }

    const addDeviceTag = (deviceId, tag) => {
        const device = getDevice(deviceId)
        if (!device) return false
        device.tags.push(tag)
        return true
    }

    const updateDeviceTag = (deviceId, tag) => {
        const device = getDevice(deviceId)
        if (!device) return false
        const tagIdx = device.tags.findIndex(x => x.template_id===tag.template_id)
        if (tagIdx===-1) return false
        device.tags[tagIdx] = tag
        return true
    }

    const deleteDeviceTag = (deviceId, tagId) => {
        const device = getDevice(deviceId)
        if (!device) return false
        const tagIdx = device.tags.findIndex(x => x.template_id===tagId)
        if (tagIdx===-1) return false
        device.tags.splice(tagIdx, 1)
        return true
    }
    
    const setAssetFrameworkProxy = (response) => {

        /*const getAssetFramework = {
            portfolios: {
                raw: getAssetFramework.portfolios,
                dictionary: list2Dict(getAssetFramework.portfolios)
            },
            sites: {
                raw: getAssetFramework.sites,
                dictionary: list2Dict(getAssetFramework.sites)
            },
            devices: {
                raw: getAssetFramework.devices,
                dictionary: list2Dict(getAssetFramework.devices)
            },
            deviceTypes: {
                raw: getAssetFramework.device_types,
                dictionary: list2Dict(getAssetFramework.device_types)
            },
            tags: {
                raw: getAssetFramework.tags,
                dictionary: list2Dict(getAssetFramework.tags)
            },
            alarms: {
                raw: getAssetFramework.alarms,
                dictionary: list2Dict(getAssetFramework.alarms)
            },
            dimensions: {
                raw: getAssetFramework.dimensions,
                dictionary: list2Dict(getAssetFramework.dimensions)
            },
            eventBlueprints: {
                raw: getAssetFramework.event_blueprints,
                dictionary: list2Dict(getAssetFramework.event_blueprints)
            },
            eventTemplates: {
                raw: getAssetFramework.event_templates,
                dictionary: list2Dict(getAssetFramework.event_templates)
            },
        }*/
        
        assetFrameworkRef.current = {
            portfolios: response.portfolios,
            sites: response.sites,
            devices: response.devices,
            deviceGroups: response.device_groups,
            deviceTypes: response.device_types,
            tags: response.tags,
            alarms: response.alarms,
            dimensions: response.dimensions,
            eventBlueprints: response.event_blueprints,
            eventTemplates: response.event_templates,
        }
        setIsAssetFrameworkLoaded(true)
    }
    
    const setDefaultURLParams = () => {

        const defaultStartDate = formatDate(startOfDay(new Date()))
        const defaultEndDate = formatDate(endOfDay(new Date()))
        const defaultResolution = 0

        const selectedStartDate = getStartTime();
        if (!selectedStartDate) setStartTime(defaultStartDate);

        const selectedEndDate = getEndTime();
        if (!selectedEndDate) setEndTime(defaultEndDate);

        const selectedResolution = getResolution();
        if (!selectedResolution) setResolution(defaultResolution);

    }
  
    if (errorLoadingAssetFramework) {
        return <Panel>Failed to load assets</Panel>
    }
    if (!isAssetFrameworkLoaded) {
        return <Panel className=''>Loading</Panel>
    }
    
    const assetFrameworkServices = buildAssetFrameworkServices()

    return (
        <div className="activity-listener flow-vertical fill-parent">
            <Navbar 
                services={{}}
                includePeripherals
                defaultURL="home"
                items={[
                    {
                        label: "Home",
                        url: "",
                        exact: true,
                        component: HomeTab,
                    },
                    {
                        label: "Monitor",
                        url: "monitor",
                        component: MonitorTab,
                    },
                    {
                        label: "Data",
                        url: "history",
                        component: HistoryTab,
                    },
                    {
                        label: "Market",
                        url: "market",
                        component: MarketTab,
                    },
                    {
                        label: "Events",
                        url: "events",
                        component: EventsTab,
                    },
                    {
                        label: "Botman",
                        url: "configure",
                        component: ConfigureTab,
                    },
                ]}
            />
            <Outlet context={{authServices, assetFrameworkServices}} />
        </div>
    )

}

export default App;