export const PowerBITab = () => {

    return (
        <div
            className="fill-parent"
            style={{margin: "10px", border: "solid lightgrey 1px"}}
        >
            <iframe
                title="Arevon - Project Info"
                className="arevon-powerbi fill-parent"
                width="100%"
                height="100%"
                src="https://app.powerbi.com/reportEmbed?reportId=2945c3de-1021-4a63-8330-05e0b281fbe7&autoAuth=true&ctid=8bd4170c-dfc6-4c65-979b-3989c4d32f92&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtdXMtYi1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9"
                frameborder="0"
                allowFullScreen="true"
            ></iframe>
        </div>
    )
}
