import { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv'
import { Menu, Popover, Button, ActionIcon } from '@mantine/core';


const defaultStyle = {}

const DataExporter = ({title, generateData, IconProps, MenuProps={}, ...props}) => {

    const [headers, setHeaders] = useState([])
    const [data, setData] = useState([])

    return (
        <Menu
            control={<ActionIcon {...IconProps}>
                <FontAwesomeIcon
                    icon={faEllipsisVertical}
                />
            </ActionIcon>}
            {...MenuProps}
        >
            <CSVLink 
                headers={headers}
                filename={`${title}.csv`}
                data={data}
                asyncOnClick={true}
                onClick={(event, done) => {
                    const [headers, data] = generateData()
                    setHeaders(headers)
                    setData(data)
                    done(true)
                }}
                {...props}
            >
                <Menu.Item icon={<FontAwesomeIcon icon={faDownload} />}>Download</Menu.Item>
            </CSVLink>
        </Menu>
    )

}

export default DataExporter