import './ChartOptionButton.css'
import {Component} from 'react'

class ChartOptionButton extends Component {

    constructor(props) {
        super()
        this.className = "chart-option-btn"
        this.appearances = ["primary", "secondary"]
    } 

    render() {

        var appearance = "secondary"

        if (this.appearances.includes(this.props.appearance)) {
            appearance = this.props.appearance
        }

        var className = this.className + " " + appearance
        className = className.concat((this.props.active ? " active" : ""))

        return (
            <button onClick={(x) => this.props.onClick(this.props.children)} className={className} style={this.props.style}>
                {this.props.children}
            </button>
        )
    }

} 

export default ChartOptionButton