import './AppSelector.css'
import {Component, useLayoutEffect, useState} from 'react'
import {
    useOutletContext,
    BrowserRouter,
    //Switch,
    Route,
} from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faLightbulb, faRightFromBracket } from '@fortawesome/free-solid-svg-icons'

import App from '../../App';
import DatabaseApp from '../../DatabaseApp'
import { Button, Text } from '@mantine/core'
import { prefixPathWithAPIDomain } from '../../utils/image'


const AppSelector = (props) => {

    const { authServices } = useOutletContext()

    var user = authServices.user
    var isSuperuser = user.is_superuser
    var user_perms = user.permissions || []
    var palantir_access = user_perms.includes("app_access_palantir") || isSuperuser
    var database_access = user_perms.includes("app_access_database") || isSuperuser

    var content = palantir_access || database_access ? <div className="flow-horizontal fill-parent application-list">
        {palantir_access && <a href="palantir" className="app-choice">Palantir</a>}
        {database_access && <a href="precious" className="app-choice">Database</a>}
    </div> : <div className="flow-horizontal fill-parent application-list" ><span className="no-apps">Sorry you don't have access to any applications at this time.</span></div>

    return (
        <div className="flow-horizontal fill-parent" style={{alignItems: "center"}}>
            <div className="flow-vertical welcome-dialog">
                <div className="logo-container">
                    <img src={prefixPathWithAPIDomain("/static/arevon_logo_large.png")} className="logo"></img>
                </div>
                {/*<div className="flow-horizontal" style={{marginBottom: "20px"}}>
                    <img src="/logo192.png" height="40px" width="40px"></img>
                    <div style={{width: "1px", height: "30px", backgroundColor: "lightgrey", margin: "0 15px"}}></div>
                    <img src="/logo192.png" height="40px" width="40px"></img>
                </div>*/}
                <Text className="prompt" color="#0e688c">Welcome back, {user.first_name}!</Text>
            </div>
            <Test className="container-one" calculateWidth={calculateLeftWidth}>
            {/*<div className="flow-verticalz fill-parent app-select-app-container container-one" style={{width: ((window.innerHeight/2)+80)+"px"}}>*/}
                <div className="flow-vertical content"  style={{position: "relative", left: -75}}>
                    <Text>I'm here for Pharos</Text>
                    <FontAwesomeIcon icon={faLightbulb} size="lg" style={{height: "10%", width: "10%"}} />
                    {/*<img src="static/lighthouse.jpg" height={100} width={100} />*/}
                    <Button color="gray" variant="light" style={{color: "#05425B"}}>
                        <a href="pharos">Continue</a>
                    </Button>
                </div>
            {/*</div>*/}
            </Test>
            <Test className="container-two" calculateWidth={calculateRightWidth}>
            {/*<div className="flow-verticalz fill-parent app-select-app-container container-two" style={{width: ((window.innerHeight/2)-80)+"px"}}>*/}
                <div className="flow-vertical content">
                    <Text className="test">I'm here for Palantir</Text>
                    <FontAwesomeIcon icon={faDatabase} size="lg" style={{height: "10%", width: "10%"}} />
                    <Button color="gray" variant="light" style={{color: "#74D3DE"}}>
                        <a href="palantir">Continue</a>
                    </Button>
                </div>
            {/*</div>*/}
            </Test>
        </div>
    )
}

const calculateLeftWidth = () => {
    return ((window.innerHeight/2)+((window.innerHeight/5.5)/2))+"px"
}
const calculateRightWidth = () => {
    return ((window.innerHeight/2)-((window.innerHeight/5.5)/2))+"px"
}

const Test = ({className, calculateWidth, ...props}) => {

    const [width, setWidth] = useState()
    useLayoutEffect(() => {
        const updateWidth = () => setWidth(calculateWidth())
        window.addEventListener('resize', updateWidth);
        updateWidth()
        return () => window.removeEventListener('resize', updateWidth);
    }, [])

    return (
        <div className={"flow-verticalz fill-parent app-select-app-container "+className} style={{width: width}}>
            {props.children}
        </div>
    )
}


export default AppSelector