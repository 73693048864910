import { DataProviderChart } from '../../charts/BaseChart'
import { TopBarVariant1 } from '../../charts/TopBars'
import { createBasicSeries } from '../../../utils/Highcharts'
import DatePicker, { useDateRangeSelectionState, longRanges } from '../../date_picker/DateRangePicker'
import { useDataProvider, DataProviderInterceptor } from '../../data_provider/StandardDataProvider'
import Highcharts from 'highcharts'
import { useEffect } from 'react'
import { startOfDay, addDays  } from 'date-fns'
import { Text } from '@mantine/core'
import DataExporter from '../../data_exporter/DataExporter'
import { useOutletContext } from 'react-router-dom'


const BESSAvailability = ({timezone, selectedSiteId}) => {

    const { authServices } = useOutletContext()

    const [startDate, endDate, setDateRange] = useDateRangeSelectionState({useUrlParam: false, initStartDate: startOfDay(addDays(new Date(), -6))})
    const dataProviderProps = useDataProvider({
        requestMethod: "POST",
        requestUrl: '/api/summary/bess_availability',
        onUnauthorized: authServices.logout,
        buildRequestBody: () => {
            return {
                siteId: selectedSiteId,
                startDate: startDate,
                endDate: endDate
            }
        }
    })

    useEffect(() => {
        dataProviderProps.refreshData()
    }, [selectedSiteId, startDate, endDate])

    const buildChartConfig = (data) => {

        let seriesData = Object.entries(data.availability).map(entry => {
            let [ts, availabilityValue] = entry
            let availabilityPowerValue = data.availabilityPower[ts]
            let availabilityEnergyValue = data.availabilityEnergy[ts]
            return {
                x: Number(ts),
                y: availabilityValue,
                custom: {
                    data: {
                        availabilityPower: {
                            units: "%",
                            v: availabilityPowerValue
                        },
                        availabilityEnergy: {
                            units: "%",
                            v: availabilityEnergyValue
                        }
                    }
                }
            }
        })

        let availabilitySeries = createBasicSeries({
            data:  seriesData,
            label: "Availability",
            axisUnits: " %",
            step: undefined,
            tooltip: {
                valueDecimals: 1,
                pointFormatter: function() {
                    return `
                    
                        <span style="color:${this.color}">●</span> ${this.series.name}: <b>${this.y.toFixed(1)} %</b> <br/>
                        Availability (Power): <b>${(Math.round(this.custom.data.availabilityPower.v*100)/100).toFixed(1)} ${this.custom.data.availabilityPower.units}</b> <br/>
                        Availability (Energy): <b>${(Math.round(this.custom.data.availabilityEnergy.v*100)/100).toFixed(1)} ${this.custom.data.availabilityEnergy.units}</b> <br/>
                    
                    `
                },
            },
        })

        return {
            chart: {
                type: "line"
            },
            legend: {
                enabled: false
            },
            series: [availabilitySeries],
            yAxis: [{title: {text: "Availability (%)"}, min: 0, max: 120}]
        }
    }

    return (
        <div className="flow-vertical fill-parent">
            <DataProviderChart
                dataProviderProps={dataProviderProps}
                timezone={timezone}
                buildChartConfig={buildChartConfig}
                renderTopBar={({...args}) => {
                    return (
                        <TopBarVariant1
                            title="BESS Technical Availability"
                            startDate={startDate}
                            endDate={endDate}
                            setDateRange={setDateRange}
                            createContent={(isDataReady, data) => {
                                return (
                                    isDataReady ?
                                    <div className="flow-horizontal" style={{alignItems: "baseline", marginRight: "40px", marginLeft: "auto"}}>
                                        <Text weight={500} size="xl" style={{marginRight: "5px"}}>{data.totalAvailabilityOverPeriod.toFixed(2)}%</Text>
                                        <Text weight={400} size="lg" style={{float: "right"}}>Availability</Text>
                                        <Text weight={100} size="md" style={{marginLeft: "5px"}}>over this period.</Text>
                                    </div> :
                                    null
                                )
                            }}
                            style={{padding: "15px 15px 0 15px"}}
                            DatePickerProps={{ranges: longRanges}}
                            {...args}
                        />
                    )
                }}
            />    
        </div>
    )
}
export default BESSAvailability