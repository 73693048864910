import {Component} from 'react'

import DataProvider from '../../../data_provider/StandardDataProvider'
import BaseChart from '../../../charts/BaseChart'

import { createBasicSeries, createYAxes } from '../../../../utils/Highcharts'

class RevenueContainer extends Component {
    constructor(props) {
        super()
        this.state = {}
    }

    render() {
        return (
            <div className="flow-vertical section-panel fill-parent">
                <div className="section-header">
                    <div className="title">Revenue</div>
                </div>
                <DataProvider
                    services={this.props.services}
                    selectedSiteId={this.props.selectedSiteId}
                    selectedStartDate={this.props.selectedStartDate}
                    selectedEndDate={this.props.selectedEndDate}
                    selectedResolution={this.props.selectedResolution}
                    queryProps={["selectedSiteId", "selectedStartDate", "selectedEndDate", "selectedResolution"]}
                    queryUrl={'/api/market/revenue'}
                    render={this.renderChart}
                />
            </div>
        )
    }

    renderChart = (data, dataRequestId) => {

        var revenueAxis = createYAxes({title: "Revenue", units: "$"})
        var powerAxis = createYAxes({title: "Power", units: "MW"}, true)

        var revenueData = data.find(x => Number(x.tag)===39)
        var powerData = data.find(x => Number(x.tag)===43)

        var revenueSeries = createBasicSeries({data: revenueData.data, label: revenueData.label, axisUnits: "$", yAxisIndex: 0, type: "column", valueDecimals: 2})
        var powerSeries = createBasicSeries({data: powerData.data, label: powerData.label, axisUnits: "MW", yAxisIndex: 1, type: "line", valueDecimals: 2})

        return <BaseChart 
                    services={this.props.services}
                    timezone={this.props.services.assetFramework.getSiteTimezone(this.props.selectedSiteId)}
                    data={{
                        yAxes: [revenueAxis, powerAxis],
                        series: [revenueSeries, powerSeries]
                    }}
                    dataRequestId={dataRequestId}
                    provideChartComponents={true}
                    chartOptions={this.props.chartOptions}
                />
    }

}

export default RevenueContainer