import { useEffect, useState } from 'react'

import { injectServices } from '../utils/ServiceInjector'
import PlantMenu from '../precious_components/menu/PlantMenu'
import TableMenu from '../precious_components/menu/TableMenu'
import TableEditingContainer from '../precious_components/TableEditingContainer'
import ViewContainer from '../precious_components/ViewContainer'
import HelpPanel from '../components/help/HelpPanel'

import DisplayMode from '../precious_components/display_mode/DisplayMode'
import { Alert } from 'rsuite'
import { useOutletContext } from 'react-router-dom'


export const HomeTab = () => {

    const { services } = useOutletContext()

    const [displayMode, setDisplayMode] = useState("project")
    const [editMode, setEditMode] = useState("table")
    const [selectedPortfolio, setSelectedPortfolio] = useState(null)
    const [selectedPlant, setSelectedPlant] = useState(null)
    const [selectedTableGroup, setSelectedTableGroup] = useState(null)
    const [selectedTable, setSelectedTable] = useState(null)
    const [selectedView, setSelectedView] = useState(null)

    const setDisplayModeProxy = (mode) => {
        if (mode==="table" && editMode==="view") {
            Alert.error("Table display mode can not be entered while in view edit mode.")
        }
        else{
            setDisplayMode(mode)
        }
    }
    const setSelectedTableProxy = (data) => {
        setSelectedTableGroup(data.tableGroup)
        setSelectedTable(data.table)
    }
    const setSelectedViewProxy = (view) => {
        setSelectedView(view)
    }
    const setEditModeProxy = (mode) => {
        if (mode==="view" && displayMode==="table") {
            Alert.info("Display mode has been switched to project.")
            setEditMode(mode)
            setDisplayMode("project")
        }
        else {
            setEditMode(mode)
        }
    }
    const setSelectedPlantProxy = (plant) => {
        const newSelection = plant===selectedPlant ? null : plant
        setSelectedPortfolio(null)
        setSelectedPlant(newSelection)
    }
    const setSelectedPortfolioProxy = (portfolio) => {
        //const newSelection = portfolio===this.state.selectedPortfolio ? null : portfolio
        setSelectedPortfolio(portfolio)
        setSelectedPlant(null)
    }

    const servicesInjected = injectServices(services, 'displayMode', {
        setDisplayModeTable: () => setDisplayModeProxy("table")
    })


    var editContainer = (
        editMode==="table" ? 
        <TableEditingContainer
            services={servicesInjected}
            displayMode={displayMode}
            selectedPortfolioId={selectedPortfolio}
            selectedPlantId={selectedPlant}
            selectedTableGroup={selectedTableGroup}
            selectedTable={selectedTable}
        /> : 
        <HelpPanel
            services={servicesInjected}
            render={(newServices) =>
                <ViewContainer
                    services={newServices}
                    selectedPlantId={selectedPlant}
                    selectedPortfolioId={selectedPortfolio}
                    selectedView={selectedView}
                />
            }
        />
    )

    return (
        <div className="flow-horizontal fill-parent">
            <div className="sidebar flow-vertical" style={{minWidth: "250px", maxWidth: "250px"}}>
                <DisplayMode activeMode={displayMode} setDisplayMode={setDisplayModeProxy} />
                <TableMenu
                    setSelectedTable={setSelectedTableProxy}
                    setSelectedView={setSelectedViewProxy}
                    setEditMode={setEditModeProxy}
                    selectedTable={selectedTable}
                    selectedView={selectedView}
                    selectedEditMode={editMode}
                />
            </div>
            {displayMode === "project" && 
            <div className="flow-vertical" style={{overflow: "visible"}}>
                <div className="plant-menu-top-filler"></div>
                <PlantMenu
                    portfolios={servicesInjected.assets.getPortfolios()}
                    plants={servicesInjected.assets.getPlants()}
                    setSelectedPlant={setSelectedPlantProxy}
                    setSelectedPortfolio={setSelectedPortfolioProxy}
                    selectedPortfolioId={selectedPortfolio}
                    selectedPlantId={selectedPlant}
                />
            </div>}
            <div className="flow-vertical fill-parent">
                {editContainer}
            </div>
        </div>
    )
}