import '../Help.css'
import {Whisper, Popover} from 'rsuite'

export function IntervalTag(props) {
    return (
        <div className={"palantir-helpnote"+(props.className ? " "+props.className : "")}>
            <div className="title">Interval Tag</div>
            <hr></hr>
            <p>
                An interval value is a single value calculated for a tag by aggregating the source data collected over the course of the <IntervalPopover>interval</IntervalPopover>.
                The value is then saved as an interval value of the tag, and is available to view in the data tab by selecting the corresponding tag in the interval tag menu. 
            </p>
            <p>The aggregation type used is specified in the tag template, and can be one of: <i>mean, min, max, first, last, sum</i>. The default is mean.</p>
            <p>Generally, interval values are calculated every 5 minutes.</p>
            <div className="header">Example</div>
            <p>Say it's 5:00PM. This means interval values for all appropriate tags are being computed right now.</p>
            <p>Let's focus on a single tag: <i>energy_delivered</i></p>
            <ol>
                <li>All of the raw energy_delivered data collected from 4:55-5:00 will be aggregated, using the tag's default aggregation setting which in this case would be average</li> 
                <li>The resulting value, representing the average energy_delivered in the last 5 minutes, is stored as an interval value for the tag.</li>
                <li>You may then view the data in the data tab.</li>
            </ol>
            <p>This process is repeated at 5:05 for all raw data collected from 5:00 - 5:05, and so on to infinity and beyond.</p>
            <hr></hr>
            <p>Note: interval values are only calculated for sites/devices that subscribe to the interval tag service.</p>
        </div>
    )
}
export function IntervalTagPopover(props) {
    return createPopover(IntervalTag, props)
}

export function Interval(props) {
    return (
        <div className={"palantir-helpnote"+(props.className ? " "+props.className : "")}>
            <div className="title">Interval</div>
            <hr></hr>
            <p>
                Most Palantir services operate in units of time referred to as intervals. 
            </p>
            <p>Services run at the end of each interval, and generally process any actions that took place within the interval, notably raw data that was received.</p>
            <p>Generally, intervals are 5 minutes long and begin at 5 minute markers (5:00, 5:05, 5:10, ...)</p>
        </div>
    )
}
export function IntervalPopover(props) {
    return createPopover(Interval, props)
}




function createPopover(Component, props) {
    const speaker = (
        <Popover>
          <div style={{maxWidth: "400px"}}><Component className="sm" /></div>
        </Popover>
    );
    return (
        <Whisper {...props} trigger="hover" preventOverflow={true} enterable speaker={speaker}>
            <span className="palantir-info-link">{props.children}</span>
        </Whisper>
    )
}