import './Tooltip.css'
import { Tooltip, Whisper } from 'rsuite';


export const Palantooltip = ({message, children, ...props}) => {

    return (
        <Whisper
            delay={500}
            placement="top"
            enterable={true}
            trigger="hover"
            speaker={
                <Tooltip
                    style={{
                        zIndex: 10000
                    }}
                >
                        {message}
                </Tooltip>
            }
            {...props}
        >
            {children}
        </Whisper>
    )
}
