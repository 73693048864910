import './PlantMenu.css'
import {Component} from 'react'

import _ from 'lodash'
import MenuContainer from '../../components/menu/MenuContainer'


class PlantMenu extends Component {

    constructor(props) {
        super()
        this.state = {

        }
    }

    shouldComponentUpdate(prevProps, prevState) {
        return true
    }

    render() {

        return (
                    <MenuContainer
                        menuSkeleton={this.buildTableHeirarchy()}
                        headerName={"Portfolios"}
                        leafNodeClick={(menuItem) => this.props.setSelectedPlant(menuItem.key)}
                        //parentNodeClick={(menuItem) => this.props.setSelectedPortfolio(menuItem.key)} 
                        className="plant-menu sidebar fill-parent"
                        searchable
                        collapsable
                    />
        )
    }

    buildTableHeirarchy = () => {
        return _.sortBy(this.props.portfolios, x => x.portfolio_name).map(portfolio => {

            let portfolioId = portfolio.portfolio_id
            let portfolioName = portfolio.portfolio_name

            let isPortfolioActive = this.props.selectedPortfolioId === portfolioId

            let plants = _.sortBy(this.props.plants.filter(x => x.portfolio_id === portfolioId), x => x.plant_name)

            const plantSkeletons = plants.map(plant => {

                let plantId = plant.plant_id
                let plantName = plant.plant_name

                let isTableActive = this.props.selectedPlantId === plantId

                return {
                    "key": plantId,
                    "label": plantName,
                    //"meta": table,
                    "selectable": true,
                    "active": isTableActive,
                    "children": null
                }
            })

            return {
                "key": portfolioId,
                "label": portfolioName,
                //"meta": schema,
                "selectable": false,
                //"active": isPortfolioActive,
                "children": plantSkeletons
            }
        })
    }

}

export default PlantMenu