import '../../../context_menu/ContextMenu.css'
import './ForecastViewContainer.css'
import { Component, useState, useEffect } from 'react'

import {Toggle} from 'rsuite'
import Panel from '../../../panel/Panel'
import DatePicker, { useDateRangeSelectionState } from '../../../date_picker/DateRangePicker'
import StandardDataProvider  from '../../../data_provider/StandardDataProvider'
import ForecastChart from '../../../charts/ForecastChart'
import TagDimensionMenu, { useTagSelectionState } from '../../../menu/TagDimensionMenu'
import SectionHeader from '../../../section/SectionHeader'
import { HelpForecastAnalyzer } from '../../../help/descriptions/tabs/Market'

import { DEVICE_TYPES, TAG_TYPES } from '../../../../utils/palantirSchema'
import { getSiteTimezone } from '../../../../utils/assetFrameworkMacros'
import { constructAssetFiltersFromSelections } from '../../../../utils/AssetFilter'
import DeviceMenu from '../../../menu/DeviceMenu'


const ForecastsViewContent = ({services, selectedSiteId}) => {

    
    const [tenaskaHistoricalDevice, setTenaskaHistoricalDevice] = useState({})
    const [tenaskaForecastDevice, setTenaskaForecastDevice] = useState({})
    const [startDate, endDate, setDateRange] = useDateRangeSelectionState({useUrlParam: false})
    const [
        visibleTags, visibleDimensions, selectedTagIds, selectedDimensionIds,
        updateSelectedTagIds, updateSelectedDimensionIds
    ] = useTagSelectionState(TAG_TYPES.source, [tenaskaForecastDevice["_id"]], null, false, services.assetFramework)

    const [animationsEnabled, setAnimationsEnabled] = useState(true)
    const toggleAnimations = () => setAnimationsEnabled((prevState) => !prevState)

    // Update device selection when site selection changes.
    useEffect(() => {
        var devices = services.assetFramework.getDevices()
        var tenaskaHistoricalDevice = devices.find(x => 
            x["site_id"]===selectedSiteId &&
            x["device_type"]===DEVICE_TYPES.tenaskaBess &&
            x.meta?.primary
        ) || {}
        var tenaskaForecastDevice = devices.find(x => 
            x["site_id"]===selectedSiteId &&
            x["device_type"]===DEVICE_TYPES.tenaskaBessForecast &&
            x.meta?.primary
        ) || {}
        setTenaskaHistoricalDevice(tenaskaHistoricalDevice)
        setTenaskaForecastDevice(tenaskaForecastDevice)
    }, [selectedSiteId])


    const determineContent = () => {

        if (selectedTagIds.length===0) {
            return <Panel className="section-panel">Please select at least one tag.</Panel>
        }

        try {
            const afService = services.assetFramework
            const assetFilters = constructAssetFiltersFromSelections(
                afService.getSites(),
                afService.getDeviceTypes(),
                [tenaskaHistoricalDevice],   // want to fetch both historical and forecast data
                selectedSiteId,
                {"source": selectedTagIds},
                {"source": selectedDimensionIds}
            )
            assetFilters.push(constructAssetFiltersFromSelections(
                afService.getSites(),
                afService.getDeviceTypes(),
                [tenaskaForecastDevice],   // want to fetch both historical and forecast data
                selectedSiteId,
                {"source": selectedTagIds},
                {"source": selectedDimensionIds}
            )[0])

            // Add forecast time to all filters for API forecast device
            assetFilters.forEach(af => {
                if (af.device_ids.includes((tenaskaForecastDevice || {})["_id"])) {
                    af.forecast_time = [startDate, endDate]
                }
            })
            assetFilters[1]["collection"] = "tenaska_api_forecast"

            return (
                <StandardDataProvider
                    services={services}
                    selectedSiteId={selectedSiteId}
                    selectedStartDate={startDate}
                    selectedEndDate={endDate}
                    assetFilters={assetFilters}
                    additionalParams={{
                        tags: selectedTagIds,
                        dimensions: selectedDimensionIds
                    }}
                    queryProps={["selectedSiteId", "selectedStartDate", "selectedEndDate", "assetFilters"]}
                    queryUrl={'/api/market/forecasts/summary'}
                    messageClassName={"section-panel"}
                    render={(data, dataReqId) => 
                        <ForecastChart 
                            services={services} 
                            timezone={getSiteTimezone(services.assetFramework.getSites(selectedSiteId))}
                            data={data}
                            animate={animationsEnabled} 
                            fixedAxis={false} 
                        />
                    }
                />
            )
        }
        // Creation fails when an available dimension is not selected
        catch (e) {
            console.log(e)
            return (
                <Panel className="section-panel">
                    Please select at least one value for each dimension.
                </Panel>
            )
        }

    }

    var content = determineContent()

    return (
        <div className="section-main fill-parent flow-vertical overflow-y">
            <SectionHeader
                services={services}
                sectionTitle="Forecast Analyzer"
                sectionDescription={<HelpForecastAnalyzer/>}
            >
                <div className="fill-parent"></div>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '15px'}}>
                    <span style={{marginRight: '5px'}}>Animations</span>
                    <Toggle onChange={toggleAnimations} checked={animationsEnabled} />
                </div>
                <DatePicker
                    startDate={startDate}
                    endDate={endDate}
                    onValueChange={setDateRange}
                    containerProps={{
                        placement: "bottomEnd"
                    }}
                />
            </SectionHeader>
            <div className="flow-horizontal overflow-visible fill-parent" style={{minHeight: '400px'}}>
                <TagDimensionMenu
                    services={services}
                    selectedSiteId={selectedSiteId}
                    selectedDeviceIds={[(tenaskaForecastDevice || {})["_id"]]}
                    visibleTags={visibleTags}
                    visibleDimensions={visibleDimensions}
                    selectedTagIds={selectedTagIds}
                    selectedDimensionIds={selectedDimensionIds}
                    updateSelectedTagIds={updateSelectedTagIds}
                    updateSelectedDimensionIds={updateSelectedDimensionIds}
                    tagType="source"
                    className={"section-panel"}
                    style={{marginRight: '20px', minWidth: '200px'}}
                    tagMenuTitle={"Forecast Tags"}
                />
                {content}
            </div>
        </div>
    )
}

export default ForecastsViewContent