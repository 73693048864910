import { buildDropdownItems, transformDisplayValue } from "../../utils/databaseAppUtils"
import { schemas } from "../Constants"
import Table from "./BaseTable"
import { makeEntityId } from "./CommonColumns"
import { Plant } from "./Project"





class StageMilestoneMatrixItemTable extends Table {

    constructor() {
        var columnSchema = {
            oid: "oid",
            name: "name",
            category: "category",
            stage: "stage",
            description: "description"
        }

        var schemaName = schemas.development
        var tableName = "stage_milestone_matrix_item"

        var displayNameSingular = "Stage Gate Matrix Item"
        var displayNamePlural = "Stage Gate Matrix Items"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
        this.initOptions()
    }

    initOptions() {
        this.options = {
            category: [{
                label: "Energy Resource", value: "Energy Resource"
            }, {
                label: "Real Estate", value: "Real Estate"
            }, {
                label: "Interconnection", value: "Interconnection"
            }, {
                label: "Permitting", value: "Permitting"
            }, {
                label: "Engineering", value: "Engineering"
            }, {
                label: "Community Relations", value: "Community Relations"
            }, {
                label: "Tax", value: "Tax"
            }, {
                label: "Procurement", value: "Procurement"
            }, {
                label: "Project Management", value: "Project Management"
            }, {
                label: "Investments", value: "Investments"
            }, {
                label: "Financing", value: "Financing"
            }, {
                label: "Insurance", value: "Insurance"
            }],
            stage: [{
                label: "Prospect (FNTP > 48 mo.)", value: "Prospect (FNTP > 48 mo.)"
            }, {
                label: "Stage 1 (FNTP > 36 mo.)", value: "Stage 1 (FNTP > 36 mo.)"
            }, {
                label: "Stage 2 (FNTP > 18 mo.)", value: "Stage 2 (FNTP > 18 mo.)"
            }, {
                label: "Stage 3 (FNTP > 9 mo.)", value: "Stage 3 (FNTP > 9 mo.)"
            }, {
                label: "Stage 4 (FNTP < 9 mo.)", value: "Stage 4 (FNTP < 9 mo.)"
            }]
        }
    }

    buildTableProps = (data) => {

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Name",
                        key: this.columnSchema.name,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true
                        }
                    }, {
                        name: "Category",
                        key: this.columnSchema.category,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.category
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true
                        }
                    }, {
                        name: "Stage",
                        key: this.columnSchema.stage,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.stage
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true
                        }
                    }, {
                        name: "Description",
                        key: this.columnSchema.description,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true
                        }
                    }
                ]
            },
        }
    }

}
export const StageMilestoneMatrixItem = new StageMilestoneMatrixItemTable()

class StageMilestoneMatrixTable extends Table {

    constructor() {
        var columnSchema = {
            oid: "oid",
            projectId: "project_id",
            name: "name",
            stage: "stage"
        }

        var schemaName = schemas.development
        var tableName = "stage_milestone_matrix"

        var displayNameSingular = "Stage Gate Matrix"
        var displayNamePlural = "Stage Gate Matrices"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [Plant]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)
        var plantIdCol = Plant.columnSchema.plantId
        var plantNameCol = Plant.columnSchema.plantName

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Name",
                        key: this.columnSchema.name,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true
                        }
                    }, {
                        name: "Project",
                        key: this.columnSchema.projectId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(plantRecords, value, plantIdCol, plantNameCol),
                            dropdownItems: buildDropdownItems(plantRecords, plantIdCol, plantNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                            fixed: true
                        }
                    }, {
                        name: "Stage",
                        key: this.columnSchema.stage,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: StageMilestoneMatrixItem.options.stage
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true
                        }
                    }
                ]
            },
        }
    }

}
export const StageMilestoneMatrix = new StageMilestoneMatrixTable()

class StageMilestoneMatrixEntryTable extends Table {

    constructor() {
        var columnSchema = {
            oid: "oid",
            matrixId: "matrix_id",
            itemId: "item_id",
            status: "status"
        }

        var schemaName = schemas.development
        var tableName = "stage_milestone_matrix_entry"

        var displayNameSingular = "Stage Gate Matrix Entry"
        var displayNamePlural = "Stage Gate Matrix Entries"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [StageMilestoneMatrix, StageMilestoneMatrixItem]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
        this.initOptions()
    }

    initOptions() {
        this.options = {
            status: [{
                label: "Not Started", value: "Not Started"
            }, {
                label: "In Progress", value: "In Progress"
            }, {
                label: "Completed", value: "Completed"
            }]
        }
    }

    buildTableProps = (data) => {

        const matrixEntryRecords = this.findTableObjectRecords(data, this)
        const matrixEntryItemIdCol = this.columnSchema.itemId

        var matrixRecords = this.findTableObjectRecords(data, StageMilestoneMatrix)
        var matrixIdCol = StageMilestoneMatrix.columnSchema.oid
        var matrixNameCol = StageMilestoneMatrix.columnSchema.name

        var matrixItemRecords = this.findTableObjectRecords(data, StageMilestoneMatrixItem)
        var matrixItemIdCol = StageMilestoneMatrix.columnSchema.oid
        var matrixItemNameCol = StageMilestoneMatrix.columnSchema.name

        const tempStageCol = "_stage"
        const tempCategoryCol = "_category"

        const itemDict = Object.fromEntries(matrixItemRecords.map(x => [x[matrixItemIdCol], x]))
        /*matrixEntryRecords.forEach(x => {
            const item = itemDict[x[matrixEntryItemIdCol]] || {}
            x[tempStageCol] = item[StageMilestoneMatrixItem.columnSchema.stage]
            x[tempCategoryCol] = item[StageMilestoneMatrixItem.columnSchema.category]
        })*/

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            structureItem: (entry) => {
                delete entry[tempStageCol]
                delete entry[tempCategoryCol]
                return entry
            },
            flattenItem: (entry) => {
                const item = itemDict[entry[matrixEntryItemIdCol]] || {}
                entry[tempStageCol] = item[StageMilestoneMatrixItem.columnSchema.stage]
                entry[tempCategoryCol] = item[StageMilestoneMatrixItem.columnSchema.category]
                return entry
            },
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Matrix ID",
                        key: this.columnSchema.matrixId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(matrixRecords, value, matrixIdCol, matrixNameCol),
                            dropdownItems: buildDropdownItems(matrixRecords, matrixIdCol, matrixNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true
                        }
                    }, {
                        name: "Matrix Item ID",
                        key: this.columnSchema.itemId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(matrixItemRecords, value, matrixItemIdCol, matrixItemNameCol),
                            dropdownItems: buildDropdownItems(matrixItemRecords, matrixItemIdCol, matrixItemNameCol),
                            transformNewValue: (newValue, newValueColumn, record, items) => {
                                const baseChange = {[newValueColumn]: newValue}
                                const item = itemDict[newValue] || {}
                                baseChange[tempStageCol] = item[StageMilestoneMatrixItem.columnSchema.stage]
                                baseChange[tempCategoryCol] = item[StageMilestoneMatrixItem.columnSchema.category]
                                return baseChange
                            }
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true
                        }
                    }, {
                        name: "Item Category",
                        key: tempCategoryCol,
                        cellType: "readOnly",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true
                        }
                    }, {
                        name: "Item Stage",
                        key: tempStageCol,
                        cellType: "readOnly",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true
                        }
                    }, {
                        name: "Status",
                        key: this.columnSchema.status,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.status
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true
                        }
                    }
                ]
            },
        }
    }

}
export const StageMilestoneMatrixEntry = new StageMilestoneMatrixEntryTable()

class StageMilestoneMatrixCategorySummaryEntryTable extends Table {

    constructor() {
        var columnSchema = {
            oid: "oid",
            matrixId: "matrix_id",
            category: "category",
            comments: "comments"
        }

        var schemaName = schemas.development
        var tableName = "stage_milestone_matrix_category_summary_entry"

        var displayNameSingular = "Stage Gate Matrix Category Summary Entry"
        var displayNamePlural = "Stage Gate Matrix Category Summary Entries"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [StageMilestoneMatrix]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }

    buildTableProps = (data) => {

        const matrixEntryItemIdCol = this.columnSchema.itemId

        var matrixRecords = this.findTableObjectRecords(data, StageMilestoneMatrix)
        var matrixIdCol = StageMilestoneMatrix.columnSchema.oid
        var matrixNameCol = StageMilestoneMatrix.columnSchema.name

        var matrixItemRecords = this.findTableObjectRecords(data, StageMilestoneMatrixItem)
        var matrixItemIdCol = StageMilestoneMatrix.columnSchema.oid
        var matrixItemNameCol = StageMilestoneMatrix.columnSchema.name

        const tempStageCol = "_stage"
        const tempCategoryCol = "_category"

        const itemDict = Object.fromEntries(matrixItemRecords.map(x => [x[matrixItemIdCol], x]))

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Matrix ID",
                        key: this.columnSchema.matrixId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(matrixRecords, value, matrixIdCol, matrixNameCol),
                            dropdownItems: buildDropdownItems(matrixRecords, matrixIdCol, matrixNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true
                        }
                    }, {
                        name: "Category",
                        key: this.columnSchema.category,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: StageMilestoneMatrixItem.options.category
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true
                        }
                    }, {
                        name: "Comments",
                        key: this.columnSchema.comments,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            flexGrow: 1,
                            resizable: true
                        }
                    }
                ]
            },
        }
    }

}
export const StageMilestoneMatrixCategorySummaryEntry = new StageMilestoneMatrixCategorySummaryEntryTable()