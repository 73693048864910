import './DimensionSelectionModal.css'
import {Component} from 'react'

import {Button, Modal} from 'rsuite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'

import EventTemplateMenu from '../menu/EventTemplateMenu'

class EventTemplateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            selectedTemplateId: props.selectedTemplateId
        };
    }
    close = (cancel) => {
        if (cancel) {
            this.setState({
                show: false,
                selectedTemplateId: this.props.selectedTemplateId
            });
        }
        else {
            this.setState({
                show: false
            })
            if (this.props.onClose) this.props.onClose(this.state.selectedTemplateId)
        }
    }
    open = () => {
        this.setState({ show: true });
    }
    render() {
        return (
            <div className="modal-container">
                <FontAwesomeIcon onClick={this.open} icon={faCog} style={{fontSize: '16px'}} fixedWidth className="fa-spin-hover" />

                <Modal show={this.state.show} onHide={() => this.close(true)}>
                <Modal.Header>
                    <Modal.Title>Select An Event Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EventTemplateMenu services={this.props.services} selectedTemplateId={this.state.selectedTemplateId} onTemplateClick={this.updateSelectedTemplateIds} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.close(false)} appearance="primary">
                    Ok
                    </Button>
                    <Button onClick={() => this.close(true)} appearance="subtle">
                    Cancel
                    </Button>
                </Modal.Footer>
                </Modal>
            </div>
        );
    }
    
    updateSelectedTemplateIds = (id) => {
        this.setState({
            selectedTemplateId: id,
        })
    }
}

  export default EventTemplateModal