import './Contract.css'
import { injectedChangeLogIdCol } from '../../../hooks/changeLog'
import { PalantirTextField, PalantirDispatchedTextField, TextMaskNumber } from '../../../components/input/Text'



export default function ContractIdentification({ContractTable, contract, contractIdCol, contractNameCol, contractPlantIdCol, onContractNameChange, errors, removeErrors}) {

    const contractId = contract[contractIdCol]
    const contractName = contract[contractNameCol]
    const contractPlantId = contract[contractPlantIdCol]
    const contractInjectedChangeLogId = contract[injectedChangeLogIdCol]

    const contractNameErrorPath = [contractId, contractNameCol]

    let contractNameError = errors.get(...contractNameErrorPath)
    let contractNameProps = contractNameError ? {error: true, helperText: contractNameError.getMessage()} : {}

    return (
        <div id="contract-identification" className="flow-horizontal" style={{marginRight: "40px", flexShrink: 0, justifyContent: "space-between", flexWrap: "wrap"}}>
            <PalantirTextField
                label="Contract Name"
                helperText="A unique name given to this contract instance."
                value={contractName}
                onChange={(x) => {
                    removeErrors(contractNameErrorPath)
                    onContractNameChange(x)
                }}
                sx={{minWidth: "340px", marginBottom: "20px", marginRight: "20px"}}
                required
                {...contractNameProps}
            />
            <PalantirTextField
                label="Contract ID"
                helperText="A unique ID associated with the contract."
                value={contractId}
                sx={{minWidth: "340px", marginBottom: "20px", marginRight: "20px"}}
                required
                disabled
            />
            <PalantirTextField
                label="Plant ID"
                helperText="A unique ID associated with the attached plant."
                value={contractPlantId}
                sx={{minWidth: "340px", marginBottom: "20px"}}
                required
                disabled
            />
        </div>
    )
}