import './Contract.css'
import { DeleteFloaterButtonWithPrompt } from '../../../components/button/FloaterButtonWithPrompt'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';


export default function ContractHeader({contractName, deleteFromContractLog}) {

    return (
        <div className="header">
            <span>{contractName || <i>Anonymous</i>}</span>
            <DeleteFloaterButtonWithPrompt
                onClick={deleteFromContractLog}
                labelContent="Delete this contract"
                height={18}
                width={18}
                style={{float: "right"}}
            />
        </div>
    )
}