import './FilterSortMenu.css'
import React, {Component} from 'react'

import { Popover, Whisper, Button, RadioGroup, Radio, RangeSlider, Checkbox, CheckboxGroup } from 'rsuite'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'

class SummaryFilterMenu extends Component {

    constructor(props) {
        super()

        this.state = {
            availability: props.availability,
            violationTypes: props.violationTypes,
            priorities: props.priorities,
        }
        this.triggerRef = React.createRef()
    }

    render() {
        var filtersActive = !_.isEqual(this.state.availability, [0,100]) || 
                            !_.isEqual(new Set(this.state.violationTypes), new Set(["ads", "agc", "mixed"])) || 
                            !_.isEqual(new Set(this.state.priorities), new Set([2,3]))

        return (
            <Whisper
                trigger="click"
                placement="bottomStart"
                ref={this.triggerRef}
                speaker={this.popover()}
                onClose={this.cancel}
            >
                <FontAwesomeIcon onClick={this.open} icon={faFilter} className={filtersActive ? "active" : ""} />
            </Whisper>
        )
    }

    setAvailabilityField = (range) => {
        this.setState({
            availability: range
        })
    }
    setViolationTypes = (types) => {
        this.setState({
            violationTypes: types
        })
    }
    setPriorities = (priorities) => {
        this.setState({
            priorities: priorities
        })
    }

    submit = () => {
        this.props.setFilters(this.state.availability, this.state.violationTypes, this.state.priorities)
        this.close()
    }
    close = () => {
        this.triggerRef.current.close()
    }
    cancel = () => {
        this.setState({
            availability: this.props.availability,
            violationTypes: this.props.violationTypes,
            priorities: this.props.priorities,
        })
        this.close()
    }

    popover = () => {

        return (
            <Popover>
                <div className="flow-vertical" style={{width: "200px"}}>
                    <div className="flow-horizontal" style={{alignItems: "baseline"}}>
                        <div style={{fontWeight: "bold", fontSize: "16px"}}>Filter</div>
                    </div>
                    <div style={{textAlign: "center"}}>
                        <div>Availability</div>
                        <RangeSlider 
                            defaultValue={this.state.availability} 
                            graduated
                            min={0}
                            max={100}
                            onChange={this.setAvailabilityField}
                            renderMark={mark => {
                                if ([0,100].includes(mark)) {
                                    return <span>{mark} %</span>
                                }
                                return null
                            }}
                            style={{margin: "15px 20px"}} 
                        />
                        <div style={{marginTop: "40px"}}>Type</div>
                        <CheckboxGroup className="flow-horizontal" defaultValue={this.state.violationTypes} onChange={this.setViolationTypes}>
                            <Checkbox value={"ads"}>ADS</Checkbox>
                            <Checkbox value={"agc"}>AGC</Checkbox>
                            <Checkbox value={"mixed"}>Mixed</Checkbox>
                        </CheckboxGroup>
                        <div style={{marginTop: "15px"}}>Priority</div>
                        <CheckboxGroup className="flow-horizontal" defaultValue={this.state.priorities} onChange={this.setPriorities}>
                            <Checkbox value={1}>1</Checkbox>
                            <Checkbox value={2}>2</Checkbox>
                            <Checkbox value={3}>3</Checkbox>
                        </CheckboxGroup>
                    </div>
                </div>
                <hr></hr>
                <Button onClick={this.submit} style={{marginRight: "5px"}}>Submit</Button>
                <Button onClick={this.cancel}>Cancel</Button>
            </Popover>
        );
    }

}

export default SummaryFilterMenu