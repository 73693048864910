import './Contract.css'
import { Counterparty } from '../../table_configuration/Entity'
import { injectedChangeLogIdCol } from '../../../hooks/changeLog'
import { AddFloaterButtonWithPrompt, DeleteFloaterButtonWithPrompt } from '../../../components/button/FloaterButtonWithPrompt'
import { PalantirAutocomplete } from '../../../components/input/SelectPicker'
import { PalantirDispatchedTextField } from '../../../components/input/Text'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash'


export default function ContractCounterparties({
    ContractCounterpartyTable, contract, contractIdCol,
    contractCounterpartyIdCol, contractCounterpartyNameCol, contractCounterpartyNotesCol,
    allCounterpartyRecords, filteredContractCounterpartyRecords, deleteFromCounterpartyLog, updateContractCounterparty, addToCounterpartyLog,
    counterpartyNameCol=Counterparty.columnSchema.counterpartyName, counterpartyIdCol=Counterparty.columnSchema.counterpartyId,
    counterpartyType, counterpartyTypePlural, SupplementalComponent, orientation="vertical", excludeHeader, errors, removeErrors, ...props}) {

    const contractId = contract[contractIdCol]

    const styles = orientation==="vertical" ? {marginRight: "40px", minWidth: "300px", width: "300px", flexShrink: 0} : {minWidth: "300px", flexShrink: 1}

    const counterpartyComponents = filteredContractCounterpartyRecords.map((cp, idx) => {

        const counterpartyId = cp[contractCounterpartyIdCol]
        const counterpartyName = cp[contractCounterpartyNameCol]
        const counterpartyNotes = cp[contractCounterpartyNotesCol]
        const counterpartyInjectedChangelogId = cp[injectedChangeLogIdCol]

        const counterpartyErrorPath = [contractId, ContractCounterpartyTable.buildId(), counterpartyInjectedChangelogId, contractCounterpartyIdCol]
        const counterpartyEmptyError = errors.get(...counterpartyErrorPath)
        const counterpartyErrorProps = counterpartyEmptyError ? {error: true, helperText: counterpartyEmptyError.getMessage()} : {}

        return (
            <div key={idx} className="form-sub-instance flow-vertical contract-cp-instance" style={{minWidth: "300px", marginRight: "20px"}}>
                <div>
                    <span style={{fontSize: "16px"}}>{counterpartyType+" "+idx}</span>
                    <DeleteFloaterButtonWithPrompt
                        onClick={() => {
                            removeErrors(counterpartyErrorPath.slice(0,3))   // This will remove all errors for the counterparty
                            deleteFromCounterpartyLog(cp)
                        }}
                        style={{float: "right", marginTop: "4px"}}
                        height={14}
                        width={14}
                    />
                </div>
                <hr style={{margin: "10px"}}></hr>
                <PalantirAutocomplete
                    label={counterpartyType}
                    value={{label: counterpartyName, id: counterpartyId}}
                    options={_.sortBy(allCounterpartyRecords.map(x => {
                        return {
                            label: x[counterpartyNameCol],
                            id: x[counterpartyIdCol]
                        }
                    }), x => x.label)}
                    disableClearable
                    fullWidth
                    onUpdate={(value) => {
                        let result = updateContractCounterparty(cp, {[contractCounterpartyIdCol]: value.id, [contractCounterpartyNameCol]: value.label})
                        if (result) removeErrors(counterpartyErrorPath)
                    }}
                    InputProps={counterpartyErrorProps}
                />
                {SupplementalComponent && <SupplementalComponent contractCounterpartyRecord={cp} />}
                <PalantirDispatchedTextField
                    label="Notes"
                    value={counterpartyNotes}
                    multiline
                    rows={3}
                    fullWidth
                    onChange={(value) => updateContractCounterparty(cp, {[contractCounterpartyNotesCol]: value})}
                />
            </div>
        )
    })

    return (
        <div style={Object.assign(styles, props.style || {})}>
            {!excludeHeader && <div className="header">{counterpartyTypePlural}</div>}
            <div className="flow-horizontal" style={{display: "flex", flexWrap: "wrap"}}>
                {counterpartyComponents}
            </div>
            <AddFloaterButtonWithPrompt
                onClick={addToCounterpartyLog}
                labelContent={"Add new " + counterpartyType}
                height={14}
                width={14}
                labelStyle={{fontSize: "10px"}}
            />
        </div>
    )
}