import './DeviceMenu.css'
import React, { Component, useState, useEffect } from 'react'

import Panel from '../panel/Panel'
import MenuContainer from '../menu/MenuContainer'
import _ from 'lodash'

import { getDevices, setDevices, getDeviceGroup, setDeviceGroup } from '../../utils/UrlLocation'


export const useDeviceSelectionState = (selectedSiteId, assetFrameworkService) => {

    const [selectedDeviceIds, setSelectedDeviceIds] = useState([])
    const [selectedDeviceGroupId, setSelectedDeviceGroupId] = useState(null)

    // Recompute device selection when the site id changes as this is the only parameter that affects the visible devices.
    useEffect(() => {
        const allSelectedDeviceIds = getDevices()
        const selectedDeviceIds = assetFrameworkService.getDevices(selectedSiteId, allSelectedDeviceIds).map(device => device["_id"])
        setSelectedDeviceIds(selectedDeviceIds)
        if (allSelectedDeviceIds.length===0) {
            const selectedDeviceGroupId = getDeviceGroup()
            const selectedDeviceGroup = assetFrameworkService.getDeviceGroup(selectedDeviceGroupId)
            if (selectedDeviceGroup?.site_id===selectedSiteId) setSelectedDeviceGroupId(selectedDeviceGroupId)
            else setSelectedDeviceGroupId(null)
        }
    }, [selectedSiteId])

    const updateDeviceIds = (newDeviceIds, newDeviceId) => {
        setDevices(newDeviceId)
        setSelectedDeviceIds(newDeviceIds)
        setDeviceGroup(null)
        setSelectedDeviceGroupId(null)
    }
    const updateDeviceGroupId = (deviceGroupId) => {
        setDeviceGroup(deviceGroupId)
        setSelectedDeviceGroupId(deviceGroupId)
        // Deselect all currently selected devices
        setDevices(null)
        setSelectedDeviceIds([])
    }

    return [
        selectedDeviceIds, updateDeviceIds,
        selectedDeviceGroupId, updateDeviceGroupId
    ]
}

class DeviceMenu extends Component {

    constructor(props) {
        super()
        this.className = "device-menu "
    }

    shouldComponentUpdate(prevProps, prevState) {
        // Only rerender when the device selection has changed or the site selection has changed, as these are the only two variables that will affect the component output
        if (!_.isEqual(prevProps.selectedDeviceIds, this.props.selectedDeviceIds)) return true
        else if (prevProps.selectedDeviceGroupId !== this.props.selectedDeviceGroupId) return true
        else if (prevProps.selectedSiteId !== this.props.selectedSiteId) return true
        else return false
    }

    render() {

        if (!this.props.selectedSiteId) {
            return <Panel className="device-menu pltr-menu sidebar" >Please select a site.</Panel>
        }

        return (
            <MenuContainer 
                headerName="Device Menu"
                menuSkeleton={this.constructAssetHeirarchy()}
                leafNodeClick={this.onDeviceClick}
                parentNodeClick={this.onDeviceClick}
                className={this.className + (this.props.className || "")}
                searchable
                quickSelectItems={this.createQuickSelectItems()}
                {...this.props.menuProps}
            />
        )
    }

    createQuickSelectItems = () => {
        const deviceGroups = this.props.services.assetFramework.getDeviceGroups(this.props.selectedSiteId)
        return deviceGroups.map(x => {
            return {
                key: x._id,
                label: x.name,
                meta: Object.assign({}, x, {device_ids: JSON.stringify(x.device_ids)}),
                onClick: () => this.props.setSelectedDeviceGroupId(x._id),
                active: x._id===this.props.selectedDeviceGroupId
            }
        })
    }

    onDeviceClick = (menuItem) => {
        const deviceId = menuItem.meta._id
        const selectedDeviceIds = Object.assign([], this.props.selectedDeviceIds)
        var curIdx = selectedDeviceIds.indexOf(deviceId)
        curIdx===-1 ? selectedDeviceIds.push(deviceId) : selectedDeviceIds.splice(curIdx, 1)
        this.props.onDeviceClick(selectedDeviceIds, deviceId)
    }

    getSiteDevices() {
        var allDevices = this.props.services.assetFramework.getDevices()
        var siteDevices = allDevices.filter(x => x.site_id===this.props.selectedSiteId)
        return siteDevices
    }

    buildDeviceChildren(device, deviceParentMap, selectedDeviceIds) {
        var deviceId = device._id
        var deviceName = device.name
        var deviceMetadata = device
        var deviceChildren = deviceParentMap[deviceId]

        var deviceChildrenNodes = (deviceChildren ? deviceChildren.map(x => this.buildDeviceChildren(x, deviceParentMap, selectedDeviceIds)) : null)

        var menuObject = {
            "key": deviceId,
            "label": deviceName,
            "meta": deviceMetadata,
            "selectable": true,
            "active": selectedDeviceIds.includes(deviceId),
            "children": deviceChildrenNodes,
            "expandTree": device.config?.device_menu?.default_expanded
        }
        return menuObject
    }

    constructAssetHeirarchy() {

        var siteDevices = this.getSiteDevices()
        if (this.props.devicesFilter) siteDevices = this.props.devicesFilter(siteDevices)

        let topLevelDevices = []
        let deviceParentMap = {}
        siteDevices.forEach(function(device) {
            let parentId = device.parent_id
            if (!parentId) {
                topLevelDevices.push(device)
                return
            }

            if (!(parentId in deviceParentMap)) deviceParentMap[parentId] = []
            deviceParentMap[parentId].push(device)
        })

        var deviceHeirarchy = topLevelDevices.map(x => this.buildDeviceChildren(x, deviceParentMap, this.props.selectedDeviceIds))
        return deviceHeirarchy
    }

}

export default DeviceMenu