import { schemas } from '../Constants'
import { transformDisplayValue, buildDropdownItems } from '../../utils/databaseAppUtils'
import { Plant } from './Project'
import { ProjectInverter, ProjectModule, ProjectTransformer } from './ProjectEquipment'
import { InverterSpec, ModuleSpec, TransformerSpec } from './EquipmentSpec'
import Table from './BaseTable'

const INJECTED_PLANT_ID = "injectedPlantId"


/**
 * Return plant id referenced via the associated equipment group
 * See Table Class documentation
 */
 function getReferencedPlantId(record, equipmentGroupRecords, equipmentGroupIdCol, plantIdCol) {
    var equipmentGroupId = record[equipmentGroupIdCol]
    var equipmentGroup = equipmentGroupRecords.find(x => x[equipmentGroupIdCol]===equipmentGroupId) || {}

    return equipmentGroup[plantIdCol]
}

/**
 * Validates a warranty record
 * @param {Object} record 
 * @returns 
 */
function validateWarranty(record, equipmentGroupRecords, equipmentGroupIdCol, plantIdCol, warrantyType) {
    
    // ensure the selected equipment group belongs to the selected plant
    let plantId = record[INJECTED_PLANT_ID]
    let equipmentGroup = equipmentGroupRecords.find(x => x[equipmentGroupIdCol]===record[equipmentGroupIdCol]) || {}
    let equipmentGroupPlantId = equipmentGroup[plantIdCol]

    if (equipmentGroupPlantId !== plantId) {
        return {pass: false, message: `Please select a ${warrantyType} group from the selected site.`}
    }
    return {pass: true, message: null}
}

function flattenWarrantyRecord(record, equipmentGroupRecords, equipmentGroupIdCol, plantIdCol, startDateCol, endDateCol) {
    // derive the plant id from the equipment group and add to record
    let equipmentGroupId = record[equipmentGroupIdCol]
    let inverterGroup = equipmentGroupRecords.find(z => z[equipmentGroupIdCol]===equipmentGroupId) || {}
    let plantId = inverterGroup[plantIdCol]
    record[INJECTED_PLANT_ID] = plantId

    // parse sql date
    //record[startDateCol] = parseFromSQLDate(record[startDateCol])
    //record[endDateCol] = parseFromSQLDate(record[endDateCol])

    return record
}
function structureWarrantyRecord(record, startDateCol, endDateCol) {
    // remove the artificially injected plant id attribute
    delete record[INJECTED_PLANT_ID]

    // return date object to sql date string
    //record[startDateCol] = parseToSQLDate(record[startDateCol])
    //record[endDateCol] = parseToSQLDate(record[endDateCol])

    return record
}

/**
 * 
 * @param {String} equipmentGroupId 
 * @param {Object} recordsObject object of mappings to necessary record lists.
 * Should contain equipment group records and equipment spec records
 * @param {Object} colObject object of mappings to neccessary column names.
 * Should contain equipment group id, plant id / name, equipment spec id / manufacturer / model
 * @returns 
 */
function transformEquipmentGroupIdDisplayValue(equipmentGroupId, equipmentGroupRecords, equipmentSpecRecords, plantRecords, colObject) {

    // display the referenced inverter manufacturer/model and plant
    let equipmentGroup = equipmentGroupRecords.find(x => x[colObject.equipmentGroupIdCol]===equipmentGroupId) || {}
    
    let plantId = equipmentGroup[colObject.plantIdCol]
    let plant = plantRecords.find(x => x[colObject.plantIdCol]===plantId) || {}
    
    let equipmentSpecId = equipmentGroup[colObject.equipmentSpecIdCol]
    let equipmentSpec = equipmentSpecRecords.find(x => x[colObject.equipmentSpecIdCol]===equipmentSpecId) || {}

    return `${equipmentSpec[colObject.equipmentSpecManufacturerCol]} - ${equipmentSpec[colObject.equipmentSpecModelCol]} (${plant[colObject.plantNameCol]})`
}
/**
 * Builds equipment group dropdown item list. Filters equipment group records for the given site.
 * @param {*} warrantyRecord 
 * @param {*} equipmentGroupRecords 
 * @param {*} equipmentSpecRecords 
 * @param {} colObject Object of mappings to necessary column names.
 * Should contain equipment group plant id / id / equipment spec, equipment spec id / manufacturer / model
 * @returns 
 */
function buildEquipmentGroupDropdownItems(warrantyRecord, equipmentGroupRecords, equipmentSpecRecords, colObject) {

    var equipmentGroupPlantIdCol = colObject.equipmentGroupPlantIdCol   // plant id col in eq group table
    var equipmentGroupIdCol = colObject.equipmentGroupIdCol   // eq group id col in eq group table
    var equipmentGroupEquipmentSpecIdCol = colObject.equipmentGroupEquipmentSpecIdCol   // eq spec id col in the eq group table
    var equipmentSpecIdCol = colObject.equipmentSpecIdCol   // eq spec id col in eq spec table
    var equipmentSpecManufacturerCol = colObject.equipmentSpecManufacturerCol
    var equipmentSpecModelCol = colObject.equipmentSpecModelCol

    let plantId = warrantyRecord[INJECTED_PLANT_ID]
    let plantEquipmentGroupRecords = equipmentGroupRecords.filter(x => x[equipmentGroupPlantIdCol]===plantId)
    return plantEquipmentGroupRecords.map(x => {
        let equipmentGroupId = x[equipmentGroupIdCol]
        let equipmentSpecId = x[equipmentGroupEquipmentSpecIdCol]
        let equipmentSpec = equipmentSpecRecords.find(x => x[equipmentSpecIdCol]===equipmentSpecId) || {}
        return {
            label: `${equipmentSpec[equipmentSpecManufacturerCol]} - ${equipmentSpec[equipmentSpecModelCol]}`,
            value: equipmentGroupId,
        }
    })
}

function makePlantId(plantRecords, plantIdCol, plantNameCol) {
    return {
        name: "Plant",
        key: INJECTED_PLANT_ID,
        cellType: "dropdown",
        cellOptions: {
            searchable: true,
            transformDisplayValue: (value) => transformDisplayValue(plantRecords, value, plantIdCol, plantNameCol),
            dropdownItems: buildDropdownItems(plantRecords, plantIdCol, plantNameCol),
        },
        filterable: true,
        sortable: true,
        colProps: {
            width: 300,
            resizable: true,
        },
    }
}
function getStartDateColumnConfig(key) {
    return {
        name: "Start Date",
        key: key,
        cellType: "date",
        cellOptions: {},
        filterable: true,
        sortable: true,
        colProps: {
            width: 175,
            resizable: true,
        }
    }
}
function getEndDateColumnConfig(key) {
    return {
        name: "End Date",
        key: key,
        cellType: "date",
        cellOptions: {},
        filterable: true,
        sortable: true,
        colProps: {
            width: 175,
            resizable: true,
        }
    }
}
function getNotesColumnConfig(key) {
    return {
        name: "Notes",
        key: key,
        cellType: "editable",
        cellOptions: {},
        filterable: false,
        sortable: false,
        colProps: {
            flexGrow: 1,
            //width: 175,
            //resizable: true,
        }
    }
}



class InverterWarrantyTable extends Table {

    constructor() {

        var columnSchema = {
            warrantyId: "warranty_id",
            inverterGroupId: "inverter_group_id",
            startDate: "start_date",
            endDate: "end_date",
            notes: "notes",
        }

        var schemaName = schemas.warranty
        var tableName = "inverter"

        var displayNameSingular = "Inverter"
        var displayNamePlural = "Inverters"

        var pkUidColumn = columnSchema.warrantyId
        var identifiers = [columnSchema.warrantyId]
        var dependencies = [Plant, ProjectInverter, InverterSpec]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
    }

    getReferencedPlantId = (record, data) => {
        var inverterGroupRecords = this.findTableObjectRecords(data, ProjectInverter)  
        return getReferencedPlantId(record, inverterGroupRecords, ProjectInverter.columnSchema.inverterGroupId, Plant.columnSchema.plantId)
    }

    buildTableProps = (data) => {

        let inverterGroupRecords = this.findTableObjectRecords(data, ProjectInverter)
        let plantRecords = this.findTableObjectRecords(data, Plant)
        let inverterSpecRecords = this.findTableObjectRecords(data, InverterSpec)

        var inverterGroupIdCol = ProjectInverter.columnSchema.inverterGroupId
        var plantIdCol = Plant.columnSchema.plantId
        var plantNameCol = Plant.columnSchema.plantName
        var inverterSpecIdCol = InverterSpec.columnSchema.inverterSpecId
        var inverterSpecManufacturerCol = InverterSpec.columnSchema.manufacturer
        var inverterSpecModelCol = InverterSpec.columnSchema.model

        const validateInverterWarranty = (warrantyRecord) => {
            return validateWarranty(
                warrantyRecord, inverterGroupRecords, 
                inverterGroupIdCol, plantIdCol, 
                this.displayNameSingular
            )
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: (item) => {
                return flattenWarrantyRecord(
                    item, inverterGroupRecords, 
                    inverterGroupIdCol, plantIdCol, 
                    this.columnSchema.startDate, this.columnSchema.endDate
                )
            },
            structureItem: (item) => {
                return structureWarrantyRecord(
                    item, 
                    this.columnSchema.startDate, this.columnSchema.endDate
                )
            },
            validateNewItem: validateInverterWarranty,
            validateUpdateItem: validateInverterWarranty,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makePlantId(plantRecords, plantIdCol, plantNameCol), 
                    {
                        name: "Inverter Group",
                        key: this.columnSchema.inverterGroupId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (inverterGroupId) => {
                                return transformEquipmentGroupIdDisplayValue(
                                    inverterGroupId, inverterGroupRecords, inverterSpecRecords, plantRecords,
                                    {
                                        equipmentGroupIdCol: inverterGroupIdCol,
                                        plantIdCol: plantIdCol, plantNameCol: plantNameCol,
                                        equipmentSpecIdCol: inverterSpecIdCol, equipmentSpecManufacturerCol: inverterSpecManufacturerCol, equipmentSpecModelCol: inverterSpecModelCol,

                                    }
                                )
                            },
                            dropdownItems: (warrantyRecord) => {
                                return buildEquipmentGroupDropdownItems(
                                    warrantyRecord, inverterGroupRecords, inverterSpecRecords,
                                    {
                                        equipmentGroupIdCol: inverterGroupIdCol, 
                                        equipmentGroupPlantIdCol: ProjectInverter.columnSchema.plantId, 
                                        equipmentGroupEquipmentSpecIdCol: ProjectInverter.columnSchema.inverterSpecId,
                                        equipmentSpecIdCol: inverterSpecIdCol, equipmentSpecManufacturerCol: inverterSpecManufacturerCol, equipmentSpecModelCol: inverterSpecModelCol,
                                    }
                                )
                            }
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 300,
                            resizable: true,
                        },
                    },
                    getStartDateColumnConfig(this.columnSchema.startDate), getEndDateColumnConfig(this.columnSchema.endDate), getNotesColumnConfig(this.columnSchema.notes),
                ]
            },
        }
    }

}
export var InverterWarranty = new InverterWarrantyTable()

class ModuleWarrantyTable extends Table {

    constructor() {

        var columnSchema = {
            warrantyId: "warranty_id",
            moduleGroupId: "module_group_id",
            startDate: "start_date",
            endDate: "end_date",
            notes: "notes",
        }

        var schemaName = schemas.warranty
        var tableName = "module"

        var displayNameSingular = "Module"
        var displayNamePlural = "Modules"

        var pkUidColumn = columnSchema.warrantyId
        var identifiers = [columnSchema.warrantyId]
        var dependencies = [Plant, ProjectModule, ModuleSpec]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
    }

    getReferencedPlantId = (record, data) => {
        var moduleGroupRecords = this.findTableObjectRecords(data, ProjectModule)
        return getReferencedPlantId(record, moduleGroupRecords, ProjectModule.columnSchema.moduleGroupId, Plant.columnSchema.plantId)
    }

    buildTableProps = (data) => {

        let moduleGroupRecords = this.findTableObjectRecords(data, ProjectModule)
        let plantRecords = this.findTableObjectRecords(data, Plant)
        let moduleSpecRecords = this.findTableObjectRecords(data, ModuleSpec)

        var moduleGroupIdCol = ProjectModule.columnSchema.moduleGroupId
        var plantIdCol = Plant.columnSchema.plantId
        var plantNameCol = Plant.columnSchema.plantName
        var moduleSpecIdCol = ModuleSpec.columnSchema.moduleSpecId
        var moduleSpecManufacturerCol = ModuleSpec.columnSchema.manufacturer
        var moduleSpecModelCol = ModuleSpec.columnSchema.model

        const validateModuleWarranty = (warrantyRecord) => {
            return validateWarranty(
                warrantyRecord, moduleGroupRecords, 
                moduleGroupIdCol, plantIdCol, 
                this.displayNameSingular
            )
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: (item) => {
                return flattenWarrantyRecord(
                    item, moduleGroupRecords, 
                    moduleGroupIdCol, plantIdCol, 
                    this.columnSchema.startDate, this.columnSchema.endDate
                )
            },
            structureItem: (item) => {
                return structureWarrantyRecord(
                    item, 
                    this.columnSchema.startDate, this.columnSchema.endDate
                )
            },
            validateNewItem: validateModuleWarranty,
            validateUpdateItem: validateModuleWarranty,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makePlantId(plantRecords, plantIdCol, plantNameCol),
                    {
                        name: "Module Group",
                        key: this.columnSchema.moduleGroupId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (moduleGroupId) => {
                                return transformEquipmentGroupIdDisplayValue(
                                    moduleGroupId, moduleGroupRecords, moduleSpecRecords, plantRecords,
                                    {
                                        equipmentGroupIdCol: moduleGroupIdCol,
                                        plantIdCol: plantIdCol, plantNameCol: plantNameCol,
                                        equipmentSpecIdCol: moduleSpecIdCol, equipmentSpecManufacturerCol: moduleSpecManufacturerCol, equipmentSpecModelCol: moduleSpecModelCol,

                                    }
                                )
                            },
                            dropdownItems: (warrantyRecord) => {
                                return buildEquipmentGroupDropdownItems(
                                    warrantyRecord, moduleGroupRecords, moduleSpecRecords,
                                    {
                                        equipmentGroupIdCol: moduleGroupIdCol, 
                                        equipmentGroupPlantIdCol: ProjectModule.columnSchema.plantId, 
                                        equipmentGroupEquipmentSpecIdCol: ProjectModule.columnSchema.moduleSpecId,
                                        equipmentSpecIdCol: moduleSpecIdCol, equipmentSpecManufacturerCol: moduleSpecManufacturerCol, equipmentSpecModelCol: moduleSpecModelCol,
                                    }
                                )
                            }
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 300,
                            resizable: true,
                        },
                    },
                    getStartDateColumnConfig(this.columnSchema.startDate), getEndDateColumnConfig(this.columnSchema.endDate), getNotesColumnConfig(this.columnSchema.notes),
                ]
            },
        }
    }

}
export var ModuleWarranty = new ModuleWarrantyTable()

class TransformerWarrantyTable extends Table {

    constructor() {

        var columnSchema = {
            warrantyId: "warranty_id",
            transformerGroupId: "transformer_group_id",
            startDate: "start_date",
            endDate: "end_date",
            notes: "notes",
        }

        var schemaName = schemas.warranty
        var tableName = "transformer"

        var displayNameSingular = "Transformer"
        var displayNamePlural = "Transformers"

        var pkUidColumn = columnSchema.warrantyId
        var identifiers = [columnSchema.warrantyId]
        var dependencies = [Plant, ProjectTransformer, TransformerSpec]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural,
            identifiers, dependencies, pkUidColumn
        )
    }

    getReferencedPlantId = (record, data) => {
        var transformerGroupRecords = this.findTableObjectRecords(data, ProjectTransformer)
        return getReferencedPlantId(record, transformerGroupRecords, ProjectTransformer.columnSchema.transformerGroupId, Plant.columnSchema.plantId)
    }

    buildTableProps = (data) => {

        let transformerGroupRecords = this.findTableObjectRecords(data, ProjectTransformer)
        let plantRecords = this.findTableObjectRecords(data, Plant)
        let transformerSpecRecords = this.findTableObjectRecords(data, TransformerSpec)

        var transformerGroupIdCol = ProjectTransformer.columnSchema.transformerGroupId
        var plantIdCol = Plant.columnSchema.plantId
        var plantNameCol = Plant.columnSchema.plantName
        var transformerSpecIdCol = TransformerSpec.columnSchema.transformerSpecId
        var transformerSpecManufacturerCol = TransformerSpec.columnSchema.manufacturer
        var transformerSpecModelCol = TransformerSpec.columnSchema.model

        const validateTransformerWarranty = (warrantyRecord) => {
            return validateWarranty(
                warrantyRecord, transformerGroupRecords, 
                transformerGroupIdCol, plantIdCol, 
                this.displayNameSingular
            )
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: (item) => {
                return flattenWarrantyRecord(
                    item, transformerGroupRecords, 
                    transformerGroupIdCol, plantIdCol, 
                    this.columnSchema.startDate, this.columnSchema.endDate
                )
            },
            structureItem: (item) => {
                return structureWarrantyRecord(
                    item, 
                    this.columnSchema.startDate, this.columnSchema.endDate
                )
            },
            validateNewItem: validateTransformerWarranty,
            validateUpdateItem: validateTransformerWarranty,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makePlantId(plantRecords, plantIdCol, plantNameCol), 
                    {
                        name: "Transformer Group",
                        key: this.columnSchema.transformerGroupId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (transformerGroupId) => {
                                return transformEquipmentGroupIdDisplayValue(
                                    transformerGroupId, transformerGroupRecords, transformerSpecRecords, plantRecords,
                                    {
                                        equipmentGroupIdCol: transformerGroupIdCol,
                                        plantIdCol: plantIdCol, plantNameCol: plantNameCol,
                                        equipmentSpecIdCol: transformerSpecIdCol, equipmentSpecManufacturerCol: transformerSpecManufacturerCol, equipmentSpecModelCol: transformerSpecModelCol,

                                    }
                                )
                            },
                            dropdownItems: (warrantyRecord) => {
                                return buildEquipmentGroupDropdownItems(
                                    warrantyRecord, transformerGroupRecords, transformerSpecRecords,
                                    {
                                        equipmentGroupIdCol: transformerGroupIdCol, 
                                        equipmentGroupPlantIdCol: ProjectTransformer.columnSchema.plantId, 
                                        equipmentGroupEquipmentSpecIdCol: ProjectTransformer.columnSchema.transformerSpecId,
                                        equipmentSpecIdCol: transformerSpecIdCol, equipmentSpecManufacturerCol: transformerSpecManufacturerCol, equipmentSpecModelCol: transformerSpecModelCol,
                                    }
                                )
                            }
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 300,
                            resizable: true,
                        },
                    },
                    getStartDateColumnConfig(this.columnSchema.startDate), getEndDateColumnConfig(this.columnSchema.endDate), getNotesColumnConfig(this.columnSchema.notes),
                ]
            },
        }
    }

}
export var TransformerWarranty = new TransformerWarrantyTable()