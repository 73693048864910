import './DisplayMode.css'
import { Component } from 'react'

import { Tooltip, Whisper } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTable, faSolarPanel } from '@fortawesome/free-solid-svg-icons'

class DisplayMode extends Component {

    constructor(props) {
        super(props)
        this.state = {
            
        }
    }

    render() {
        return (
            <div className="display-mode-selector flow-horizontal" style={this.props.style || {}}>
                <div className="prop mode-prompt">
                    <FontAwesomeIcon icon={faEye} style={{marginRight: "0px"}} />
                    <div style={{marginRight: "0px"}}>Display Mode</div>
                </div>
                <div style={{margin: "0 10px"}}>|</div>
                <Whisper 
                    placement="bottom" 
                    trigger="hover" 
                    speaker={<Tooltip>View data for a specific plant or portfolio.</Tooltip>}
                >
                    <div 
                        className={this.props.activeMode==="project" ? "prop mode active" : "prop mode"} 
                        style={{marginRight: "10px"}}
                        onClick={() => this.props.setDisplayMode("project")}
                    >
                        <FontAwesomeIcon icon={faSolarPanel} style={{marginRight: "2px"}} />
                        Project
                    </div>
                </Whisper>
                <Whisper 
                    placement="bottom" 
                    trigger="hover" 
                    speaker={<Tooltip>View all available data in a single table.</Tooltip>}
                >
                    <div
                        className={this.props.activeMode === "table" ? "prop mode active" : "prop mode"}
                        onClick={() => this.props.setDisplayMode("table")}
                    >
                        <FontAwesomeIcon icon={faTable} style={{marginRight: "2px"}} />
                        Table
                    </div>
                </Whisper>
            </div>
        )
    }

}

export default DisplayMode