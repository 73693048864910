import {Component} from 'react'

import Panel from '../../../panel/Panel'

import RegulationTrackingContainer from './RegulationTrackingContainer'
import RegulationUsageContainer from './RegulationUsageContainer'
import { isSiteBess } from '../../../../utils/assetFrameworkMacros'
import { useOutletContext } from 'react-router-dom'
import { getSite } from '../../../../utils/UrlLocation'

const RegulationContent = () => {

    const {assetFrameworkServices, authServices} = useOutletContext()

    const selectedSiteId = getSite()

    const services = {assetFramework: assetFrameworkServices, auth: authServices}


    if (!selectedSiteId) {
        return <Panel>Please select a site.</Panel>
    }

    const selectedSite = assetFrameworkServices.getSites(selectedSiteId)
    if (!isSiteBess(selectedSite)){
        return <Panel>This dashboard is only available for sites with a battery system.</Panel>
    }

    return (
        <div className="section-main flow-vertical fill-parent overflow-y">
            <RegulationTrackingContainer
                services={services}
                selectedSiteId={selectedSiteId}
            />
            <RegulationUsageContainer
                services={services}
                selectedSiteId={selectedSiteId}
            />
        </div>
    )

}

export default RegulationContent