import React, {Component} from 'react'

import { SelectPicker } from 'rsuite'

class ResolutionDropdown extends Component {

    constructor(props) {
        super()
        this.state = {

        }
    }

    constructDropdownItem(resolutionOption) {
        if (resolutionOption === "raw") {
            return {value: 0, label: <div>raw</div>}
        }

        var dropdownItemLabel, resolutionMultiplier;
        let resolutionId = resolutionOption.slice(-1)
        let resolutionValue = Number(resolutionOption.slice(0, -1))

        switch(resolutionId) {
            case "m":
                resolutionMultiplier = 60
                dropdownItemLabel = "min"
                break
            case "H":
                resolutionMultiplier = 3600
                dropdownItemLabel = "hour"
                break
            case "D":
                resolutionMultiplier = 86400
                dropdownItemLabel = "day"
                break
            default:
                resolutionMultiplier = 1
                dropdownItemLabel = resolutionId
        }

        if (resolutionValue > 1) dropdownItemLabel = dropdownItemLabel.concat("'s")

        let dropdownItemKey = resolutionValue * resolutionMultiplier
        dropdownItemLabel = String(resolutionValue).concat(" ", dropdownItemLabel)

        return {value: dropdownItemKey, label: <div>{dropdownItemLabel}</div>}
    }

    render() {
        return (
            <SelectPicker 
                value={this.props.selectedResolution}
                title="Resolution"
                onSelect={this.props.onSelectItem}
                cleanable={false}
                searchable={false}
                data={this.props.resolutionOptions.map(this.constructDropdownItem)}
                style={{minWidth: '100px'}} 
                {...this.props.containerProps}
            />
        )
    }

}

export default ResolutionDropdown