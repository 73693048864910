import React, {Component} from 'react'

import { Popover, Whisper, Button, RadioGroup, Radio } from 'rsuite'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'

class SummarySortMenu extends Component {

    constructor(props) {
        super()

        this.state = {
            sortOrder: props.sortOrder,
            sortField: props.sortField,
        }
        this.triggerRef = React.createRef()
    }

    render() {

        var sortOrder = this.state.sortOrder || "Toggle sort order"
        var sortIcon
        switch (sortOrder) {
            case "ascending":
                sortIcon = faSortUp
                break;
            case "descending":
                sortIcon = faSortDown
                break;
            default:
                sortIcon = faSort
        }

        return (
            <Whisper
                trigger="click"
                placement="bottomStart"
                ref={this.triggerRef}
                speaker={this.popover(sortOrder, sortIcon)}
                onClose={this.cancel}
            >
                <FontAwesomeIcon onClick={this.open} icon={sortIcon} style={this.props.style || {}} />
            </Whisper>
        )
    }

    setSortOrder = (value) => {
        var curOrder = this.state.sortOrder
        var newOrder = curOrder==="ascending" ? "descending" : "ascending"
        this.setState({
            sortOrder: newOrder
        })
    }
    setSortField = (value) => {
        this.setState({
            sortField: value
        })
    }

    submit = () => {
        this.props.setSortParams(this.state.sortOrder, this.state.sortField)
        this.close()
    }
    close = () => {
        this.triggerRef.current.close()
    }
    cancel = () => {
        this.setState({
            sortOrder: this.props.sortOrder,
            sortField: this.props.sortField,
        })
    }

    popover = (sortOrder, sortIcon) => {
        return (
            <Popover>
                <div className="flow-vertical" style={{width: "200px"}}>
                    <div className="flow-horizontal" style={{alignItems: "baseline"}}>
                        <div style={{fontWeight: "bold", fontSize: "16px"}}>Sort</div>
                        <div className="fill-parent"></div>
                        {sortIcon && <FontAwesomeIcon icon={sortIcon} style={{marginRight: "5px"}} />}
                        <div onClick={this.setSortOrder} style={{flexShrink: 0, cursor: "pointer"}}>{sortOrder}</div>
                    </div>
                    <RadioGroup name="radioList" defaultValue={this.state.sortField}>
                        <Radio value="availability" onChange={this.setSortField}>Availability</Radio>
                        <Radio value="duration" onChange={this.setSortField}>Duration</Radio>
                        <Radio value="start" onChange={this.setSortField}>Start</Radio>
                    </RadioGroup>
                </div>
                <Button onClick={this.submit}>Submit</Button>
            </Popover>
        );
    }

}

export default SummarySortMenu